import React from 'react'
import { Document, Image, Page } from '@react-pdf/renderer'
import { renderToStaticMarkup } from 'react-dom/server'
import Html from 'react-pdf-html'
import CCLogo from '../../../Images/Branding/cc-combination-blue.png'
import { Event, Registrant } from '../../../swagger'
import RegistrantsPDF from './RegistrantsPDF'
import { styles } from './styles'

const RegistrantsReportPDF: React.FC<{
  registrants: Registrant[]
  event: Event
}> = ({ registrants, event }) => {
  return (
    <Document title="Registrants">
      <Page size="LETTER" style={styles.page}>
        <Image style={styles.logo} src={CCLogo} />
        <Html>
          {renderToStaticMarkup(
            <RegistrantsPDF registrants={registrants} event={event} />
          )}
        </Html>
      </Page>
    </Document>
  )
}

export default RegistrantsReportPDF

/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  FeeType,
  FeeTypeFromJSON,
  FeeTypeToJSON,
  ParentRegion,
  ParentRegionFromJSON,
  ParentRegionToJSON,
  ProgramType,
  ProgramTypeFromJSON,
  ProgramTypeToJSON,
  ReceiverRole,
  ReceiverRoleFromJSON,
  ReceiverRoleToJSON,
  RegionManager,
  RegionManagerFromJSON,
  RegionManagerToJSON,
} from './'

/**
 *
 * @export
 * @interface RegionOptions
 */
export interface RegionOptions {
  /**
   * A list of regional managers. These are different from the Regional Directors.
   * @type {Array<RegionManager>}
   * @memberof RegionOptions
   */
  regionManagers: Array<RegionManager>
  /**
   * The potential types that a program can have.
   * @type {Array<ProgramType>}
   * @memberof RegionOptions
   */
  programTypes: Array<ProgramType>
  /**
   * A list of all fee types.
   * @type {Array<FeeType>}
   * @memberof RegionOptions
   */
  feeTypes: Array<FeeType>
  /**
   * A list of roles to choose from that will be able to receive an agreement from a specified sender role within the region. The sender roles are any roles within the system and can be retrieved using the fetchRoles endpoint.
   * @type {Array<ReceiverRole>}
   * @memberof RegionOptions
   */
  receiverRoles: Array<ReceiverRole>
  /**
   * A list of regions to select as a parent region. An admin can select from any region to be a parent region. Any other role with create or edit Region grant will only be able to select a region that was originally created by them.
   * @type {Array<ParentRegion>}
   * @memberof RegionOptions
   */
  parentRegions: Array<ParentRegion>
}

export function RegionOptionsFromJSON(json: any): RegionOptions {
  return RegionOptionsFromJSONTyped(json, false)
}

export function RegionOptionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RegionOptions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    regionManagers: (json['regionManagers'] as Array<any>).map(
      RegionManagerFromJSON
    ),
    programTypes: (json['programTypes'] as Array<any>).map(ProgramTypeFromJSON),
    feeTypes: (json['feeTypes'] as Array<any>).map(FeeTypeFromJSON),
    receiverRoles: (json['receiverRoles'] as Array<any>).map(
      ReceiverRoleFromJSON
    ),
    parentRegions: (json['parentRegions'] as Array<any>).map(
      ParentRegionFromJSON
    ),
  }
}

export function RegionOptionsToJSON(value?: RegionOptions | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    regionManagers: (value.regionManagers as Array<any>).map(
      RegionManagerToJSON
    ),
    programTypes: (value.programTypes as Array<any>).map(ProgramTypeToJSON),
    feeTypes: (value.feeTypes as Array<any>).map(FeeTypeToJSON),
    receiverRoles: (value.receiverRoles as Array<any>).map(ReceiverRoleToJSON),
    parentRegions: (value.parentRegions as Array<any>).map(ParentRegionToJSON),
  }
}

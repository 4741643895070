/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 * The usual username and password pairing.
 * @export
 * @interface Credentials
 */
export interface Credentials {
  /**
   * Usernames are always an email address. Further, they should not be an `@classicalconversations.com` address.
   * @type {string}
   * @memberof Credentials
   */
  username: string
  /**
   * Passwords should be at least 8 characters long.
   * @type {string}
   * @memberof Credentials
   */
  password: string
  /**
   * Unique identifier for the actorKey the user will act as in this session. If not provided, the user will be logged in as their last chosen session actorKey, if available. If the user does not have an existing actor, user will be logged in with parent privileges. Any requested actor must be an actor that belongs to the user and is currently valid.
   * @type {number}
   * @memberof Credentials
   */
  actorKey?: number
}

export function CredentialsFromJSON(json: any): Credentials {
  return CredentialsFromJSONTyped(json, false)
}

export function CredentialsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Credentials {
  if (json === undefined || json === null) {
    return json
  }
  return {
    username: json['username'],
    password: json['password'],
    actorKey: !exists(json, 'actorKey') ? undefined : json['actorKey'],
  }
}

export function CredentialsToJSON(value?: Credentials | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    username: value.username,
    password: value.password,
    actorKey: value.actorKey,
  }
}

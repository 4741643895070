/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  ActorSelectOption,
  ActorSelectOptionFromJSON,
  ActorSelectOptionToJSON,
  ProgramCommunityOption,
  ProgramCommunityOptionFromJSON,
  ProgramCommunityOptionToJSON,
  ProgramType,
  ProgramTypeFromJSON,
  ProgramTypeToJSON,
} from './'

/**
 *
 * @export
 * @interface ProgramOptions
 */
export interface ProgramOptions {
  /**
   * A list of possible communities that this program can belong to.
   * @type {Array<ProgramCommunityOption>}
   * @memberof ProgramOptions
   */
  communities: Array<ProgramCommunityOption>
  /**
   * The potential types that a program can have.
   * @type {Array<ProgramType>}
   * @memberof ProgramOptions
   */
  programTypes: Array<ProgramType>
  /**
   * DEPRECATED: moved to the response of fetchCommunitySpecificProgramOptions
   * @type {Array<ActorSelectOption>}
   * @memberof ProgramOptions
   */
  directors?: Array<ActorSelectOption>
  /**
   * DEPRECATED: moved to the response of fetchCommunitySpecificProgramOptions
   * @type {Array<ActorSelectOption>}
   * @memberof ProgramOptions
   */
  tutors?: Array<ActorSelectOption>
}

export function ProgramOptionsFromJSON(json: any): ProgramOptions {
  return ProgramOptionsFromJSONTyped(json, false)
}

export function ProgramOptionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProgramOptions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    communities: (json['communities'] as Array<any>).map(
      ProgramCommunityOptionFromJSON
    ),
    programTypes: (json['programTypes'] as Array<any>).map(ProgramTypeFromJSON),
    directors: !exists(json, 'directors')
      ? undefined
      : (json['directors'] as Array<any>).map(ActorSelectOptionFromJSON),
    tutors: !exists(json, 'tutors')
      ? undefined
      : (json['tutors'] as Array<any>).map(ActorSelectOptionFromJSON),
  }
}

export function ProgramOptionsToJSON(value?: ProgramOptions | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    communities: (value.communities as Array<any>).map(
      ProgramCommunityOptionToJSON
    ),
    programTypes: (value.programTypes as Array<any>).map(ProgramTypeToJSON),
    directors:
      value.directors === undefined
        ? undefined
        : (value.directors as Array<any>).map(ActorSelectOptionToJSON),
    tutors:
      value.tutors === undefined
        ? undefined
        : (value.tutors as Array<any>).map(ActorSelectOptionToJSON),
  }
}

import React, { ReactNode, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import TableFooterPagination from '../../Pagination/TableFooterPagination'
import OutlinedButton, {
  OutlinedButtonVariant,
} from '../../Buttons/OutlinedButton'
import { useTranslation } from 'react-i18next'
import TableHeaders from '../../Interfaces/TableHeaders'
import TableHeader from '../../Table/TableHeader'
import { Box, useTheme } from '@mui/material'
import TextButton, { TextButtonVariant } from '../../Buttons/TextButton'
import ViewBillingInfoModal from '../../Families/ViewBillingInfoModal'
import { reinterpretYearMonthDayAsLocalTime } from '../../../utils/reinterpretYearMonthDayAsLocalTime'
import EnrollmentFeeInfoModal from '../../Modals/EnrollmentFeeInfoModal'
import type { TFunction } from 'i18next'
import CardFormHeader from '../../Card/CardFormHeader'
import AmountDue from './AmountDue'
import Header, { HeaderVariant } from '../../Elements/Header'
import TransactionModal from '../../Modals/TransactionModal'
import { Payment } from '../../Interfaces/Payment'
import { getCommaSeparatedList } from '../../../utils/getCommaSeparatedList'
import getLocaleCurrencyForAmount from '../../../utils/getLocaleCurrencyForAmount'
import ProgramOption from '../../Interfaces/ProgramOption'
import {
  dateToSlashString,
  dateToSlashStringReinterpretedAsLocal,
} from '../../../utils/dateUtility'
import { styled } from '@mui/system'
import { PaymentStatusKey } from '../../../swagger'

const StyledTableContainer = styled(TableContainer)({
  paddingLeft: 0,
})

const StyledTable = styled(Table)({
  paddingLeft: 0,
})

export enum BillingHistoryType {
  Invoice = 'Invoice',
  Payment = 'Payment',
}

export enum BillingHistorySummaryTableVariant {
  /**
   * Shows a Program Director their history of `Licensing Payments` made to CC Home Office.
   *
   * * Visible when logged in as a Program Director
   * * Table located in UI under Account > Billing as "Licensing Payment History"
   */
  LicensingPayments = 'licensingPayments',
  /**
   * Shows a CSR/Admin a director's history of `Licensing Payments` made to CC Home Office.
   *
   * * Visible when logged in as a Program Director
   * * Table located in UI under Account > Billing as "Licensing Payment History"
   */
  LicensingPaymentsCSR = 'licensingPaymentsCSR',
  /**
   * Shows a Parent their history of `Enrollment Payments` made to a Program Director.
   *
   * * Visible when logged in as a Parent
   * * Table located in UI under Account > Billing as "Transactions"
   */
  EnrollmentPayments = 'enrollmentPayments',
  /**
   * Shows a CSR/Admin a parent's history of `Enrollment Payments` made to a Program Director.
   *
   * * Visible when logged in as a CSR
   * * Table located in UI under User Accounts > User Details as "Enrollment Payment Transactions"
   */
  EnrollmentPaymentsCSR = 'enrollmentPaymentsCSR',
}

interface Transaction {
  paymentMethod: string
  date: Date
  status: string
  amount: string
  orderId: string
  parentId: number
  studentNames?: string[]
}

interface BillingHistorySummaryTableProps {
  /**
   * Variant of the BillingHistorySummaryTable to be rendered. Determines the
   * fields, headers, and more.
   * @type BillingHistorySummaryTableVariant
   */
  variant: BillingHistorySummaryTableVariant
  /**
   * optional aria-labelledby tag provided to the table to be labelled by a
   * header in another component.
   * @type string
   */
  ariaLabelledBy?: string
  /**
   * List of payments made to be displayed within the rendered table. If there
   * is no history, 'No previous transactions' is displayed
   * @type Payment Array
   */
  paymentHistory: Payment[]
  /**
   * The amount due to display above the table.
   * @type number
   */
  amountDue: number
  /**
   * User key provided to add and edit transaction modals. Required when making
   * edits to payments or starting a new payment.
   * @type number
   */
  userKey?: number
  /**
   * Available programs when adding or editing a transaction for enrollments
   * @type ProgramOption Array
   */
  enrollmentProgramOptions?: ProgramOption[]
  /**
   * Available programs when adding or editing a transaction for licensing
   * @type ProgramOption Array
   */
  licensingProgramOptions?: ProgramOption[]
  /**
   * A callback method to update the billing history when a change occurs
   * @type function
   */
  triggerRefresh?: () => void
  /**
   * Determines whether a user has permission to see information pertaining to adding and editing payments
   * Defaults to false
   * @type boolean
   */
  hasPermissionToFetchPaymentOptions?: boolean
  /**
   * Detailed licensing history
   * @type Payment[]
   */
  licensingPaymentHistory?: Payment[]
}

export const getHeadersForVariant = (
  variant: BillingHistorySummaryTableVariant,
  t: TFunction
): TableHeaders[] => {
  const dateHeader: TableHeaders = {
    label: t('BillingHistorySummaryTable.Date.Header', 'Date'),
    align: 'left',
  }
  const amountHeader: TableHeaders = {
    label: t('BillingHistorySummaryTable.Amount.Header', 'Amount'),
    align: 'right',
  }
  const emptyHeader: TableHeaders = {
    label: t('BillingHistorySummaryTable.Empty.Header', ''),
    align: 'right',
  }
  const communityNameHeader: TableHeaders = {
    label: t(
      'BillingHistorySummaryTable.CommunityName.Header',
      'Community Name'
    ),
    align: 'left',
  }
  const communityKeyHeader: TableHeaders = {
    label: t('BillingHistorySummaryTable.CommunityKey.Header', 'Community Id'),
    align: 'left',
  }
  const programHeader: TableHeaders = {
    label: t('BillingHistorySummaryTable.Program.Header', 'Program'),
    align: 'left',
  }
  const descriptionHeader: TableHeaders = {
    label: t('BillingHistorySummaryTable.Header.Description', 'Description'),
    align: 'left',
  }
  const paymentMethodHeader: TableHeaders = {
    label: t(
      'BillingHistorySummaryTable.Header.PaymentMethod',
      'Payment Method'
    ),
    align: 'left',
  }
  const acumaticaOrderNumberHeader: TableHeaders = {
    label: t(
      'BillingHistorySummaryTable.Header.AcumaticaOrderNumber',
      'Acumatica Order #'
    ),
    align: 'left',
  }
  const statusHeader: TableHeaders = {
    label: t('BillingHistorySummaryTable.Header.Status', 'Status'),
    align: 'right',
  }

  switch (variant) {
    case BillingHistorySummaryTableVariant.LicensingPayments:
      return [
        dateHeader,
        descriptionHeader,
        communityNameHeader,
        communityKeyHeader,
        programHeader,
        paymentMethodHeader,
        acumaticaOrderNumberHeader,
        amountHeader,
        statusHeader,
      ]
    case BillingHistorySummaryTableVariant.LicensingPaymentsCSR:
      return [
        dateHeader,
        descriptionHeader,
        communityNameHeader,
        communityKeyHeader,
        programHeader,
        paymentMethodHeader,
        acumaticaOrderNumberHeader,
        amountHeader,
        statusHeader,
        emptyHeader,
      ]
    case BillingHistorySummaryTableVariant.EnrollmentPayments:
    case BillingHistorySummaryTableVariant.EnrollmentPaymentsCSR:
    default:
      return [
        dateHeader,
        descriptionHeader,
        programHeader,
        paymentMethodHeader,
        amountHeader,
        statusHeader,
        emptyHeader,
      ]
  }
}

export const generateKeyForProgramOptionsMap = (
  program: number,
  studentKey: number
): string => {
  return `${program}-${studentKey}`
}

const BillingHistorySummaryTable: React.FC<BillingHistorySummaryTableProps> = ({
  variant,
  ariaLabelledBy,
  paymentHistory,
  amountDue,
  licensingProgramOptions,
  enrollmentProgramOptions,
  userKey,
  triggerRefresh,
  hasPermissionToFetchPaymentOptions,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const isLicensingVariant = variant.includes('licensing')

  /** State Objects */
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [currencyCode] = useState('USD')
  const [billingHistoryViewModalOpen, setBillingHistoryViewModalOpen] =
    useState(false)
  const [isEnrollmentFeeInfoModalOpen, setIsEnrollmentFeeInfoModalOpen] =
    useState(false)
  // TODO: Make this value dynamic (See CCP1-2487)
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false)

  const [transaction, setTransaction] = useState<Transaction>({
    paymentMethod: '',
    date: new Date(),
    status: '',
    amount: '',
    orderId: '',
    parentId: -1,
    studentNames: [''],
  })

  const [selectedPayment, setSelectedPayment] = useState<Payment | undefined>()
  const [isEditClicked, setIsEditClicked] = useState(false)
  const [selectedPrograms, setSelectedPrograms] = useState<ProgramOption[]>([])

  /** Methods */

  const handleOnClose = () => {
    setSelectedPayment(undefined)
    setSelectedPrograms([])
    setIsEditClicked(false)
    setIsTransactionModalOpen((isOpen) => !isOpen)
    triggerRefresh?.()
  }

  const billingHistoryViewModalOnClose = () => {
    setBillingHistoryViewModalOpen(false)
  }

  const onCloseEnrollmentFeeInfoModal = () => {
    setIsEnrollmentFeeInfoModalOpen(false)
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleMoreInfo = (
    paymentMethod: string,
    date: Date,
    status: string,
    amount: string,
    orderId: string,
    parentKey: number,
    studentNames: string[]
  ) => {
    setTransaction({
      paymentMethod,
      date,
      status,
      amount,
      orderId,
      parentId: parentKey,
      studentNames,
    })
    setBillingHistoryViewModalOpen(true)
  }

  const handleEdit = (payment: Payment) => {
    setSelectedPayment(payment)
    setIsEditClicked((isClicked) => !isClicked)
    setSelectedPrograms(
      isLicensingVariant
        ? licensingProgramOptions?.filter(
            (licensingProgramOption) =>
              payment.paymentsMade[0].programKey ===
              licensingProgramOption.programKey
          ) ?? []
        : enrollmentProgramOptions?.filter((enrollmentProgramOption) => {
            return payment.paymentsMade.some((paymentMade) => {
              return (
                paymentMade.programKey === enrollmentProgramOption.programKey &&
                paymentMade.studentKey === enrollmentProgramOption.studentKey
              )
            })
          }) ?? []
    )
    setIsTransactionModalOpen((isOpen) => !isOpen)
  }

  const AdditionalTableHeader = (
    variant: BillingHistorySummaryTableVariant
  ) => {
    switch (variant) {
      case BillingHistorySummaryTableVariant.EnrollmentPaymentsCSR:
        return (
          <CardFormHeader
            header={
              <Box>
                <Header
                  id="enrollmentPaymentTransactionsTableHeader"
                  headerName={t(
                    'EnrollmentPaymentTransactionsCard.Header.MembershipPaymentTransactions',
                    'Membership Payment Transactions'
                  )}
                  component="h3"
                  variant={HeaderVariant.Card}
                />
                <AmountDue amountDue={amountDue} currencyCode={currencyCode} />
              </Box>
            }
            buttons={
              hasPermissionToFetchPaymentOptions && (
                <Box mr={3} mt={3}>
                  <OutlinedButton
                    id="addTransaction"
                    variant={OutlinedButtonVariant.AddTransaction}
                    onClick={() =>
                      setIsTransactionModalOpen((isOpen) => !isOpen)
                    }
                  />
                </Box>
              )
            }
            headerContainerProps={{ marginLeft: 0 }}
          />
        )
      case BillingHistorySummaryTableVariant.LicensingPaymentsCSR:
        return (
          <Box>
            <CardFormHeader
              header={
                <Box>
                  <Header
                    id="licensingPaymentTransactionsTableHeader"
                    headerName={t(
                      'LicensingPaymentTransactionsCard.Header.LicensingPaymentTransactions',
                      'Licensing Payment History'
                    )}
                    component="h3"
                    variant={HeaderVariant.Card}
                  />
                  <AmountDue
                    amountDue={amountDue}
                    currencyCode={currencyCode}
                  />
                </Box>
              }
              buttons={
                hasPermissionToFetchPaymentOptions ? (
                  <Box mr={3} mt={3}>
                    <OutlinedButton
                      id="addLicensingTransaction"
                      variant={OutlinedButtonVariant.AddTransaction}
                      onClick={() =>
                        setIsTransactionModalOpen((isOpen) => !isOpen)
                      }
                    />
                  </Box>
                ) : null
              }
              headerContainerProps={{ marginLeft: 0 }}
            />
          </Box>
        )
      case BillingHistorySummaryTableVariant.LicensingPayments:
      default:
        return null
    }
  }
  const TableCellsForVariant = (
    variant: BillingHistorySummaryTableVariant,
    record: Payment
  ) => {
    const DateCells = (
      <React.Fragment key={`date-${record.paymentKey}`}>
        <TableCell
          color={theme.palette.primary.main}
          scope="row"
          variant="head"
          key={`date-${record.paymentKey}`}
        >
          <Typography variant="body1" component="p">
            {dateToSlashString(record.begunAt)}
          </Typography>
        </TableCell>
      </React.Fragment>
    )

    const programs = variant.includes('licensing')
      ? /**
         * If we're showing licensing details for a payment made for a given program,
         * use the licensing payment provided to show ProgramType (SemesterOneStartDate)
         */
        `${record.licensingPayment?.programType ?? ''} (${
          record.licensingPayment?.semesterOneStartDate
            ? dateToSlashStringReinterpretedAsLocal(
                record.licensingPayment?.semesterOneStartDate as Date
              )
            : ''
        })`
      : /**
         * Otherwise, we're showing the enrollments as a comma separated list.
         */
        getCommaSeparatedList(
          record.enrollmentPayments?.map(
            (it) =>
              `${it.studentFirstName} - ${it.programType ?? ''} (${
                it.semesterOneStartDate
                  ? dateToSlashStringReinterpretedAsLocal(
                      it.semesterOneStartDate
                    )
                  : ''
              })`
          ) ?? []
        )

    const CommunityNameCell = (
      <TableCell
        style={{
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          maxWidth: '300',
        }}
        color={theme.palette.primary.main}
        align="left"
        key={`communityName-${record.paymentKey}`}
      >
        <Typography variant="body1" component="p">
          {record.licensingPayment?.communityName ?? ''}
        </Typography>
      </TableCell>
    )

    const CommunityKeyCell = (
      <TableCell
        color={theme.palette.primary.main}
        align="left"
        key={`communityKey-${record.paymentKey}`}
      >
        <Typography variant="body1" component="p">
          {record.licensingPayment?.communityKey ?? ''}
        </Typography>
      </TableCell>
    )

    const ProgramCell = (
      <TableCell
        color={theme.palette.primary.main}
        align="left"
        key={`program-${record.paymentKey}`}
      >
        <Typography variant="body1" component="p">
          {programs}
        </Typography>
      </TableCell>
    )

    const totalPayment = record.total?.amount
      ? record.total.amount
      : record.amount.amount + record.tax.amount

    const AmountCell = (
      <TableCell
        color={theme.palette.primary.main}
        align="right"
        key={`amount-${record.paymentKey}`}
      >
        <Typography variant="body1" component="p">
          {getLocaleCurrencyForAmount(totalPayment, record.amount.currencyCode)}
        </Typography>
      </TableCell>
    )

    const DescriptionCell = (
      <TableCell key={`Description-${record.paymentKey}`}>
        <Typography variant="body1" component="p">
          {`${record.desc ? record.desc : '-'}`}
        </Typography>
      </TableCell>
    )

    const StatusCell = (
      <TableCell
        color={theme.palette.primary.main}
        align="right"
        key={`status-${record.paymentKey}`}
      >
        <Typography variant="body1" component="p">
          {record.paymentStatusKey}
        </Typography>
      </TableCell>
    )

    const PaymentMethodCell = (
      <TableCell
        color={theme.palette.primary.main}
        align="left"
        key={`paymentMethod-${record.paymentKey}`}
      >
        <Typography variant="body1" component="p">
          {record.paymentMethodKey}
        </Typography>
      </TableCell>
    )

    const AcumaticaOrderCell = (
      <TableCell
        color={theme.palette.primary.main}
        align="left"
        key={`orderNumber-${record.paymentKey}`}
      >
        <Typography variant="body1" component="p">
          {record.orderId}
        </Typography>
      </TableCell>
    )

    const PaymentButton = (
      <TableCell key={`moreInfo-${record.paymentKey}`} align="right">
        <TextButton
          id={
            variant === BillingHistorySummaryTableVariant.EnrollmentPayments
              ? 'moreInfo'
              : 'edit'
          }
          variant={
            variant === BillingHistorySummaryTableVariant.EnrollmentPayments
              ? TextButtonVariant.MoreInfo
              : TextButtonVariant.Edit
          }
          onClick={
            variant === BillingHistorySummaryTableVariant.EnrollmentPayments
              ? () =>
                  handleMoreInfo(
                    record.paymentMethodKey,
                    record.begunAt,
                    record.paymentStatusKey,
                    record.total?.amount.toString() ?? '',
                    record.orderId ?? '',
                    record.userKey,
                    record.enrollmentPayments?.map(
                      (it) => it?.studentFirstName as string
                    ) ?? []
                  )
              : () => handleEdit(record)
          }
        />
      </TableCell>
    )

    let allCells: ReactNode[]

    switch (variant) {
      case BillingHistorySummaryTableVariant.LicensingPayments:
        allCells = [
          DateCells,
          DescriptionCell,
          CommunityNameCell,
          CommunityKeyCell,
          ProgramCell,
          PaymentMethodCell,
          AcumaticaOrderCell,
          AmountCell,
          StatusCell,
        ]
        break
      case BillingHistorySummaryTableVariant.LicensingPaymentsCSR:
        allCells = [
          DateCells,
          DescriptionCell,
          CommunityNameCell,
          CommunityKeyCell,
          ProgramCell,
          PaymentMethodCell,
          AcumaticaOrderCell,
          AmountCell,
          StatusCell,
          PaymentButton,
        ]
        break
      case BillingHistorySummaryTableVariant.EnrollmentPayments:
      case BillingHistorySummaryTableVariant.EnrollmentPaymentsCSR:
      default:
        allCells = [
          DateCells,
          DescriptionCell,
          ProgramCell,
          PaymentMethodCell,
          AmountCell,
          StatusCell,
          PaymentButton,
        ]
    }
    return allCells
  }
  /** Other Objects */
  const tableHeaders: TableHeaders[] = getHeadersForVariant(variant, t)

  const tableRows = paymentHistory.map((record) => (
    <TableRow
      key={record.paymentKey}
      sx={
        record.paymentStatusKey !== PaymentStatusKey.Err
          ? {}
          : { backgroundColor: theme.palette.customBackground.error }
      }
    >
      {TableCellsForVariant(variant, record)}
    </TableRow>
  ))

  return (
    <>
      {/* 
        Either we clicked edit and await our selected payment information
        OR we have clicked Add Transaction and don't need to wait.
      */}
      {(isEditClicked || (!isEditClicked && isTransactionModalOpen)) && (
        <TransactionModal
          userKey={userKey}
          isLicensingVariant={isLicensingVariant}
          isOpen={isTransactionModalOpen}
          onClose={handleOnClose}
          programOptions={
            isLicensingVariant
              ? licensingProgramOptions ?? []
              : enrollmentProgramOptions ?? []
          }
          payment={selectedPayment}
          selectedPrograms={selectedPrograms}
        />
      )}
      <ViewBillingInfoModal
        transactionType={transaction.paymentMethod}
        date={reinterpretYearMonthDayAsLocalTime(transaction.date)}
        status={transaction.status}
        amount={transaction.amount}
        orderId={transaction.orderId}
        students={transaction.studentNames ?? []}
        isOpen={billingHistoryViewModalOpen}
        onClose={billingHistoryViewModalOnClose}
      />
      <EnrollmentFeeInfoModal
        isOpen={isEnrollmentFeeInfoModalOpen}
        onClose={onCloseEnrollmentFeeInfoModal}
      />
      <Box margin={theme.spacing(2, 3, 0, 2)}>
        <StyledTableContainer>
          {AdditionalTableHeader(variant)}
          <StyledTable aria-labelledby={ariaLabelledBy}>
            <TableHead>
              <TableHeader tableHeaders={tableHeaders} />
            </TableHead>
            <TableBody>
              {paymentHistory.length > 0 ? (
                rowsPerPage > 0 ? (
                  tableRows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                ) : (
                  tableRows
                )
              ) : (
                <TableRow>
                  <TableCell>
                    <Typography
                      component="p"
                      variant="subtitle2"
                      color={theme.palette.textOrIcon.subheader}
                    >
                      {t(
                        'TransactionsTable.NoPreviousTransactions',
                        'No previous transactions'
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            {paymentHistory.length > rowsPerPage ? (
              <TableFooterPagination
                label={t('BillingHistorySummaryTable.ViewAll', 'All')}
                count={paymentHistory.length ?? 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                colSpan={tableHeaders.length}
                useDefaultPageSize={false}
              />
            ) : null}
          </StyledTable>
        </StyledTableContainer>
      </Box>
    </>
  )
}

export default BillingHistorySummaryTable

import { Box, IconButton, Typography } from '@mui/material'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { fetchBilling } from '../../../../api/payments'
import { extractedErrorObject } from '../../../../api/swagger'
import useLoadingContext from '../../../../hooks/useLoadingContext'
import { useLoadingIds } from '../../../../hooks/useLoadingIds'
import { useMountEffect } from '../../../../hooks/useMountEffect'
import {
  FetchBillingRequest,
  LicensingBill,
  UserAccountDetails,
} from '../../../../swagger'
import { useUser } from '../../../../UserContext'
import { LoadingContext } from '../../../Context/LoadingContext'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../../../Context/SnackbarContext'
import LoadingProgress from '../../../Elements/LoadingProgress'
import { PdfViewerSkeleton } from '../../../Skeleton/PdfViewerSkeleton'
import { LicensingBillingPDF } from './LicensingBillingPDF'
import EmptyPage from '../../../Elements/EmptyPage'
import PdfIcon from '@mui/icons-material/PictureAsPdf'
import fullName from '../../../../utils/fullName'

type BillingPDFPreviewProps = {
  userAccount?: UserAccountDetails
}

export const BillingPDFPreview: React.FunctionComponent<
  BillingPDFPreviewProps
> = (props) => {
  const { userAccount } = props
  const { actorKey, userKey } = useParams()
  const { t } = useTranslation()
  const { user: userDetails } = useUser()
  const { loadingIds, addLoadingIds } = useContext(LoadingContext)
  const {
    BillingTab: { fetchBilling: fetchBillingLoadingId },
  } = useLoadingIds()
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()
  const [licensingBills, setLicensingBills] = useState<LicensingBill[]>([])
  const [hasFetchingError, setHasFetchingError] = useState(false)

  const userAgent = navigator.userAgent.toLowerCase()
  const isMobileBrowser =
    /iphone|ipad|ipod|android|blackberry|windows phone|ipad|tablet|playbook|silk/g.test(
      userAgent
    )

  const fetchBillingHistory = async () => {
    try {
      const { licensing } = await fetchBilling({
        actorKey: userKey ? undefined : actorKey,
        userKey: userKey ?? undefined,
      } as FetchBillingRequest)

      if (!!licensing) {
        if (!!licensing.programs && licensing.programs.length > 0) {
          setLicensingBills(licensing.programs)
        }
      }
    } catch (e) {
      setHasFetchingError(true)
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message:
          (e as unknown as Error).message ??
          t(
            'Billing.PDFPreview.FetchBilling.Error',
            'An unknown error occurred fetching billing history.'
          ),
      }
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarState(true)
    }
  }

  useLoadingContext({
    asyncFunction: fetchBillingHistory,
    loadingId: fetchBillingLoadingId,
  })

  useMountEffect(() => {
    addLoadingIds([fetchBillingLoadingId])
  })

  const userName = userKey ? fullName(userAccount) : fullName(userDetails)

  if (loadingIds.size > 0 || !userDetails || (!!userKey && !userAccount)) {
    return <LoadingProgress />
  }

  if (hasFetchingError) {
    return (
      <EmptyPage
        message={
          <Typography variant="subtitle1" component="p">
            {t(
              'Billing.PDF.Preview.ErrorMessage',
              'Could not load PDF report, please try again later.'
            )}
          </Typography>
        }
      />
    )
  }

  if (licensingBills && licensingBills.length === 0) {
    return (
      <EmptyPage
        message={
          <Typography variant="subtitle1" component="p">
            {t(
              'Billing.PDF.Preview.NoBillingFoundMessage',
              'No billing found.'
            )}
          </Typography>
        }
      />
    )
  }

  return (
    <Box display="flex" flexDirection={'column'} height={'70vh'}>
      <Box position="relative" width="100%" height="100%">
        <Box position="absolute" width="100%" height="70%" top={0} zIndex={1}>
          {!isMobileBrowser && <PdfViewerSkeleton />}
        </Box>
        <Box position="absolute" width="100%" height="100%" top={0} zIndex={2}>
          {isMobileBrowser && (
            <Box
              justifyContent="center"
              alignItems="center"
              display="flex"
              flexDirection="column"
              textAlign="center"
            >
              <Typography>
                {t(
                  'Billing.PDF.Preview.PreviewNotAvailableMessage',
                  'Preview not available on mobile devices, please download the PDF clicking the button below.'
                )}
              </Typography>
              <PDFDownloadLink
                document={
                  <LicensingBillingPDF
                    licensingBills={licensingBills}
                    fullName={userName}
                  />
                }
                fileName="billing.pdf"
              >
                <IconButton color="success">
                  <PdfIcon />
                  <Typography>
                    {t('Billing.PDF.Preview.DownloadPDF', 'Download PDF')}
                  </Typography>
                </IconButton>
              </PDFDownloadLink>
            </Box>
          )}
          <PDFViewer style={{ flex: 1, width: '100%', height: '100%' }}>
            <LicensingBillingPDF
              licensingBills={licensingBills}
              fullName={userName}
            />
          </PDFViewer>
        </Box>
      </Box>
    </Box>
  )
}

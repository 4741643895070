import React, { useEffect, useState } from 'react'
import {
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import {
  LicensingInternationalPayment,
  OrderByDirection,
  PaginationResponse,
} from '../swagger'
import { dateToSlashString } from '../utils/dateUtility'
import useLoadingContext from './useLoadingContext'
import getOrderByClause from '../utils/getOrderByClause'
import { extractedErrorObject, paymentsApi } from '../api/swagger'
import { DEFAULT_PAGE_SIZE } from '../utils/constants'
import { useLoadingIds } from './useLoadingIds'
import { ActionableTableColumn } from '../components/Table/ActionableTable'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../components/Context/SnackbarContext'

export type UseCountryCoordinatorLicensingPaymentHistoryReturn = {
  columns: ActionableTableColumn[]
  handlePagination: (_pagination: PaginationResponse) => void
  pagination: PaginationResponse
  rows: LicensingInternationalPayment[]
  noResultsMessage: string
  isLoading: boolean
}

export type UseCountryCoordinatorLicensingPaymentHistoryProps =
  | {
      userKey: number
    }
  | {
      actorKey: number
    }

export const useCountryCoordinatorLicensingPaymentHistory = (
  props: UseCountryCoordinatorLicensingPaymentHistoryProps
): UseCountryCoordinatorLicensingPaymentHistoryReturn => {
  const { t } = useTranslation()

  const isAccountingView = 'userKey' in props

  const key = isAccountingView ? 'userKey' : 'actorKey'
  const keyValue = isAccountingView ? props.userKey : props.actorKey

  const {
    CountryCoordinatorLicensingPaymentHistory: {
      fetchLicensingInternationalPayments:
        fetchLicensingInternationalPaymentsLoadingId,
    },
  } = useLoadingIds()

  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()

  const [paymentHistory, setPaymentHistory] = useState<
    LicensingInternationalPayment[]
  >([])

  const [pagination, setPagination] = useState<PaginationResponse>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: 0,
    orderBy: [{ begunAt: OrderByDirection.Desc }],
  })

  const noResultsMessage = t(
    'Hooks.CountryCoordinatorLicensingPaymentHistory.NoTransactionHistory',
    'No transaction history'
  )

  const errorMessage = t(
    'Hooks.CountryCoordinatorLicensingPaymentHistory.Error',
    'An error occurred while attempting to load the payment history'
  )

  const fetchPaymentHistory = async () => {
    try {
      const data = await paymentsApi.fetchLicensingInternationalPayments({
        [key]: keyValue,
        page: pagination.page,
        pageSize: pagination.pageSize,
        orderBy: getOrderByClause(pagination.orderBy),
      })

      setPaymentHistory(
        data.internationalPayments?.map((payment) => {
          return {
            ...payment,
            roleNameRegionName: `Country Coordinator - ${payment.regionName}`,
          }
        }) || []
      )
      setPagination((prev) => {
        return {
          ...prev,
          totalCount: data.pagination?.totalCount ?? 0,
        }
      })
    } catch (error) {
      const errorObject = (await extractedErrorObject(error)) ?? {
        code: 'Unknown',
        message: (error as unknown as Error).message ?? errorMessage,
      }
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarState(true)
    }
  }

  const { triggerFetch, isLoading } = useLoadingContext({
    asyncFunction: fetchPaymentHistory,
    loadingId: fetchLicensingInternationalPaymentsLoadingId,
  })

  useEffect(() => {
    triggerFetch()
  }, [pagination.page, pagination.pageSize, triggerFetch])

  const commonColumns: ActionableTableColumn[] = [
    {
      fieldName: 'begunAt',
      columnHeaderName: t(
        'CountryCoordinatorLicensingPaymentHistory.Table.Header.Date',
        'Date'
      ),
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        return dateToSlashString(params.value)
      },
    },
    {
      fieldName: 'desc',
      columnHeaderName: t(
        'CountryCoordinatorLicensingPaymentHistory.Table.Header.Description',
        'Description'
      ),
      minWidth: 200,
    },
    {
      fieldName: 'paymentMethodKey',
      columnHeaderName: t(
        'CountryCoordinatorLicensingPaymentHistory.Table.Header.PaymentMethod',
        'Payment Method'
      ),
      align: 'right',
      headerAlign: 'right',
    },
    {
      fieldName: 'orderId',
      columnHeaderName: t(
        'CountryCoordinatorLicensingPaymentHistory.Table.Header.OrderId',
        'Acumatica Order #'
      ),
      align: 'right',
      headerAlign: 'right',
    },
    {
      fieldName: 'amount',
      columnHeaderName: t(
        'CountryCoordinatorLicensingPaymentHistory.Table.Header.Amount',
        'Amount'
      ),
      align: 'right',
      headerAlign: 'right',
      renderCell: ({
        row,
      }: GridRenderCellParams<LicensingInternationalPayment>) => {
        return (
          <div className="MuiDataGrid-cellContent">
            {`${row.amount.amount.toFixed(2)}`}
          </div>
        )
      },
    },
    {
      fieldName: 'paymentStatusKey',
      columnHeaderName: t(
        'CountryCoordinatorLicensingPaymentHistory.Table.Header.Status',
        'Status'
      ),
      align: 'right',
      headerAlign: 'right',
    },
  ]

  const regionHeader = {
    fieldName: 'roleNameRegionName',
    columnHeaderName: t(
      'CountryCoordinatorLicensingPaymentHistory.Table.Header.roleNameRegionName',
      'Role'
    ),
    align: 'left',
    headerAlign: 'left',
    minWidth: 300,
  } as ActionableTableColumn

  const columns: ActionableTableColumn[] = isAccountingView
    ? [...commonColumns, regionHeader]
    : commonColumns

  const handlePagination = (_pagination: PaginationResponse) => {
    if (pagination.page < 1) return
    setPagination({
      ..._pagination,
      page: _pagination.page + 1,
      pageSize: _pagination.pageSize,
    })
  }

  return {
    columns,
    handlePagination,
    pagination,
    rows: paymentHistory,
    noResultsMessage,
    isLoading,
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  FetchMetricsResponseBodyMetrics,
  FetchMetricsResponseBodyMetricsFromJSON,
  FetchMetricsResponseBodyMetricsToJSON,
} from './'

/**
 *
 * @export
 * @interface FetchMetricsResponseBody
 */
export interface FetchMetricsResponseBody {
  /**
   *
   * @type {Array<FetchMetricsResponseBodyMetrics>}
   * @memberof FetchMetricsResponseBody
   */
  metrics: Array<FetchMetricsResponseBodyMetrics>
}

export function FetchMetricsResponseBodyFromJSON(
  json: any
): FetchMetricsResponseBody {
  return FetchMetricsResponseBodyFromJSONTyped(json, false)
}

export function FetchMetricsResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FetchMetricsResponseBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    metrics: (json['metrics'] as Array<any>).map(
      FetchMetricsResponseBodyMetricsFromJSON
    ),
  }
}

export function FetchMetricsResponseBodyToJSON(
  value?: FetchMetricsResponseBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    metrics: (value.metrics as Array<any>).map(
      FetchMetricsResponseBodyMetricsToJSON
    ),
  }
}

import React from 'react'
import ConfirmWithButtonsModal from './ConfirmWithButtonsModal'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import { useTranslation } from 'react-i18next'

interface ConfirmForParchmentModalProps {
  isOpen: boolean
  onConfirm: () => void
  onCancel: () => void
  title?: string
  question?: string
}

const ConfirmForParchmentModal: React.FC<ConfirmForParchmentModalProps> = ({
  isOpen,
  onConfirm,
  onCancel,
  title,
  question
}) => {
  const { t } = useTranslation()

  return (
    <ConfirmWithButtonsModal
      isOpen={isOpen}
      title={title ?? t(
        'Transcripts.Modal.ConfirmForParchment.Title',
        'Request Send to Parchment'
      )}
      question={question ?? t(
        'Transcripts.Modal.ConfirmForParchment.Question',
        'Are you sure you want to send your transcript to parchment?'
      )}
      confirmVariant={ContainedButtonVariant.YesImSure}
      cancelVariant={TextButtonVariant.NoCancel}
      onConfirm={onConfirm}
      onCancel={onCancel}
      questionProps={{
        textAlign: 'center',
      }}
    />
  )
}

export default ConfirmForParchmentModal

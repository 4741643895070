/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * an array containing objects with each programKey and the number of previously sent invitations
 * @export
 * @interface ValidateInvitationFamilyResponse
 */
export interface ValidateInvitationFamilyResponse {
  /**
   * describe if one of the programs has previous invitations
   * @type {boolean}
   * @memberof ValidateInvitationFamilyResponse
   */
  hasPreviousInvitations: boolean
}

export function ValidateInvitationFamilyResponseFromJSON(
  json: any
): ValidateInvitationFamilyResponse {
  return ValidateInvitationFamilyResponseFromJSONTyped(json, false)
}

export function ValidateInvitationFamilyResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ValidateInvitationFamilyResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    hasPreviousInvitations: json['hasPreviousInvitations'],
  }
}

export function ValidateInvitationFamilyResponseToJSON(
  value?: ValidateInvitationFamilyResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    hasPreviousInvitations: value.hasPreviousInvitations,
  }
}

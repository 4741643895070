/**
 * Check if the userAgent is a mobile browser
 * 
 * @param {string} userAgent - The user agent string to check
 * default value is navigator.userAgent.toLowerCase()
 * 
 * We need this verification to show a message to the user
 * informing the PDF preview is not available on mobile devices
 * due to the PDFViewer component not being supported on mobile browsers.
 * 
 * @returns {boolean} - True if the userAgent is a mobile browser, false otherwise
 */
export const isMobileBrowser = (
  userAgent = navigator.userAgent.toLowerCase()
): boolean => {
  return /iphone|ipad|ipod|android|blackberry|windows phone|ipad|tablet|playbook|silk/g.test(
    userAgent
  )
}

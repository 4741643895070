import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { contentApi } from '../../../api/swagger'
import { useErrorSnackbar } from '../../../hooks/useErrorSnackbar'
import useLoadingContext from '../../../hooks/useLoadingContext'
import { FetchLearningCenterContentProgramTypeEnum } from '../../../swagger'
import {
  FilterFileType,
  LearningCenterTabs,
} from '../../../utils/searchAndFilterEnums'
import {
  LearningCenterCategories,
  useLearningCenterContext,
} from '../../Context/LearningCenterContext'
import { useAuth } from '../../Routes/AuthProvider'

export const useLearningCenterContent = (options: {
  /** If not provided, don't call the endpoint. */
  programType?: FetchLearningCenterContentProgramTypeEnum
  /** If not greater than 0, don't call the endpoint */
}): {
  loadingId: string
} => {
  const { programType } = options
  const { t } = useTranslation()
  const { handleError } = useErrorSnackbar()
  const { featureAbility } = useAuth()
  const {
    searchQuery,
    updateAssets,
    contentLoadingId: loadingId,
    contentType: filetype,
    level,
    selectedTabKey,
    category,
    cycle,
    includeOnlyFavorites,
    welcomeCenterContent,
  } = useLearningCenterContext()

  const isChallengeTab = selectedTabKey.includes(LearningCenterTabs.Challenge)
  const fetchLearningCenterContent = async () => {
    if (
      (level === undefined && !welcomeCenterContent && isChallengeTab) ||
      (cycle === undefined && !isChallengeTab && !welcomeCenterContent)
    )
      return

    try {
      const { assets } = await contentApi.fetchLearningCenterContent({
        /**
         * If we're here, this is defined. We prevent calling the endpoint if
         * undefined, unless the level is defined and we're on the Challenge tab.
         */
        programType: (isChallengeTab
          ? level
          : programType) as FetchLearningCenterContentProgramTypeEnum,
        searchQuery: searchQuery ?? undefined,
        filetype: filetype?.includes(FilterFileType.AllTypes)
          ? undefined
          : filetype,
        category: category?.includes(LearningCenterCategories.AllCategories)
          ? undefined
          : category,
        cycle,
        onlyIncludeFavorites: includeOnlyFavorites,
        welcomeCenterContent,
      })
      updateAssets(assets)
    } catch (e) {
      await handleError(
        e,
        t(
          'Endpoints.FetchLearningCenterContent.Error',
          'An unknown error occurred fetching learning center content.'
        )
      )
    }
  }

  const { triggerFetch } = useLoadingContext({
    loadingId,
    asyncFunction: fetchLearningCenterContent,
  })

  useEffect(() => {
    /** Do call the endpoint
     * - if we do have a programType and we aren't on the Challenge tab,
     * - or we do have a challenge level and are on the Challenge tab,
     * - or we don't have a programType and are fetching welcomeCenterContent
     * - if we can access the feature,
     * */
    if (
      ((!!programType && !isChallengeTab) ||
        (!!level && isChallengeTab) ||
        welcomeCenterContent) &&
      featureAbility.can('learningCenter', 'Feature')
    ) {
      triggerFetch()
    }
  }, [
    featureAbility,
    isChallengeTab,
    level,
    loadingId,
    programType,
    welcomeCenterContent,
    cycle,
    triggerFetch,
  ])

  return {
    loadingId,
  }
}

import { Box, SxProps, Typography, useTheme } from '@mui/material'
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid'
import React, { CSSProperties, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderByDirection, PaginationResponse } from '../../swagger'
import Header from '../Elements/Header'
import { DEFAULT_PAGE_SIZE } from '../../utils/constants'
import { toPascalCase } from '../../utils/stringUtility'
import RowActions, { Action, rowActionsTourStepsType } from './RowActions'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { DataGridPropsWithoutDefaultValue } from '@mui/x-data-grid/models/props/DataGridProps'
import { ActionableTableSkeleton } from '../Skeleton/ActionableTableSkeleton'

const DEFAULT_COLUMN_WIDTH = 150

export type ActionableTableColumn = Pick<
  GridColDef,
  | 'align'
  | 'flex'
  | 'headerAlign'
  | 'minWidth'
  | 'sortable'
  | 'valueFormatter'
  | 'width'
  | 'sortingOrder'
  | 'cellClassName'
  | 'description'
  | 'headerClassName'
  | 'groupable'
  | 'renderCell'
  | 'renderHeader'
  | 'valueGetter'
  | 'maxWidth'
> & {
  fieldName: string
  columnHeaderName?: string
}

export interface ActionableTableProps<T>
  extends Pick<
    DataGridPropsWithoutDefaultValue,
    'onRowSelectionModelChange' | 'rowSelectionModel' | 'isRowSelectable'
  > {
  columns: ActionableTableColumn[]
  noResultsMessage: string
  rows: Array<T>
  rowActions?: Action<T>[]
  pagination?: PaginationResponse
  title?: string
  handlePaginationChange?: (pagination: PaginationResponse) => void
  onCellClick?: (params: GridCellParams) => void
  onCompleteTour?: () => void
  isTourCompleted?: boolean
  tourClass?: string
  joyRideTourId?: string
  rowActionsTourSteps?: rowActionsTourStepsType[]
  scrollToFirstStep?: boolean
  /** Id of the label for this table */
  ariaLabelledBy?: string
  checkboxSelection?: boolean
  columnHeaderHeight?: number
  rowHeight?: number
  customCssTable?: CSSProperties | SxProps
  onRowClick?: GridEventListener<'rowClick'> | undefined
  isLoading?: boolean
}

export const ActionableTable = <T,>({
  columns,
  rows,
  noResultsMessage,
  title,
  rowActions,
  pagination = {
    pageSize: DEFAULT_PAGE_SIZE,
    page: 0,
    totalCount: 0,
    orderBy: [],
  },
  handlePaginationChange,
  onCellClick,
  onCompleteTour,
  isTourCompleted,
  tourClass,
  joyRideTourId,
  rowActionsTourSteps,
  scrollToFirstStep,
  ariaLabelledBy,
  checkboxSelection = false,
  rowSelectionModel,
  onRowSelectionModelChange,
  columnHeaderHeight,
  rowHeight,
  customCssTable,
  isRowSelectable,
  onRowClick,
  isLoading,
}: ActionableTableProps<T>): JSX.Element => {
  const { t } = useTranslation()
  const isDesktop = useShowOnDesktop()
  const actionsTitle = t(
    'Table.ActionableTable.ColumnHeader.Actions',
    'Actions'
  )
  const hidePagination = pagination.totalCount < pagination.pageSize
  const theme = useTheme()

  const handleSortModelChange = (model: GridSortModel) => {
    const orderBy = model.length
      ? [{ [model[0].field]: model[0].sort as OrderByDirection }]
      : []

    handlePaginationChange?.({
      ...pagination,
      orderBy,
    })
  }

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    handlePaginationChange?.({
      ...pagination,
      page: model.page,
      pageSize: model.pageSize,
    })
  }

  const cols: GridColDef[] = useMemo(() => {
    return columns.map((column) => ({
      ...column,
      field: column.fieldName,
      headerName: column.columnHeaderName ?? toPascalCase(column.fieldName),
      sortable: column.sortable || false,
      align: column.align || 'left',
      minWidth:
        column.minWidth || (isDesktop ? undefined : DEFAULT_COLUMN_WIDTH),
      width: column.width || undefined,
      flex: column.flex || 1,
      valueFormatter: column.valueFormatter || undefined,
      headerAlign: column.headerAlign || undefined,
      description: column.description,
      headerClassName: column.headerClassName ?? '',
    }))
  }, [columns, isDesktop])

  /**
   * Add the table unique identifier id for each row
   * on the array index, when the don't have an id property
   */
  const tableRows = rows.map((row, index) => {
    const { id = index, ...rest } = row as T & { id?: number }
    return {
      id,
      ...rest,
    }
  })

  /**
   * Add actions column to the end of the table
   */

  if (!!rowActions && rowActions.length) {
    cols.push({
      field: 'actions',
      headerName: actionsTitle,
      sortable: false,
      align: 'center',
      minWidth: isDesktop ? undefined : DEFAULT_COLUMN_WIDTH,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <RowActions
            actions={rowActions}
            row={params.row}
            rowId={params.row.id}
            onCompleteTour={() => {
              onCompleteTour && onCompleteTour()
            }}
            elementsCount={rows.length}
            isTourCompleted={isTourCompleted}
            joyRideTourId={joyRideTourId}
            rowActionsTourSteps={rowActionsTourSteps}
            tourClass={tourClass}
            scrollToFirstStep={scrollToFirstStep}
          />
        )
      },
    })
  }

  const pageSizeOptions = Array.from(
    new Set([pagination.pageSize, DEFAULT_PAGE_SIZE, 50, 100])
  ).sort((a, b) => a - b)

  if (isLoading) {
    return <ActionableTableSkeleton rows={DEFAULT_PAGE_SIZE} />
  }

  return (
    <>
      {title && <Header id={title} headerName={title} component="h3" />}
      <Box sx={{ width: '100%', background: 'white' }}>
        <DataGrid
          pageSizeOptions={pageSizeOptions}
          checkboxSelection={checkboxSelection}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={onRowSelectionModelChange}
          aria-labelledby={ariaLabelledBy}
          autoHeight={tableRows.length > 0}
          rows={tableRows}
          isRowSelectable={isRowSelectable}
          columns={cols}
          getRowHeight={() => rowHeight ?? 'auto'}
          rowCount={pagination.totalCount}
          paginationMode="server"
          paginationModel={pagination}
          hideFooter={hidePagination}
          hideFooterPagination={hidePagination}
          disableColumnMenu={true}
          disableRowSelectionOnClick={true}
          sortingMode="server"
          onPaginationModelChange={handlePaginationModelChange}
          onSortModelChange={handleSortModelChange}
          onCellClick={onCellClick}
          onRowClick={onRowClick}
          sx={{
            '.MuiDataGrid-checkboxInput': {
              color: theme.palette.borderColor.grey,
            },
            height: tableRows.length > 0 ? 'auto' : '25vh',
            ...customCssTable,
          }}
          slots={{
            noRowsOverlay: () => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <Box mt={1}>
                  <Typography
                    variant="subtitle2"
                    color={theme.palette.textOrIcon.subheader}
                    component="p"
                  >
                    {noResultsMessage}
                  </Typography>
                </Box>
              </Box>
            ),
          }}
          //used to display all columns in test mode
          disableVirtualization={process.env.NODE_ENV === 'test'}
          columnHeaderHeight={columnHeaderHeight ?? 56}
        />
      </Box>
    </>
  )
}

import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import { Box, useTheme } from '@mui/system'
import { useShowOnDesktop } from '../../../hooks/useShowOnDesktop'
import { UserRoleAssignment } from '../../../swagger'
import Header, { HeaderVariant } from '../../Elements/Header'
import CardFormHeader from '../../Card/CardFormHeader'
import SpaceBetweenSection from '../../Elements/SpaceBetweenSection'
import CoCoLicensingAccordionItem from './CoCoLicensingAccordionItem'

type AccountingViewCountryCoordinatorLicensingTableProps = {
  roles: Omit<UserRoleAssignment[], 'validTo' | 'validFrom'>
}

const AccountingViewCountryCoordinatorLicensingTable: React.FunctionComponent<
  AccountingViewCountryCoordinatorLicensingTableProps
> = ({ roles }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const showOnDesktop = useShowOnDesktop()

  return (
    <>
      <Box>
        <SpaceBetweenSection
          isCentered
          marginRight={2}
          marginLeft={2}
          left={
            <CardFormHeader
              header={
                <Box>
                  <Header
                    id="AccountingCountryCoordinatorLicensingTableHeader"
                    headerName={t(
                      'AccountingCountryCoordinatorLicensingTable.Header.Licensing',
                      'Licensing'
                    )}
                    component="h3"
                    variant={HeaderVariant.Card}
                  />
                </Box>
              }
              headerContainerProps={{ marginLeft: 0 }}
            />
          }
        />
      </Box>
      <Box
        sx={{
          flexWrap: 'wrap',
          ...(showOnDesktop && {
            justifyContent: 'center',
          }),
        }}
      >
        {roles.map((role) => {
          return (
            <Accordion
              key={role.actorKey}
              defaultExpanded={roles.length === 1}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                sx={{
                  margin: theme.spacing(2),
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-label={`${role.name} - ${role.actorKey} - ${role.region}`}
                id={`licensing-details-${role.actorKey}`}
              >
                <Typography variant="button" component="p">
                  {`${role.name} - ${role.actorKey} - ${role.region}`}
                </Typography>
              </AccordionSummary>

              <CoCoLicensingAccordionItem actorKey={role.actorKey} />
            </Accordion>
          )
        })}

        {roles.length > 1 && (
          <Divider
            sx={{ borderBottomWidth: '3px', padding: theme.spacing(2) }}
          />
        )}
      </Box>
    </>
  )
}

export default AccountingViewCountryCoordinatorLicensingTable

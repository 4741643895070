import React from 'react'
import { Address } from '../../swagger'
import { useTranslation } from 'react-i18next'
import AddressCard from './AddressCard'
import StudentInformationHeader from '../Transcripts/StudentInformationHeader'
import { useTranscriptContext } from '../Context/TranscriptContext'
import { Alert, Typography, useTheme } from '@mui/material'
import { ErrorIcon } from '../Alerts/ErrorAlert'

export interface StudentInformationCardProps {
  /** Passed for testing purposes primarily, though it is better to pass in using the context value, also. */
  address: Address
  /**
   * Determines if fields should be editable, and, if Edit button for addresses are enabled
   */
  isFieldDisabled?: boolean
  /** Determines whether to use the same address information as the school
   *
   * vs
   *
   * Using a different address.
   *
   * Behavior is as follows:
   * - If checked:
   *  - Disabled StudentAddress edit button
   * - If unchecked or Checked => Unchecked
   *  - Enabled StudentAddress edit button
   * - Unchecked => Checked
   *  - Keep initial transcriptDetails studentAddress (this is already done in the context)
   *  - Update the studentAddress to be the values of the schoolAddress.
   *  - Do not update the address keys for either address
   *
   * We cannot validate if Checked => Unchecked has a delta because
   * all manner of combinations could lead to modifying the address
   * which are NOT Unchecked => Checked.
   */
  isSameAddress: boolean
}

export const studentInformationCardFieldProps: {
  [k: string]: { [k: string]: string }
} = {
  studentAddress: {
    headerId: 'student-address-header',
    buttonId: 'edit-student-address',
  },
}

export const StudentInformationCard: React.FC<StudentInformationCardProps> = ({
  address,
  isFieldDisabled,
  isSameAddress,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const {
    updateTranscriptDetails,
    transcriptDetails,
    transcriptValidity,
    updateFormValidity,
  } = useTranscriptContext()

  const handleSameAddress = (value: boolean) => {
    let studentAddress = transcriptDetails.studentAddress
    /** If the values aren't the same */
    if (transcriptDetails.studentAddressSameAsSchool !== value) {
      /** And we are going from unchecked to checked  */
      if (value) {
        /** Then get the transcriptDetails.schoolAddress and set that, too. */
        studentAddress = transcriptDetails.schoolAddress
      }
    }
    updateTranscriptDetails({
      ...transcriptDetails,
      studentAddress,
      studentAddressSameAsSchool: value,
    })
  }

  const onAddressConfirm = (newAddress: Address) => {
    updateFormValidity({
      ...transcriptValidity,
      studentAddress: { input: true },
    })
    updateTranscriptDetails({
      ...transcriptDetails,
      studentAddress: newAddress,
    })
  }

  return (
    <AddressCard
      address={address}
      headerProps={{
        id: studentInformationCardFieldProps.studentAddress.headerId,
        headerName: t(
          'Transcripts.StudentInformationCard.Header.StudentAddress',
          'Student Address *'
        ),
      }}
      isEditMode={!isFieldDisabled && !isSameAddress}
      /**
       * Address validity is reset on the confirmation of the address
       */
      onAddressConfirm={onAddressConfirm}
      buttonLabel={studentInformationCardFieldProps.studentAddress.buttonId}
      skipModalAddressReset
      tooltipTitle={
        t('StudentInforamtionCard.AddressCard.Tooltip.Title',
          'Edit Student Address'
        )
      }
      addressMessage={
        !transcriptValidity.studentAddress.input ? (
          <Alert
            sx={{
              color: theme.palette.error.main,
            }}
            elevation={6}
            severity={'error'}
            icon={<ErrorIcon />}
          >
            <Typography variant="subtitle2" component="p">
              {t(
                'Transcripts.StudentInformationCard.Error.StudentAddress',
                'Student Address is required.'
              )}
            </Typography>
          </Alert>
        ) : isSameAddress ? (
          t(
            'Transcripts.StudentInformationCard.AddressMessage',
            'Uncheck Student Address same as School Address to modify'
          )
        ) : undefined
      }
      formHeading={
        <StudentInformationHeader
          isFieldDisabled={isFieldDisabled}
          isSameAddress={isSameAddress}
          handleSameAddress={handleSameAddress}
        />
      }
    />
  )
}

export default StudentInformationCard

/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Role, RoleFromJSON, RoleToJSON } from '../models'
import * as runtime from '../runtime'

export interface CreateRoleRequest extends runtime.BaseRequestParameters {
  body?: Role
}

export interface DeleteRoleRequest extends runtime.BaseRequestParameters {
  roleKey: number
}

export interface UpdateRoleRequest extends runtime.BaseRequestParameters {
  body?: Role
}

/**
 *
 */
export class RolesApi extends runtime.BaseAPI {
  /**
   * A user with an active grant to create a Role is able to create a new role. A role is group of grants i.e. a Role of Support Representative can create / edit / view their team\'s communities.
   * Create a new role with grants
   */
  async createRoleRaw(
    requestParameters: CreateRoleRequest
  ): Promise<runtime.ApiResponse<Role>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/roles`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: RoleToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RoleFromJSON(jsonValue)
    )
  }

  /**
   * A user with an active grant to create a Role is able to create a new role. A role is group of grants i.e. a Role of Support Representative can create / edit / view their team\'s communities.
   * Create a new role with grants
   */
  async createRole(requestParameters: CreateRoleRequest): Promise<Role> {
    const response = await this.createRoleRaw(requestParameters)
    return await response.value()
  }

  /**
   * A user with an active grant to DELETE a Role is able to delete the role along with all its grants.
   * Delete a role along with the grants.
   */
  async deleteRoleRaw(
    requestParameters: DeleteRoleRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.roleKey === null ||
      requestParameters.roleKey === undefined
    ) {
      throw new runtime.RequiredError(
        'roleKey',
        'Required parameter requestParameters.roleKey was null or undefined when calling deleteRole.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/roles/{roleKey}`.replace(
        `{${'roleKey'}}`,
        encodeURIComponent(String(requestParameters.roleKey))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * A user with an active grant to DELETE a Role is able to delete the role along with all its grants.
   * Delete a role along with the grants.
   */
  async deleteRole(requestParameters: DeleteRoleRequest): Promise<void> {
    await this.deleteRoleRaw(requestParameters)
  }

  /**
   * A user with an active grant to update a Role is able to modify the grants on the existing role.
   * Update the grants on an existing role.
   */
  async updateRoleRaw(
    requestParameters: UpdateRoleRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/roles`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: RoleToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * A user with an active grant to update a Role is able to modify the grants on the existing role.
   * Update the grants on an existing role.
   */
  async updateRole(requestParameters: UpdateRoleRequest): Promise<void> {
    await this.updateRoleRaw(requestParameters)
  }
}

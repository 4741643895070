import React, { PropsWithChildren, useRef, useState } from 'react'
import {
  TranscriptOverview,
  Transcript,
  CreateTranscript,
  GradingScale,
} from '../../swagger'
import { Breadcrumb } from '../Elements/DynamicBreadcrumbs'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { FieldValidity } from '../Interfaces/FieldValidity'

export type TranscriptDetails = Partial<Transcript & CreateTranscript>

interface TranscriptFormValidity {
  transcriptName: FieldValidity
  studentKey: FieldValidity
  schoolAddress: FieldValidity
  studentAddress: FieldValidity
  studentEmail: FieldValidity
  dateGraduation: FieldValidity
  dateOfBirth: FieldValidity
  schoolName: FieldValidity
}

export const defaultTranscriptContextValue = {
  transcripts: [] as TranscriptOverview[],
  updateTranscripts: (newTranscripts: TranscriptOverview[]): void => {
    console.warn(
      `Running the default updateTranscripts(${newTranscripts}). Did you forget to provide a TranscriptProvider?`
    )
  },
  breadcrumbs: [{ label: 'Transcripts' }] as Breadcrumb[],
  updateBreadcrumbs: (stage: TranscriptFlowStages): void => {
    console.warn(
      `The TranscriptContext.updateBreadcrumbs was called with value ${stage}. Did you forget to use a TranscriptContextProvider?`
    )
  },

  resetContextToDefaults: (): void => {
    console.warn(
      'The TranscriptContext.resetContextToDefaults was called. Did you forget to use a TranscriptProvider?'
    )
  },
  selectedTranscriptKey: undefined as number | undefined,
  updateSelectedTranscriptKey: (selectedKey: number | undefined): void => {
    console.warn(
      `The method TranscriptContext.updateTranscriptKey(${selectedKey}) was called. Did you forget to use a TranscriptProvider?`
    )
  },
  transcriptDetails: {
    transcriptName: '',
    schoolName: '',
    gradingScaleKey: undefined,
    studentKey: -1,
    studentEmail: '',
    student: {
      /**
       * Leaving this empty causes problems in studentKeyToStudentIdForTranscript,
       * but we've handled the empty case there.
       */
    },
  } as TranscriptDetails,
  updateTranscriptDetails: (newDetails: TranscriptDetails): void => {
    console.warn(
      `The method TranscriptContext.updateTranscriptDetails(${newDetails}) was called. Did you forget to use a TranscriptProvider?`
    )
  },
  /** Used to update the initial details to reset when cancelling an edit */
  updateInitialTranscriptDetails: (
    newInitialDetails: TranscriptDetails
  ): void => {
    console.warn(
      `The method TranscriptContext.updateInitialTranscriptDetails(${newInitialDetails}) was called. Did you forget to use a TranscriptProvider?`
    )
  },
  cancelTranscriptEdit: (): void => {
    console.warn(
      'The TranscriptContext.cancelTranscriptEdit was called. Did you forget to use a TranscriptProvider?'
    )
  },

  gradingScales: [] as GradingScale[],
  updateGradingScales: (gradingScales: GradingScale[]): void => {
    console.warn(
      `The TranscriptContext.updateGradingScales(${gradingScales}) was called. Did you forget to use a TranscriptProvider?`
    )
  },

  findGradingScale: (gradingScaleKey: number): GradingScale => {
    console.warn(
      `The TranscriptContext.findGradingScale(${gradingScaleKey}) was called. Did you forget to use a TranscriptProvider?`
    )
    return {
      gradingScaleKey: -1,
    }
  },

  transcriptValidity: {
    transcriptName: {
      input: true,
    },
    /** Whether in the StudentForTranscript or CreateTranscript object */
    studentKey: {
      input: true,
    },
    /**
     * Only validates the existence of the field as the validity
     * of the address is handled in AddressCard/Modal
     */
    schoolAddress: {
      input: true,
    },
    studentAddress: {
      input: true,
    },
    studentEmail: {
      input: true,
      email: true,
    },
    dateGraduation: {
      input: true,
    },
    dateOfBirth: {
      input: true,
    },
    schoolName: { input: true },
  } as TranscriptFormValidity,
  updateFormValidity: (validity: TranscriptFormValidity): void => {
    console.warn(
      `The TranscriptContext.updateFormValidity(${validity}) was called. Did you forget to use a TranscriptProvider?`
    )
  },
}

export const TranscriptContext = React.createContext(
  defaultTranscriptContextValue
)

export const useTranscriptContext = (): typeof defaultTranscriptContextValue =>
  React.useContext(TranscriptContext)

export type TestTranscriptConfig = Partial<typeof defaultTranscriptContextValue>

export interface TestTranscriptContextProps extends PropsWithChildren {
  testConfig?: TestTranscriptConfig
}

export enum TranscriptFlowStages {
  Transcripts = 'transcripts',
  TranscriptDetails = 'transcriptDetails',
  AddTranscript = 'addTranscripts',
  Print = 'print',
}

export const TranscriptProvider: React.FC<TestTranscriptContextProps> = ({
  testConfig,
  children,
}) => {
  const [transcripts, setTranscripts] = useState(
    defaultTranscriptContextValue.transcripts
  )

  const updateTranscripts = (newTranscripts: TranscriptOverview[]) => {
    setTranscripts(newTranscripts)
  }

  const [gradingScales, setGradingScales] = useState(
    defaultTranscriptContextValue.gradingScales
  )

  const updateGradingScales = (gradingScales: GradingScale[]) => {
    setGradingScales(gradingScales)
  }

  const [selectedTranscriptKey, setSelectedTranscriptKey] =
    useState<typeof defaultTranscriptContextValue.selectedTranscriptKey>()

  const updateSelectedTranscriptKey = (selectedKey: number | undefined) => {
    setSelectedTranscriptKey(selectedKey)
  }

  /** Use this to reset cancelling an edit! */
  const initialTranscriptDetails = useRef(
    defaultTranscriptContextValue.transcriptDetails
  )

  /**
   * The detail object will be empty for new transcripts and load
   * in TranscriptDetails the selected transcript by fetchTranscript (by key)
   */
  const [transcriptDetails, setTranscriptDetails] = useState(
    initialTranscriptDetails.current
  )

  const updateTranscriptDetails = (newDetails: TranscriptDetails) => {
    setTranscriptDetails(newDetails)
  }

  const updateInitialTranscriptDetails = (
    newInitialDetails: TranscriptDetails
  ) => {
    initialTranscriptDetails.current = newInitialDetails
  }

  const cancelTranscriptEdit = () => {
    setTranscriptDetails(initialTranscriptDetails.current)
    updateFormValidity(defaultTranscriptContextValue.transcriptValidity)
  }

  const resetContextToDefaults = (): void => {
    // This should use different setter methods to reset the context
    // to default, check LearningCenterContext.tsx for example
    updateTranscripts(defaultTranscriptContextValue.transcripts)
    updateGradingScales(defaultTranscriptContextValue.gradingScales)
    // Generally we want to have default values as what we update to, but we also
    // want the translation object to be used when displaying the breadcrumb
    updateBreadcrumbs(TranscriptFlowStages.Transcripts)
    updateTranscriptDetails(defaultTranscriptContextValue.transcriptDetails)
    updateSelectedTranscriptKey(
      defaultTranscriptContextValue.selectedTranscriptKey
    )
    /** Although we don't expose the initial details, where we set the
     * transcriptDetails back to default, we ought to for the initials.
     * This way editing an existing transcript does not populate when
     * adding a new transcript.
     */
    updateInitialTranscriptDetails(
      defaultTranscriptContextValue.transcriptDetails
    )
    updateFormValidity(defaultTranscriptContextValue.transcriptValidity)
  }
  const { t } = useTranslation()
  const navigate = useNavigate()

  const getBreadcrumbsForStage = (stage: TranscriptFlowStages) => {
    const TranscriptsBreadcrumb = {
      label: t('Transcript.Transcripts.Breadcrumb', 'Transcripts'),
      onClick: () => {
        navigate({ pathname: '/transcripts/overview' })
      },
    }
    switch (stage) {
      case TranscriptFlowStages.Transcripts:
        return [
          {
            ...TranscriptsBreadcrumb,
            onClick: undefined,
          },
        ]
      case TranscriptFlowStages.AddTranscript:
        return [
          {
            ...TranscriptsBreadcrumb,
          },
          {
            label: t('Transcript.AddTranscript.Breadcrumb', 'Add Transcript'),
          },
        ]
      case TranscriptFlowStages.TranscriptDetails:
        return [
          {
            ...TranscriptsBreadcrumb,
          },
          {
            label: t(
              'Transcript.TranscriptDetails.Breadcrumb',
              'Transcript Details'
            ),
          },
        ]
      case TranscriptFlowStages.Print:
        return [
          {
            ...TranscriptsBreadcrumb,
          },
          {
            label: t('Transcript.TranscriptDetails.Breadcrumb', 'Transcript Details'),
            onClick: transcriptDetails.transcriptKey ? () => {
              navigate({ pathname: `transcripts/transcript-details/${transcriptDetails.transcriptKey}`})
            } : undefined
          },
          {
            label: t('Transcript.Print.Breadcrumb', 'Print'),
          },
        ]
    }
  }

  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([
    { label: t('Transcript.Transcripts.Breadcrumb', 'Transcripts') },
  ])

  const updateBreadcrumbs = (stage: TranscriptFlowStages) =>
    // !!!: This breaks if you add a stage but don't handle the case. Remember to add the case!
    setBreadcrumbs(getBreadcrumbsForStage(stage))

  const [transcriptValidity, setTranscriptValidity] = useState(
    defaultTranscriptContextValue.transcriptValidity
  )

  const updateFormValidity = (validity: TranscriptFormValidity) => {
    setTranscriptValidity(validity)
  }

  const findGradingScale = (gradingScaleKey: number): GradingScale => {
    return (
      gradingScales.find(
        (gradingScale) => gradingScale.gradingScaleKey === gradingScaleKey
      ) ?? gradingScales[0]
    )
  }

  const value = {
    transcripts,
    updateTranscripts,
    gradingScales,
    updateGradingScales,
    resetContextToDefaults,
    breadcrumbs,
    updateBreadcrumbs,
    selectedTranscriptKey,
    updateSelectedTranscriptKey,
    transcriptDetails,
    updateTranscriptDetails,
    updateInitialTranscriptDetails,
    cancelTranscriptEdit,
    transcriptValidity,
    updateFormValidity,
    findGradingScale,
    ...testConfig,
  } as typeof defaultTranscriptContextValue

  return (
    <TranscriptContext.Provider value={value}>
      {children}
    </TranscriptContext.Provider>
  )
}

export default { TranscriptProvider }

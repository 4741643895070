import { PropsWithChildren, useContext, useState } from 'react'
import { FetchTranscriptsRequestStatusEnum } from '../../swagger'
import React from 'react'

export interface FilterValues {
  studentKey: number | undefined
  requestStatus: FetchTranscriptsRequestStatusEnum | undefined
}

export const defaultFilterContextValue = {
  fetchTranscriptsParams: {
    studentKey: undefined,
    requestStatus: undefined,
  } as FilterValues,

  setFetchTranscriptParams: (filter: FilterValues): void => {
    console.warn(
      `The FilterContext.setFetchTranscriptParams(${JSON.stringify(
        filter
      )}) was called. Did you forget to use a FilterProvider?`
    )
  },

  resetContextToDefaults: (): void => {
    console.warn(
      'The FilterContext.resetContextToDefaults was called. Did you forget to use a FilterProvider?'
    )
  },
}

export const FilterContext = React.createContext(defaultFilterContextValue)

export const useFilterContext = (): typeof defaultFilterContextValue =>
  useContext(FilterContext)

export type TestFilterConfig = typeof defaultFilterContextValue

export interface TestFilterConfigProps extends PropsWithChildren {
  testConfig?: Partial<TestFilterConfig>
}

export const FilterProvider: React.FC<TestFilterConfigProps> = ({
  testConfig,
  children,
}) => {
  const [fetchTranscriptsParams, setFetchTranscriptParams] =
    useState<FilterValues>({
      studentKey: undefined,
      requestStatus: undefined,
    })

  const resetContextToDefaults = (): void => {
    setFetchTranscriptParams(defaultFilterContextValue.fetchTranscriptsParams)
  }

  const value = {
    fetchTranscriptsParams,
    setFetchTranscriptParams,
    resetContextToDefaults,
    ...testConfig,
  } as typeof defaultFilterContextValue

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  )
}

export default FilterProvider

/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  ProgramFeeStructure,
  ProgramFeeStructureFromJSON,
  ProgramFeeStructureToJSON,
} from './'

/**
 * The various fees associated with the chosen community and region.
 * @export
 * @interface CommunitySpecificProgramOptionsFees
 */
export interface CommunitySpecificProgramOptionsFees {
  /**
   * The percentage of fees that are given to the tutor defined on the community.
   * @type {number}
   * @memberof CommunitySpecificProgramOptionsFees
   */
  communityFeePercentage?: number
  /**
   * The fee structures available in the community's region for each program type.
   * @type {Array<ProgramFeeStructure>}
   * @memberof CommunitySpecificProgramOptionsFees
   */
  programFeeStructures: Array<ProgramFeeStructure>
}

export function CommunitySpecificProgramOptionsFeesFromJSON(
  json: any
): CommunitySpecificProgramOptionsFees {
  return CommunitySpecificProgramOptionsFeesFromJSONTyped(json, false)
}

export function CommunitySpecificProgramOptionsFeesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommunitySpecificProgramOptionsFees {
  if (json === undefined || json === null) {
    return json
  }
  return {
    communityFeePercentage: !exists(json, 'communityFeePercentage')
      ? undefined
      : json['communityFeePercentage'],
    programFeeStructures: (json['programFeeStructures'] as Array<any>).map(
      ProgramFeeStructureFromJSON
    ),
  }
}

export function CommunitySpecificProgramOptionsFeesToJSON(
  value?: CommunitySpecificProgramOptionsFees | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    communityFeePercentage: value.communityFeePercentage,
    programFeeStructures: (value.programFeeStructures as Array<any>).map(
      ProgramFeeStructureToJSON
    ),
  }
}

import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Breadcrumb } from '../Elements/DynamicBreadcrumbs'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Event, OrderByDirection, PaginationResponse } from '../../swagger'
import { events, extractedErrorObject, Registrant } from '../../api/swagger'
import { EventFormCardVariants } from '../Events/EventEnums'
import { SnackbarSeverity, useSnackbarContext } from './SnackbarContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import useLoadingContext from '../../hooks/useLoadingContext'
import { DEFAULT_PAGE_SIZE } from '../../utils/constants'
import getOrderByClause from '../../utils/getOrderByClause'

const warnDeveloper = <T,>(propertyName?: T) => {
  const placeHold =
    propertyName === undefined ? '.' : ` with the value ${propertyName}.`
  console.warn(
    `The EventContext.resetContextToDefaults was called${placeHold} Did you forget to use a EventProvider?`
  )
}

export const defaultEventContextValue = {
  breadcrumbs: [{ label: 'Events' }] as Breadcrumb[],

  updateBreadcrumbs: (eventName?: string): void => {
    warnDeveloper(eventName)
  },

  eventDetails: undefined as Event | undefined,

  updateEventDetails: (eventDetails?: Event): void => {
    warnDeveloper(eventDetails)
  },

  resetContextToDefaults: (): void => {
    warnDeveloper()
  },

  registrants: undefined as Registrant[] | undefined,

  updateEventRegistrants: (eventKey: number): void => {
    warnDeveloper(eventKey)
  },

  updateEventName: (name: string): void => {
    warnDeveloper(name)
  },

  hasRegistrants: false,

  variant: EventFormCardVariants.CreateEvent,

  updateVariant: (variant: EventFormCardVariants): void => {
    warnDeveloper(variant)
  },

  updateEventKey: (eventKey: number): void => {
    warnDeveloper(eventKey)
  },
  isLoadingEventDetails: false,

  eventKey: undefined as number | undefined,

  capacity: undefined as number | undefined,

  refetchRegistrant: (): void => {
    warnDeveloper()
  },

  handleRegistrantPagination: (pagination: PaginationResponse): void => {
    warnDeveloper(pagination)
  },
  pagination: {
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: 0,
    orderBy: [{ firstName: OrderByDirection.Asc }],
  } as PaginationResponse,
}

export const EventContext = React.createContext(defaultEventContextValue)

export const useEventContext = (): typeof defaultEventContextValue =>
  React.useContext(EventContext)

export type TestEventConfig = Partial<typeof defaultEventContextValue>

export interface TestEventConfigWithProps extends PropsWithChildren {
  testConfig?: TestEventConfig
}

export const EventProvider: React.FC<TestEventConfigWithProps> = ({
  testConfig,
  children,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const errorMessageFetchRegistrants = t(
    'Events.EventFormCard.FetchRegistrants.ErrorMessage',
    'Something went wrong while loading event registrants. Please reload the page'
  )

  const failedToFetchEventMessage = t(
    'Events.EventDetails.FailedFetchEvent',
    'An unknown error occurred fetching event details.'
  )

  const {
    Events: {
      fetchEventRegistrants: fetchEventRegistrantsLoadingId,
      fetchEvent: fetchEventLoadingId,
    },
  } = useLoadingIds()

  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()
  const eventsTranslated = t('Event.Events', 'Events')
  const defaultBreadcrumbs = useRef([{ label: eventsTranslated }])

  const [eventKey, setEventKey] = useState<number>()
  const [pagination, setPagination] = useState<PaginationResponse>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: 0,
    orderBy: [{ firstName: OrderByDirection.Asc }],
  })

  const updateEventKey = (eventKey: number) => {
    setEventKey(eventKey)
  }

  const [variant, setVariant] = useState<EventFormCardVariants>(
    EventFormCardVariants.CreateEvent
  )

  const updateVariant = (newVariant: EventFormCardVariants) => {
    setVariant(newVariant)
  }

  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>(
    defaultBreadcrumbs.current
  )

  const updateBreadcrumbs = useCallback(
    (eventName?: string) => {
      setBreadcrumbs([
        {
          label: eventsTranslated,
          onClick: () => {
            navigate({ pathname: '/events' })
          },
        },
        {
          label: eventName,
        },
      ])
    },
    [eventsTranslated, navigate]
  )

  const [registrants, setRegistrants] = useState<Registrant[]>()

  const fetchRegistrants = async () => {
    if (!!eventKey) {
      try {
        const fetchedRegistrants = await events.fetchEventRegistrants({
          eventKey: eventKey ?? 0,
          page: pagination.page,
          pageSize: pagination.pageSize,
          orderBy: getOrderByClause(pagination.orderBy),
        })
        setRegistrants(fetchedRegistrants.data)
        setPagination((prev) => {
          return {
            ...prev,
            totalCount: fetchedRegistrants.pagination?.totalCount ?? 0,
          }
        })
      } catch (e) {
        const errorObject = (await extractedErrorObject(e)) ?? {
          code: 'Unknown Code',
          message:
            (e as unknown as Error).message ?? errorMessageFetchRegistrants,
        }
        setSnackbarSeverity(SnackbarSeverity.Error)
        setSnackbarMessage(errorObject.message)
        setSnackbarState(true)
      }
    }
  }

  const refetchRegistrant = () => {
    fetchRegistrant()
  }

  const { triggerFetch: fetchRegistrant } = useLoadingContext({
    asyncFunction: fetchRegistrants,
    loadingId: fetchEventRegistrantsLoadingId,
  })

  const [eventDetails, setEventDetails] = useState<Event | undefined>(undefined)

  const fetchEventDetails = async () => {
    if (!!eventKey) {
      try {
        const fetchedEventDetails = await events.fetchEvent({
          eventKey: eventKey ?? 0,
        })

        updateEventDetails(fetchedEventDetails)
      } catch (error) {
        const errorObject = (await extractedErrorObject(error)) ?? {
          code: 'Unknown',
          message:
            (error as unknown as Error).message ?? failedToFetchEventMessage,
        }
        setSnackbarState?.(true)
        setSnackbarMessage?.(errorObject.message)
        setSnackbarSeverity?.(SnackbarSeverity.Error)
      }
    }
  }

  const { triggerFetch: fetchEventDetail, isLoading: isLoadingEventDetails } =
    useLoadingContext({
      asyncFunction: fetchEventDetails,
      loadingId: fetchEventLoadingId,
    })

  useEffect(() => {
    fetchRegistrant()
    fetchEventDetail()
  }, [fetchRegistrant, fetchEventDetail, eventKey])

  useEffect(() => {
    if (variant === EventFormCardVariants.CreateEvent) {
      updateEventDetails(undefined)
    }
  }, [variant])

  const updateEventDetails = (eventDetails?: Event): void => {
    setEventDetails(eventDetails)
  }

  const resetContextToDefaults = (): void => {
    setBreadcrumbs(defaultEventContextValue.breadcrumbs)
    setEventDetails(defaultEventContextValue.eventDetails)
    setEventKey(defaultEventContextValue.eventKey)
    setVariant(defaultEventContextValue.variant)
    setRegistrants(defaultEventContextValue.registrants)
    setPagination(defaultEventContextValue.pagination as PaginationResponse)
  }

  useEffect(() => {
    fetchRegistrant()
  }, [pagination.page, pagination.pageSize, fetchRegistrant])

  const handleRegistrantPagination = (_pagination: PaginationResponse) => {
    if (pagination.page < 1) return
    setPagination({
      ..._pagination,
      page: _pagination.page + 1,
      pageSize: _pagination.pageSize,
    })
  }

  const value = {
    breadcrumbs,
    pagination,
    handleRegistrantPagination,
    updateBreadcrumbs,
    eventDetails,
    updateEventDetails,
    resetContextToDefaults,
    registrants,
    hasRegistrants: !!registrants && registrants.length > 0,
    variant,
    eventKey,
    capacity: eventDetails?.capacity,
    updateEventKey,
    updateVariant,
    refetchRegistrant,
    isLoadingEventDetails,
    ...testConfig,
  } as typeof defaultEventContextValue

  return <EventContext.Provider value={value}>{children}</EventContext.Provider>
}

/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  EnrollmentStatus,
  EnrollmentStatusFromJSON,
  EnrollmentStatusToJSON,
  ProgramType,
  ProgramTypeFromJSON,
  ProgramTypeToJSON,
} from './'

/**
 *
 * @export
 * @interface Program3
 */
export interface Program3 {
  /**
   *
   * @type {number}
   * @memberof Program3
   */
  programKey: number
  /**
   * The name of the community the enrolled in program belongs
   * @type {string}
   * @memberof Program3
   */
  communityName: string
  /**
   *
   * @type {ProgramType}
   * @memberof Program3
   */
  programType: ProgramType
  /**
   *
   * @type {EnrollmentStatus}
   * @memberof Program3
   */
  enrollmentStatus: EnrollmentStatus
  /**
   *
   * @type {Array<EnrollmentStatus>}
   * @memberof Program3
   */
  enrollmentStatuses: Array<EnrollmentStatus>
  /**
   * The date the status of the program was acquired.
   * @type {Date}
   * @memberof Program3
   */
  enrollmentStatusValidFrom: Date
  /**
   *
   * @type {Date}
   * @memberof Program3
   */
  semesterOneStartDate: Date
}

export function Program3FromJSON(json: any): Program3 {
  return Program3FromJSONTyped(json, false)
}

export function Program3FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Program3 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    programKey: json['programKey'],
    communityName: json['communityName'],
    programType: ProgramTypeFromJSON(json['programType']),
    enrollmentStatus: EnrollmentStatusFromJSON(json['enrollmentStatus']),
    enrollmentStatuses: (json['enrollmentStatuses'] as Array<any>).map(
      EnrollmentStatusFromJSON
    ),
    enrollmentStatusValidFrom: new Date(json['enrollmentStatusValidFrom']),
    semesterOneStartDate: new Date(json['semesterOneStartDate']),
  }
}

export function Program3ToJSON(value?: Program3 | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    programKey: value.programKey,
    communityName: value.communityName,
    programType: ProgramTypeToJSON(value.programType),
    enrollmentStatus: EnrollmentStatusToJSON(value.enrollmentStatus),
    enrollmentStatuses: (value.enrollmentStatuses as Array<any>).map(
      EnrollmentStatusToJSON
    ),
    enrollmentStatusValidFrom: value.enrollmentStatusValidFrom.toISOString(),
    semesterOneStartDate: value.semesterOneStartDate
      .toISOString()
      .substr(0, 10),
  }
}

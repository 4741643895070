import React, { CSSProperties, useState } from 'react'
import { Box, Divider, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFeeLabelMap } from '../../../hooks/useFeeLabelMap'
import { useShowOnDesktop } from '../../../hooks/useShowOnDesktop'
import { FetchCountryCoordinatorLicensingDetailsResponse } from '../../../swagger'
import { dateToSlashStringReinterpretedAsLocal } from '../../../utils/dateUtility'
import getLocaleCurrencyForAmount from '../../../utils/getLocaleCurrencyForAmount'
import ContainedButton, {
  ContainedButtonVariant,
} from '../../Buttons/ContainedButton'
import OutlinedButton, {
  OutlinedButtonVariant,
} from '../../Buttons/OutlinedButton'
import CardFormHeader from '../../Card/CardFormHeader'
import Header, { HeaderVariant } from '../../Elements/Header'
import SpaceBetweenSection from '../../Elements/SpaceBetweenSection'
import TableHeaders from '../../Interfaces/TableHeaders'
import { Breakdown } from './Breakdown'
import {
  buildBreakdownLedgerRows,
  BreakdownLedgerContainer,
  BreakDownLedgerVariants,
  BreakdownLedgerRow,
} from './BreakdownLedger'
import { ActionableTableSkeleton } from '../../Skeleton/ActionableTableSkeleton'
import IntlCountryCoordinatorPaymentModal from '../../Modals/IntlCountryCoordinatorPaymentModal'

interface InternationalLicensingBreakdownProps {
  billingDetails: FetchCountryCoordinatorLicensingDetailsResponse
  hideLicensingDetails: () => void
  isAccountingView: boolean
  isLoading: boolean
  directorName: string
  programKey: number
  userKey: number
  fetchBillingLoadingId?: string
}

const MAX_BREAKDOWN_ROWS = 8

export const InternationalLicensingBreakdown: React.FC<
  InternationalLicensingBreakdownProps
> = (props) => {
  const {
    billingDetails,
    hideLicensingDetails,
    isAccountingView,
    isLoading,
    directorName,
    programKey,
    userKey,
    fetchBillingLoadingId,
  } = props

  const theme = useTheme()
  const { t } = useTranslation()
  const feeLabelMap = useFeeLabelMap(t)
  const showOnDesktop = useShowOnDesktop()

  const [
    isIntlCountryCoordinatorPaymentModalOpen,
    setIsIntlCountryCoordinatorPaymentModalOpen,
  ] = useState(false)

  const currencyCode = billingDetails.totalPaid?.currencyCode ?? ''

  const totalOwedByDirector = billingDetails.feeDetails
    .map(({ directorOwes }) => directorOwes.amount)
    .reduce((acc, curr) => acc + curr, 0)

  const totalOwedByCoco = billingDetails.feeDetails
    .map(({ coordinatorOwes }) => coordinatorOwes.amount)
    .reduce((acc, curr) => acc + curr, 0)

  const licensingLedgerRows = billingDetails.feeDetails.map((feeDetail) => {
    const feeName = feeLabelMap[feeDetail.feeType]

    const translationKey = `licensingLedgerRows.Row.${feeName}`

    const breakdownRows = {
      feeName: {
        content: t('{{translationKey}}', '{{feeName}}', {
          feeName,
          translationKey,
        }),
        colSpan: 1,
        align: 'left',
      } as BreakdownLedgerRow,
      studentCount: {
        content: `${feeDetail?.studentCount ?? ''}`,
        colSpan: 1,
        align: 'left',
      } as BreakdownLedgerRow,
      // Director Owes
      directorOwes: {
        content: `${getLocaleCurrencyForAmount(
          feeDetail?.directorOwes.amount ?? 0,
          currencyCode
        )}`,
        colSpan: 1,
        align: 'right',
      } as BreakdownLedgerRow,

      //Coco owes CC
      coordinatorOwes: {
        content: `${getLocaleCurrencyForAmount(
          feeDetail.coordinatorOwes.amount,
          currencyCode
        )}`,
        colSpan: 1,
        align: 'right',
      } as BreakdownLedgerRow,
    }

    return isAccountingView
      ? buildBreakdownLedgerRows(
          breakdownRows.feeName,
          breakdownRows.studentCount,
          breakdownRows.coordinatorOwes
        )
      : buildBreakdownLedgerRows(
          breakdownRows.feeName,
          breakdownRows.studentCount,
          breakdownRows.directorOwes,
          breakdownRows.coordinatorOwes
        )
  })

  const totalOwedRow = {
    totalOwed: {
      content: t('BreakdownLedger.Row.TotalOwed', 'Total Owed'),
      colSpan: 1,
      align: 'left',
    } as BreakdownLedgerRow,
    dash: {
      content: '-',
      colSpan: 1,
      align: 'left',
    } as BreakdownLedgerRow,
    // Director Owes To Country Coordinator
    directorOwes: {
      content: `${getLocaleCurrencyForAmount(
        totalOwedByDirector ?? 0,
        currencyCode
      )}`,
      colSpan: 1,
      align: 'right',
    } as BreakdownLedgerRow,
    // Country Coordinator Owes CC
    coordinatorOwesCC: {
      content: `${getLocaleCurrencyForAmount(
        totalOwedByCoco ?? 0,
        currencyCode
      )}`,
      colSpan: 1,
      align: 'right',
    } as BreakdownLedgerRow,
  }

  const totalOwedRowBasedOnAccountingView = isAccountingView
    ? buildBreakdownLedgerRows(
        totalOwedRow.totalOwed,
        totalOwedRow.dash,
        totalOwedRow.coordinatorOwesCC
      )
    : buildBreakdownLedgerRows(
        totalOwedRow.totalOwed,
        totalOwedRow.dash,
        totalOwedRow.directorOwes,
        totalOwedRow.coordinatorOwesCC
      )

  const totalPaidRow = {
    totalPaid: {
      content: t('BreakdownLedger.Row.TotalPaid', 'Total Paid'),
      colSpan: 1,
      align: 'left',
    } as BreakdownLedgerRow,
    dash: {
      content: '-',
      colSpan: 1,
      align: 'left',
    } as BreakdownLedgerRow,
    // Director Owes To Country Coordinator
    directorOwes: {
      content: `${getLocaleCurrencyForAmount(
        billingDetails.totalPaid?.amount ?? 0,
        currencyCode
      )}`,
      colSpan: 1,
      align: 'right',
    } as BreakdownLedgerRow,
    dashRightAligned: {
      content: '-',
      colSpan: 1,
      align: 'right',
    } as BreakdownLedgerRow,
  }

  const totalPaidRowBasedOnAccountingView = isAccountingView
    ? buildBreakdownLedgerRows(
        totalPaidRow.totalPaid,
        totalPaidRow.dash,
        totalPaidRow.directorOwes
      )
    : buildBreakdownLedgerRows(
        totalPaidRow.totalPaid,
        totalPaidRow.dash,
        totalPaidRow.directorOwes,
        totalPaidRow.dashRightAligned
      )

  const balanceRow = {
    balance: {
      content: t('BreakdownLedger.Row.Balance', 'Balance'),
      colSpan: 1,
    } as BreakdownLedgerRow,
    dash: {
      content: '-',
      colSpan: 1,
      align: 'left',
    } as BreakdownLedgerRow,
    // Director Owes
    directorOwes: {
      content: `${getLocaleCurrencyForAmount(
        totalOwedByDirector - Number(billingDetails?.totalPaid?.amount ?? 0),
        currencyCode
      )}`,
      colSpan: 1,
      align: 'right',
    } as BreakdownLedgerRow,
    dashRightAligned: {
      content: '-',
      colSpan: 1,
      align: 'right',
    } as BreakdownLedgerRow,
  }

  const balanceRowBasedOnAccountingView = isAccountingView
    ? buildBreakdownLedgerRows(
        balanceRow.balance,
        balanceRow.dash,
        balanceRow.dashRightAligned
      )
    : buildBreakdownLedgerRows(
        balanceRow.balance,
        balanceRow.dash,
        balanceRow.directorOwes,
        balanceRow.dashRightAligned
      )

  const licensingDueBreakDownCSSProps: CSSProperties = {
    color: theme.palette.primary.main,
    padding: theme.spacing(2, 0),
    minWidth: '88px',
    maxHeight: '30px',
    borderBottom: 1,
    borderColor: theme.palette.customBackground.divider,
    backgroundColor: 'transparent',
    paddingLeft: 0,
  }

  const breakdownHeaders: TableHeaders[] = [
    {
      label: t(
        'CountryCoordinatorLicensing.Breakdown.Header.FeeName',
        'Fee Name'
      ),
      align: 'left',
      cssProps: {
        ...licensingDueBreakDownCSSProps,
      },
    },
    {
      label: t(
        'CountryCoordinatorLicensing.Breakdown.Header.NumberOfInvitedStudents',
        '# Of Invited Students'
      ),
      align: 'left',
      cssProps: {
        ...licensingDueBreakDownCSSProps,
      },
    },
    {
      label: t(
        'CountryCoordinatorLicensing.Breakdown.Header.DirectorOws',
        'Director Owes To Country Coordinator'
      ),
      align: 'right',
      cssProps: {
        ...licensingDueBreakDownCSSProps,
      },
    },
    {
      label: t(
        'CountryCoordinatorLicensing.Breakdown.Header.CountryCoordinatorOws',
        'Country Coordinator Owes CC'
      ),
      align: 'right',
      cssProps: {
        ...licensingDueBreakDownCSSProps,
      },
    },
  ]

  const [feeName, studentsCount, directorOwes, coordinatorOwesCC] =
    breakdownHeaders

  const licensingBreakdownHeadersBasedOnAccountingView: TableHeaders[] =
    isAccountingView
      ? [feeName, studentsCount, coordinatorOwesCC]
      : [feeName, studentsCount, directorOwes, coordinatorOwesCC]

  if (isLoading) {
    return <ActionableTableSkeleton rows={MAX_BREAKDOWN_ROWS} />
  }

  return (
    <>
      <Box>
        <SpaceBetweenSection
          isCentered
          left={
            <CardFormHeader
              header={
                <Box>
                  <Header
                    id={`${billingDetails.community} - 
                        ${billingDetails.semesterOneStartDate}
                      `}
                    headerName={`${
                      billingDetails.programType
                    } - (${dateToSlashStringReinterpretedAsLocal(
                      billingDetails.semesterOneStartDate
                    )}) - ${billingDetails.community}`}
                    component="h3"
                    variant={HeaderVariant.Card}
                  />
                </Box>
              }
              headerContainerProps={{ marginLeft: 0 }}
            />
          }
          right={
            <Box mt={showOnDesktop ? 0 : 1} padding={showOnDesktop ? 0 : 1}>
              <OutlinedButton
                id="showDetails"
                variant={OutlinedButtonVariant.HideDetails}
                onClick={hideLicensingDetails}
                fullWidth={!showOnDesktop}
              />
              {!isAccountingView && (
                <ContainedButton
                  id="makeAPayment"
                  variant={ContainedButtonVariant.RecordAPayment}
                  onClick={() =>
                    setIsIntlCountryCoordinatorPaymentModalOpen(true)
                  }
                  fullWidth={!showOnDesktop}
                  disabled={undefined}
                />
              )}
            </Box>
          }
        />
      </Box>
      <Box>
        <BreakdownLedgerContainer
          aria-labelledby="`${billingDetails.community} - 
                        ${billingDetails.semesterOneStartDate}
                      `"
          headers={licensingBreakdownHeadersBasedOnAccountingView}
        >
          <>
            {!!licensingLedgerRows &&
              licensingLedgerRows.map((row, index) => (
                <Breakdown
                  key={`breakdown-row-${row.cells[index]?.content}`}
                  breakdownRows={[row]}
                  variant={BreakDownLedgerVariants.InternationalLicensing}
                />
              ))}
            <Breakdown
              breakdownRows={[totalOwedRowBasedOnAccountingView]}
              variant={BreakDownLedgerVariants.InternationalLicensing}
            />
            {!isAccountingView && (
              <Breakdown
                breakdownRows={[totalPaidRowBasedOnAccountingView]}
                variant={BreakDownLedgerVariants.InternationalLicensing}
              />
            )}
            {!isAccountingView && (
              <Breakdown
                breakdownRows={[balanceRowBasedOnAccountingView]}
                variant={BreakDownLedgerVariants.InternationalLicensing}
              />
            )}
          </>
        </BreakdownLedgerContainer>
        <Divider
          sx={{ borderBottomWidth: '3px', paddingTop: theme.spacing(4) }}
        />
      </Box>
      <IntlCountryCoordinatorPaymentModal
        isOpen={isIntlCountryCoordinatorPaymentModalOpen}
        onClose={() => setIsIntlCountryCoordinatorPaymentModalOpen(false)}
        titleInfo={{
          programName: `${
            billingDetails.programType
          } - (${dateToSlashStringReinterpretedAsLocal(
            billingDetails.semesterOneStartDate
          )})`,
          communityName: billingDetails.community,
          directorName,
        }}
        programKey={programKey}
        userKey={userKey}
        fetchBillingLoadingId={fetchBillingLoadingId}
      />
    </>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import { Box, useTheme } from '@mui/system'
import { useShowOnDesktop } from '../../../hooks/useShowOnDesktop'
import CountryCoordinatorLicensingTable from './CountryCoordinatorLicensingTable'
import {
  CashAmount,
  FetchBillingProgramTypeKeyEnum,
  FetchBillingRequest,
  FetchBillingResponseLicensingDirectorBilling,
  OrderByDirection,
  PaginationResponse,
} from '../../../swagger'
import { paymentsApi } from '../../../api/swagger'
import { fetchBilling } from '../../../api/payments'
import useLoadingContext from '../../../hooks/useLoadingContext'
import { useLoadingIds } from '../../../hooks/useLoadingIds'
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants'
import getOrderByClause from '../../../utils/getOrderByClause'
import { useErrorSnackbar } from '../../../hooks/useErrorSnackbar'

type CoCoLicensingAccordionItemProps = {
  actorKey: number
}

const CoCoLicensingAccordionItem: React.FunctionComponent<
  CoCoLicensingAccordionItemProps
> = ({ actorKey }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const showOnDesktop = useShowOnDesktop()
  const { handleError } = useErrorSnackbar()

  const defaultFetchBillingErrorMessage = t(
    'CoCoLicensingAccordionItem.FetchBilling.Error',
    'Error occurred fetching billing history.'
  )

  const shouldFetchTotalBalanceRef = useRef(true)

  const [countryCoordinatorLicensing, setCountryCoordinatorLicensing] =
    useState<FetchBillingResponseLicensingDirectorBilling>()
  const [countryCoordinatorTotalBalance, setCountryCoordinatorTotalBalance] =
    useState<CashAmount | undefined>()

  const [filters, setFilters] = useState({
    director: { value: '', id: '' },
    programType: { value: '', id: '' },
    community: { value: '', id: '' },
  })
  const [pagination, setPagination] = useState<PaginationResponse>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: 0,
    orderBy: [{ begunAt: OrderByDirection.Desc }],
  })

  const fetchCocoBilling = async () => {
    try {
      const programTypeId = filters.programType.id

      const programTypeKey = Object.values(
        FetchBillingProgramTypeKeyEnum
      ).includes(programTypeId as FetchBillingProgramTypeKeyEnum)
        ? (programTypeId as FetchBillingProgramTypeKeyEnum)
        : undefined

      const { licensing } = await fetchBilling({
        actorKey,
        page: pagination.page,
        pageSize: pagination.pageSize,
        orderBy: getOrderByClause(pagination.orderBy),
        communityKey: !!filters.community.id ? +filters.community.id : 0,
        programTypeKey,
        directorKey: !!filters.director.id ? +filters.director.id : 0,
      } as FetchBillingRequest)

      if (!!licensing?.director?.billing?.data) {
        setCountryCoordinatorLicensing(licensing.director.billing)

        if (licensing.director.billing.pagination) {
          setPagination({
            ...pagination,
            totalCount: licensing.director.billing.pagination.totalCount,
          })
        }
      }
    } catch (e) {
      await handleError(e, defaultFetchBillingErrorMessage)
    }
  }

  const {
    BillingTab: {
      fetchBilling: fetchBillingLoadingId,
      fetchCountryCoordinatorTotalBalance:
        fetchCountryCoordinatorTotalBalanceLoadingId,
    },
  } = useLoadingIds()

  const { triggerFetch, isLoading } = useLoadingContext({
    asyncFunction: fetchCocoBilling,
    loadingId: fetchBillingLoadingId + actorKey,
  })

  const fetchTotalBalance = async () => {
    const { totalOwed } = await paymentsApi.fetchCountryCoordinatorTotalBalance(
      {
        actorKey,
      }
    )

    setCountryCoordinatorTotalBalance(totalOwed)
  }

  const { triggerFetch: triggerFetchCoCoTotalBalance } = useLoadingContext({
    asyncFunction: fetchTotalBalance,
    loadingId: fetchCountryCoordinatorTotalBalanceLoadingId,
  })

  useEffect(() => {
    if (
      !!countryCoordinatorLicensing?.data &&
      countryCoordinatorLicensing?.data.length > 0 &&
      shouldFetchTotalBalanceRef.current
    ) {
      triggerFetchCoCoTotalBalance()
      shouldFetchTotalBalanceRef.current = false
    }
  }, [countryCoordinatorLicensing, triggerFetchCoCoTotalBalance])

  /** Load billing history for current user */
  useEffect(() => {
    triggerFetch()
  }, [pagination.page, pagination.pageSize, triggerFetch])

  useEffect(() => {
    // reset the pagination to the initial page (1)
    setPagination({
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      totalCount: 0,
      orderBy: [{ begunAt: OrderByDirection.Desc }],
    })
    triggerFetch()
  }, [filters, triggerFetch])

  const handlePagination = (_pagination: PaginationResponse) => {
    if (pagination.page < 1) return
    setPagination({
      ...pagination,
      page: _pagination.page,
      pageSize: _pagination.pageSize,
    })
  }

  return (
    <Box
      sx={{
        flexWrap: 'wrap',
        ...(showOnDesktop && {
          justifyContent: 'center',
        }),
      }}
    >
      <CountryCoordinatorLicensingTable
        aria-labelledby="CoCoLicensingAccordionItem"
        handlePagination={handlePagination}
        actorKey={actorKey}
        countryCoordinatorLicensing={countryCoordinatorLicensing?.data ?? []}
        countryCoordinatorTotalBalance={countryCoordinatorTotalBalance}
        isAccountingView
        pagination={pagination}
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
      />
      <Divider sx={{ borderBottomWidth: '3px', padding: theme.spacing(2) }} />
    </Box>
  )
}

export default CoCoLicensingAccordionItem

/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { Address, AddressFromJSON, AddressToJSON } from './'

/**
 *
 * @export
 * @interface InvoiceSignature
 */
export interface InvoiceSignature {
  /**
   *
   * @type {number}
   * @memberof InvoiceSignature
   */
  invoiceSignatureKey?: number
  /**
   *
   * @type {boolean}
   * @memberof InvoiceSignature
   */
  useDefaultSignature: boolean
  /**
   *
   * @type {string}
   * @memberof InvoiceSignature
   */
  payableTo: string
  /**
   *
   * @type {string}
   * @memberof InvoiceSignature
   */
  phoneNumber?: string
  /**
   *
   * @type {string}
   * @memberof InvoiceSignature
   */
  closingMessage?: string
  /**
   *
   * @type {string}
   * @memberof InvoiceSignature
   */
  email?: string
  /**
   *
   * @type {Address}
   * @memberof InvoiceSignature
   */
  address?: Address
  /**
   *
   * @type {number}
   * @memberof InvoiceSignature
   */
  addressKey?: number
  /**
   *
   * @type {number}
   * @memberof InvoiceSignature
   */
  invoiceSenderKey: number
}

export function InvoiceSignatureFromJSON(json: any): InvoiceSignature {
  return InvoiceSignatureFromJSONTyped(json, false)
}

export function InvoiceSignatureFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InvoiceSignature {
  if (json === undefined || json === null) {
    return json
  }
  return {
    invoiceSignatureKey: !exists(json, 'invoiceSignatureKey')
      ? undefined
      : json['invoiceSignatureKey'],
    useDefaultSignature: json['useDefaultSignature'],
    payableTo: json['payableTo'],
    phoneNumber: !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
    closingMessage: !exists(json, 'closingMessage')
      ? undefined
      : json['closingMessage'],
    email: !exists(json, 'email') ? undefined : json['email'],
    address: !exists(json, 'address')
      ? undefined
      : AddressFromJSON(json['address']),
    addressKey: !exists(json, 'addressKey') ? undefined : json['addressKey'],
    invoiceSenderKey: json['invoiceSenderKey'],
  }
}

export function InvoiceSignatureToJSON(value?: InvoiceSignature | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    invoiceSignatureKey: value.invoiceSignatureKey,
    useDefaultSignature: value.useDefaultSignature,
    payableTo: value.payableTo,
    phoneNumber: value.phoneNumber,
    closingMessage: value.closingMessage,
    email: value.email,
    address: AddressToJSON(value.address),
    addressKey: value.addressKey,
    invoiceSenderKey: value.invoiceSenderKey,
  }
}

import React, { useEffect, useState } from 'react'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import { Box, TextField, useTheme } from '@mui/material'
import BasicModal from './BasicModal'
import { useTranslation } from 'react-i18next'
import { OutlinedButtonVariant } from '../Buttons/OutlinedButton'
import { validateEmail } from '../../helpers/validateEmail'
import { useCountryContext } from '../Context/CountryContext'
import { FieldValidity } from '../Interfaces/FieldValidity'
import AddressForm from '../Address/AddressForm'
import useLoadingContext from '../../hooks/useLoadingContext'
import { events } from '../../api/swagger'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { useEventContext } from '../Context/EventContext'
import { useErrorSnackbar } from '../../hooks/useErrorSnackbar'

interface AddRegistrantModalProps {
  isOpen?: boolean
  onClose?: () => void
}

const AddRegistrantModal: React.FunctionComponent<AddRegistrantModalProps> = ({
  isOpen = false,
  onClose,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { countryOptions } = useCountryContext()

  const { handleError } = useErrorSnackbar()
  const {
    Events: { createRegistrant: createRegistrantLoadingId },
  } = useLoadingIds()
  const { eventDetails, refetchRegistrant } = useEventContext()

  const defaultCreateRegistrantErrorMessage = t(
    'AddRegistrantModal.CreateRegistrant.Error',
    'Error occurred creating new registrant.'
  )

  const defaultCountryCode = 'US'

  const initialEmptyRegistrantData = {
    firstName: {
      value: '',
    },
    lastName: {
      value: '',
    },
    email: {
      value: '',
    },
    phone: {
      value: '',
    },
    streetAddress1: {
      value: '',
    },
    city: {
      value: '',
    },
    state: {
      value: '',
    },
    countryCode: {
      value: defaultCountryCode,
    },
    zip: {
      value: '',
    },
  }

  const initialRegistrantValidity = {
    firstName: {
      input: true,
    },
    lastName: { input: true },
    email: { input: true, email: true },
    zip: { input: true },
  }

  const [registrantData, setRegistrantData] = useState(
    initialEmptyRegistrantData
  )

  const [registrantValidity, setRegistrantValidity] = useState<{
    firstName: FieldValidity
    lastName: FieldValidity
    email: FieldValidity
    zip: FieldValidity
  }>(initialRegistrantValidity)

  const [isSave, setIsSave] = useState(false)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name: fieldName, value: fieldValue } = event.target

    setRegistrantData((prevRegistrantData) => {
      return {
        ...prevRegistrantData,
        [fieldName]: { value: fieldValue },
      }
    })
  }

  const createRegistrant = async () => {
    try {
      await events.createRegistrant({
        body: {
          firstName: registrantData.firstName.value,
          lastName: registrantData.lastName.value,
          email: registrantData.email.value,
          phone: registrantData.phone.value,
          eventId: eventDetails?.eventKey as unknown as string,
          address: {
            locationName: '',
            streetAddress1: registrantData.streetAddress1.value,
            city: registrantData.city.value,
            state: registrantData.state.value,
            zip: registrantData.zip.value,
            countryCode: registrantData.countryCode.value,
          },
        },
      })

      if (isSave) {
        onClose && onClose()
      }

      setRegistrantData(initialEmptyRegistrantData)
      setRegistrantValidity(initialRegistrantValidity)
      refetchRegistrant()
    } catch (e) {
      await handleError(e, defaultCreateRegistrantErrorMessage)
    } finally {
      setIsSave(false)
    }
  }

  const { triggerFetch: createRegistrantFn } = useLoadingContext({
    asyncFunction: createRegistrant,
    loadingId: createRegistrantLoadingId,
  })

  useEffect(() => {
    if (!!isSave) {
      createRegistrantFn()
    }
  }, [isSave, createRegistrantFn])

  const fieldsDoValidate = () => {
    const isEmailValid = validateEmail(registrantData.email.value)

    const isPostalCodeValid =
      (registrantData.countryCode.value === defaultCountryCode &&
        !!registrantData.zip.value) ||
      (registrantData.countryCode.value != defaultCountryCode &&
        (!registrantData.zip.value || !!registrantData.zip.value))

    const areFieldsValid =
      !!registrantData.firstName.value &&
      !!registrantData.lastName.value &&
      !!registrantData.email.value &&
      isPostalCodeValid &&
      isEmailValid

    return areFieldsValid
  }

  const setFieldValidity = () => {
    setRegistrantValidity({
      firstName: {
        input: !!registrantData.firstName.value,
      },
      lastName: { input: !!registrantData.lastName.value },
      email: {
        input: !!registrantData.email.value,
        email: validateEmail(registrantData.email.value),
      },
      zip: { input: !!registrantData.zip.value },
    })
  }

  const handleModalSave = () => {
    setFieldValidity()
    if (fieldsDoValidate()) {
      setIsSave(true)
    }
  }

  const handleModalFormSubmit = () => {
    setFieldValidity()
    if (fieldsDoValidate()) {
      createRegistrantFn()
    }
  }

  const handleCloseModal = () => {
    onClose && onClose()
    setRegistrantData(initialEmptyRegistrantData)
    setRegistrantValidity(initialRegistrantValidity)
  }

  const modalForm = (
    <>
      <Box mb={4} width="100%">
        <TextField
          id="firstNameModalField"
          label={t('AddRegistrantModal.Field.FirstName', 'First Name')}
          name="firstName"
          variant="filled"
          value={registrantData.firstName.value}
          error={!registrantValidity.firstName.input}
          helperText={
            !registrantValidity.firstName.input &&
            t(
              'AddRegistrantModal.Field.HelperText.FirstName',
              'First Name cannot be empty'
            )
          }
          required
          sx={{
            marginBottom: theme.spacing(3),
            width: '100%',
          }}
          onChange={handleInputChange}
        />
      </Box>

      <Box mb={4} width="100%">
        <TextField
          id="lastNameModalField"
          name="lastName"
          label={t('AddRegistrantModal.Field.LastName', 'Last Name')}
          variant="filled"
          value={registrantData.lastName.value}
          error={!registrantValidity.lastName.input}
          helperText={
            !registrantValidity.lastName.input &&
            t(
              'AddRegistrantModal.Field.HelperText.LastName',
              'Last Name cannot be empty'
            )
          }
          required
          sx={{
            marginBottom: theme.spacing(3),
            width: '100%',
          }}
          onChange={handleInputChange}
        />
      </Box>
      <Box mb={4} width="100%">
        <TextField
          id="emailModalField"
          name="email"
          type="email"
          label={t('AddRegistrantModal.Field.Email', 'Email')}
          variant="filled"
          value={registrantData.email.value}
          error={
            !registrantValidity.email.input || !registrantValidity.email.email
          }
          helperText={
            !registrantValidity.email.input ? (
              t(
                'AddRegistrantModal.Field.HelperText.Email',
                'Email cannot be empty'
              )
            ) : !registrantValidity.email.email ? (
              t(
                'AddRegistrantModal.Field.HelperText.Email',
                'Please enter a valid email address.'
              )
            ) : (
              <></>
            )
          }
          required
          sx={{
            marginBottom: theme.spacing(3),
            width: '100%',
          }}
          onChange={handleInputChange}
        />
      </Box>
      <Box mb={4} width="100%">
        <TextField
          id="phoneModalField"
          name="phone"
          label={t('AddRegistrantModal.Field.Phone', 'Phone')}
          variant="filled"
          value={registrantData.phone.value}
          sx={{
            marginBottom: theme.spacing(3),
            width: '100%',
          }}
          onChange={handleInputChange}
        />
      </Box>

      <AddressForm
        addressInformation={{
          locationName: '',
          streetAddress1: registrantData.streetAddress1.value,
          city: registrantData.city.value,
          zip: registrantData.zip.value,
          state: registrantData.state.value,
          countryCode: registrantData.countryCode.value,
        }}
        isRegistrantFormAddress
        fullWidthFields
        postalCodeValidity={registrantValidity.zip.input}
        countryOptions={countryOptions}
        onInputChange={handleInputChange}
      />
    </>
  )

  const modalProps = {
    dialogTitle: t('AddRegistrantModal.Title', 'Add Registrant'),
    handleFormSubmit: handleModalFormSubmit,
    ariaLabel: 'Add Registrant Modal',
    dialogContent: modalForm,
    fullWidth: false,
    dialogActions: (
      <ActionButtons
        primaryButtonLabel={OutlinedButtonVariant.saveAndAddAnother}
        disablePrimaryButton={false}
        secondaryButtonLabel={ContainedButtonVariant.Save}
        secondaryClick={handleModalSave}
        tertiaryButtonLabel={TextButtonVariant.Cancel}
        tertiaryClick={handleCloseModal}
        fullWidthButtons={false}
      />
    ),
  }

  return <BasicModal isOpen={isOpen} maxWidth="lg" {...modalProps} noValidate />
}

export default AddRegistrantModal

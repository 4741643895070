import { DirectorFilterOption } from '../../../swagger'

/**
 * Formats a DirectorFilterOption into a specific string format.
 * Removes "Program Director" from the roleName if present.
 * @param {DirectorFilterOption} directorOption - The director option to format.
 * @returns {string} - Formatted string in the format: name - roleName - (validFromYear - validToYear).
 */
export function formatDirectorOption(
  directorOption: DirectorFilterOption | undefined | null
): string {
  if (!directorOption) return ''

  const { name, roleName, validFrom, validTo } = directorOption

  const validFromYear = validFrom.getFullYear()
  const validToYear = validTo.getFullYear()

  // Remove "Program Director" if present in the roleName
  const cleanedRoleName = roleName.replace(/^Program Director\s*/i, '')

  return `${name} / ${cleanedRoleName} / ${validFromYear} - ${validToYear}`
}

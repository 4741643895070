import React, { CSSProperties, useCallback, useState } from 'react'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/system'
import DropDown, { DropDownVariant } from '../Menus/DropDown'
import { Box, TextField } from '@mui/material'
import { FormDivider } from '../Elements/FormDivider'
import { useTranslation } from 'react-i18next'
import {
  TranscriptDetails,
  useTranscriptContext,
} from '../Context/TranscriptContext'
import Header, { HeaderVariant } from '../Elements/Header'
import ClassInformationTable from '../Transcripts/ClassInformationTable'
import TranscriptConfirmClearModal from '../Modals/TranscriptConfirmClearModal'
import TranscriptConfirmDeleteRowModal from '../Modals/TranscriptConfirmDeleteRowModal'
import AddEditRowModal from '../Modals/AddEditRowModal'
import { GradingScale, TranscriptYearCourseWork } from '../../swagger'
import { useTranscriptYearsOptions } from '../../utils/useTranscriptYearsOptions'
import {
  calculateTranscriptCredits,
  calculateTranscriptGPA,
  calculateYearCredits,
  calculateYearGPA,
} from '../../utils/calculateGPA'

const ClassInformationPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4, 4, 5.5),
  width: '50%',
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
}))

const dropDownCssProps: CSSProperties = {
  padding: 0,
  height: 'auto',
  width: '100%',
  textAlign: 'left',
}

enum FormIds {
  YearDropDown = 'academicYear',
  TotalCreditsTextField = 'totalCredits',
  GPATextField = 'gpa',
  NotesTextField = 'notes',
}

interface ClassInformationCardProps {
  isFieldDisabled: boolean
  /** Making these explicit because typescript complains otherwise */
  transcriptYear: 1 | 2 | 3 | 4
  onPrepopulate: () => void
}

const ClassInformationCard: React.FC<ClassInformationCardProps> = ({
  isFieldDisabled,
  transcriptYear,
  onPrepopulate,
}) => {
  const { t } = useTranslation()
  const { transcriptDetails, updateTranscriptDetails } = useTranscriptContext()
  const yearsOptions = useTranscriptYearsOptions()

  const [isConfirmClearModalOpen, setIsConfirmClearModalOpen] = useState(false)
  const [isConfirmDeleteRowModalOpen, setIsConfirmDeleteRowModalOpen] = useState(false)
  // selected courseWork and pull key from the sent information
  const [selectedCourseWork, setSelectedCourseWork] = useState<TranscriptYearCourseWork | undefined>(undefined)
  const [isAddEditRowModalOpen, setIsAddEditRowModalOpen] = useState(false)
  const [courseworkDetails, setCourseworkDetails] = useState<
  TranscriptYearCourseWork | undefined
  >()
  
  const transcriptYearObj = transcriptDetails[`transcriptYear${transcriptYear}`]

  /**
   * Easily grant us 9th, 10th, 11th, and 12th grade for a header.
   *
   * Since we have years: 1, 2, 3, 4 adding 8 is just simple.
   */
  const headerTitle = `${transcriptYear + 8}th Grade`

  const onDeleteCourseWork = useCallback(
    (courseWorkKey: number | undefined) => {
      setIsConfirmDeleteRowModalOpen(false)

      const courseWork = transcriptYearObj?.transcriptYearCourseWork

      if (!courseWork) {
        return
      }

      const courseWorkToDeleteIndex = courseWork.findIndex(
        (courseWork) => courseWork.transcriptYearCourseWorkKey === courseWorkKey
      )

      // Recalculate gpa & credits
      const transcriptYearKey: keyof Pick<
        TranscriptDetails,
        | 'transcriptYear1'
        | 'transcriptYear2'
        | 'transcriptYear3'
        | 'transcriptYear4'
      > = `transcriptYear${transcriptYear}`
      // Transcript Year object not typed because typescript gymnastics
      const everythingPastCourseWorkIndex = courseWork.slice(
        courseWorkToDeleteIndex + 1
      )
      let updatedTranscriptYear = {
        ...transcriptDetails[transcriptYearKey],

        /** Previously on splice vs slice
         *
         * Slice returns a shallow copy of the elements
         *
         * Splice modifies the existing coursework, which
         * in turn modifies the initial transcript details
         * resulting in a cancellation missing coursework
         * which was deleted.
         *
         */
        transcriptYearCourseWork:
          courseWorkToDeleteIndex === 0
            ? [...everythingPastCourseWorkIndex]
            : [
                ...courseWork.slice(0, courseWorkToDeleteIndex),
                ...everythingPastCourseWorkIndex,
              ],
      }
      // Transcript Details typed because type gymnastics
      let updatedTranscriptDetails: TranscriptDetails = {
        ...transcriptDetails,
        [transcriptYearKey]: {
          ...updatedTranscriptYear,
        },
      }
      // Recalculated credits
      const transcriptCredits = calculateTranscriptCredits(
        updatedTranscriptDetails
      )
      const transcriptGPA = calculateTranscriptGPA(
        updatedTranscriptDetails,
        updatedTranscriptDetails.gradingScale as GradingScale
      )
      const yearCredits = calculateYearCredits(
        updatedTranscriptYear.transcriptYearCourseWork ?? []
      )
      const yearGPA = calculateYearGPA(
        updatedTranscriptYear.transcriptYearCourseWork,
        transcriptDetails.gradingScale as GradingScale
      )
      // Put them back in the objects
      updatedTranscriptYear = {
        ...updatedTranscriptYear,
        totalCredits: yearCredits,
        gpa: yearGPA,
      }
      updatedTranscriptDetails = {
        ...updatedTranscriptDetails,
        totalCredits: transcriptCredits,
        gpa: transcriptGPA,
        [`transcriptYear${transcriptYear}`]: {
          ...updatedTranscriptYear,
        },
      }

      // Update the details with the right values
      updateTranscriptDetails({ ...updatedTranscriptDetails })
    },
    [
      transcriptDetails,
      transcriptYearObj,
      updateTranscriptDetails,
      transcriptYear,
    ]
  )

  const onRowDelete = (courseWork: TranscriptYearCourseWork) => {
    if (courseWork.transcriptYearCourseWorkKey == null) return
    setIsConfirmDeleteRowModalOpen(true)
    setSelectedCourseWork(courseWork)
  }
  
  const onClear = () => {
    setIsConfirmClearModalOpen(true)
  }

  const onEditCourseWork = (courseWork: TranscriptYearCourseWork) => {
    setIsAddEditRowModalOpen(true)
    setCourseworkDetails(courseWork)
  }

  const onAdd = () => {
    setIsAddEditRowModalOpen(true)
  }

  const handleSelection = (selection: string, id: string) => {
    let sanatizeSelection: string | number | undefined = selection

    if (selection.toLowerCase() === yearsOptions[0].name.toLowerCase())
      sanatizeSelection = undefined
    else if (+selection) sanatizeSelection = +selection

    updateDetails(id, sanatizeSelection)
  }

  const updateDetails = (id: string, value?: string | number) => {
    updateTranscriptDetails({
      ...transcriptDetails,
      [`transcriptYear${transcriptYear}`]: {
        ...transcriptDetails[`transcriptYear${transcriptYear}`],
        [id]: value,
      },
    })
  }

  const notes = transcriptYearObj?.notes ?? ''

  const onConfirmClear = () => {
    updateTranscriptDetails({
      ...transcriptDetails,
      [`transcriptYear${transcriptYear}`]: {
        ...transcriptDetails[`transcriptYear${transcriptYear}`],
        transcriptYearCourseWork: [],
      },
    })
    setIsConfirmClearModalOpen(false)
  }

  return (
    <ClassInformationPaper>
      <AddEditRowModal
        open={isAddEditRowModalOpen}
        onClose={() => {
          setIsAddEditRowModalOpen(false)
          setCourseworkDetails(undefined)
        }}
        transcriptYear={transcriptYear}
        courseworkDetails={courseworkDetails}
      />
      <TranscriptConfirmClearModal
        isOpen={isConfirmClearModalOpen}
        onConfirm={onConfirmClear}
        onCancel={() => {
          setIsConfirmClearModalOpen(false)
        }}
      />
      <TranscriptConfirmDeleteRowModal
        isOpen={isConfirmDeleteRowModalOpen}
        onConfirm={() => onDeleteCourseWork(selectedCourseWork?.transcriptYearCourseWorkKey)}
        courseWork={selectedCourseWork}
        onCancel={() => {
          setIsConfirmDeleteRowModalOpen(false)
        }}
      />
      <Header variant={HeaderVariant.Card} headerName={headerTitle} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '10px',
          width: '48%',
        }}
      >
        <DropDown
          id={FormIds.YearDropDown}
          label={t(
            'Transcript.ClassInformationCard.DropDown.YearDropDown',
            'Year'
          )}
          menuOptions={yearsOptions}
          handleSelection={handleSelection}
          variant={DropDownVariant.FormField}
          value={transcriptYearObj?.academicYear?.toString() ?? ''}
          fullWidth
          disabled={isFieldDisabled}
          formControlProps={{
            maxWidth: '1',
          }}
          cssProps={dropDownCssProps}
        />
      </Box>

      <FormDivider />

      <ClassInformationTable
        classInformation={transcriptYearObj?.transcriptYearCourseWork}
        onRowDelete={onRowDelete}
        onEditCourseWork={onEditCourseWork}
        onClear={onClear}
        onPrepopulate={onPrepopulate}
        onAdd={onAdd}
        isFieldDisabled={isFieldDisabled}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          <TextField
            id={FormIds.TotalCreditsTextField}
            aria-label="totalCredits"
            variant="filled"
            label={t(
              'Transcript.ClassInformationCard.TextField.TotalCredits',
              'Total Credits'
            )}
            value={transcriptYearObj?.totalCredits ?? 0}
            disabled
            fullWidth
          />

          <TextField
            id={FormIds.GPATextField}
            aria-label="gpa"
            variant="filled"
            label={t('Transcript.ClassInformationCard.TextField.GPA', 'GPA')}
            value={transcriptYearObj?.gpa ?? 0}
            disabled
            fullWidth
          />
        </Box>

        <TextField
          id={FormIds.NotesTextField}
          aria-label="notes"
          variant="filled"
          label={t('Transcript.ClassInformationCard.TextField.Notes', 'Notes')}
          multiline
          minRows={5}
          disabled={isFieldDisabled}
          value={notes}
          onChange={(event) => {
            updateDetails(event.target.id, event.target.value)
          }}
        />
      </Box>
    </ClassInformationPaper>
  )
}

export default ClassInformationCard

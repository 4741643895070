import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { useTranslation } from 'react-i18next'
import { Address, Country } from '../../swagger'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { CircularProgress } from '@mui/material'
import useStatesForCountry from '../../hooks/useStatesForCountry'
import { LoadingContext } from '../Context/LoadingContext'
import { CountryCode } from '../../utils/isStateInCountry'

const AddressForm: React.FC<{
  addressInformation: Address
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onInputBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  countryOptions: Country[]
  billingAddress?: boolean
  fullWidthFields?: boolean
  isCardSelected?: boolean
  isFieldDisabled?: boolean
  isRegistrantFormAddress?: boolean
  postalCodeValidity?: boolean
}> = ({
  addressInformation,
  onInputChange,
  onInputBlur,
  countryOptions,
  billingAddress = false,
  fullWidthFields = false,
  isCardSelected = false,
  isFieldDisabled = false,
  isRegistrantFormAddress = false,
  postalCodeValidity = true,
}) => {
  const { t } = useTranslation()
  const showOnDesktop = useShowOnDesktop()

  const countryOptionsDefault =
    countryOptions.length > 0 && !!addressInformation.countryCode
      ? addressInformation.countryCode
      : 'US'
  const { addLoadingIds } = React.useContext(LoadingContext)

  const isStateDisabled =
    addressInformation.countryCode === '' && countryOptionsDefault === ''

  const { defaultStates: defaultStateOptions, loadingId } = useStatesForCountry(
    {
      countryCode: countryOptionsDefault,
    }
  )

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    /** Whenever the country changes, update the states */
    if (event.target.name === 'countryCode') {
      addLoadingIds([loadingId])
    }

    onInputChange?.(event)
  }

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    /** Whenever the country changes, update the states */
    if (event.target.name === 'countryCode') {
      addLoadingIds([loadingId])
    }

    onInputBlur?.(event)
  }

  if (isCardSelected && countryOptions.length === 0) {
    return <CircularProgress />
  }

  const addressLabel = isRegistrantFormAddress
    ? t('Address.FormFields.StreetAddress1', 'Address')
    : t('Address.FormFields.StreetAddress1', 'Street Address')

  const stateLabel = isRegistrantFormAddress
    ? t('Address.FormFields.State', 'State')
    : t('Address.FormFields.State', 'State/Province')

  const addressRegistrantKeys = [
    'streetAddress1',
    'city',
    'state',
    'country',
    'zip',
  ]

  type addressFieldsType = {
    streetAddress1: React.JSX.Element
    streetAddress2: React.JSX.Element
    city: React.JSX.Element
    country: React.JSX.Element
    state: React.JSX.Element
    zip: React.JSX.Element
  }

  const addressFields: addressFieldsType = {
    streetAddress1: (
      <TextField
        id="streetAddress1"
        name="streetAddress1"
        variant="filled"
        label={addressLabel}
        value={addressInformation.streetAddress1}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        fullWidth={billingAddress || fullWidthFields}
        disabled={isFieldDisabled}
      />
    ),

    streetAddress2: (
      <TextField
        id="streetAddress2"
        name="streetAddress2"
        variant="filled"
        label={t('Address.FormFields.StreetAddress2', 'Building, Suite, etc.')}
        value={addressInformation.streetAddress2}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        fullWidth={billingAddress || fullWidthFields}
        disabled={isFieldDisabled}
      />
    ),
    city: (
      <TextField
        id="city"
        name="city"
        variant="filled"
        label={t('Address.FormFields.City', 'City')}
        value={addressInformation.city}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        fullWidth={billingAddress || fullWidthFields}
        disabled={isFieldDisabled}
      />
    ),
    country: (
      <TextField
        id="countryCode"
        name="countryCode"
        variant="filled"
        select
        label={t('Address.FormFields.Country', 'Country')}
        value={countryOptions.length > 0 ? addressInformation.countryCode : ''}
        onChange={handleInputChange}
        fullWidth={billingAddress || fullWidthFields}
        disabled={isFieldDisabled}
      >
        {countryOptions.map((country) => (
          <MenuItem key={country.name} value={country.countryCode}>
            {country.name}
          </MenuItem>
        ))}
      </TextField>
    ),
    state: (
      <TextField
        id="state"
        name="state"
        variant="filled"
        select={!isStateDisabled}
        disabled={isStateDisabled || isFieldDisabled}
        label={stateLabel}
        value={defaultStateOptions.length > 0 ? addressInformation.state : ''}
        onChange={handleInputChange}
        fullWidth={billingAddress || fullWidthFields}
      >
        {defaultStateOptions.map((state) => (
          <MenuItem key={state.name} value={state.stateCode}>
            {state.name}
          </MenuItem>
        ))}
      </TextField>
    ),
    zip: (
      <TextField
        id="zip"
        name="zip"
        variant="filled"
        label={t('Address.FormFields.ZipCode', 'Postal Code')}
        required={
          isRegistrantFormAddress &&
          addressInformation.countryCode === CountryCode.USA
        }
        error={
          addressInformation.countryCode === CountryCode.USA &&
          !postalCodeValidity
        }
        helperText={
          addressInformation.countryCode === CountryCode.USA &&
          !postalCodeValidity &&
          t(
            'AddRegistrantModal.Field.HelperText.postalCode',
            'Postal Code cannot be empty'
          )
        }
        value={addressInformation.zip}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        fullWidth={billingAddress || fullWidthFields}
        disabled={isFieldDisabled}
      />
    ),
  }

  return (
    <>
      {!!billingAddress ? (
        <Box display="flex" flexDirection="column">
          <Box mb={3}>{addressFields.streetAddress1}</Box>
          <Box mb={3}>{addressFields.streetAddress2}</Box>
          {!!isCardSelected && <Box mb={3}>{addressFields.country}</Box>}
          <Box mb={3}>{addressFields.city}</Box>
          <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }}>
            <Box
              width={{ sm: '100%', lg: '50%' }}
              pr={{ sm: 0, lg: 2 }}
              mb={{ xs: 3, lg: 0 }}
            >
              {addressFields.state}
            </Box>
            <Box
              width={{ sm: '100%', lg: '50%' }}
              pl={{ sm: 0, lg: 2 }}
              mb={{ xs: 3, lg: 0 }}
            >
              {addressFields.zip}
            </Box>
          </Box>
        </Box>
      ) : isRegistrantFormAddress ? (
        <>
          {addressRegistrantKeys.map((addressRegistrantKey) => {
            const key: keyof addressFieldsType =
              addressRegistrantKey as keyof addressFieldsType

            return (
              <Box key={addressRegistrantKey} mb={4} width="100%">
                {addressFields[key]}
              </Box>
            )
          })}
        </>
      ) : (
        <Grid container spacing={showOnDesktop ? 3 : 0}>
          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="column" pb={3}>
              <Box mb={3}>{addressFields.streetAddress1}</Box>
              <Box mb={3}>{addressFields.streetAddress2}</Box>
              <Box mb={3}>{addressFields.city}</Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="column">
              <Box mb={3}>{addressFields.country}</Box>
              <Box mb={3}>{addressFields.state}</Box>
              <Box mb={3}>{addressFields.zip}</Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default AddressForm

import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography, { TypographyProps } from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Divider from '@mui/material/Divider'
import LearningCenterContentCard from '../Card/LearningCenterContentCard'
import { FileType } from '../Elements/FileTypeResource'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/system'
import {
  Week,
  isFeaturedLearningPathAsset,
} from '../LearningCenter/utils/learningCenterUtils'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import Box from '@mui/material/Box'
import { useLearningCenterContext } from '../Context/LearningCenterContext'
import { LearningCenterTabs } from '../../utils/searchAndFilterEnums'
import LoadingProgress from '../Elements/LoadingProgress'
import { useMountEffect } from '../../hooks/useMountEffect'

/**
 * !!! Attempting to use component prop on Typography results in an error in the
 * form of TypeScript limitation regarding argument interface and overload
 * function signatures
 *
 * See known issues + workaround https://github.com/mui/material-ui/issues/15759#issuecomment-493994852
 */
const AccordionTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: theme.spacing(1.75, 0),
  textTransform: 'uppercase',
})) as typeof Typography

const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.customBackground.divider,
}))

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginLeft: theme.spacing(-1),
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 'unset',
  },
}))

const supplementalAssetsWeek = '99'
export interface AccordionProps {
  sectionHeading: string
  accordionData: Week
  cycleOrSemesterNumber: number
  positionCycle: number
}

const LearningCenterAccordion: React.FunctionComponent<AccordionProps> = (
  props
) => {
  const { t } = useTranslation()
  const {
    sectionHeading,
    accordionData,
    cycleOrSemesterNumber,
    positionCycle,
  } = props
  const showOnDesktop = useShowOnDesktop()

  const {
    selectedTabKey,
    accordionStateByProgramType,
    updateWeeksStateByProgramTypeAndCycle,
    initializeProgramTypeCyclesAndWeeks,
    selectedTabForAccordions,
    updateLearningCenterAssetById,
    cycle: selectedCycle,
  } = useLearningCenterContext()

  const weeksInCycle = Object.keys(accordionData)

  useMountEffect(() => {
    if (selectedTabKey !== LearningCenterTabs.WelcomeCenter) {
      initializeProgramTypeCyclesAndWeeks(
        cycleOrSemesterNumber.toString(),
        weeksInCycle
      )
    }
  })

  const filteredCyclePosition = accordionStateByProgramType[
    selectedTabForAccordions
  ].findIndex(({ cycle }) => selectedCycle?.toString() == cycle)

  return (
    <>
      {accordionStateByProgramType[selectedTabForAccordions].length === 0 ? (
        <LoadingProgress />
      ) : (
        <>
          <AccordionTitle variant="h6" component="h2">
            {sectionHeading}
          </AccordionTitle>
          {accordionStateByProgramType[selectedTabForAccordions][
            filteredCyclePosition !== -1 ? filteredCyclePosition : positionCycle
          ].weeks.map(({ week, expanded }, index, arr) => {
            // Week 99 should not show up on the page unless it's searched
            if (week === supplementalAssetsWeek) {
              return undefined
            }
            const assets = accordionData[week]
            const summaryTitle = `${sectionHeading}, ${
              week === '0'
                ? t('Accordion.Label.FirstThings', 'First Things')
                : `Week ${week}`
            }`
            return (
              <Box
                key={week}
                sx={{
                  flexWrap: 'wrap',
                  ...(showOnDesktop && {
                    justifyContent: 'center',
                  }),
                }}
              >
                <Accordion
                  expanded={expanded}
                  defaultExpanded={false}
                  TransitionProps={{ unmountOnExit: true }}
                  onChange={() =>
                    updateWeeksStateByProgramTypeAndCycle(
                      week,
                      cycleOrSemesterNumber.toString()
                    )
                  }
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${summaryTitle}-content`}
                    id={`${summaryTitle}-header`}
                  >
                    <Typography variant="button" component="p">
                      {summaryTitle}
                    </Typography>
                  </AccordionSummary>
                  <StyledAccordionDetails>
                    {!!assets &&
                      assets.map((details) => {
                        const isFeaturedLearningCenterPathAsset =
                          isFeaturedLearningPathAsset({
                            learningPathTitle: details.learningPathTitle,
                            listingPriority: details.listingPriority,
                          })

                        return (
                          <LearningCenterContentCard
                            key={details.assetKey}
                            id={details.assetKey}
                            fileType={
                              isFeaturedLearningCenterPathAsset
                                ? FileType.LearningPath
                                : (details.filetype as FileType)
                            }
                            title={
                              isFeaturedLearningCenterPathAsset
                                ? (details.learningPathTitle as string)
                                : details.title ||
                                  t(
                                    'LearningCenter.EmptyTitle',
                                    'No title available'
                                  )
                            }
                            description={
                              isFeaturedLearningCenterPathAsset &&
                              !!details.learningPathDescription
                                ? details.learningPathDescription
                                : details.description ||
                                  t(
                                    'LearningCenter.EmptyDescription',
                                    'No description available'
                                  )
                            }
                            subTitle={summaryTitle}
                            strand={details.strand ?? ''}
                            isFavorite={!!details.isFavorited}
                            viewOnly={!!details.viewOnly}
                            {...props}
                            isLearningPath={isFeaturedLearningCenterPathAsset}
                            handleFavoriteButtonClicked={() =>
                              updateLearningCenterAssetById(details.assetKey, {
                                isFavorited: !details.isFavorited,
                              })
                            }
                          />
                        )
                      })}
                  </StyledAccordionDetails>
                </Accordion>
                {index === arr.length - 1 && <StyledDivider />}
              </Box>
            )
          })}
        </>
      )}
    </>
  )
}

export default LearningCenterAccordion

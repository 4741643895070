import React from 'react'
import { Ability } from '@casl/ability'
import { useTranslation } from 'react-i18next'
import { ProgramFormValidFields } from '../Card/ProgramFormCard'
import Header, { HeaderVariant } from '../Elements/Header'
import { useAuth } from '../Routes/AuthProvider'
import { Box, Grid } from '@mui/material'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import CardFormHeader from '../Card/CardFormHeader'
import { StyledTextField } from './ProgramInformationFields'

export enum ProgramFeeNames {
  ApplicationFee = 'applicationFee',
  Tuition = 'tuition',
  LocalFeeFirstStudent = 'localFeeFirstStudent',
  LocalFeeAdditionalStudent = 'localFeeAdditionalStudent',
  FacilityFeeFirstStudent = 'facilityFeeFirstStudent',
  FacilityFeeAdditionalStudent = 'facilityFeeAdditionalStudent',
  SupplyFeeFirstStudent = 'supplyFeeFirstStudent',
  SupplyFeeAdditionalStudent = 'supplyFeeAdditionalStudent',
  EnrollmentFee = 'enrollmentFee',
  MiscFeeFirstStudent = 'miscFeeFirstStudent',
  MiscFeeAdditionalStudent = 'miscFeeAdditionalStudent',
  SubLicensedTutorPercentage = 'subLicensedTutorPercentage',
  SemesterOneLicensingFee = 'semesterOneLicensingFee',
  DiscountSemesterOneLicensingFee = 'discountSemesterOneLicensingFee',
  SemesterTwoLicensingFee = 'semesterTwoLicensingFee',
  DiscountSemesterTwoLicensingFee = 'discountSemesterTwoLicensingFee',
  MultiStudentApplicationDiscount = 'multiStudentApplicationDiscount',
  InvitationFee = 'invitationFee',
  DiscountInvitationFee = 'discountInvitationFee',
}

export type ProgramFees = {
  [ProgramFeeNames.ApplicationFee]: number
  [ProgramFeeNames.Tuition]: number
  [ProgramFeeNames.EnrollmentFee]: number
  [ProgramFeeNames.SupplyFeeFirstStudent]: number
  [ProgramFeeNames.SupplyFeeAdditionalStudent]: number
  [ProgramFeeNames.LocalFeeFirstStudent]: number
  [ProgramFeeNames.LocalFeeAdditionalStudent]: number
  [ProgramFeeNames.FacilityFeeFirstStudent]: number
  [ProgramFeeNames.FacilityFeeAdditionalStudent]: number
  [ProgramFeeNames.MiscFeeFirstStudent]: number
  [ProgramFeeNames.MiscFeeAdditionalStudent]: number
  [ProgramFeeNames.SubLicensedTutorPercentage]: number
  [ProgramFeeNames.SemesterOneLicensingFee]: number
  [ProgramFeeNames.DiscountSemesterOneLicensingFee]: number
  [ProgramFeeNames.SemesterTwoLicensingFee]: number
  [ProgramFeeNames.DiscountSemesterTwoLicensingFee]: number
  [ProgramFeeNames.MultiStudentApplicationDiscount]: number
  [ProgramFeeNames.InvitationFee]: number
  [ProgramFeeNames.DiscountInvitationFee]: number
}

interface ProgramFeeFieldsProps {
  fees: ProgramFees
  programFeeMinimaMap: Map<
    string,
    { minimum: number; default: number; allowEdit: boolean }
  >
  isFeeFieldDisabled: boolean
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  programAbility?: Ability
  validFields: ProgramFormValidFields
  useAddProgramVariant: boolean
}

export const ProgramFeeFields: React.FC<ProgramFeeFieldsProps> = (props) => {
  const { t } = useTranslation()
  const {
    fees,
    isFeeFieldDisabled,
    programFeeMinimaMap,
    handleInputChange,
    validFields,
    useAddProgramVariant,
  } = props
  const { permissionAbility } = useAuth()
  const showOnDesktop = useShowOnDesktop()

  /** Objects */
  const feeHelperText = t(
    'Programs.ProgramForm.ValidationMessage.Fees',
    'Amount must be greater than or equal to'
  )

  const minimumLocalized = t(
    'Programs.ProgramFormCard.FormField.minimum',
    'minimum'
  )

  type FeeInfoMap = {
    [key in ProgramFeeNames]: {
      minimum: number | undefined
      allowEdit: boolean | undefined
    }
  }

  const feeInfo: FeeInfoMap = Object.values(ProgramFeeNames).reduce(
    (acc, curr) => {
      const { minimum, allowEdit } = programFeeMinimaMap.get(curr) ?? {}

      return {
        ...acc,
        [curr]: {
          minimum,
          allowEdit,
        },
      }
    },
    {} as FeeInfoMap
  )

  return (
    <section aria-labelledby="programFeesHeader">
      <CardFormHeader
        header={
          <Header
            id="programFeesHeader"
            headerName={t(
              'Programs.ProgramFormCard.ProgramFees.Header',
              'Program Fees'
            )}
            component="h2"
            variant={HeaderVariant.Divider}
          />
        }
        headerContainerProps={{ margin: 0 }}
      />
      <form noValidate autoComplete="off" aria-labelledby="programFeesHeader">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="column" pb={3}>
              <StyledTextField
                id="applicationFeeField"
                sx={{
                  minHeight: 74,
                }}
                name={ProgramFeeNames.ApplicationFee}
                label={t(
                  'Programs.ProgramFormCard.FormField.ApplicationFee',
                  'Application Fee'
                )}
                variant="filled"
                value={fees[ProgramFeeNames.ApplicationFee]}
                error={!validFields[ProgramFeeNames.ApplicationFee].input}
                helperText={
                  !!feeInfo[ProgramFeeNames.ApplicationFee].minimum
                    ? `${
                        feeInfo[ProgramFeeNames.ApplicationFee].minimum
                      } ${minimumLocalized}`
                    : null
                }
                disabled={
                  isFeeFieldDisabled ||
                  !feeInfo[ProgramFeeNames.ApplicationFee].allowEdit
                }
              />
              <StyledTextField
                id="tuitionField"
                data-testid="tuitionFeeId"
                name={ProgramFeeNames.Tuition}
                label={t(
                  'Programs.ProgramFormCard.FormField.Tuition',
                  'Tuition'
                )}
                variant="filled"
                value={fees.tuition}
                onChange={handleInputChange}
                error={!validFields.tuition.input}
                helperText={
                  !!feeInfo[ProgramFeeNames.Tuition].minimum
                    ? validFields[ProgramFeeNames.Tuition].input
                      ? `${
                          feeInfo[ProgramFeeNames.Tuition].minimum
                        } ${minimumLocalized}`
                      : `${feeHelperText} ${
                          feeInfo[ProgramFeeNames.Tuition].minimum
                        } of ${ProgramFeeNames.Tuition}.`
                    : null
                }
                disabled={
                  isFeeFieldDisabled ||
                  !feeInfo[ProgramFeeNames.Tuition].allowEdit
                }
              />
              <Box
                display="flex"
                flexDirection={!showOnDesktop ? 'column' : 'row'}
              >
                <Box pr={!showOnDesktop ? 0 : 2}>
                  <StyledTextField
                    id="localFeeFirstStudentField"
                    data-testid="localFeeId"
                    sx={{
                      ...(!showOnDesktop && { width: '91vw' }),
                    }}
                    name={ProgramFeeNames.LocalFeeFirstStudent}
                    label={t(
                      'Programs.ProgramFormCard.FormField.LocalFeeFirstStudent',
                      'Local Fee First Student'
                    )}
                    variant="filled"
                    value={fees[ProgramFeeNames.LocalFeeFirstStudent]}
                    onChange={handleInputChange}
                    error={
                      !validFields[ProgramFeeNames.LocalFeeFirstStudent].input
                    }
                    helperText={
                      !!feeInfo[ProgramFeeNames.LocalFeeFirstStudent].minimum
                        ? validFields[ProgramFeeNames.LocalFeeFirstStudent]
                            .input
                          ? `${
                              feeInfo[ProgramFeeNames.LocalFeeFirstStudent]
                                .minimum
                            } ${minimumLocalized}`
                          : `${feeHelperText} ${
                              feeInfo[ProgramFeeNames.LocalFeeFirstStudent]
                                .minimum
                            } of ${ProgramFeeNames.LocalFeeFirstStudent}.`
                        : null
                    }
                    disabled={
                      isFeeFieldDisabled ||
                      !feeInfo[ProgramFeeNames.LocalFeeFirstStudent].allowEdit
                    }
                  />
                </Box>

                <StyledTextField
                  id="localFeeAdditionalStudentsField"
                  name={ProgramFeeNames.LocalFeeAdditionalStudent}
                  label={t(
                    'Programs.ProgramFormCard.FormField.LocalFeeAdditionalStudents',
                    'Local Fee Additional Students'
                  )}
                  variant="filled"
                  value={fees[ProgramFeeNames.LocalFeeAdditionalStudent]}
                  onChange={handleInputChange}
                  error={
                    !validFields[ProgramFeeNames.LocalFeeAdditionalStudent]
                      .input
                  }
                  helperText={
                    !!feeInfo[ProgramFeeNames.LocalFeeAdditionalStudent].minimum
                      ? validFields[ProgramFeeNames.LocalFeeAdditionalStudent]
                          .input
                        ? `${
                            feeInfo[ProgramFeeNames.LocalFeeAdditionalStudent]
                              .minimum
                          } ${minimumLocalized}`
                        : `${feeHelperText} ${
                            feeInfo[ProgramFeeNames.LocalFeeAdditionalStudent]
                              .minimum
                          } of ${ProgramFeeNames.LocalFeeAdditionalStudent}.`
                      : null
                  }
                  disabled={
                    isFeeFieldDisabled ||
                    !feeInfo[ProgramFeeNames.LocalFeeAdditionalStudent]
                      ?.allowEdit
                  }
                />
              </Box>

              <Box
                display="flex"
                flexDirection={!showOnDesktop ? 'column' : 'row'}
              >
                <Box pr={!showOnDesktop ? 0 : 2}>
                  <StyledTextField
                    id="facilityFeeFirstStudentField"
                    data-testid="localFacilityId"
                    sx={{
                      ...(!showOnDesktop && { width: '91vw' }),
                    }}
                    name={ProgramFeeNames.FacilityFeeFirstStudent}
                    label={t(
                      'Programs.ProgramFormCard.FormField.FacilityFeeFistStudent',
                      'Facility Fee First Student'
                    )}
                    variant="filled"
                    value={fees[ProgramFeeNames.FacilityFeeFirstStudent]}
                    onChange={handleInputChange}
                    error={
                      !validFields[ProgramFeeNames.FacilityFeeFirstStudent]
                        .input
                    }
                    helperText={
                      !!feeInfo[ProgramFeeNames.FacilityFeeFirstStudent].minimum
                        ? validFields[ProgramFeeNames.FacilityFeeFirstStudent]
                            .input
                          ? `${
                              feeInfo[ProgramFeeNames.FacilityFeeFirstStudent]
                                .minimum
                            } ${minimumLocalized}`
                          : `${feeHelperText} ${
                              feeInfo[ProgramFeeNames.FacilityFeeFirstStudent]
                                .minimum
                            } of ${ProgramFeeNames.FacilityFeeFirstStudent}.`
                        : null
                    }
                    disabled={
                      isFeeFieldDisabled ||
                      !feeInfo[ProgramFeeNames.FacilityFeeFirstStudent]
                        .allowEdit
                    }
                  />
                </Box>

                <StyledTextField
                  id="facilityFeeAdditionalStudentsField"
                  name={ProgramFeeNames.FacilityFeeAdditionalStudent}
                  label={t(
                    'Programs.ProgramFormCard.FormField.FacilityFeeAdditionalStudents',
                    'Facility Fee Additional Students'
                  )}
                  variant="filled"
                  value={fees[ProgramFeeNames.FacilityFeeAdditionalStudent]}
                  onChange={handleInputChange}
                  error={
                    !validFields[ProgramFeeNames.FacilityFeeAdditionalStudent]
                      .input
                  }
                  helperText={
                    !!feeInfo[ProgramFeeNames.FacilityFeeAdditionalStudent]
                      .minimum
                      ? validFields[
                          ProgramFeeNames.FacilityFeeAdditionalStudent
                        ].input
                        ? `${
                            feeInfo[
                              ProgramFeeNames.FacilityFeeAdditionalStudent
                            ].minimum
                          } ${minimumLocalized}`
                        : `${feeHelperText} ${
                            feeInfo[
                              ProgramFeeNames.FacilityFeeAdditionalStudent
                            ].minimum
                          } of ${ProgramFeeNames.FacilityFeeAdditionalStudent}.`
                      : null
                  }
                  disabled={
                    isFeeFieldDisabled ||
                    !feeInfo[ProgramFeeNames.FacilityFeeAdditionalStudent]
                      .allowEdit
                  }
                />
              </Box>
              {(props.programAbility?.can('view', 'Program') ||
                (useAddProgramVariant &&
                  permissionAbility.can('create', 'Program'))) && (
                <StyledTextField
                  id="semesterOneLicensingFeeField"
                  name={ProgramFeeNames.SemesterOneLicensingFee}
                  label={t(
                    'Programs.ProgramFormCard.FormField.SemesterOneLicensingFee',
                    'Semester One First Student Licensing Fee'
                  )}
                  variant="filled"
                  value={fees[ProgramFeeNames.SemesterOneLicensingFee]}
                  onChange={handleInputChange}
                  error={
                    !validFields[ProgramFeeNames.SemesterOneLicensingFee].input
                  }
                  helperText={
                    !!feeInfo[ProgramFeeNames.SemesterOneLicensingFee].minimum
                      ? validFields[ProgramFeeNames.SemesterOneLicensingFee]
                          .input
                        ? `${
                            feeInfo[ProgramFeeNames.SemesterOneLicensingFee]
                              .minimum
                          } ${minimumLocalized}`
                        : `${feeHelperText} ${
                            feeInfo[ProgramFeeNames.SemesterOneLicensingFee]
                              .minimum
                          }.`
                      : null
                  }
                  disabled={
                    isFeeFieldDisabled ||
                    !feeInfo[ProgramFeeNames.SemesterOneLicensingFee].allowEdit
                  }
                />
              )}
              {(props.programAbility?.can('view', 'Program') ||
                (useAddProgramVariant &&
                  permissionAbility.can('create', 'Program'))) && (
                <StyledTextField
                  id="semesterTwoLicensingFeeField"
                  name={ProgramFeeNames.SemesterTwoLicensingFee}
                  label={t(
                    'Programs.ProgramFormCard.FormField.SemesterTwoLicensingFee',
                    'Semester Two First Student Licensing Fee'
                  )}
                  variant="filled"
                  value={fees[ProgramFeeNames.SemesterTwoLicensingFee]}
                  onChange={handleInputChange}
                  error={
                    !validFields[ProgramFeeNames.SemesterTwoLicensingFee].input
                  }
                  helperText={
                    !!feeInfo[ProgramFeeNames.SemesterTwoLicensingFee].minimum
                      ? validFields[ProgramFeeNames.SemesterTwoLicensingFee]
                          .input
                        ? `${
                            feeInfo[ProgramFeeNames.SemesterTwoLicensingFee]
                              .minimum
                          } ${minimumLocalized}`
                        : `${feeHelperText} ${
                            feeInfo[ProgramFeeNames.SemesterTwoLicensingFee]
                              .minimum
                          }.`
                      : null
                  }
                  disabled={
                    isFeeFieldDisabled ||
                    !feeInfo[ProgramFeeNames.SemesterTwoLicensingFee].allowEdit
                  }
                />
              )}
              <StyledTextField
                id="multiStudentApplicationDiscountField"
                name={ProgramFeeNames.MultiStudentApplicationDiscount}
                label={t(
                  'Programs.ProgramFormCard.FormField.MultiStudentApplicationDiscount',
                  'Multi-Student Application Fee Discount'
                )}
                variant="filled"
                value={fees[ProgramFeeNames.MultiStudentApplicationDiscount]}
                onChange={handleInputChange}
                error={
                  !validFields[ProgramFeeNames.MultiStudentApplicationDiscount]
                    .input
                }
                helperText={
                  !!feeInfo[ProgramFeeNames.MultiStudentApplicationDiscount]
                    .minimum
                    ? validFields[
                        ProgramFeeNames.MultiStudentApplicationDiscount
                      ].input
                      ? `${
                          feeInfo[
                            ProgramFeeNames.MultiStudentApplicationDiscount
                          ].minimum
                        } ${minimumLocalized}`
                      : `${feeHelperText} ${
                          feeInfo[
                            ProgramFeeNames.MultiStudentApplicationDiscount
                          ].minimum
                        }.`
                    : null
                }
                disabled={
                  isFeeFieldDisabled ||
                  !feeInfo[ProgramFeeNames.MultiStudentApplicationDiscount]
                    .allowEdit
                }
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="column" pb={3}>
              <Box
                display="flex"
                flexDirection={!showOnDesktop ? 'column' : 'row'}
              >
                <Box pr={!showOnDesktop ? 0 : 2}>
                  <StyledTextField
                    id="supplyFeeFieldFirstStudentField"
                    data-testid="supplyFeeId"
                    sx={{
                      ...(!showOnDesktop && { width: '91vw' }),
                    }}
                    name={ProgramFeeNames.SupplyFeeFirstStudent}
                    label={t(
                      'Programs.ProgramFormCard.FormField.SupplyFeeFirstStudent',
                      'Supply Fee First Student'
                    )}
                    variant="filled"
                    value={fees[ProgramFeeNames.SupplyFeeFirstStudent]}
                    onChange={handleInputChange}
                    error={
                      !validFields[ProgramFeeNames.SupplyFeeFirstStudent].input
                    }
                    helperText={
                      !!feeInfo[ProgramFeeNames.SupplyFeeFirstStudent].minimum
                        ? validFields[ProgramFeeNames.SupplyFeeFirstStudent]
                            .input
                          ? `${
                              feeInfo[ProgramFeeNames.SupplyFeeFirstStudent]
                                .minimum
                            } ${minimumLocalized}`
                          : `${feeHelperText} ${
                              feeInfo[ProgramFeeNames.SupplyFeeFirstStudent]
                                .minimum
                            } of ${ProgramFeeNames.SupplyFeeFirstStudent}.`
                        : null
                    }
                    disabled={
                      isFeeFieldDisabled ||
                      !feeInfo[ProgramFeeNames.SupplyFeeFirstStudent].allowEdit
                    }
                  />
                </Box>

                <StyledTextField
                  id="supplyFeeFieldAdditionalStudentField"
                  name={ProgramFeeNames.SupplyFeeAdditionalStudent}
                  label={t(
                    'Programs.ProgramFormCard.FormField.SupplyFeedAdditionalStudent',
                    'Supply Fee Additional Students'
                  )}
                  variant="filled"
                  value={fees[ProgramFeeNames.SupplyFeeAdditionalStudent]}
                  onChange={handleInputChange}
                  error={
                    !validFields[ProgramFeeNames.SupplyFeeAdditionalStudent]
                      .input
                  }
                  helperText={
                    !!feeInfo[ProgramFeeNames.SupplyFeeAdditionalStudent]
                      .minimum
                      ? validFields[ProgramFeeNames.SupplyFeeAdditionalStudent]
                          .input
                        ? `${
                            feeInfo[ProgramFeeNames.SupplyFeeAdditionalStudent]
                              .minimum
                          } ${minimumLocalized}`
                        : `${feeHelperText} ${
                            feeInfo[ProgramFeeNames.SupplyFeeAdditionalStudent]
                              .minimum
                          } of ${ProgramFeeNames.SupplyFeeAdditionalStudent}.`
                      : null
                  }
                  disabled={
                    isFeeFieldDisabled ||
                    !feeInfo[ProgramFeeNames.SupplyFeeAdditionalStudent]
                      .allowEdit
                  }
                />
              </Box>

              <Box
                display="flex"
                flexDirection={!showOnDesktop ? 'column' : 'row'}
              >
                <Box pr={!showOnDesktop ? 0 : 2}>
                  <StyledTextField
                    id="miscFeedFirstStudentField"
                    sx={{
                      ...(!showOnDesktop && { width: '91vw' }),
                    }}
                    name={ProgramFeeNames.MiscFeeFirstStudent}
                    label={t(
                      'Programs.ProgramFormCard.FormField.MiscFeeFirstStudent',
                      'Misc Fee First Student'
                    )}
                    variant="filled"
                    value={fees[ProgramFeeNames.MiscFeeFirstStudent]}
                    onChange={handleInputChange}
                    error={
                      !validFields[ProgramFeeNames.MiscFeeFirstStudent].input
                    }
                    helperText={
                      !!feeInfo[ProgramFeeNames.MiscFeeFirstStudent].minimum
                        ? validFields[ProgramFeeNames.MiscFeeFirstStudent].input
                          ? `${
                              feeInfo[ProgramFeeNames.MiscFeeFirstStudent]
                                .minimum
                            } ${minimumLocalized}`
                          : `${feeHelperText} ${
                              feeInfo[ProgramFeeNames.MiscFeeFirstStudent]
                                .minimum
                            } of ${ProgramFeeNames.MiscFeeFirstStudent}.`
                        : null
                    }
                    disabled={
                      isFeeFieldDisabled ||
                      !feeInfo[ProgramFeeNames.MiscFeeFirstStudent].allowEdit
                    }
                  />
                </Box>

                <StyledTextField
                  id="miscFeedAdditionalStudentsField"
                  name={ProgramFeeNames.MiscFeeAdditionalStudent}
                  label={t(
                    'Programs.ProgramFormCard.FormField.MiscFeedAdditionalStudent',
                    'Misc Fee Additional Students'
                  )}
                  variant="filled"
                  value={fees[ProgramFeeNames.MiscFeeAdditionalStudent]}
                  onChange={handleInputChange}
                  error={
                    !validFields[ProgramFeeNames.MiscFeeAdditionalStudent].input
                  }
                  helperText={
                    !!feeInfo[ProgramFeeNames.MiscFeeAdditionalStudent].minimum
                      ? validFields[ProgramFeeNames.MiscFeeAdditionalStudent]
                          .input
                        ? `${
                            feeInfo[ProgramFeeNames.MiscFeeAdditionalStudent]
                              .minimum
                          } ${minimumLocalized}`
                        : `${feeHelperText} ${
                            feeInfo[ProgramFeeNames.MiscFeeAdditionalStudent]
                              .minimum
                          } of ${ProgramFeeNames.MiscFeeAdditionalStudent}.`
                      : null
                  }
                  disabled={
                    isFeeFieldDisabled ||
                    !feeInfo[ProgramFeeNames.MiscFeeAdditionalStudent].allowEdit
                  }
                />
              </Box>
              {(props.programAbility?.can('view', 'Program') ||
                (useAddProgramVariant &&
                  permissionAbility.can('create', 'Program'))) && (
                <StyledTextField
                  id="invitationFeeField"
                  name={ProgramFeeNames.InvitationFee}
                  label={t(
                    'Programs.ProgramFormCard.FormField.InvitationFee',
                    'First Student Licensing (Application)'
                  )}
                  variant="filled"
                  value={fees[ProgramFeeNames.InvitationFee]}
                  onChange={handleInputChange}
                  error={!validFields[ProgramFeeNames.InvitationFee].input}
                  helperText={
                    !!feeInfo[ProgramFeeNames.InvitationFee].minimum
                      ? validFields[ProgramFeeNames.InvitationFee].input
                        ? `${
                            feeInfo[ProgramFeeNames.InvitationFee].minimum
                          } ${minimumLocalized}`
                        : `${feeHelperText} ${
                            feeInfo[ProgramFeeNames.InvitationFee].minimum
                          }.`
                      : null
                  }
                  disabled={
                    isFeeFieldDisabled ||
                    !feeInfo[ProgramFeeNames.InvitationFee].allowEdit
                  }
                />
              )}
              {(props.programAbility?.can('view', 'Program') ||
                (useAddProgramVariant &&
                  permissionAbility.can('create', 'Program'))) && (
                <StyledTextField
                  id="discountInvitationFeeField"
                  name={ProgramFeeNames.DiscountInvitationFee}
                  label={t(
                    'Programs.ProgramFormCard.FormField.InvitationFee',
                    'Additional Students Licensing (Application)'
                  )}
                  variant="filled"
                  value={fees[ProgramFeeNames.DiscountInvitationFee]}
                  onChange={handleInputChange}
                  error={
                    !validFields[ProgramFeeNames.DiscountInvitationFee].input
                  }
                  helperText={
                    !!feeInfo[ProgramFeeNames.DiscountInvitationFee].minimum
                      ? validFields[ProgramFeeNames.DiscountInvitationFee].input
                        ? `${
                            feeInfo[ProgramFeeNames.DiscountInvitationFee]
                              .minimum
                          } ${minimumLocalized}`
                        : `${feeHelperText} ${
                            feeInfo[ProgramFeeNames.DiscountInvitationFee]
                              .minimum
                          }.`
                      : null
                  }
                  disabled={
                    isFeeFieldDisabled ||
                    !feeInfo[ProgramFeeNames.DiscountInvitationFee].allowEdit
                  }
                />
              )}
              <StyledTextField
                id="membershipFeeField"
                data-testid="membershipFeeId"
                name={ProgramFeeNames.EnrollmentFee}
                label={t(
                  'Programs.ProgramFormCard.FormField.MembershipFee',
                  'Membership Fee'
                )}
                variant="filled"
                value={fees[ProgramFeeNames.EnrollmentFee]}
                onChange={handleInputChange}
                error={!validFields[ProgramFeeNames.EnrollmentFee].input}
                helperText={
                  !!feeInfo[ProgramFeeNames.EnrollmentFee].minimum
                    ? validFields[ProgramFeeNames.EnrollmentFee].input
                      ? `${
                          feeInfo[ProgramFeeNames.EnrollmentFee].minimum
                        } ${minimumLocalized}`
                      : `${feeHelperText} ${
                          feeInfo[ProgramFeeNames.EnrollmentFee].minimum
                        }.`
                    : null
                }
                disabled={
                  isFeeFieldDisabled ||
                  !feeInfo[ProgramFeeNames.EnrollmentFee].allowEdit
                }
              />

              {(props.programAbility?.can('view', 'Program') ||
                (useAddProgramVariant &&
                  permissionAbility.can('create', 'Program'))) && (
                <StyledTextField
                  id="subLicensedTutorPercentageField"
                  name={ProgramFeeNames.SubLicensedTutorPercentage}
                  label={t(
                    'Programs.ProgramFormCard.FormField.SubLicensedTutorPercentage',
                    'Percentage of Tuition Collected by Tutor'
                  )}
                  variant="filled"
                  value={fees[ProgramFeeNames.SubLicensedTutorPercentage]}
                  onChange={handleInputChange}
                  error={
                    !validFields[ProgramFeeNames.SubLicensedTutorPercentage]
                      .input
                  }
                  helperText={
                    !!feeInfo[ProgramFeeNames.SubLicensedTutorPercentage]
                      .minimum
                      ? validFields[ProgramFeeNames.SubLicensedTutorPercentage]
                          .input
                        ? `${
                            feeInfo[ProgramFeeNames.SubLicensedTutorPercentage]
                              .minimum
                          } ${minimumLocalized}`
                        : `${feeHelperText} ${
                            feeInfo[ProgramFeeNames.SubLicensedTutorPercentage]
                              .minimum
                          }.`
                      : null
                  }
                  disabled={
                    isFeeFieldDisabled ||
                    !feeInfo[ProgramFeeNames.SubLicensedTutorPercentage]
                      .allowEdit
                  }
                />
              )}
              {(props.programAbility?.can('view', 'Program') ||
                (useAddProgramVariant &&
                  permissionAbility.can('create', 'Program'))) && (
                <StyledTextField
                  id="discountSemesterOneLicensingFeeField"
                  name={ProgramFeeNames.DiscountSemesterOneLicensingFee}
                  label={t(
                    'Programs.ProgramFormCard.FormField.DiscountSemesterOneLicensingFee',
                    'Semester One Additional Students Licensing Fee'
                  )}
                  variant="filled"
                  value={fees[ProgramFeeNames.DiscountSemesterOneLicensingFee]}
                  onChange={handleInputChange}
                  error={
                    !validFields[
                      ProgramFeeNames.DiscountSemesterOneLicensingFee
                    ].input
                  }
                  helperText={
                    !!feeInfo[ProgramFeeNames.DiscountSemesterOneLicensingFee]
                      .minimum
                      ? validFields[
                          ProgramFeeNames.DiscountSemesterOneLicensingFee
                        ].input
                        ? `${
                            feeInfo[
                              ProgramFeeNames.DiscountSemesterOneLicensingFee
                            ].minimum
                          } ${minimumLocalized}`
                        : `${feeHelperText} ${
                            feeInfo[
                              ProgramFeeNames.DiscountSemesterOneLicensingFee
                            ].minimum
                          }.`
                      : null
                  }
                  disabled={
                    isFeeFieldDisabled ||
                    !feeInfo[ProgramFeeNames.DiscountSemesterOneLicensingFee]
                      .allowEdit
                  }
                />
              )}
              {(props.programAbility?.can('view', 'Program') ||
                (useAddProgramVariant &&
                  permissionAbility.can('create', 'Program'))) && (
                <StyledTextField
                  id="discountSemesterTwoLicensingFeeField"
                  name={ProgramFeeNames.DiscountSemesterTwoLicensingFee}
                  label={t(
                    'Programs.ProgramFormCard.FormField.DiscountSemesterTwoLicensingFee',
                    'Semester Two Additional Students Licensing Fee'
                  )}
                  variant="filled"
                  value={fees[ProgramFeeNames.DiscountSemesterTwoLicensingFee]}
                  onChange={handleInputChange}
                  error={
                    !validFields[
                      ProgramFeeNames.DiscountSemesterTwoLicensingFee
                    ].input
                  }
                  helperText={
                    !!feeInfo[ProgramFeeNames.DiscountSemesterTwoLicensingFee]
                      .minimum
                      ? validFields[
                          ProgramFeeNames.DiscountSemesterTwoLicensingFee
                        ].input
                        ? `${
                            feeInfo[
                              ProgramFeeNames.DiscountSemesterTwoLicensingFee
                            ].minimum
                          } ${minimumLocalized}`
                        : `${feeHelperText} ${
                            feeInfo[
                              ProgramFeeNames.DiscountSemesterTwoLicensingFee
                            ].minimum
                          }.`
                      : null
                  }
                  disabled={
                    isFeeFieldDisabled ||
                    !feeInfo[ProgramFeeNames.DiscountSemesterTwoLicensingFee]
                      .allowEdit
                  }
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </form>
    </section>
  )
}

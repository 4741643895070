import React, { useCallback, useEffect, useMemo, useState } from 'react'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import { useTranslation } from 'react-i18next'
import { Box, Card, Divider, useTheme } from '@mui/material'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import CardFormHeader from '../Card/CardFormHeader'
import Header, { HeaderVariant } from '../Elements/Header'
import SpaceBetweenSection from '../Elements/SpaceBetweenSection'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import useEndpoint from '../../hooks/useEndpoint'
import {
  FetchMetricMetricIdEnum,
  FetchMetricsResponseBodyMetrics,
} from '../../swagger'
import {
  dashboardApi
} from '../../api/swagger'
import { getDashboardColor } from './utils/getDashboardColor'
import { InfoTooltip } from '../Elements/InfoTooltip'
import { useUser } from '../../UserContext'
import Typography from '@mui/material/Typography'
import { SpaceBetweenSectionPlaceholder } from '../Placeholders/SpaceBetweenSectionPlaceholder'

const DashboardSummary: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const abortController = new AbortController()

  const showOnDesktop = useShowOnDesktop()
  const {
    DashboardTab: { fetchMetrics },
  } = useLoadingIds()

  const { selectedActorRole } = useUser()

  const actorRoleValid = useMemo(
    () =>
      `${selectedActorRole?.subtitle && '/'} ${
        selectedActorRole?.subtitle ?? ''
      }`,
    [selectedActorRole?.subtitle]
  )

  const actorRoleName = useMemo(
    () => `${selectedActorRole?.name} ${actorRoleValid}`,
    [actorRoleValid, selectedActorRole?.name]
  )

  const [metrics, setMetrics] = useState<FetchMetricsResponseBodyMetrics[]>()

  const { triggerFetch } = useEndpoint({
    loadingId: fetchMetrics,
    abortController,
    swaggerCall: async (abortController?: AbortController) => {
      const response = await dashboardApi.fetchMetrics({ $signal: abortController?.signal })
      setMetrics(response.metrics)
    },
  })

  useEffect(() => {
    triggerFetch()
  }, [selectedActorRole, triggerFetch])

  const summaryLinePlaceholder = useCallback(
    (value: string) => (
      <Box key={value}>
        <Divider />
        <Box
          sx={{
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            marginBottom: theme.spacing(2),
          }}
        >
          <SpaceBetweenSectionPlaceholder />
        </Box>
      </Box>
    ),
    [theme]
  )

  const Metrics = useMemo(
    () =>
      metrics?.map(({ metricName, values, style, actionLink }) => {
        return (
          <Box key={metricName}>
            <Divider />
            <Box
              sx={{
                marginTop: theme.spacing(2),
                marginLeft: theme.spacing(2),
                marginBottom: theme.spacing(2),
              }}
            >
              <SpaceBetweenSection
                containerStyles={{
                  [theme.breakpoints.down('md')]: {
                    flexDirection: 'column',
                  },
                }}
                leftStyle={{
                  width: '60%',
                }}
                left={
                  <Typography
                    variant="subtitle2"
                    color={theme.palette.primary.dark}
                  >
                    {metricName}:
                  </Typography>
                }
                center={
                  <Typography
                    variant="body2"
                    component="span"
                    fontWeight={700}
                    sx={{
                      color: getDashboardColor(style, theme),
                      typography: 'h5',
                    }}
                  >
                    {values[0]?.toString() ?? ''}
                  </Typography>
                }
                right={
                  <ContainedButton
                    id={metricName}
                    css={{
                      [theme.breakpoints.down('md')]: {
                        marginLeft: 0,
                      },
                      whiteSpace: 'nowrap',
                    }}
                    variant={ContainedButtonVariant.ClickForDetails}
                    to={actionLink}
                    disabled={values.every((value) => value === 0)}
                  />
                }
              />
            </Box>
          </Box>
        )
      }) ?? [
        Object.values(FetchMetricMetricIdEnum).map((value) =>
          summaryLinePlaceholder(value)
        ),
      ],
    [metrics, theme, summaryLinePlaceholder]
  )

  return (
    <>
      <DynamicBreadcrumbs
        breadcrumbs={[
          {
            onClick: undefined,
            label: t(
              'Account.Dashboard.Breadcrumb',
              '{{actorRoleName}} - Dashboard',
              { actorRoleName }
            ),
          },
        ]}
      />
      <Card
        sx={{
          color: theme.palette.primary.main,
          marginBottom: theme.spacing(4),
          mt: theme.spacing(4),
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          minWidth="25%"
          alignContent={'center'}
          px={showOnDesktop ? 0 : 4}
        >
          <CardFormHeader
            header={
              <Box>
                <Header
                  id="dashboardHeader"
                  headerName={t(
                    'DashboardCard.Header.CurrentStatistics',
                    'Current Statistics'
                  )}
                  component="h3"
                  variant={HeaderVariant.Card}
                  endAdornment={
                    <InfoTooltip
                      message={t(
                        'DashboardCard.Header.HyperLinkInstruction',
                        'Select CLICK FOR DETAILS button for any of the links below'
                      )}
                    />
                  }
                />
              </Box>
            }
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mx: theme.spacing(4),
            width: '50%',
            [theme.breakpoints.down('md')]: {
              width: '80%',
              margin: '0 auto',
            },
          }}
        >
          {Metrics}
        </Box>
      </Card>
    </>
  )
}

export default DashboardSummary

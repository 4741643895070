import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  FetchCountryCoordinatorLicensingDetailsResponse,
  paymentsApi,
} from '../api/swagger'
import { useTranslation } from 'react-i18next'
import { useLoadingIds } from './useLoadingIds'
import useLoadingContext from './useLoadingContext'
import { useErrorSnackbar } from './useErrorSnackbar'

interface CountryCoordinatorBillingHook {
  billingDetails: FetchCountryCoordinatorLicensingDetailsResponse | undefined
  errorFetchingDetails: boolean
  isLoadingBillingDetails: boolean
  fetchBillingDetailsForCoco: (programKey?: number) => void
  setBillingDetailsForCoco: Dispatch<
    SetStateAction<FetchCountryCoordinatorLicensingDetailsResponse | undefined>
  >
}

const useCountryCoordinatorBillingDetails = (
  programKeyToFetch?: number
): CountryCoordinatorBillingHook => {
  const { t } = useTranslation()
  const { handleError } = useErrorSnackbar()

  const {
    PaymentApi: {
      fetchCountryCoordinatorLicensingDetails:
        fetchCountryCoordinatorLicensingDetailsLoadingId,
    },
  } = useLoadingIds()

  const [billingDetailsForCoco, setBillingDetailsForCoco] = useState<
    FetchCountryCoordinatorLicensingDetailsResponse | undefined
  >()

  const [errorFetchingDetails, setErrorFetchingDetails] = useState(false)

  const fetchBillingDetailsForCocoFn = async () => {
    if (programKeyToFetch === undefined) return

    try {
      const fetchedLicensingDetails =
        await paymentsApi.fetchCountryCoordinatorLicensingDetails({
          programKey: programKeyToFetch,
        })

      setBillingDetailsForCoco(fetchedLicensingDetails)
      setErrorFetchingDetails(false)
    } catch (error) {
      setErrorFetchingDetails(true)
      await handleError(
        error,
        t(
          'useCountryCoordinatorBillingDetails.fetchBillingDetailsForCocoFn.Error',
          'Failed to fetch billing details'
        )
      )
    }
  }

  const { triggerFetch: fetchBillingDetailsForCoco, isLoading } =
    useLoadingContext({
      asyncFunction: fetchBillingDetailsForCocoFn,
      loadingId: fetchCountryCoordinatorLicensingDetailsLoadingId,
    })

  useEffect(() => {
    fetchBillingDetailsForCoco()
  }, [fetchBillingDetailsForCoco, programKeyToFetch])

  return {
    billingDetails: billingDetailsForCoco,
    errorFetchingDetails,
    fetchBillingDetailsForCoco,
    setBillingDetailsForCoco,
    isLoadingBillingDetails: isLoading,
  }
}

export default useCountryCoordinatorBillingDetails

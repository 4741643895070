import React from 'react'
import { PersonAdd, PersonRemove } from '@mui/icons-material'
import { Tooltip, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ProgramType, TransferStatus } from '../../swagger'
import { dateToSlashStringReinterpretedAsLocal } from '../../utils/dateUtility'

export type TransferStatusIconProps = {
  transferStatus: TransferStatus
  transferDate: Date
  communityName: string
  programName: ProgramType
}
export const TransferStatusIcon: React.FC<TransferStatusIconProps> = ({
  transferStatus,
  transferDate,
  communityName,
  programName,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const Icon =
    transferStatus === TransferStatus.TransferIn ? PersonAdd : PersonRemove

  const title = transferStatus.replace(/([a-z](?=[A-Z]))/g, '$1 ')
  const transferDateStr = dateToSlashStringReinterpretedAsLocal(transferDate)

  return (
    <Tooltip
      title={
        <>
          <Typography variant="subtitle1">
            {t('TransferStatusIcon.Tooltip.Title', '{{ title }}', { title })}
          </Typography>
          <Typography variant="body2">
            {t(
              'TransferStatusIcon.Tooltip.TransferDate',
              'Date: {{transferDate}}',
              {
                transferDate: transferDateStr,
              }
            )}
          </Typography>
          <Typography variant="body2">
            {t(
              'TransferStatusIcon.Tooltip.CommunityName',
              'Community: {{communityName}}',
              {
                communityName,
              }
            )}
          </Typography>
          <Typography variant="body2">
            {t(
              'TransferStatusIcon.Tooltip.ProgramName',
              'Program: {{programName}}',
              {
                programName,
              }
            )}
          </Typography>
        </>
      }
    >
      <Icon
        sx={{
          color: theme.palette.primary.dark,
        }}
      />
    </Tooltip>
  )
}

export default TransferStatusIcon

import { getAmountFromLocaleCurrency } from '../../../utils/getAmountFromLocalCurrency'

export const validateAmount = (amount: string): boolean => {
  // Verify amount is non-empty
  if (!amount.trim()) {
    return false
  }

  const amountFromCurrency = getAmountFromLocaleCurrency(amount)

  // Ensure the conversion to number is valid and amount is non-zero
  if (isNaN(amountFromCurrency) || amountFromCurrency === 0) {
    return false
  }

  // For now we only care that something is non-zero
  return true
}

import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import {
  ContentAsset,
  FetchLearningCenterOptionsProgramTypeEnum,
  LearningCenterOptions,
} from '../../swagger'
import {
  FilterFileByChallengeLevel,
  FilterFileType,
  LearningCenterTabs,
} from '../../utils/searchAndFilterEnums'
import { OperationIds } from '../../swagger/operationIdEnum'

export enum LearningCenterCategories {
  AllCategories = 'All Categories',
  ArtMusic = 'Art & Music',
  English = 'English',
  Geography = 'Geography',
  LatinEnglish = 'Latin/English',
  SupplementalParentResource = 'Supplemental Parent Resource',
  LogicStrand = 'Logic Strand',
  GrammarStrand = 'Grammar Strand',
}

interface SearchAndFilterValues {
  searchQuery: string
  category: LearningCenterCategories
  contentType: FilterFileType
  includeOnlyFavorites: boolean
  cycle?: number
}

type AccordionState = {
  week: string
  expanded: boolean
}

type AccordionCycleWeeks = Array<{ cycle: string; weeks: AccordionState[] }>

type AccordionStateByNonChallenge = Exclude<
  LearningCenterTabs,
  LearningCenterTabs.Challenge
>

export type AccordionStateByProgramType = Record<
  AccordionStateByNonChallenge | FilterFileByChallengeLevel,
  AccordionCycleWeeks
>

/** Made exportable to validate defaults are used initially and maintained on resetContextToDefaults */
export const defaultLearningCenterContextValue = {
  /** The academicYear for the filter in Learning Center  */
  academicYear: undefined as number | undefined,
  /** Method to allow updates to the academicYear in LearningCenter */
  updateAcademicYear: (academicYear: number): void => {
    console.warn(
      `The LearningCenterContext.updateAcademicYear was called with value ${academicYear}. Did you forget to use a LearningCenterProvider?`
    )
  },
  /** Learning Center options for the filters */
  learningCenterOptions: {
    categories: [],
    filetypes: [],
    programTypes: [],
    cycles: [],
  } as LearningCenterOptions,
  /** Method to update learningCenterOptions, particularly when the tabs change */
  updateLearningCenterOptions: (newOptions: LearningCenterOptions): void => {
    console.warn(
      `The LearningCenterContext.updateLearningCenterOptions was called with value ${newOptions}. Did you forget to use a LearningCenterProvider?`
    )
  },

  /**
   * Filter Options below are updated when selected to the value selected and
   * updated to their defaults when logging out or selecting a new ActingAs.
   */
  searchQuery: '' as string,
  updateSearchQuery: (newSearchQuery: string): void => {
    console.warn(
      `The LearningCenterContext.updateSearchQuery was called with value ${newSearchQuery}. Did you forget to use a LearningCenterProvider?`
    )
  },
  category: LearningCenterCategories.AllCategories,
  updateCategory: (newCategory: LearningCenterCategories): void => {
    console.warn(
      `The LearningCenterContext.updateCategory was called with value ${newCategory}. Did you forget to use a LearningCenterProvider?`
    )
  },
  contentType: FilterFileType.AllTypes as FilterFileType,
  updateContentType: (newContentType: FilterFileType): void => {
    console.warn(
      `The LearningCenterContext.updateContentType was called with value ${newContentType}. Did you forget to use a LearningCenterProvider?`
    )
  },
  cycle: undefined as number | undefined,
  updateCycle: (newCycle: number): void => {
    console.warn(
      `The LearningCenterContext.updateCycle was called with value ${newCycle}. Did you forget to use a LearningCenterProvider?`
    )
  },

  level: undefined as FilterFileByChallengeLevel | undefined,
  updateLevel: (newLevel: FilterFileByChallengeLevel | undefined): void => {
    console.warn(
      `The LearningCenterContext.updateLevel was called with value ${newLevel}. Did you forget to use a LearningCenterProvider?`
    )
  },
  assets: [] as ContentAsset[],
  updateAssets: (newAssets: ContentAsset[]): void => {
    console.warn(
      `The LearningCenterContext.updateAssets was called with value ${newAssets}. Did you forget to use a LearningCenterProvider?`
    )
  },
  /** Key of the tab to load for LearningCenter */
  selectedTabKey: LearningCenterTabs.WelcomeCenter,
  updateSelectedTabKey: (newKey: LearningCenterTabs): void => {
    console.warn(
      `The LearningCenterContext.updateSelectedTabKey was called with value ${newKey}. Did you forget to use a LearningCenterProvider?`
    )
  },

  programTypeForOptions: undefined as
    | FetchLearningCenterOptionsProgramTypeEnum
    | undefined,
  updateProgramTypeForOptions: (
    newProgramType: FetchLearningCenterOptionsProgramTypeEnum | undefined
  ): void => {
    console.warn(
      `The LearningCenterContext.updateSelectedTabKey was called with value ${newProgramType}. Did you forget to use a LearningCenterProvider?`
    )
  },

  /**
   * State used to differentiate between challenge and nonChallenge accordions
   */
  selectedTabForAccordions: LearningCenterTabs.WelcomeCenter as
    | AccordionStateByNonChallenge
    | FilterFileByChallengeLevel,

  /**
   * LoadingId to use when initiating the fetchLearningCenterAvailableProgramTypes
   */
  programTypesLoadingId: OperationIds.FetchLearningCenterAvailableProgramTypes,
  /**
   * LoadingId to use when initiating the fetchLearningCenterOptions
   */
  optionsLoadingId: OperationIds.FetchLearningCenterOptions,
  /**
   * LoadingId to use when initiating the fetchLearningCenterContent
   */
  contentLoadingId: OperationIds.FetchLearningCenterContent,
  /**
   * programTypes applicable to the Challenge level filter
   */
  challengePrograms: undefined as string[] | undefined,
  /**
   * Yeah, this comment is just to see who actually reads comments.
   */
  updateChallengePrograms: (programs: string[]): void => {
    console.warn(
      `The LearningCenterContext.updateChallengePrograms was called with value ${programs}. Did you forget to use a LearningCenterProvider?`
    )
  },

  /**
   * Keep track of whether fetchLearningCenterContent includes only favorites,
   * or all the mundane content, too.
   */
  includeOnlyFavorites: false,
  updateIncludeOnlyFavorites: (newValue: boolean): void => {
    console.warn(
      `The LearningCenterContext.updateIncludeOnlyFavorites was called with value ${newValue}. Did you forget to use a LearningCenterProvider?`
    )
  },

  /**
   * Keep track of the welcome center parameter used in the fetchLearningCenterContent
   */
  welcomeCenterContent: true,
  updateWelcomeCenterContent: (newValue: boolean): void => {
    console.warn(
      `The LearningCenterContext.updateWelcomeCenterContent was called with value ${newValue}. Did you forget to use a LearningCenterProvider?`
    )
  },

  /**
   * Used to reset the default values of the context, generally when
   * logging out or changing the actingAs.
   */
  resetContextToDefaults: (): void => {
    console.warn(
      `The LearningCenterContext.resetContextToDefaults was called. Did you forget to use a LearningCenterProvider?`
    )
  },

  /**
   * Track search and filter values by tab selected. We'll need to reset the whole
   * object on resetContextToDefaults.
   */
  tabSpecificSearchAndFilter: Object.values(LearningCenterTabs)
    .map((it) => `${it}`)
    .reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: {
          searchQuery: '',
          category: LearningCenterCategories.AllCategories,
          contentType: FilterFileType.AllTypes,
          includeOnlyFavorites: false,
        },
      }
    }, {} as { [k: string]: SearchAndFilterValues }),

  updateTabSpecificSearchAndFilter: (newTabSpecificSearchAndFilter: {
    [k: string]: SearchAndFilterValues
  }): void => {
    console.warn(
      `The LearningCenterContext.updateTabSpecificSearchAndFilter was called with value ${newTabSpecificSearchAndFilter}. Did you forget to use a LearningCenterProvider?`
    )
  },

  /**
   * Update the filters for a new tab selection.
   */
  updateSearchAndFiltersForTab: (tabKey: LearningCenterTabs): void => {
    console.warn(
      `The LearningCenterContext.updateSearchAndFiltersForTab was called with value ${tabKey}. Did you forget to use a LearningCenterProvider?`
    )
  },

  accordionStateByProgramType: {
    [LearningCenterTabs.WelcomeCenter]: [],
    [LearningCenterTabs.Scribblers]: [],
    [LearningCenterTabs.Essentials]: [],
    [LearningCenterTabs.Foundations]: [],
    [FilterFileByChallengeLevel.ChallengeA]: [],
    [FilterFileByChallengeLevel.ChallengeB]: [],
    [FilterFileByChallengeLevel.Challenge1]: [],
    [FilterFileByChallengeLevel.Challenge2]: [],
    [FilterFileByChallengeLevel.Challenge3]: [],
    [FilterFileByChallengeLevel.Challenge4]: [],
  } as AccordionStateByProgramType,

  /**
   *  This function allows toggling the accordion component
   *  by updating the state for specific accordion program type.
   */
  updateAccordionStateByProgramType: (
    accordionStateByProgramType: AccordionStateByProgramType
  ): void => {
    console.warn(
      `The LearningCenterContext.updateAccordionStateByProgramType was called with value ${accordionStateByProgramType}. Did you forget to use a LearningCenterProvider?`
    )
  },
  /**
   *  This function allows toggling the accordion component
   *  by updating the state for the specified cycle and week of the selected tab.
   */
  updateWeeksStateByProgramTypeAndCycle: (
    week: string,
    cycle: string
  ): void => {
    console.warn(
      `The LearningCenterContext.updateWeeksStateByProgramTypeAndCycle was called with values ${week}, and ${cycle}. Did you forget to use a LearningCenterProvider?`
    )
  },
  /**
   * When a tab is first selected, load the cycles and weeks for each program type,
   * and set the accordion toggle's default value to 'false',
   * ensuring all accordions start in a collapsed state.
   */
  initializeProgramTypeCyclesAndWeeks: (
    cycle: string,
    weeks: string[]
  ): void => {
    console.warn(
      `The LearningCenterContext.initializeProgramTypeCyclesAndWeeks was called with values ${cycle}, and ${weeks}}. Did you forget to use a LearningCenterProvider?`
    )
  },
  /**
   * Updates the state of a specific asset within the learning center by its ID.
   * This function is used to modify the details of an asset, such as changing its favorited status.
   **/
  updateLearningCenterAssetById: (
    assetKey: number,
    newState: Partial<ContentAsset>
  ): void => {
    console.warn(
      `The LearningCenterContext.updateLearningCenterAssetById was called with values ${assetKey}, and ${newState}}. Did you forget to use a LearningCenterProvider?`
    )
  },
}

export const LearningCenterContext = React.createContext(
  defaultLearningCenterContextValue
)

export const useLearningCenterContext =
  (): typeof defaultLearningCenterContextValue =>
    React.useContext(LearningCenterContext)

export type TestLearningCenterConfig = typeof defaultLearningCenterContextValue

export interface TestLearningCenterContextProps extends PropsWithChildren {
  testConfig?: Partial<TestLearningCenterConfig>
}

/**
 * Provider tracking the search, filter, and selected tab on the LearningCenter.
 */

export const LearningCenterProvider: React.FC<
  TestLearningCenterContextProps
> = ({ testConfig, children }) => {
  const [academicYear, setAcademicYear] = useState<number | undefined>()
  const updateAcademicYear = (academicYear: number) => {
    setAcademicYear(academicYear)
  }

  const [learningCenterOptions, setLearningCenterOptions] =
    useState<LearningCenterOptions>({
      categories: [],
      filetypes: [],
      /**
       * This is deprecated, so we need a different variable for programTypes
       * which will use availableProgramTypes from fetchLearningCenterAvailableProgramTypes
       */
      programTypes: [],
      cycles: [],
    })

  const updateLearningCenterOptions = (newOptions: LearningCenterOptions) => {
    setLearningCenterOptions(newOptions)
  }

  const [searchQuery, setSearchQuery] = useState('')
  const updateSearchQuery = (newSearchQuery: string) => {
    setSearchQuery(newSearchQuery)
  }

  const [category, setCategory] = useState<LearningCenterCategories>(
    LearningCenterCategories.AllCategories
  )
  const updateCategory = (newCategory: LearningCenterCategories) => {
    setCategory(newCategory)
  }
  const [contentType, setContentType] = useState<FilterFileType>(
    FilterFileType.AllTypes
  )
  const updateContentType = (newContentType: FilterFileType) => {
    setContentType(newContentType)
  }

  const [cycle, setCycle] = useState<number | undefined>(
    defaultLearningCenterContextValue.cycle
  )
  const updateCycle = (newCycle: number) => {
    setCycle(newCycle)
  }

  const [level, setLevel] = useState<FilterFileByChallengeLevel | undefined>()
  const updateLevel = (newLevel: FilterFileByChallengeLevel | undefined) => {
    setLevel(newLevel)
  }

  const [assets, setAssets] = useState<ContentAsset[]>([])
  const updateAssets = (newAssets: ContentAsset[]) => {
    setAssets(newAssets)
  }

  const [selectedTabKey, setSelectedTabKey] = useState<LearningCenterTabs>(
    LearningCenterTabs.WelcomeCenter
  )
  const updateSelectedTabKey = (newKey: LearningCenterTabs) => {
    setSelectedTabKey(newKey)
  }

  /**
   * Maintains the options call when updating the tab, too. This is different
   * from the tab because we select the type of program, like Challenge A where
   * the tab would just be Challenge. Should be undefined for the Welcome Center.
   */
  const [programTypeForOptions, setProgramTypeForOptions] = useState<
    FetchLearningCenterOptionsProgramTypeEnum | undefined
  >()
  const updateProgramTypeForOptions = (
    newProgramType: FetchLearningCenterOptionsProgramTypeEnum | undefined
  ) => {
    setProgramTypeForOptions(newProgramType)
  }

  const [challengePrograms, setChallengePrograms] = useState<string[]>()
  const updateChallengePrograms = (programs: string[]) => {
    setChallengePrograms(programs)
  }

  const [includeOnlyFavorites, setIncludeOnlyFavorites] = useState(false)
  const updateIncludeOnlyFavorites = (newValue: boolean) => {
    setIncludeOnlyFavorites(newValue)
  }

  const [welcomeCenterContent, setWelcomeCenterContent] = useState(true)
  const updateWelcomeCenterContent = (newValue: boolean) => {
    setWelcomeCenterContent(newValue)
  }

  const [accordionStateByProgramType, setAccordionStateByProgramType] =
    useState<AccordionStateByProgramType>(
      defaultLearningCenterContextValue.accordionStateByProgramType
    )

  const [selectedTabForAccordions, setSelectedTabForAccordions] = useState<
    AccordionStateByNonChallenge | FilterFileByChallengeLevel
  >(selectedTabKey as AccordionStateByNonChallenge)

  useEffect(() => {
    setSelectedTabForAccordions(
      selectedTabKey === LearningCenterTabs.Challenge && !!level
        ? level
        : (selectedTabKey as AccordionStateByNonChallenge)
    )
  }, [level, selectedTabKey])

  const updateWeeksStateByProgramTypeAndCycle = (
    week: string,
    cycle: string
  ): void => {
    setAccordionStateByProgramType((prevAccordionState) => {
      const updatedState: AccordionStateByProgramType = {
        ...prevAccordionState,
      }

      const tabData = updatedState[selectedTabForAccordions]

      if (tabData && tabData.length > 0) {
        const updatedTabData = tabData.map(
          (data: { cycle: string; weeks: AccordionState[] }) => {
            if (data.cycle === cycle) {
              const updatedWeeks = data.weeks.map((weekItem) => {
                if (weekItem.week === week) {
                  return {
                    ...weekItem,
                    expanded: !weekItem.expanded,
                  }
                }
                return weekItem
              })

              return {
                ...data,
                weeks: updatedWeeks,
              }
            }
            return data
          }
        )

        updatedState[selectedTabForAccordions] = updatedTabData

        return updatedState
      }

      return updatedState
    })
  }

  const initializeProgramTypeCyclesAndWeeks = (
    cycle: string,
    weeks: string[]
  ) => {
    setAccordionStateByProgramType((prevAccordionState) => {
      const updatedState: AccordionStateByProgramType = {
        ...prevAccordionState,
      }

      const initialWeeksState = weeks.map((week) => ({
        week,
        expanded: false,
      }))

      if (updatedState[selectedTabForAccordions].length === 0) {
        updatedState[selectedTabForAccordions].push({
          cycle,
          weeks: initialWeeksState,
        })
      } else {
        const existingCycle = updatedState[selectedTabForAccordions].find(
          (item) => item.cycle === cycle
        )

        if (!existingCycle) {
          updatedState[selectedTabForAccordions].push({
            cycle,
            weeks: initialWeeksState,
          })
        }
      }

      return updatedState
    })
  }

  const initialTabSpecificSearchAndFilter = useRef(
    Object.values(LearningCenterTabs)
      .map((it) => `${it}`)
      .reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: {
            searchQuery: '',
            category: LearningCenterCategories.AllCategories,
            contentType: FilterFileType.AllTypes,
            includeOnlyFavorites: false,
          },
        }
      }, {} as { [k: string]: SearchAndFilterValues })
  )
  const [tabSpecificSearchAndFilter, setTabSpecificSearchAndFilter] = useState(
    initialTabSpecificSearchAndFilter.current
  )

  const updateTabSpecificSearchAndFilter = (newTabSpecificSearchAndFilter: {
    [k: string]: SearchAndFilterValues
  }) => {
    setTabSpecificSearchAndFilter(newTabSpecificSearchAndFilter)
    const {
      searchQuery = defaultLearningCenterContextValue.searchQuery,
      contentType = defaultLearningCenterContextValue.contentType,
      category = defaultLearningCenterContextValue.category,
      includeOnlyFavorites = defaultLearningCenterContextValue.includeOnlyFavorites,
      cycle = defaultLearningCenterContextValue.cycle,
    } = newTabSpecificSearchAndFilter[selectedTabKey]

    setContentType(contentType)
    setSearchQuery(searchQuery)
    setCategory(category)
    setAcademicYear(academicYear)
    setIncludeOnlyFavorites(includeOnlyFavorites)
    setCycle(cycle)
  }

  const updateSearchAndFiltersForTab = (tabKey: LearningCenterTabs) => {
    const {
      searchQuery = defaultLearningCenterContextValue.searchQuery,
      contentType = defaultLearningCenterContextValue.contentType,
      category = defaultLearningCenterContextValue.category,
      includeOnlyFavorites = defaultLearningCenterContextValue.includeOnlyFavorites,
    } = tabSpecificSearchAndFilter[tabKey]

    setContentType(contentType)
    setSearchQuery(searchQuery)
    setCategory(category)
    setCycle(defaultLearningCenterContextValue.cycle)
    setAcademicYear(academicYear)
    setIncludeOnlyFavorites(includeOnlyFavorites)
  }
  const updateLearningCenterAssetById = (
    assetKey: number,
    newState: Partial<ContentAsset>
  ) => {
    setAssets((prevContent) =>
      prevContent.map((asset) =>
        asset.assetKey === assetKey ? { ...asset, ...newState } : asset
      )
    )
  }
  const resetContextToDefaults = () => {
    setChallengePrograms(defaultLearningCenterContextValue.challengePrograms)
    setProgramTypeForOptions(
      defaultLearningCenterContextValue.programTypeForOptions
    )
    setSelectedTabKey(defaultLearningCenterContextValue.selectedTabKey)
    setAssets(defaultLearningCenterContextValue.assets)
    setTabSpecificSearchAndFilter(initialTabSpecificSearchAndFilter.current)
    setLevel(defaultLearningCenterContextValue.level)
    setContentType(defaultLearningCenterContextValue.contentType)
    setSearchQuery(defaultLearningCenterContextValue.searchQuery)
    setCategory(LearningCenterCategories.AllCategories)
    setCycle(defaultLearningCenterContextValue.cycle)
    setAcademicYear(defaultLearningCenterContextValue.academicYear)
    setWelcomeCenterContent(
      defaultLearningCenterContextValue.welcomeCenterContent
    )
    setIncludeOnlyFavorites(
      defaultLearningCenterContextValue.includeOnlyFavorites
    )
    setLearningCenterOptions(
      defaultLearningCenterContextValue.learningCenterOptions
    )
    /**
     *  defaultLearningCenterContextValue.accordionStateByProgramType
     *  avoid using it to setAccordionStateByProgramType
     *  since have values set previous by other parts of the code
     */
    setAccordionStateByProgramType({
      [LearningCenterTabs.WelcomeCenter]: [],
      [LearningCenterTabs.Scribblers]: [],
      [LearningCenterTabs.Essentials]: [],
      [LearningCenterTabs.Foundations]: [],
      [FilterFileByChallengeLevel.ChallengeA]: [],
      [FilterFileByChallengeLevel.ChallengeB]: [],
      [FilterFileByChallengeLevel.Challenge1]: [],
      [FilterFileByChallengeLevel.Challenge2]: [],
      [FilterFileByChallengeLevel.Challenge3]: [],
      [FilterFileByChallengeLevel.Challenge4]: [],
    })
  }

  const value = {
    academicYear,
    updateAcademicYear,
    learningCenterOptions,
    updateLearningCenterOptions,
    searchQuery,
    category,
    contentType,
    level,
    cycle,
    updateSearchQuery,
    updateCategory,
    updateContentType,
    updateCycle,
    updateLevel,
    assets,
    updateAssets,
    selectedTabKey,
    updateSelectedTabKey,
    programTypeForOptions,
    updateProgramTypeForOptions,
    programTypesLoadingId:
      OperationIds.FetchLearningCenterAvailableProgramTypes,
    optionsLoadingId: OperationIds.FetchLearningCenterOptions,
    contentLoadingId: OperationIds.FetchLearningCenterContent,
    challengePrograms,
    updateChallengePrograms,
    resetContextToDefaults,
    includeOnlyFavorites,
    updateIncludeOnlyFavorites,
    welcomeCenterContent,
    updateWelcomeCenterContent,
    tabSpecificSearchAndFilter,
    updateTabSpecificSearchAndFilter,
    updateSearchAndFiltersForTab,
    accordionStateByProgramType,
    updateWeeksStateByProgramTypeAndCycle,
    initializeProgramTypeCyclesAndWeeks,
    selectedTabForAccordions,
    updateAccordionStateByProgramType: setAccordionStateByProgramType,
    updateLearningCenterAssetById,
    ...testConfig,
  } as typeof defaultLearningCenterContextValue

  return (
    <LearningCenterContext.Provider value={value}>
      {children}
    </LearningCenterContext.Provider>
  )
}

export default LearningCenterProvider

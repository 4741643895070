import React, { useEffect, useMemo, useState } from 'react'
import { dashboardApi } from '../../api/swagger'
import {
  Dashboard,
  DashboardStyleEnum,
  FetchMetricMetricIdEnum,
  ProgramDashboard,
  SingleDownlineMetricResult,
} from '../../swagger'
import useEndpoint from '../../hooks/useEndpoint'
import { LoadingContext } from '../Context/LoadingContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { Box, Card, Divider, Typography, useTheme } from '@mui/material'
import ProgramRenderer, {
  DashboardDetailsProgramCard,
} from '../Programs/ProgramRenderer'
import { ElevatedCardVariants } from '../Card/ElevatedCard'
import { parseActorsMetrics } from './utils/parseActorsMetrics'
import Header, { HeaderVariant } from '../Elements/Header'
import fullName from '../../utils/fullName'
import SpaceBetweenSection from '../Elements/SpaceBetweenSection'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CardFormHeader from '../Card/CardFormHeader'
import { InfoTooltip } from '../Elements/InfoTooltip'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../Routes/AuthProvider'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import { useTeamLeadDashboardBreadcrumbs } from '../../hooks/useTeamLeadDashboardBreadcrumbs'
import { UnassignedDirectorsTable } from './UnassignedDirectorsTable'
import { getDashboardColor } from './utils/getDashboardColor'
import { ElevatedCardPlaceholder } from '../Placeholders/ElevatedCardPlaceholder'
import { SpaceBetweenSectionPlaceholder } from '../Placeholders/SpaceBetweenSectionPlaceholder'

export interface DashboardDetailsProps {
  metricId: FetchMetricMetricIdEnum
}

export enum DirectorDashboardDetailsVariants {
  pendingEnrollments = 'pendingEnrollments',
  outstandingTuition = 'outstandingTuition',
  outstandingEnrollments = 'outstandingEnrollments',
  outstandingInvitations = 'outstandingInvitations',
  programs = 'programs',
  billing = 'Billing',
}

export type ProgramData = ProgramDashboard &
  Dashboard & {
    Fullname?: string
    Rolename?: string
    validTo?: string
    validFrom?: string
    actionLink?: string
  }

export const DashboardDetails: React.FC<DashboardDetailsProps> = ({
  metricId,
}) => {
  const {
    DashboardTab: { fetchMetric: fetchMetricLoadingId },
  } = useLoadingIds()

  const { addLoadingIds, loadingIds } = React.useContext(LoadingContext)
  const showOnDesktop = useShowOnDesktop()
  const theme = useTheme()
  const { t } = useTranslation()

  /** Intended to show metrics based on the booleans passed to ProgramRenderer */
  const metricBooleans = useMemo<
    Pick<
      DashboardDetailsProgramCard,
      'isInvitation' | 'isOutstandingTuition' | 'isPendingEnrollment'
    >
  >(() => {
    return {
      isInvitation: metricId === FetchMetricMetricIdEnum.InProgressInvitations,
      isOutstandingTuition:
        metricId === FetchMetricMetricIdEnum.OutstandingTuition,
      isPendingEnrollment:
        metricId === FetchMetricMetricIdEnum.PendingEnrollments,
    }
  }, [metricId])

  const [actorMetrics, setActorMetrics] = useState<ProgramData[]>([])
  const [roleAndNameOfActor, setRoleAndNameOfActor] = useState<
    string | undefined
  >()
  const [downlineMetrics, setDownlineMetrics] = useState<
    SingleDownlineMetricResult[]
  >([])
  const { actorKey } = useParams<{ actorKey: string }>()

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { userDetails } = useAuth()

  const location = useLocation()

  const [metricName, setMetricName] = useState<string | undefined>()

  const { breadcrumbTrail: breadcrumbs } = useTeamLeadDashboardBreadcrumbs({
    pathName: location.pathname,
    locationState: location.state,
    metricName,
  })

  useEndpoint({
    loadingId: fetchMetricLoadingId,
    swaggerCall: async () => {
      // Try-catch is in swaggerCall
      const {
        forActorFirstName,
        forActorKey,
        forActorLastName,
        forActorRoleName,
        actorsMetrics,
        downlineMetrics: fetchedDownlineMetrics,
        name,
      } = await dashboardApi.fetchMetric({
        metricId,
        actorKey: !!actorKey ? +actorKey : undefined,
      })
      const metricsToRender = parseActorsMetrics(actorsMetrics)
      setRoleAndNameOfActor(
        forActorKey === userDetails.actingAs
          ? t('DashboardDetails.MetricHeader.My', 'My')
          : `${fullName({
              firstName: forActorFirstName,
              lastName: forActorLastName,
            })} - ${forActorRoleName}`
      )

      setMetricName(name)
      // FIXME: clear metrics on Click for Details
      setActorMetrics(metricsToRender)
      setDownlineMetrics(fetchedDownlineMetrics)
    },
  })

  useEffect(() => {
    addLoadingIds([fetchMetricLoadingId])
  }, [addLoadingIds, actorKey, fetchMetricLoadingId])

  const hasActorMetrics = actorMetrics.length !== 0
  const hasDownlineMetrics = downlineMetrics.length !== 0

  const DownlinePlaceholder = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mx: theme.spacing(2.5),
        [theme.breakpoints.down('md')]: {
          width: '80%',
          margin: '0 auto',
        },
      }}
    >
      <Divider />
      <Box mb={2} mt={2} ml={2}>
        <SpaceBetweenSectionPlaceholder />
      </Box>
    </Box>
  )

  const clearMetrics = () => {
    setActorMetrics([])
    setDownlineMetrics([])
  }

  const MetricDetails = () => {
    let details: React.ReactElement = (
      <ProgramRenderer
        programs={actorMetrics}
        type={ElevatedCardVariants.DirectorDashboard}
        {...metricBooleans}
      />
    )
    if (metricId === FetchMetricMetricIdEnum.UnassignedDirectors) {
      details = (
        <UnassignedDirectorsTable
          rows={actorMetrics.map(
            ({ Fullname, Rolename, validFrom, validTo }) => ({
              roleName:
                Rolename ??
                t(
                  'Dashboard.DashboardDetails.UnassignedDirectors.EmptyRoleName',
                  'Role Not Provided'
                ),
              fullName:
                Fullname ??
                t(
                  'Dashboard.DashboardDetails.UnassignedDirectors.EmptyFullName',
                  'Name Not Provided'
                ),
              validFrom:
                validFrom ??
                t(
                  'Dashboard.DashboardDetails.UnassignedDirectors.EmptyValidFrom',
                  'Valid From Not Provided'
                ),
              validTo:
                validTo ??
                t(
                  'Dashboard.DashboardDetails.UnassignedDirectors.EmptyValidTo',
                  'Valid To Not Provided'
                ),
            })
          )}
        />
      )
    }
    return details
  }

  return (
    <>
      <DynamicBreadcrumbs
        breadcrumbs={
          !!breadcrumbs?.length
            ? breadcrumbs.map((breadcrumb) => ({
                onClick: () => {
                  clearMetrics()
                  // if we have a breadcrumb.after as undefined, we don't want to navigate to it since it's the last one.
                  !!breadcrumb.after && breadcrumb.breadcrumb?.onClick?.()
                },
                label: breadcrumb.breadcrumb.label,
              }))
            : []
        }
      />
      <section
        aria-labelledby="metric-details-header"
        key="metric-details-section"
      >
        <Header
          headerName={t(
            'Dashboard.DashboardDetails.Header.Metrics',
            `{{roleAndNameOfActor}} Metrics`,
            { roleAndNameOfActor }
          )}
          id="metric-details-header"
        />
        {hasActorMetrics ? (
          MetricDetails()
        ) : (
          <>
            {loadingIds.size !== 0 ? (
              <ElevatedCardPlaceholder />
            ) : (
              <Typography variant="body1" component="p">
                {t(
                  'Dashboard.DashboardDetails.DownlineDetails.NoMetrics',
                  'You have no metrics to display at this time.'
                )}
              </Typography>
            )}
          </>
        )}
      </section>

      <section
        aria-labelledby="metric-downline-header"
        key="metric-downline-section"
      >
        <Card sx={{ mt: 2 }}>
          <Box
            display="flex"
            alignItems="center"
            minWidth="25%"
            alignContent={'center'}
            px={showOnDesktop ? 0 : 4}
          >
            <CardFormHeader
              headerContainerProps={{
                mt: '24px', // Following the header bottom margin
              }}
              header={
                <Header
                  headerName={t(
                    'Dashboard.DashboardDetails.Header.TeamMetrics',
                    `{{roleAndNameOfActor}} Team Metrics`,
                    { roleAndNameOfActor }
                  )}
                  id="metric-downline-header"
                  variant={HeaderVariant.Card}
                  endAdornment={
                    <InfoTooltip
                      message={t(
                        'DashboardDetails.Header.HyperLinkInstruction',
                        'Select CLICK FOR DETAILS button for any of the links below'
                      )}
                    />
                  }
                />
              }
            />
          </Box>
          {hasDownlineMetrics ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                mx: theme.spacing(2.5),
                [theme.breakpoints.down('md')]: {
                  width: '80%',
                  margin: '0 auto',
                },
              }}
            >
              {downlineMetrics.map(
                ({
                  downlineName,
                  downlineRole,
                  downlineMetricValue,
                  downlineActorKey,
                  downlineMetricStyle,
                }) => (
                  <Box key={`${downlineActorKey}-metric`}>
                    <Divider />
                    <Box mb={2} mt={2} ml={2}>
                      <SpaceBetweenSection
                        containerStyles={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: showOnDesktop ? 'center' : 'left',
                          [theme.breakpoints.down('md')]: {
                            flexDirection: 'column',
                          },
                        }}
                        leftStyle={{
                          width: showOnDesktop ? '70%' : '100%',
                        }}
                        left={
                          <Typography
                            variant="subtitle2"
                            color={theme.palette.primary.dark}
                          >
                            {`${downlineName} - ${downlineRole}`}:
                          </Typography>
                        }
                        center={
                          <Typography
                            variant="body2"
                            component="span"
                            fontWeight={700}
                            sx={{
                              color: getDashboardColor(
                                downlineMetricStyle ??
                                  DashboardStyleEnum.Normal,
                                theme
                              ),
                              typography: 'h5',
                            }}
                          >
                            {`${downlineMetricValue}`}
                          </Typography>
                        }
                        rightStyle={{
                          width: showOnDesktop ? 'auto' : '100%',
                        }}
                        right={
                          <ContainedButton
                            id={`click-for-details-${downlineActorKey}`}
                            variant={ContainedButtonVariant.ClickForDetails}
                            css={{
                              [theme.breakpoints.down('md')]: {
                                // Fix a slight margin  when moving buttons
                                marginLeft: 0,
                              },
                            }}
                            onClick={() => {
                              clearMetrics()
                              const newPath = actorKey
                                ? pathname.split('/').slice(0, -1).join('/')
                                : pathname
                              navigate(`${newPath}/${downlineActorKey}`, {
                                state: {
                                  label: `${downlineName} - ${downlineRole}`,
                                  downlineActorKey,
                                },
                              })
                            }}
                          />
                        }
                      />
                    </Box>
                  </Box>
                )
              )}
            </Box>
          ) : (
            <>
              {loadingIds.size !== 0 ? (
                DownlinePlaceholder
              ) : (
                <Box sx={{ ml: 2, width: '100%' }}>
                  <Typography variant="body1" component="p">
                    {t(
                      'Dashboard.DashboardDetails.DownlineDetails.NoTeamMetrics',
                      'You have no team members with metrics to display at this time.'
                    )}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Card>
      </section>
    </>
  )
}

export default DashboardDetails

import Typography from '@mui/material/Typography'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { fetchBilling } from '../../../api/payments'
import {
  EnrollmentBill,
  LicensingBill,
  ParentInvoiceInfo,
  PaymentConfiguration,
  PaymentConfigurationCodeEnum,
  FetchBillingRequest,
  LicensingInternationalPayment,
  PaginationResponse,
  CoordinatorLicensingBilling,
  FetchBillingProgramTypeKeyEnum,
  CashAmount,
} from '../../../swagger'
import { paymentsApi } from '../../../api/swagger'
import { CanAccess } from '../../Elements/Access'
import EmptyPage from '../../Elements/EmptyPage'
import { Payment, PaymentMade } from '../../Interfaces/Payment'
import { AnnualEnrollmentPaymentCard } from './AnnualEnrollmentPaymentCard'
import { BillingHistorySummaryTableVariant } from './BillingHistorySummaryTable'
import { LicensingPaymentsCard } from './LicensingPaymentsCard'
import useLoadingContext from '../../../hooks/useLoadingContext'
import { useLoadingIds } from '../../../hooks/useLoadingIds'
import { ParentInvoiceCard } from './ParentInvoiceCard'
import { Box } from '@mui/material'
import { useAuth } from '../../Routes/AuthProvider'
import { useLocation, useParams } from 'react-router'
import { NavigationCard } from '../../Elements/NavigationCard'
import { useUser } from '../../../UserContext'
import { ACTING_AS_PARENT, DEFAULT_PAGE_SIZE } from '../../../utils/constants'
import { CoCoLicensingPaginationHandler } from './CountryCoordinatorLicensingTable'
import { useErrorSnackbar } from '../../../hooks/useErrorSnackbar'
import { useMountEffect } from '../../../hooks/useMountEffect'
import LoadingProgress from '../../Elements/LoadingProgress'

export const BillingTab: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { userDetails } = useAuth()
  const {
    BillingTab: {
      fetchBilling: fetchBillingLoadingId,
      fetchCountryCoordinatorTotalBalance,
    },
  } = useLoadingIds()
  const { handleError } = useErrorSnackbar()
  const { actorKey } = useParams()
  const { uniqueInactiveUserRoles } = useUser()
  const auth = useAuth()

  const isViewingBillingHistory = /\/account\/billing\/history\/\d+/.test(
    location.pathname
  )

  const userHasInactiveRoles = uniqueInactiveUserRoles.length > 0
  const defaultFetchBillingErrorMessage = t(
    'AnnualEnrollmentPaymentCard.FetchBilling.Error',
    'Error occurred fetching billing history.'
  )

  const shouldFetchTotalBalanceRef = useRef(true)

  const [licensingPaymentHistory, setLicensingPaymentHistory] = useState<
    Payment[]
  >([])
  const [licensingBills, setLicensingBills] = useState<LicensingBill[]>([])

  const [enrollmentPaymentHistory, setEnrollmentPaymentHistory] = useState<
    Payment[]
  >([])
  const [isFirstLoad, setIsFirstLoad] = useState(false)

  const [countryCoordinatorTotalBalance, setCountryCoordinatorTotalBalance] =
    useState<CashAmount | undefined>()

  const [enrollmentBill, setEnrollmentBill] = useState<
    EnrollmentBill | undefined
  >()
  const [countryCoordinatorLicensing, setCountryCoordinatorLicensing] =
    useState<CoordinatorLicensingBilling[] | undefined>()

  const [countryCoordinatorLicensingPaymentHistory] = useState<
    Partial<LicensingInternationalPayment>[]
  >([])
  const [
    isFilteringCountryCoordinatorLicensing,
    setIsFilteringCountryCoordinatorLicensing,
  ] = useState(false)

  const [parentInvoices, setParentInvoices] = useState<ParentInvoiceInfo[]>([])
  const [filters, setFilters] = useState({
    director: { value: '', id: '' },
    programType: { value: '', id: '' },
    community: { value: '', id: '' },
  })
  const [configurations, setConfigurations] = useState<PaymentConfiguration[]>(
    []
  )
  const [triggerRefetch, setTriggerRefetch] = useState(false)
  const [isAchAllowed, setIsAchAllowed] = useState(true)
  const [coCoLicensingPagination, setCoCoLicensingPagination] = useState<
    Pick<PaginationResponse, 'page' | 'pageSize' | 'totalCount'>
  >({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: 0,
  })

  useMountEffect(() => {
    setIsFirstLoad(true)
  })

  const handleCoCoLicensingPagination: CoCoLicensingPaginationHandler = (
    pagination
  ) => {
    setCoCoLicensingPagination((prev) => ({
      ...prev,
      ...pagination,
    }))
  }

  const fetchBillingHistory = async () => {
    try {
      const programTypeId = filters.programType.id

      const programTypeKey = Object.values(
        FetchBillingProgramTypeKeyEnum
      ).includes(programTypeId as FetchBillingProgramTypeKeyEnum)
        ? (programTypeId as FetchBillingProgramTypeKeyEnum)
        : undefined

      const {
        enrollment,
        licensing,
        configurations: fetchedConfigs,
        achAllowed,
        parentInvoices,
      } = await fetchBilling({
        actorKey,
        page: coCoLicensingPagination.page,
        pageSize: coCoLicensingPagination.pageSize,
        communityKey: !!filters.community.id ? +filters.community.id : 0,
        programTypeKey,
        directorKey: !!filters.director.id ? +filters.director.id : 0,
      } as FetchBillingRequest)
      setConfigurations(fetchedConfigs)
      setIsAchAllowed(achAllowed)
      setParentInvoices(parentInvoices ?? [])
      // Setup all billing history data based on the object's information
      if (!!enrollment) {
        // Temporary array to store all programKey for history/enrollment payments
        let programKeysForHistory: number[] = []
        setEnrollmentPaymentHistory(
          enrollment.history?.map((enrollmentHistory) => {
            const paymentHistory: Payment = {
              ...enrollmentHistory,
              paymentsMade: enrollmentHistory.enrollmentPayments.map(
                (enrollmentPayment) => {
                  const paymentMade: PaymentMade = {
                    ...enrollmentPayment,
                    paymentKey: enrollmentPayment.enrollmentPaymentKey,
                  }
                  return paymentMade
                }
              ),
            }
            // Reduce each enrollment payment to just the program key
            programKeysForHistory = enrollmentHistory.enrollmentPayments.reduce(
              (acc, curr) => [...acc, curr.programKey],
              programKeysForHistory
            )
            return paymentHistory
          }) ?? []
        )
        setEnrollmentBill(enrollment.owed)
      }

      if (!!licensing) {
        setLicensingPaymentHistory(
          licensing.history?.map((licensingHistory) => {
            const paymentHistory: Payment = {
              ...licensingHistory,
              paymentsMade: [
                {
                  ...licensingHistory.licensingPayment,
                  paymentKey:
                    licensingHistory.licensingPayment.licensingPaymentKey,
                  studentKey: -1,
                },
              ], // No student key applicable to licensing payment, but shared with enrollments
            }
            return paymentHistory
          }) ?? []
        )

        setLicensingBills(licensing.programs ?? [])
      }

      if (
        !!licensing?.programs &&
        licensing?.programs?.length === 0 &&
        !!licensing?.director?.billing?.data
      ) {
        setCoCoLicensingPagination((prev) => ({
          ...prev,
          totalCount: licensing.director?.billing?.pagination?.totalCount ?? 0,
        }))

        setCountryCoordinatorLicensing(licensing.director.billing.data)

        if (licensing.director.billing.data.length === 0) {
          setIsFilteringCountryCoordinatorLicensing(true)
          return
        }

        setIsFilteringCountryCoordinatorLicensing(false)
      }
    } catch (e) {
      await handleError(e, defaultFetchBillingErrorMessage)
    } finally {
      setIsFirstLoad(false)
    }
  }

  const fetchCoCoTotalBalance = async () => {
    const { totalOwed } = await paymentsApi.fetchCountryCoordinatorTotalBalance(
      {
        actorKey: Number(auth.userDetails.actingAs),
      }
    )

    setCountryCoordinatorTotalBalance(totalOwed)
  }

  /** Hooks */
  const { triggerFetch } = useLoadingContext({
    asyncFunction: fetchBillingHistory,
    loadingId: fetchBillingLoadingId + JSON.stringify(filters),
  })

  const { triggerFetch: triggerFetchCoCoTotalBalance } = useLoadingContext({
    asyncFunction: fetchCoCoTotalBalance,
    loadingId: fetchCountryCoordinatorTotalBalance,
  })

  useEffect(() => {
    triggerFetch()
  }, [
    coCoLicensingPagination.page,
    coCoLicensingPagination.pageSize,
    triggerFetch,
  ])

  useEffect(() => {
    // reset the pagination to the initial page (1)
    setCoCoLicensingPagination({
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      totalCount: 0,
    })
  }, [filters])

  /** Load billing history for current user */
  useEffect(() => {
    triggerFetch()
  }, [triggerRefetch, userDetails.actingAs, actorKey, triggerFetch])

  useEffect(() => {
    if (
      !!countryCoordinatorLicensing &&
      countryCoordinatorLicensing.length > 0 &&
      shouldFetchTotalBalanceRef.current
    ) {
      triggerFetchCoCoTotalBalance()
      shouldFetchTotalBalanceRef.current = false
    }
  }, [countryCoordinatorLicensing, triggerFetchCoCoTotalBalance])

  const achDiscount =
    configurations.find(
      (configuration) =>
        configuration.code === PaymentConfigurationCodeEnum.AchDiscount
    )?.amount ?? 0

  // Program map would be built if there were programs present within enrollments or licensing.
  // If the program Map is not built and we have attempted a load, we likely have NO data.
  const billingDataFetchedAndEmpty =
    !!enrollmentPaymentHistory &&
    enrollmentPaymentHistory.length === 0 &&
    !!licensingBills &&
    licensingBills.length === 0 &&
    !!licensingPaymentHistory &&
    licensingPaymentHistory.length === 0 &&
    !enrollmentBill &&
    !!countryCoordinatorLicensing &&
    countryCoordinatorLicensing.length === 0 &&
    !isFilteringCountryCoordinatorLicensing

  const hasInvoiceCard =
    !isViewingBillingHistory && userDetails.actingAs === 'parent'
  const canViewAnnualEnrollmentPaymentCard =
    (!!enrollmentBill ||
      (!!enrollmentPaymentHistory && enrollmentPaymentHistory.length > 0)) &&
    !isViewingBillingHistory

  const hasLicensingPaymentsCard =
    (!!licensingBills && licensingBills.length > 0) ||
    (!!licensingPaymentHistory && licensingPaymentHistory.length > 0)

  const hasCoCoLicensingBills =
    !!countryCoordinatorLicensing && countryCoordinatorLicensing.length > 0

  const EmptyPageMessage = (
    <EmptyPage
      message={
        <Typography variant="subtitle1" component="p">
          {t(
            'BillingTab.Message.NoBillingData',
            'No billing information available.'
          )}
        </Typography>
      }
    />
  )

  if (isFirstLoad) {
    return <LoadingProgress />
  }

  //Director view will have more parts to the billing tab
  return (
    <CanAccess I="billing" on="Feature">
      {!isViewingBillingHistory && userHasInactiveRoles && (
        <NavigationCard
          icons={{
            startIcon: 'history_icon',
            endIcon: 'navigate_next_icon',
          }}
          title="Billing History"
          subtitle="View billing information from expired roles."
          navigateTo="/account/billing/roles"
        />
      )}
      {!billingDataFetchedAndEmpty ? (
        <>
          {canViewAnnualEnrollmentPaymentCard && (
            <AnnualEnrollmentPaymentCard
              enrollmentPaymentHistory={enrollmentPaymentHistory}
              enrollmentBill={enrollmentBill}
              refetch={() => setTriggerRefetch((prev) => !prev)}
              achDiscount={achDiscount}
            />
          )}
          {hasLicensingPaymentsCard || hasCoCoLicensingBills ? (
            <LicensingPaymentsCard
              countryCoordinatorTotalBalance={countryCoordinatorTotalBalance}
              countryCoordinatorLicensingPaymentHistory={
                countryCoordinatorLicensingPaymentHistory
              }
              countryCoordinatorLicensing={countryCoordinatorLicensing}
              licensingBills={licensingBills}
              licensingPaymentHistory={licensingPaymentHistory}
              refetch={() => setTriggerRefetch((prev) => !prev)}
              achDiscount={achDiscount}
              variantForBillingHistorySummaryTable={
                BillingHistorySummaryTableVariant.LicensingPayments
              }
              hasCoCoLicensingBills={hasCoCoLicensingBills}
              achAllowed={isAchAllowed}
              coCoLicensingPagination={coCoLicensingPagination}
              handleCoCoLicensingPagination={handleCoCoLicensingPagination}
              filters={filters}
              setFilters={setFilters}
            />
          ) : (
            userDetails.actingAs !== ACTING_AS_PARENT && EmptyPageMessage
          )}
          {hasInvoiceCard && (
            <Box mt={11}>
              <ParentInvoiceCard parentInvoices={parentInvoices} />
            </Box>
          )}
        </>
      ) : (
        EmptyPageMessage
      )}
    </CanAccess>
  )
}

export default BillingTab

/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PublicContact,
  PublicContactFromJSON,
  PublicContactToJSON,
  PublicProgramType,
  PublicProgramTypeFromJSON,
  PublicProgramTypeToJSON,
} from './'

/**
 *
 * @export
 * @interface PublicProgram1
 */
export interface PublicProgram1 {
  /**
   *
   * @type {PublicProgramType}
   * @memberof PublicProgram1
   */
  type: PublicProgramType
  /**
   * The day of the week the program is available. Where 1 is Monday and 7 is Sunday
   * @type {number}
   * @memberof PublicProgram1
   */
  dayOfTheWeek: number
  /**
   * The starting time for the program using ISO standard 24 hour format: `hh:mm`.
   * @type {string}
   * @memberof PublicProgram1
   */
  startTime: string
  /**
   * The ending time for the program using ISO standard 24 hour format: `hh:mm`.
   * @type {string}
   * @memberof PublicProgram1
   */
  endTime: string
  /**
   *
   * @type {PublicContact}
   * @memberof PublicProgram1
   */
  director: PublicContact
}

export function PublicProgram1FromJSON(json: any): PublicProgram1 {
  return PublicProgram1FromJSONTyped(json, false)
}

export function PublicProgram1FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PublicProgram1 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: PublicProgramTypeFromJSON(json['type']),
    dayOfTheWeek: json['dayOfTheWeek'],
    startTime: json['startTime'],
    endTime: json['endTime'],
    director: PublicContactFromJSON(json['director']),
  }
}

export function PublicProgram1ToJSON(value?: PublicProgram1 | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: PublicProgramTypeToJSON(value.type),
    dayOfTheWeek: value.dayOfTheWeek,
    startTime: value.startTime,
    endTime: value.endTime,
    director: PublicContactToJSON(value.director),
  }
}

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { contentApi, extractedErrorObject } from '../../../api/swagger'
import { useErrorSnackbar } from '../../../hooks/useErrorSnackbar'
import useLoadingContext from '../../../hooks/useLoadingContext'
import {
  FetchLearningCenterOptionsProgramTypeEnum,
  ProgramType,
} from '../../../swagger'
import { LearningCenterTabs } from '../../../utils/searchAndFilterEnums'
import { useLearningCenterContext } from '../../Context/LearningCenterContext'
import { useAuth } from '../../Routes/AuthProvider'
import { stringToChallengeLevelMap } from './useLearningCenterOptions'

const challengeStringArray = [
  'Challenge A',
  'Challenge B',
  'Challenge I',
  'Challenge II',
  'Challenge III',
  'Challenge IV',
]

export const getTabsUserCanAccess = (
  availableProgramTypes?: string[]
): string[] => {
  const validLearningCenterProgramTypes: string[] = []
  validLearningCenterProgramTypes.push(LearningCenterTabs.WelcomeCenter)
  if (availableProgramTypes) {
    if (availableProgramTypes.includes('Foundations')) {
      validLearningCenterProgramTypes.push(LearningCenterTabs.Foundations)
    }
    if (availableProgramTypes.includes('Scribblers')) {
      validLearningCenterProgramTypes.push(LearningCenterTabs.Scribblers)
    }
    if (availableProgramTypes.includes('Essentials')) {
      validLearningCenterProgramTypes.push(LearningCenterTabs.Essentials)
    }

    const checkIsChallenge = (programType: string) =>
      challengeStringArray.includes(programType)

    if (availableProgramTypes.some(checkIsChallenge)) {
      validLearningCenterProgramTypes.push(LearningCenterTabs.Challenge)
    }
  }
  return validLearningCenterProgramTypes
}

const stringToEnumMap = new Map<
  string,
  FetchLearningCenterOptionsProgramTypeEnum
>([
  ['Scribblers', FetchLearningCenterOptionsProgramTypeEnum.Scribblers],
  ['Foundations', FetchLearningCenterOptionsProgramTypeEnum.Foundations],
  ['Essentials', FetchLearningCenterOptionsProgramTypeEnum.Essentials],
  ['Challenge A', FetchLearningCenterOptionsProgramTypeEnum.ChallengeA],
  ['Challenge B', FetchLearningCenterOptionsProgramTypeEnum.ChallengeB],
  ['Challenge I', FetchLearningCenterOptionsProgramTypeEnum.ChallengeI],
  ['Challenge II', FetchLearningCenterOptionsProgramTypeEnum.ChallengeIi],
  ['Challenge III', FetchLearningCenterOptionsProgramTypeEnum.ChallengeIii],
  ['Challenge IV', FetchLearningCenterOptionsProgramTypeEnum.ChallengeIv],
])

const LearningCenterProgramOrder = [
  ProgramType.Foundations,
  ProgramType.Scribblers,
  ProgramType.Essentials,
  ProgramType.ChallengeA,
  ProgramType.ChallengeB,
  ProgramType.ChallengeI,
  ProgramType.ChallengeIi,
  ProgramType.ChallengeIii,
  ProgramType.ChallengeIv,
]

export const useLearningCenterProgramTypes = (): {
  tabsUserCanAccess: string[] | undefined
  availableProgramTypes: FetchLearningCenterOptionsProgramTypeEnum[]
  loadingId: string
} => {
  const { t } = useTranslation()
  const { featureAbility } = useAuth()
  const {
    programTypesLoadingId: loadingId,
    updateLevel,
    level,
    selectedTabKey,
    updateChallengePrograms,
  } = useLearningCenterContext()

  const { handleError } = useErrorSnackbar()
  /**
   * By default, the user gets nothing. https://www.youtube.com/watch?v=fpK36FZmTFY&t=74s
   *
   * Tabs are determined by getTabsUserCanAccess and are not always matching the
   * availableProgramTypes. Any Challenge program returns the Challenge tab, for
   * instance.
   *
   */
  const [tabsUserCanAccess, setTabsUserCanAccess] = useState<string[]>([
    LearningCenterTabs.WelcomeCenter,
  ])

  /**
   * These programTypes are in the order provided by LearningCenterProgramOrder
   */
  const [availableProgramTypes, setAvailableProgramTypes] = useState<
    FetchLearningCenterOptionsProgramTypeEnum[]
  >([])

  const fetchLearningCenterAvailableProgramTypes = async () => {
    try {
      const fetchedAvailableProgramTypes =
        await contentApi.fetchLearningCenterAvailableProgramTypes({})

      const availableProgramTypesArray =
        fetchedAvailableProgramTypes.programTypes.map(
          (programType) =>
            stringToEnumMap.get(
              programType
            ) as FetchLearningCenterOptionsProgramTypeEnum
        )

      const availableProgramTypesInOrder =
        [] as FetchLearningCenterOptionsProgramTypeEnum[]

      /**
       * Simple way of putting our programs returned in the order we want
       * to display them for the user.
       */
      for (const type of LearningCenterProgramOrder) {
        const typeAsApplicableEnum =
          type as unknown as FetchLearningCenterOptionsProgramTypeEnum
        if (availableProgramTypesArray.includes(typeAsApplicableEnum)) {
          availableProgramTypesInOrder.push(typeAsApplicableEnum)
        }
      }

      /**
       * This gives us all our program types which enables us to call other
       * endpoints to fetch options and content.
       */
      setAvailableProgramTypes(availableProgramTypesInOrder)

      /**
       * Whereas the available types gives us the ability to call endpoints, we
       * use these tabs to display tab headers and panels to display content.
       */
      const tabs = getTabsUserCanAccess(
        fetchedAvailableProgramTypes.programTypes
      )

      setTabsUserCanAccess(tabs)

      /**
       * Add only challenge programs to the context for challengePrograms
       * to load the filter when having selected the Challenge tab.
       */
      const challengePrograms = availableProgramTypesInOrder.filter((it) =>
        it.includes(LearningCenterTabs.Challenge)
      ) as string[]

      updateChallengePrograms(challengePrograms)

      /**
       * Default the selectedTabKey to the first tab a user can access, or empty
       * string if none to prevent typescripts complaints. It's easier than forcing
       * an `as string` on selectedTabKey.
       *
       * If the selectedTabKey was already selected, don't update.
       */
      if (selectedTabKey.includes(LearningCenterTabs.Challenge) && !level) {
        updateLevel(
          /**
           * Grab the first Challenge program if we are selecting the Challenge
           * tab by default. It should not be undefined since we can't see this
           * tab without having a Challenge program available.
           *
           * Also validate we didn't have a level selected prior so navigating
           * away and back retains our filter.
           */
          stringToChallengeLevelMap.get(challengePrograms[0])
        )
      }
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: t(
          'Endpoints.FetchLearningCenterProgramTypes.Error',
          'An unknown error occurred fetching available program types.'
        ),
      }

      await handleError(e, errorObject.message)
    }
  }

  const { addLoadingIds } = useLoadingContext({
    asyncFunction: fetchLearningCenterAvailableProgramTypes,
    loadingId,
  })

  useEffect(() => {
    /**
     * Don't call the endpoint if we can't access the feature
     */
    if (featureAbility.can('learningCenter', 'Feature')) {
      addLoadingIds([loadingId])
    }
  }, [addLoadingIds, featureAbility, loadingId])

  return {
    tabsUserCanAccess,
    availableProgramTypes,
    loadingId,
  }
}

import { useNavigate } from 'react-router'
import { ActionLink } from '../swagger'
import { useTranslation } from 'react-i18next'
import { useUser } from '../UserContext'
import { useMemo, useEffect, useState } from 'react'

type TeamLeadDashboardBreadcrumbsProps = {
  pathName: string
  locationState?: {
    label: string
    downlineActorKey: number
  }
  metricName?: string
}

interface Breadcrumb {
  label: string
  onClick: (() => void) | undefined
}

type BreadcrumbTrail = {
  before?: number /** ActorKey of reportsTo breadcrumb */
  after?: number /** ActorKey of directReport breadcrumb */
  current?: number /** ActorKey of current breadcrumb */
  breadcrumb: Breadcrumb
  userKey?: number /** UserId of current breadcrumb */
  role?: string
}

export interface DashboardBreadcrumbTrail {
  breadcrumbTrail: BreadcrumbTrail[]
}
/**
 * Parses a link and removes the trailing numbers and the last slash.
 *
 * @param {string} link - The link to be parsed.
 * @returns {string} - The parsed link with trailing numbers and last slash removed.
 */
export function parseLink(link: string): string {
  // Use split and slice to handle removal of trailing numbers and last slash
  return link.replace(/\/\d+$/, '')
}

export const useTeamLeadDashboardBreadcrumbs = ({
  pathName,
  locationState,
  metricName,
}: TeamLeadDashboardBreadcrumbsProps): DashboardBreadcrumbTrail => {
  const baseTeamLeadDashboardPath = '/account/new-dashboard'

  const basePath = parseLink(pathName)

  const navigate = useNavigate()

  const { t } = useTranslation()

  const { selectedActorRole } = useUser()

  const actorRoleValid = useMemo(
    () =>
      `${selectedActorRole?.subtitle && '/'} ${
        selectedActorRole?.subtitle ?? ''
      }`,
    [selectedActorRole?.subtitle]
  )

  const actorRoleName = useMemo(
    () => `${selectedActorRole?.name ?? ''}${!!actorRoleValid ? ` ${actorRoleValid}` : ''}` ,
    [actorRoleValid, selectedActorRole?.name]
  )

  const genericMetricLabel = t('Account.Dashboard.Breadcrumb.Metric', 'Metric')

  /**
   * We define base breadcrumbs here and then update them with the user selection.
   * This are considered base since we will always have them at the beginning
   * before selecting a user downline.
   * EG: dashboard actorRoleName > inProgressInvitations > from here we may select a user
   *     dashboard actorRoleName > licensingDue > from here we may select a user
   */
  const baseBreadcrumbs: Record<string, BreadcrumbTrail> = {
    dashboard: {
      current: -2,
      before: undefined,
      after: -1,
      userKey: undefined,
      breadcrumb: {
        label: t(
          'Account.Dashboard.Breadcrumb',
          '{{actorRoleName}} - Dashboard',
          { actorRoleName }
        ),
        onClick: () => navigate(baseTeamLeadDashboardPath),
      },
    },
    inProgressInvitations: {
      current: -1,
      before: -2,
      after: undefined,
      userKey: undefined,
      breadcrumb: {
        label: genericMetricLabel,
        onClick: () => navigate(ActionLink.InProgressInvitations),
      },
    },
    licensingDue: {
      current: -1,
      before: undefined,
      after: undefined,
      userKey: undefined,
      breadcrumb: {
        label: genericMetricLabel,
        onClick: () => navigate(ActionLink.LicensingDue),
      },
    },
    outstandingTuition: {
      current: -1,
      before: undefined,
      after: undefined,
      userKey: undefined,
      breadcrumb: {
        label: genericMetricLabel,
        onClick: () => navigate(ActionLink.OutstandingTuition),
      },
    },
    unassignedDirectors: {
      current: -1,
      before: undefined,
      after: undefined,
      userKey: undefined,
      breadcrumb: {
        label: genericMetricLabel,
        onClick: () => navigate(ActionLink.UnassignedDirectors),
      },
    },
    pendingEnrollments: {
      current: -1,
      before: undefined,
      after: undefined,
      userKey: undefined,
      breadcrumb: {
        label: genericMetricLabel,
        onClick: () => navigate(ActionLink.PendingEnrollments),
      },
    },
    myPrograms: {
      current: -1,
      before: undefined,
      after: undefined,
      userKey: undefined,
      breadcrumb: {
        label: genericMetricLabel,
        onClick: () => navigate(ActionLink.Programs),
      },
    },
  }

  // Update base breadcrumbs based on location, past the first two levels we will update according user selection.
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbTrail[]>(() => {
    const dashboardBreadcrumbs = baseBreadcrumbs.dashboard
    const trail: BreadcrumbTrail[] = [dashboardBreadcrumbs]
    switch (basePath) {
      case ActionLink.InProgressInvitations:
        trail.push(baseBreadcrumbs.inProgressInvitations)
        break
      case ActionLink.LicensingDue:
        trail.push(baseBreadcrumbs.licensingDue)
        break
      case ActionLink.OutstandingTuition:
        trail.push(baseBreadcrumbs.outstandingTuition)
        break
      case ActionLink.PendingEnrollments:
        trail.push(baseBreadcrumbs.pendingEnrollments)
        break
      case ActionLink.UnassignedDirectors:
        trail.push(baseBreadcrumbs.unassignedDirectors)
        break
      case ActionLink.Programs:
        trail.push(baseBreadcrumbs.myPrograms)
        break
    }
    return trail
  })

  useEffect(() => {
    if (!!metricName && !!actorRoleName) {
      setBreadcrumbs(([first, second, ...rest]) => [
        {
          ...first,
          breadcrumb: {
            ...first.breadcrumb,
            label: t(
              'Account.Dashboard.Breadcrumb',
              '{{actorRoleName}} - Dashboard',
              { actorRoleName }
            ),
            onClick: () => navigate(baseTeamLeadDashboardPath),
          }
        },
        {
          ...second,
          breadcrumb: {
            ...second.breadcrumb,
            label: metricName,
          },
        },
        ...rest,
      ])
    }
  }, [metricName, actorRoleName, t, navigate])

  useEffect(() => {
    if (!!locationState) {
      setBreadcrumbs((prev) => {
        const [first, second, ...rest] = prev
        const existingIndex = prev.findIndex(
          ({ current }) => current === locationState.downlineActorKey
        )
        if (existingIndex === -1) {
          const last = rest.pop() ?? second
          last.after = locationState?.downlineActorKey
          return [
            first,
            second,
            ...rest,
            ...(last.current !== second.current ? [last] : []),
            {
              current: locationState?.downlineActorKey,
              before: second.current, // reference to previous breadcrumb/actorKey
              after: undefined,
              userKey: locationState?.downlineActorKey,
              breadcrumb: {
                label: locationState?.label ?? '',
                onClick: () =>
                  navigate(
                    `${parseLink(pathName)}/${locationState?.downlineActorKey}`,
                    {
                      state: {
                        label: locationState?.label,
                        downlineActorKey: locationState?.downlineActorKey,
                      },
                    }
                  ),
              },
            },
          ]
        } else {
          prev = prev.slice(0, existingIndex + 1)
          prev[prev.length - 1].after = undefined
          return prev
        }
      })
    } else {
      setBreadcrumbs(([first, second]) => [
        first,
        { ...second, after: undefined },
      ])
    }
  }, [locationState, navigate, pathName])

  return {
    breadcrumbTrail: breadcrumbs,
  }
}

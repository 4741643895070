/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  CreateTranscriptRequestBody,
  CreateTranscriptRequestBodyToJSON,
  FetchCoursesOfStudiesResponseBody,
  FetchCoursesOfStudiesResponseBodyFromJSON,
  FetchGradingScalesResponse,
  FetchGradingScalesResponseFromJSON,
  FetchTranscriptResponse,
  FetchTranscriptResponseFromJSON,
  FetchTranscriptsResponse,
  FetchTranscriptsResponseFromJSON,
  MarkParchmentAsSentRequestBody,
  MarkParchmentAsSentRequestBodyToJSON,
  UpdateTranscriptRequestBody,
  UpdateTranscriptRequestBodyToJSON,
  UpdateTranscriptResponseBody,
  UpdateTranscriptResponseBodyFromJSON,
} from '../models'
import * as runtime from '../runtime'

export interface CreateTranscriptRequest extends runtime.BaseRequestParameters {
  body?: CreateTranscriptRequestBody
}

export interface DeleteTranscriptRequest extends runtime.BaseRequestParameters {
  transcriptKey: number
}

export interface FetchCoursesOfStudiesRequest
  extends runtime.BaseRequestParameters {
  programType: FetchCoursesOfStudiesProgramTypeEnum
  year?: number
}

export interface FetchTranscriptRequest extends runtime.BaseRequestParameters {
  transcriptKey: number
}

export interface FetchTranscriptsRequest extends runtime.BaseRequestParameters {
  page?: number
  pageSize?: number
  orderBy?: Array<string>
  studentKey?: number
  requestStatus?: FetchTranscriptsRequestStatusEnum
  search?: string
}

export interface MarkParchmentAsSentRequest
  extends runtime.BaseRequestParameters {
  body?: MarkParchmentAsSentRequestBody
}

export interface SendTranscriptToParchmentRequest
  extends runtime.BaseRequestParameters {
  transcriptKey: number
}

export interface UpdateTranscriptRequest extends runtime.BaseRequestParameters {
  body?: UpdateTranscriptRequestBody
}

/**
 *
 */
export class TranscriptsApi extends runtime.BaseAPI {
  /**
   * Inserts a new transcript record into the database. User must be acting as parent.
   */
  async createTranscriptRaw(
    requestParameters: CreateTranscriptRequest
  ): Promise<runtime.ApiResponse<FetchTranscriptResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/transcripts/create`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CreateTranscriptRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FetchTranscriptResponseFromJSON(jsonValue)
    )
  }

  /**
   * Inserts a new transcript record into the database. User must be acting as parent.
   */
  async createTranscript(
    requestParameters: CreateTranscriptRequest
  ): Promise<FetchTranscriptResponse> {
    const response = await this.createTranscriptRaw(requestParameters)
    return await response.value()
  }

  /**
   * Takes a transcriptKey as a url param and deletes the transcript from the database. User must be acting as parent and the transcript must belong to the user
   */
  async deleteTranscriptRaw(
    requestParameters: DeleteTranscriptRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.transcriptKey === null ||
      requestParameters.transcriptKey === undefined
    ) {
      throw new runtime.RequiredError(
        'transcriptKey',
        'Required parameter requestParameters.transcriptKey was null or undefined when calling deleteTranscript.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/transcript/{transcriptKey}`.replace(
        `{${'transcriptKey'}}`,
        encodeURIComponent(String(requestParameters.transcriptKey))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Takes a transcriptKey as a url param and deletes the transcript from the database. User must be acting as parent and the transcript must belong to the user
   */
  async deleteTranscript(
    requestParameters: DeleteTranscriptRequest
  ): Promise<void> {
    await this.deleteTranscriptRaw(requestParameters)
  }

  /**
   * Fetches the courses of studies for prepopulating the transcript class information.
   * Fetches the courses of studies for prepopulating the transcript class information.
   */
  async fetchCoursesOfStudiesRaw(
    requestParameters: FetchCoursesOfStudiesRequest
  ): Promise<runtime.ApiResponse<FetchCoursesOfStudiesResponseBody>> {
    if (
      requestParameters.programType === null ||
      requestParameters.programType === undefined
    ) {
      throw new runtime.RequiredError(
        'programType',
        'Required parameter requestParameters.programType was null or undefined when calling fetchCoursesOfStudies.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.year !== undefined) {
      queryParameters['year'] = requestParameters.year
    }

    if (requestParameters.programType !== undefined) {
      queryParameters['programType'] = requestParameters.programType
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/coursesOfStudies`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FetchCoursesOfStudiesResponseBodyFromJSON(jsonValue)
    )
  }

  /**
   * Fetches the courses of studies for prepopulating the transcript class information.
   * Fetches the courses of studies for prepopulating the transcript class information.
   */
  async fetchCoursesOfStudies(
    requestParameters: FetchCoursesOfStudiesRequest
  ): Promise<FetchCoursesOfStudiesResponseBody> {
    const response = await this.fetchCoursesOfStudiesRaw(requestParameters)
    return await response.value()
  }

  /**
   * No permissions. No params. Returns all gradingScale records.
   */
  async fetchGradingScalesRaw(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<runtime.ApiResponse<FetchGradingScalesResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/transcripts/gradingScales`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FetchGradingScalesResponseFromJSON(jsonValue)
    )
  }

  /**
   * No permissions. No params. Returns all gradingScale records.
   */
  async fetchGradingScales(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<FetchGradingScalesResponse> {
    const response = await this.fetchGradingScalesRaw(requestParameters)
    return await response.value()
  }

  /**
   * Takes a transcriptKey as a url parameter and returns that transcript.
   */
  async fetchTranscriptRaw(
    requestParameters: FetchTranscriptRequest
  ): Promise<runtime.ApiResponse<FetchTranscriptResponse>> {
    if (
      requestParameters.transcriptKey === null ||
      requestParameters.transcriptKey === undefined
    ) {
      throw new runtime.RequiredError(
        'transcriptKey',
        'Required parameter requestParameters.transcriptKey was null or undefined when calling fetchTranscript.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/transcripts/{transcriptKey}`.replace(
        `{${'transcriptKey'}}`,
        encodeURIComponent(String(requestParameters.transcriptKey))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FetchTranscriptResponseFromJSON(jsonValue)
    )
  }

  /**
   * Takes a transcriptKey as a url parameter and returns that transcript.
   */
  async fetchTranscript(
    requestParameters: FetchTranscriptRequest
  ): Promise<FetchTranscriptResponse> {
    const response = await this.fetchTranscriptRaw(requestParameters)
    return await response.value()
  }

  /**
   * Returns the transcripts available to the calling user.
   */
  async fetchTranscriptsRaw(
    requestParameters: FetchTranscriptsRequest
  ): Promise<runtime.ApiResponse<FetchTranscriptsResponse>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize
    }

    if (requestParameters.orderBy) {
      queryParameters['orderBy'] = requestParameters.orderBy.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    if (requestParameters.studentKey !== undefined) {
      queryParameters['studentKey'] = requestParameters.studentKey
    }

    if (requestParameters.requestStatus !== undefined) {
      queryParameters['requestStatus'] = requestParameters.requestStatus
    }

    if (requestParameters.search !== undefined) {
      queryParameters['search'] = requestParameters.search
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/transcripts`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FetchTranscriptsResponseFromJSON(jsonValue)
    )
  }

  /**
   * Returns the transcripts available to the calling user.
   */
  async fetchTranscripts(
    requestParameters: FetchTranscriptsRequest
  ): Promise<FetchTranscriptsResponse> {
    const response = await this.fetchTranscriptsRaw(requestParameters)
    return await response.value()
  }

  /**
   * Takes an array of transcriptKeys and updates dateSentToParchment field with the current date for each transcriptKey provided.
   */
  async markParchmentAsSentRaw(
    requestParameters: MarkParchmentAsSentRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/transcripts/markParchmentAsSent`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: MarkParchmentAsSentRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Takes an array of transcriptKeys and updates dateSentToParchment field with the current date for each transcriptKey provided.
   */
  async markParchmentAsSent(
    requestParameters: MarkParchmentAsSentRequest
  ): Promise<void> {
    await this.markParchmentAsSentRaw(requestParameters)
  }

  /**
   * Takes a transcriptKey as a url param and updates requestedSendToParchment field with the current date, if the following required fields are filled out:    student.firstName   dateGraduation   studentEmail    A student is also required for the parchment proccess, however, a studentKey is required to create a transcript.    Returns a 400 status code if fields are not filled out.
   */
  async sendTranscriptToParchmentRaw(
    requestParameters: SendTranscriptToParchmentRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.transcriptKey === null ||
      requestParameters.transcriptKey === undefined
    ) {
      throw new runtime.RequiredError(
        'transcriptKey',
        'Required parameter requestParameters.transcriptKey was null or undefined when calling sendTranscriptToParchment.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/transcripts/send/{transcriptKey}`.replace(
        `{${'transcriptKey'}}`,
        encodeURIComponent(String(requestParameters.transcriptKey))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Takes a transcriptKey as a url param and updates requestedSendToParchment field with the current date, if the following required fields are filled out:    student.firstName   dateGraduation   studentEmail    A student is also required for the parchment proccess, however, a studentKey is required to create a transcript.    Returns a 400 status code if fields are not filled out.
   */
  async sendTranscriptToParchment(
    requestParameters: SendTranscriptToParchmentRequest
  ): Promise<void> {
    await this.sendTranscriptToParchmentRaw(requestParameters)
  }

  /**
   * Updates the transcript. Only callable by the parent for now.
   */
  async updateTranscriptRaw(
    requestParameters: UpdateTranscriptRequest
  ): Promise<runtime.ApiResponse<UpdateTranscriptResponseBody>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/transcripts/update`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: UpdateTranscriptRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UpdateTranscriptResponseBodyFromJSON(jsonValue)
    )
  }

  /**
   * Updates the transcript. Only callable by the parent for now.
   */
  async updateTranscript(
    requestParameters: UpdateTranscriptRequest
  ): Promise<UpdateTranscriptResponseBody> {
    const response = await this.updateTranscriptRaw(requestParameters)
    return await response.value()
  }
}

/**
 * @export
 * @enum {string}
 */
export enum FetchCoursesOfStudiesProgramTypeEnum {
  Foundations = 'Foundations',
  Scribblers = 'Scribblers',
  Essentials = 'Essentials',
  ChallengeA = 'Challenge A',
  ChallengeB = 'Challenge B',
  ChallengeI = 'Challenge I',
  ChallengeIi = 'Challenge II',
  ChallengeIii = 'Challenge III',
  ChallengeIv = 'Challenge IV',
}
/**
 * @export
 * @enum {string}
 */
export enum FetchTranscriptsRequestStatusEnum {
  NotRequested = 'Not Requested',
  Requested = 'Requested',
  Sent = 'Sent',
}

import { Box, Skeleton } from '@mui/material'
import React from 'react'

export const ActionableTableSkeleton: React.FC<{
  rows?: number
}> = ({ rows }) => {
  return (
    <Box>
      <Skeleton
        animation="pulse"
        variant="rectangular"
        style={{
          height: 45,
        }}
      />
      {Array.from({ length: rows ?? 5 }).map((_, index) => (
        <Skeleton
          role="row"
          key={`skeleton-row-${index}`}
          variant="rectangular"
          animation="pulse"
          style={{
            height: 40,
            backgroundColor: index % 2 === 0 ? 'white' : '#f9f9f9',
          }}
        />
      ))}
    </Box>
  )
}

import Tab from '@mui/material/Tab'
import AddIcon from '@mui/icons-material/Add'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router'
import TitleContext from '../../../TitleContext'
import ResponsiveFab from '../../Buttons/ResponsiveFab'
import Roles from '../Roles/Roles'
import { useAuth } from '../../Routes/AuthProvider'
import { Can } from '@casl/react'
import { addIconFudgeFactor } from '../../../utils/addIconFudgeFactor'
import UserAccountsTab, { UserAccountSortBy } from './UserAccountsTab'
import { SnackbarSeverity } from '../../Alerts/SnackbarAlert'
import AddAccountModal from '../../Modals/AddAccountModal'
import { FetchUsersReply, Role } from '../../../swagger'
import {
  extractedErrorObject,
  meta,
  userAccountsApi,
} from '../../../api/swagger'
import NoPermission from '../../Elements/NoPermission'
import { LoadingContext } from '../../Context/LoadingContext'
import { useSnackbarContext } from '../../Context/SnackbarContext'
import { defaultPageSize } from '../../Pagination/TableFooterPagination'
import { styled } from '@mui/system'
import { useLoadingIds } from '../../../hooks/useLoadingIds'
import useLoadingContext from '../../../hooks/useLoadingContext'
import { useMountEffect } from '../../../hooks/useMountEffect'
import { Box } from '@mui/material'
import UserAccountLicensingReport from './UserAccountLicensingReport'

export const defaultSortProperty = [
  UserAccountSortBy.LastNameASC,
  UserAccountSortBy.FirstNameASC,
]

const UserAccountsTabList = styled(TabList)({
  backgroundColor: 'white',
  // FIXME: Increase tab height when at or above medium (CCP1-521)
})

export const UserAccounts: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation()
  const { useTitleEffect } = React.useContext(TitleContext)
  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()

  const { addLoadingIds } = React.useContext(LoadingContext)
  const availableLoadingIds = useLoadingIds()

  const location = useLocation()
  const selectedTab = location.pathname.match(/\/roles/) ? '2' : '1'

  const title = t('UserAccounts.Title', 'User Accounts')
  const rolesTitle = t('Roles.Title', 'Roles')

  useTitleEffect(selectedTab === '1' ? title : rolesTitle)

  const navigate = useNavigate()
  const auth = useAuth()

  const canInviteTeamMember = auth.permissions.some(
    (permission) => permission.resourceCode === 'TeamInvite'
  )

  const canViewUserAccountsTab =
    auth.permissionAbility.can('view', 'User') ||
    auth.permissionAbility.can('editRoleAssignment', 'User') ||
    auth.permissionAbility.can('accessUsersTab', 'Admin') ||
    canInviteTeamMember
  const setSelectedTab = React.useCallback(
    (newValue: string) => {
      switch (newValue) {
        case '1':
          navigate(
            {
              pathname: '/admin/user-accounts',
            },
            {
              /** Navigation Options */
            }
          )
          return
        case '2':
          navigate(
            {
              pathname: '/admin/roles',
            },
            {
              /** Navigation Options */
            }
          )
      }
    },
    [navigate]
  )

  const [isAddAccountModalOpen, setIsAddAccountModalOpen] = useState(false)

  /**
   * Response from fetchUsers endpoint.
   *
   * Existence is used to determine whether we should initially call the endpoint.
   */
  const [usersReply, setUsersReply] = useState<FetchUsersReply>()
  const [error, setError] = useState('')
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [sortAccountsBy, setSortAccountsBy] =
    useState<UserAccountSortBy[]>(defaultSortProperty)

  const [searchQuery, setSearchQuery] = useState('')
  const [roleKeyQuery, setRoleKeyQuery] = useState<number>()

  const errorMessage = t(
    'UserAccounts.ErrorMessage',
    'Something went wrong while trying to load User Accounts data.'
  )

  // This is easiest as a function instead of providing a type for a state setter
  const refetchUserAccountData = useCallback(() => {
    // Trigger refetching data unless we already are.
    addLoadingIds([availableLoadingIds.UserAccounts.fetchUsers])
  }, [addLoadingIds, availableLoadingIds.UserAccounts.fetchUsers])

  /** Separate this from the useEffect and put in useCallback to define its own dependencies */

  const onPageChange = useCallback(
    (page: number): void => {
      setPage(page)
      // Trigger refresh when changing page
      refetchUserAccountData()
    },
    [refetchUserAccountData]
  )

  const handleSortOptions = useCallback(
    (sortProperty: string[]): void => {
      setPage(1)
      setSortAccountsBy(sortProperty as UserAccountSortBy[])
      // Trigger refresh when sort options change
      refetchUserAccountData()
    },
    [refetchUserAccountData]
  )

  const handleSearch = (query: string): void => {
    setSearchQuery(query)
    // Trigger refresh on search
    refetchUserAccountData()
  }

  const handleRowsPerPage = (rowAmount: number): void => {
    setPageSize(rowAmount)
    // Trigger refresh on rows per page change
    refetchUserAccountData()
  }

  const fetchUserAccounts = async () => {
    try {
      if (canViewUserAccountsTab) {
        const fetchedUserAccounts = await userAccountsApi.fetchUsers({
          page: page,
          pageSize: pageSize,
          orderBy: sortAccountsBy,
          search: searchQuery === '' ? undefined : searchQuery,
          roleKey: roleKeyQuery,
          validNowOnly: !!roleKeyQuery,
        })
        setUsersReply(fetchedUserAccounts)
      }
    } catch (error) {
      const errorObject = (await extractedErrorObject(error)) ?? {
        code: 'Unknown',
        message: (error as unknown as Error).message ?? errorMessage,
      }
      setError(errorObject.message)
    }
  }

  useLoadingContext({
    asyncFunction: fetchUserAccounts,
    loadingId: availableLoadingIds.UserAccounts.fetchUsers,
  })

  // If error fetching, display snack bar alert
  useEffect(() => {
    if (!!error) {
      setSnackbarState(true)
      setSnackbarMessage(error)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  })

  // Fetch roles for filter menu options and roles tab
  const [roles, setRoles] = useState<Role[]>([
    { name: '', grants: [], notes: '' },
  ])

  const handleRoleKeySearch = useCallback(
    (searchByRoleQuery: string): void => {
      setPage(1)
      const filteredRole = roles
        ?.filter((role) => role.name === searchByRoleQuery)
        .map((it) => it.roleKey)
      // Prevent triggering a refetch on first render
      if (roleKeyQuery !== filteredRole[0]) {
        setRoleKeyQuery(filteredRole[0])
        // Trigger refresh when filtering by role
        refetchUserAccountData()
      }
    },
    [roles, roleKeyQuery, refetchUserAccountData]
  )

  const failedFetchingRolesMessage = t(
    'UserAccounts.Error.FetchRoles',
    'An unknown error occurred fetching roles.'
  )

  const fetchRoles = async () => {
    const abortController = new AbortController()
    try {
      const _roles = await meta.fetchRoles({
        $signal: abortController.signal,
      })
      if (!abortController.signal.aborted) {
        setRoles(
          _roles.sort((a, b) =>
            new Intl.Collator(i18n.language).compare(a.name, b.name)
          )
        )
      }
    } catch (err) {
      const errorObj = (await extractedErrorObject(err)) ?? {
        code: 'Unknown',
        message:
          (err as unknown as Error).message ?? failedFetchingRolesMessage,
      }
      setSnackbarState(true)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarMessage(errorObj.message)
    } finally {
      abortController.abort()
    }
  }

  useLoadingContext({
    asyncFunction: fetchRoles,
    loadingId: availableLoadingIds.UserAccounts.fetchRoles,
  })

  /** Once on load */
  useMountEffect(() => {
    addLoadingIds([availableLoadingIds.UserAccounts.fetchRoles])
    addLoadingIds([availableLoadingIds.UserAccounts.fetchUsers])
  })

  return (
    <div>
      {isAddAccountModalOpen && (
        <AddAccountModal
          isOpen={isAddAccountModalOpen}
          onClose={() => setIsAddAccountModalOpen(false)}
          refetchUserAccountData={refetchUserAccountData}
        />
      )}
      <TabContext value={selectedTab}>
        <UserAccountsTabList
          value={selectedTab}
          onChange={(_, newValue) => setSelectedTab(newValue)}
          textColor="primary"
        >
          <Tab label={title} value="1" />
          <Tab label={rolesTitle} value="2" />
        </UserAccountsTabList>
        <Can I={'create'} on="User" ability={auth.permissionAbility}>
          {selectedTab === '1' && (
            <Box
              sx={{
                animation: 'fadeIn 250ms ease-in',
                zIndex: '1500',
                position: 'fixed',
              }}
            >
              <ResponsiveFab
                onClick={() => setIsAddAccountModalOpen(true)}
                icon={AddIcon}
                spacingFudgeFactor={addIconFudgeFactor}
                iconLabel={t(
                  'UserAccounts.UserAccountsTab.AddAccountButton.IconLabel',
                  'Add'
                )}
                textLabel={t(
                  'UserAccounts.UserAccountsTab.AddAccountButton.TextLabel',
                  'Account'
                )}
                fullLabel={t(
                  'UserAccounts.UserAccountsTab.AddAccountButton.FullLabel',
                  'Add Account'
                )}
              />
            </Box>
          )}
        </Can>
        <Can I={'createEditDelete'} on="Role" ability={auth.permissionAbility}>
          {selectedTab === '2' && (
            <Box
              sx={{
                animation: 'fadeIn 250ms ease-in',
                zIndex: '1500',
                position: 'fixed',
              }}
            >
              <ResponsiveFab
                to="/admin/roles/add-role"
                icon={AddIcon}
                spacingFudgeFactor={addIconFudgeFactor}
                iconLabel={t(
                  'UserAccounts.UserAccountsTab.AddRoleButton.IconLabel',
                  'Add'
                )}
                textLabel={t(
                  'UserAccounts.UserAccountsTab.AddRoleButton.TextLabel',
                  'Role'
                )}
                fullLabel={t(
                  'UserAccounts.RoleTab.AddRoleButton.Label',
                  'Add Role'
                )}
              />
            </Box>
          )}
        </Can>
        {/** Users Tab */}
        <TabPanel value="1">
          {canViewUserAccountsTab &&
            (location.pathname.match(/\/report/) ? (
              <UserAccountLicensingReport />
            ) : (
              <div>
                <UserAccountsTab
                  userAccounts={usersReply?.userAccounts ?? []}
                  totalCount={usersReply?.pagination?.totalCount ?? -1}
                  filterOptions={roles ?? []}
                  pageSize={pageSize}
                  onPageChange={onPageChange}
                  handleRowsPerPage={handleRowsPerPage}
                  handleSortOptions={handleSortOptions}
                  handleSearch={handleSearch}
                  handleRoleKeySearch={handleRoleKeySearch}
                />
              </div>
            ))}
          {!canViewUserAccountsTab && (
            <div>
              <NoPermission
                content={t(
                  'UserAccounts.NoPermission',
                  `Sorry, you do not have permission to view this content.`
                )}
              />
            </div>
          )}
        </TabPanel>

        {/* * Roles Tab */}
        <TabPanel value="2">
          <Can I={'accessRolesTab'} on="Admin" ability={auth.permissionAbility}>
            {roles && <Roles roles={roles} />}
          </Can>

          <Can
            not
            I={'accessRolesTab'}
            on="Admin"
            ability={auth.permissionAbility}
          >
            <NoPermission
              content={t(
                'UserAccounts.Roles.NoPermission',
                `Sorry, you do not have permission to view this content.`
              )}
            />
          </Can>
        </TabPanel>
      </TabContext>
    </div>
  )
}

export default UserAccounts

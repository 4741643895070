/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface PossibleNewOwners
 */
export interface PossibleNewOwners {
  /**
   * Unique identifier for the actor.
   * @type {number}
   * @memberof PossibleNewOwners
   */
  actorKey: number
  /**
   * Full name of the user.
   * @type {string}
   * @memberof PossibleNewOwners
   */
  user: string
  /**
   * Full name of the supervisor.
   * @type {string}
   * @memberof PossibleNewOwners
   */
  supervisorUser: string
  /**
   * Role name of the user.
   * @type {string}
   * @memberof PossibleNewOwners
   */
  role: string
}

export function PossibleNewOwnersFromJSON(json: any): PossibleNewOwners {
  return PossibleNewOwnersFromJSONTyped(json, false)
}

export function PossibleNewOwnersFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PossibleNewOwners {
  if (json === undefined || json === null) {
    return json
  }
  return {
    actorKey: json['actorKey'],
    user: json['user'],
    supervisorUser: json['supervisorUser'],
    role: json['role'],
  }
}

export function PossibleNewOwnersToJSON(value?: PossibleNewOwners | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    actorKey: value.actorKey,
    user: value.user,
    supervisorUser: value.supervisorUser,
    role: value.role,
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  CashAmount,
  CashAmountFromJSON,
  CashAmountToJSON,
  TuitionPaymentInfo,
  TuitionPaymentInfoFromJSON,
  TuitionPaymentInfoToJSON,
} from './'

/**
 * Object that encapsulates the information needed for each payment.
 * @export
 * @interface PaymentInfo
 */
export interface PaymentInfo {
  /**
   * The unique identifier of the payment.
   * @type {number}
   * @memberof PaymentInfo
   */
  paymentKey: number
  /**
   * The date the payment was made.
   * @type {Date}
   * @memberof PaymentInfo
   */
  paymentDate: Date
  /**
   *
   * @type {CashAmount}
   * @memberof PaymentInfo
   */
  amountPaid: CashAmount
  /**
   * The description of the payment.
   * @type {string}
   * @memberof PaymentInfo
   */
  description: string
  /**
   * The full name of the person who created the payment.
   * @type {string}
   * @memberof PaymentInfo
   */
  paymentCreatorName?: string
  /**
   * List of tuition payments made to a director or tutor.
   * @type {Array<TuitionPaymentInfo>}
   * @memberof PaymentInfo
   */
  tuitionPayments: Array<TuitionPaymentInfo>
}

export function PaymentInfoFromJSON(json: any): PaymentInfo {
  return PaymentInfoFromJSONTyped(json, false)
}

export function PaymentInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaymentInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    paymentKey: json['paymentKey'],
    paymentDate: new Date(json['paymentDate']),
    amountPaid: CashAmountFromJSON(json['amountPaid']),
    description: json['description'],
    paymentCreatorName: !exists(json, 'paymentCreatorName')
      ? undefined
      : json['paymentCreatorName'],
    tuitionPayments: (json['tuitionPayments'] as Array<any>).map(
      TuitionPaymentInfoFromJSON
    ),
  }
}

export function PaymentInfoToJSON(value?: PaymentInfo | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    paymentKey: value.paymentKey,
    paymentDate: value.paymentDate.toISOString(),
    amountPaid: CashAmountToJSON(value.amountPaid),
    description: value.description,
    paymentCreatorName: value.paymentCreatorName,
    tuitionPayments: (value.tuitionPayments as Array<any>).map(
      TuitionPaymentInfoToJSON
    ),
  }
}

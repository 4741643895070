import { useTheme } from '@mui/material'
import { SnackbarSeverity } from '../components/Context/SnackbarContext'
import { Color } from '../stylesheet'
import { dateToSlashString, isSameDate, parsedDate } from '../utils/dateUtility'
import { TextButtonVariant } from '../components/Buttons/TextButton'

export interface UseAlertProps {
  lastSendEmail: Date | undefined
  lastInvoiceLineModifiedDate: Date | undefined
  navigateToInvoice: () => void
  hideAlert: () => void
}

type UseAlertResult = {
  severity: SnackbarSeverity
  message: string
  labelButton: TextButtonVariant
  colorButton: Color
  onClick: () => void
}

const useAlert = ({
  lastSendEmail,
  lastInvoiceLineModifiedDate,
  navigateToInvoice,
  hideAlert,
}: UseAlertProps): UseAlertResult => {
  const resultDefaultUseAlert: UseAlertResult = {
    severity: SnackbarSeverity.Success,
    message: '',
    labelButton: TextButtonVariant.Send,
    colorButton: '',
    onClick: () => null,
  }
  const theme = useTheme()

  if (lastSendEmail === undefined && lastInvoiceLineModifiedDate === undefined)
    return resultDefaultUseAlert

  const severityColorMap = new Map<string, Color>([
    [SnackbarSeverity.Success, theme.palette.secondary.main],
    [SnackbarSeverity.Warning, theme.palette.textOrIcon.warning],
  ])

  switch (true) {
    case !lastSendEmail:
      return {
        severity: SnackbarSeverity.Warning,
        message: 'This invoice has not been sent',
        labelButton: TextButtonVariant.Send,
        colorButton: severityColorMap.get(SnackbarSeverity.Warning),
        onClick: navigateToInvoice,
      }
    case parsedDate((lastSendEmail as Date).toUTCString()) <
      parsedDate((lastInvoiceLineModifiedDate as Date).toUTCString()):
      return {
        severity: SnackbarSeverity.Warning,
        message: `The invoice was updated. Last sent on ${dateToSlashString(
          lastSendEmail as Date
        )}`,
        labelButton: TextButtonVariant.EditResend,
        colorButton: severityColorMap.get(SnackbarSeverity.Warning),
        onClick: navigateToInvoice,
      }
    case isSameDate(
      lastSendEmail as Date,
      lastInvoiceLineModifiedDate as Date
    ) ||
      parsedDate((lastSendEmail as Date).toUTCString()) >
        parsedDate((lastInvoiceLineModifiedDate as Date).toUTCString()):
      return {
        severity: SnackbarSeverity.Success,
        message: 'The invoice is up to date',
        labelButton: TextButtonVariant.Dismiss,
        colorButton: severityColorMap.get(SnackbarSeverity.Success),
        onClick: hideAlert,
      }
    default:
      return resultDefaultUseAlert
  }
}

export default useAlert

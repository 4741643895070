import React, { useContext, useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import Header, { HeaderVariant } from '../Elements/Header'
import { Registrant } from '../../swagger/models/Registrant'
import TableHeaders from '../Interfaces/TableHeaders'
import TableHeader from '../Table/TableHeader'
import { getCommaSeparatedList } from '../../utils/getCommaSeparatedList'
import NoResultsFound from '../Table/NoResultsFound'
import PrintIcon from '@mui/icons-material/Print'
import SpaceBetweenSection from '../Elements/SpaceBetweenSection'
import IconTextButton, {
  IconTextButtonVariant,
} from '../Buttons/IconTextButton'
import { useNavigate, useParams } from 'react-router'
import { Box, styled } from '@mui/system'
import {
  ActionableTable,
  ActionableTableColumn,
} from '../Table/ActionableTable'
import { useAuth } from '../Routes/AuthProvider'
import RegistrantModal from '../Modals/RegistrantModal'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { Action } from '../Table/RowActions'
import { GridRowSelectionModel, GridValueFormatterParams } from '@mui/x-data-grid'
import {
  extractedErrorObject,
  PaginationResponse,
  registrantsApi,
  RegistrantsToUpdate,
} from '../../api/swagger'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { LoadingContext } from '../Context/LoadingContext'
import useLoadingContext from '../../hooks/useLoadingContext'
import LoadingProgress from '../Elements/LoadingProgress'
import { dateToSlashString } from '../../utils/dateUtility'
import { Card, IconButton, Tooltip } from '@mui/material'
import CardFormHeader from '../Card/CardFormHeader'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import { useMountEffect } from '../../hooks/useMountEffect'
import { useErrorSnackbar } from '../../hooks/useErrorSnackbar'


const RegistrantsTableBody = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

interface RegistrantsTableProps {
  registrants: Registrant[]
  eventRegistrants: number
  eventCapacity?: number
  refetch?: () => void
  pagination?: PaginationResponse
  handleRegistrantPagination?: (pagination: PaginationResponse) => void
}

const RegistrantsTable: React.FC<RegistrantsTableProps> = ({
  registrants,
  eventRegistrants,
  eventCapacity,
  refetch,
  pagination,
  handleRegistrantPagination,
}) => {
  const { handleError } = useErrorSnackbar()
  const { addLoadingIds, loadingIds } = useContext(LoadingContext)
  const { t } = useTranslation()
  const { featureAbility, permissionAbility } = useAuth()
  const denominator = `/${eventCapacity}`
  const title = `Registrants (${eventRegistrants}${
    !!eventCapacity ? denominator : ''
  })`

  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()
  const theme = useTheme()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedRegistrant, setSelectedRegistrant] = useState<Registrant>()
  const [selectedRegistrants, setSelectedRegistrants] = useState<Array<RegistrantsToUpdate>>()

  const { RegistrantsTable } = useLoadingIds()

    const [rowSelectionModel, setRowSelectionModel] =
      React.useState<GridRowSelectionModel>([])
  
    const onRowSelectionModelChange = (
      newRowSelectionModel: GridRowSelectionModel
    ) => {
      setRowSelectionModel(newRowSelectionModel)
    }

  const updateRegistrantsLoadingId = RegistrantsTable.updateRegistrants
  const updateRegistrantLoadingId = RegistrantsTable.updateRegistrant
  /**
   * This concatenates an extra information since the same operationId
   *  cannot be used in the same component scope
   * */
  const updateRegistrantAttendingLoadingId = `${RegistrantsTable.updateRegistrant}-attending`

  const ActionableTableColumnsHeader: ActionableTableColumn[] = [
    {
      fieldName: 'firstName',
      sortable: false,
    },
    {
      fieldName: 'lastName',
      sortable: false,
    },
    {
      fieldName: 'roles',
      columnHeaderName: t('Events.Registrants.Table.Header.Role', 'Role'),
      valueFormatter: (
        params: GridValueFormatterParams<string[] | undefined>
      ) => {
        const roles = params?.value
        return roles && roles.length > 0
          ? roles && roles.length > 1
            ? getCommaSeparatedList(roles)
            : roles
          : t('Events.Registrants.Table.NoRole', 'None')
      },
    },
    {
      fieldName: 'email',
      sortable: false,
    },
    {
      fieldName: 'phone',
      columnHeaderName: t(
        'Events.Registrants.Table.Header.PhoneNumber',
        'Phone Number'
      ),
      sortable: false,
    },
    {
      fieldName: 'createdDate',
      columnHeaderName: t(
        'Events.Registrants.Table.Header.DateRegistered',
        'Date Registered'
      ),
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        return dateToSlashString(params.value)
      },
    },
    {
      fieldName: 'attending',
      columnHeaderName: t(
        'Events.Registrants.Table.Header.Attending',
        'Attending'
      ),
      valueFormatter: (params: GridValueFormatterParams<boolean>) => {
        return params.value ? 'Yes' : 'No'
      },
      align: 'center',
      headerAlign: 'center',
    },
  ]
  const tableHeaders: TableHeaders[] = [
    {
      label: t('Events.Registrants.Table.Header.FirstName', 'First Name'),
      align: 'left',
    },
    {
      label: t('Events.Registrants.Table.Header.LastName', 'Last Name'),
      align: 'left',
    },
    {
      label: t('Events.Registrants.Table.Header.Role', 'Role'),
      align: 'left',
    },
    {
      label: t('Events.Registrants.Table.Header.Email', 'Email'),
      align: 'left',
    },
    {
      label: t('Events.Registrants.Table.Header.PhoneNumber', 'Phone Number'),
      align: 'left',
    },
  ]

  const handleEditRegistrant = (registrant?: Registrant) => {
    setSelectedRegistrant(registrant)
    setIsModalOpen(true)
  }

  const closeModal = (options?: { refetch?: boolean }) => {
    setIsModalOpen(false)
    setSelectedRegistrant(undefined)
    if (options?.refetch) {
      refetch?.()
    }
  }

  const rowActions: Action<Registrant>[] = []
  const updateSelectedRegistrant = (row: Registrant | undefined) => {
    setSelectedRegistrant(row)
    addLoadingIds([updateRegistrantAttendingLoadingId])
  }

  if (permissionAbility.can('edit', 'Registrant')) {
    rowActions.push({
      actionName: t('Events.Registrants.Table.RowAction.Edit', 'Edit'),
      actionFunction: handleEditRegistrant,
      actionKey: `edit`,
    })
    rowActions.push({
      actionName: 'attending',
      actionFunction: updateSelectedRegistrant,
      actionKey: 'attending',
      dynamicActionName: {
        fieldNameUseForFlip: 'attending',
        switchableActionsNames: [
          { flipValue: true, actionName: 'Not attending' },
          { flipValue: false, actionName: 'Attending' },
          // In the case the attending value is null menuItem name should be attending
          { flipValue: undefined, actionName: 'Attending' },
        ],
      },
    })
  }

  const defaultErrorMessage = t(
    'Registrant.RegistrantUpdate.ErrorMessage',
    'Something went wrong while updating the registrant attendance.'
  )

  const updateRegistrantAttending = async (): Promise<void> => {
    try {
      if (selectedRegistrant) {
        await registrantsApi.updateRegistrant({
          body: {
            ...selectedRegistrant,
            registrantKey: Number(selectedRegistrant.id),
            attending: !selectedRegistrant.attending,
          },
        })
        refetch?.()
        setSnackbarState?.(true)
        setSnackbarMessage?.(
          t(
            'RegistrantModal.UpdateRegistrant.SuccessMessage',
            'Registrant attendance was successfully updated.'
          )
        )
        setSnackbarSeverity?.(SnackbarSeverity.Success)
      }
    } catch (error) {
      const errorObject = (await extractedErrorObject(error)) ?? {
        code: 'Unknown Code',
        message: (error as unknown as Error).message ?? defaultErrorMessage,
      }
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarMessage(errorObject.message)
      setSnackbarState(true)
    }
  }

  useLoadingContext({
    asyncFunction: updateRegistrantAttending,
    loadingId: updateRegistrantAttendingLoadingId,
  })

  const updateRegistrantsAttending = async (): Promise<void> => {
    try {
      if (selectedRegistrants) {
        await registrantsApi.updateRegistrants({
          body: {
            registrants: selectedRegistrants
          },
        })
        refetch?.()
        setSnackbarState?.(true)
        setSnackbarMessage?.(
          t(
            'RegistrantModal.UpdateRegistrants.SuccessMessage',
            'Registrant attendance was successfully updated.'
          )
        )
        setSnackbarSeverity?.(SnackbarSeverity.Success)
      }
    } catch (error) {
      await handleError(error, defaultErrorMessage)
    }
  }

  const { triggerFetch } = useLoadingContext({
    loadingId: updateRegistrantsLoadingId,
    asyncFunction: updateRegistrantsAttending,
  })

  useMountEffect(() => {
    triggerFetch()
  })

  useEffect(() => {
    triggerFetch()
  },[selectedRegistrants, triggerFetch])


  const { eventKey } = useParams()
  const navigate = useNavigate()

  if (loadingIds.has(updateRegistrantAttendingLoadingId)) {
    return <LoadingProgress />
  }

  const printReports = async () => {
    navigate({
      pathname: `/events/event-details/${eventKey}/registrants-report`,
    })
  }

  return (
    <section aria-labelledby="registrants">
      <RegistrantModal
        isOpen={isModalOpen}
        onClose={closeModal}
        initialRegistrant={selectedRegistrant as Registrant}
        primaryButtonLoadingId={updateRegistrantLoadingId}
      />
      <SpaceBetweenSection
        isCentered
        left={<Header id="registrants" headerName={title} component="h3" />}
        right={
          <IconTextButton
            id="reports"
            variant={IconTextButtonVariant.Report}
            startIcon={
              <PrintIcon
                sx={{
                  color: theme.palette.primary.dark,
                }}
              />
            }
            onClick={printReports}
          />
        }
      ></SpaceBetweenSection>
      {featureAbility.can('registrantActionableTable', 'Feature') ? (
        <Card>
          <Box>
          <CardFormHeader
        header={
          <Header
            id="registrants-header"
            headerName={t(
              'EventRegistrantCard.RegistrantsTable.Title',
              'Registrants'
            )}
            component="h2"
            variant={HeaderVariant.Card}
          />
        }
        buttons={
          <Box mr={2} sx={{ display: 'flex', gap: theme.spacing(0.4) }}>
            {permissionAbility.can('edit', 'Registrant') && (
              <>
              <Tooltip
              title={t(
                'EventRegistrantCard.Tooltip.AddAttending',
                'Add attending'
              )}
            >
                <IconButton
                  aria-label="Add attending"
                  onClick={      
                    () => {
                    setSelectedRegistrants(rowSelectionModel.map(
                      (registrantKey) => {return {registrantKey, attending: true}}
                    ) as unknown as RegistrantsToUpdate[] )
                  }
                  }
                >
                  <EventAvailableIcon />
                </IconButton>
                </Tooltip>
                <Tooltip
                title={t(
                  'EventRegistrantCard.Tooltip.NotAttending',
                  'Not attending'
                )}
              >
                <IconButton
                  aria-label="Not attending"
                  onClick={      
                    () => {
                    setSelectedRegistrants(rowSelectionModel.map(
                      (registrantKey) => {return {registrantKey, attending: false}}
                    ) as unknown as RegistrantsToUpdate[] )

                  }
                  }
                >
                  <EventBusyIcon />
                </IconButton>
              </Tooltip>
              </>
            )}
          </Box>
        }
        />
          </Box>
          <Box>
            <ActionableTable
              checkboxSelection={registrants.length > 0}
              columns={ActionableTableColumnsHeader}
              rows={registrants}
              rowActions={rowActions.length ? rowActions : undefined}
              noResultsMessage={t(
                'Events.Registrants.Table.NoRegistrants',
                'No registrants for this event'
              )}
              pagination={pagination}
              handlePaginationChange={handleRegistrantPagination}
              rowSelectionModel={rowSelectionModel}
              onRowSelectionModelChange={onRowSelectionModelChange}
            />
        </Box>
        </Card>

      ) : (
        <TableContainer
          sx={{
            padding: theme.spacing(3, 4),
          }}
          component={Paper}
        >
          <Table aria-labelledby={title}>
            <TableHead>
              <TableHeader tableHeaders={tableHeaders} />
            </TableHead>
            <TableBody>
              {registrants.length > 0 ? (
                registrants.map((row) => (
                  <TableRow key={row.id}>
                    <RegistrantsTableBody>
                      <Typography variant="body1" component="p">
                        {row.firstName}
                      </Typography>
                    </RegistrantsTableBody>
                    <RegistrantsTableBody>
                      <Typography variant="body1" component="p">
                        {row.lastName}
                      </Typography>
                    </RegistrantsTableBody>
                    <RegistrantsTableBody>
                      <Typography variant="body1" component="p">
                        {row.roles && row.roles.length > 0
                          ? row.roles && row.roles.length > 1
                            ? getCommaSeparatedList(row.roles)
                            : row.roles
                          : t('Events.Registrants.Table.NoRole', 'None')}
                      </Typography>
                    </RegistrantsTableBody>
                    <RegistrantsTableBody>
                      <Typography variant="body1" component="p">
                        {row.email}
                      </Typography>
                    </RegistrantsTableBody>
                    <RegistrantsTableBody>
                      <Typography variant="body1" component="p">
                        {row.phone}
                      </Typography>
                    </RegistrantsTableBody>
                  </TableRow>
                ))
              ) : (
                <NoResultsFound
                  message={t(
                    'Events.Registrants.Table.NoRegistrants',
                    'No Registrants For This Event'
                  )}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </section>
  )
}

export default RegistrantsTable

/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  Actor,
  ActorFromJSON,
  CreateUserRequestParams,
  CreateUserRequestParamsToJSON,
  EditRoleRequestBody,
  EditRoleRequestBodyToJSON,
  FetchUserRolesResponse,
  FetchUserRolesResponseFromJSON,
  FetchUsersReply,
  FetchUsersReplyFromJSON,
  UpdateUserRequestBody,
  UpdateUserRequestBodyToJSON,
  UserAccountDetails,
  UserAccountDetailsFromJSON,
} from '../models'
import * as runtime from '../runtime'

export interface AssignRoleRequest extends runtime.BaseRequestParameters {
  roleKey: number
  userKey: number
  body?: EditRoleRequestBody
}

export interface CreateUserRequest extends runtime.BaseRequestParameters {
  body?: CreateUserRequestParams
}

export interface EditRoleRequest extends runtime.BaseRequestParameters {
  userKey: number
  actorKey: number
  body?: EditRoleRequestBody
}

export interface FetchUserRequest extends runtime.BaseRequestParameters {
  userKey: number
}

export interface FetchUserAccountRolesRequest
  extends runtime.BaseRequestParameters {
  userKey: number
  page?: number
  pageSize?: number
  orderBy?: Array<string>
}

export interface FetchUsersRequest extends runtime.BaseRequestParameters {
  page?: number
  pageSize?: number
  orderBy?: Array<string>
  search?: string
  roleKey?: number
  downlineOnly?: boolean
  validNowOnly?: boolean
}

export interface UnassignRoleRequest extends runtime.BaseRequestParameters {
  userKey: number
  actorKey: number
}

export interface UpdateUserRequest extends runtime.BaseRequestParameters {
  userId: string
  body?: UpdateUserRequestBody
}

/**
 *
 */
export class UserAccountsApi extends runtime.BaseAPI {
  /**
   * Users with the User/assignRole grant can assign a role to a user. They can set the role (passed in with the request params) and specify when the role is valid along with the supervisor of the new role.
   * Assigns a role to a user.
   */
  async assignRoleRaw(
    requestParameters: AssignRoleRequest
  ): Promise<runtime.ApiResponse<Actor>> {
    if (
      requestParameters.roleKey === null ||
      requestParameters.roleKey === undefined
    ) {
      throw new runtime.RequiredError(
        'roleKey',
        'Required parameter requestParameters.roleKey was null or undefined when calling assignRole.'
      )
    }

    if (
      requestParameters.userKey === null ||
      requestParameters.userKey === undefined
    ) {
      throw new runtime.RequiredError(
        'userKey',
        'Required parameter requestParameters.userKey was null or undefined when calling assignRole.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/u/{userKey}/role/{roleKey}`
        .replace(
          `{${'roleKey'}}`,
          encodeURIComponent(String(requestParameters.roleKey))
        )
        .replace(
          `{${'userKey'}}`,
          encodeURIComponent(String(requestParameters.userKey))
        ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: EditRoleRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ActorFromJSON(jsonValue)
    )
  }

  /**
   * Users with the User/assignRole grant can assign a role to a user. They can set the role (passed in with the request params) and specify when the role is valid along with the supervisor of the new role.
   * Assigns a role to a user.
   */
  async assignRole(requestParameters: AssignRoleRequest): Promise<Actor> {
    const response = await this.assignRoleRaw(requestParameters)
    return await response.value()
  }

  /**
   * Users with the User/create grant can create a new user with basic profile information (first name, last name, username) and an optional email and phone number.
   * Creates a new user.
   */
  async createUserRaw(
    requestParameters: CreateUserRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/u`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CreateUserRequestParamsToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Users with the User/create grant can create a new user with basic profile information (first name, last name, username) and an optional email and phone number.
   * Creates a new user.
   */
  async createUser(requestParameters: CreateUserRequest): Promise<void> {
    await this.createUserRaw(requestParameters)
  }

  /**
   * Users with the User/editRoleAssignment grant can assign edit a role for a user. They can edit when the role is valid along with the supervisor of the role.
   * Edits a role assignment for a user.
   */
  async editRoleRaw(
    requestParameters: EditRoleRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userKey === null ||
      requestParameters.userKey === undefined
    ) {
      throw new runtime.RequiredError(
        'userKey',
        'Required parameter requestParameters.userKey was null or undefined when calling editRole.'
      )
    }

    if (
      requestParameters.actorKey === null ||
      requestParameters.actorKey === undefined
    ) {
      throw new runtime.RequiredError(
        'actorKey',
        'Required parameter requestParameters.actorKey was null or undefined when calling editRole.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/u/{userKey}/role/{actorKey}`
        .replace(
          `{${'userKey'}}`,
          encodeURIComponent(String(requestParameters.userKey))
        )
        .replace(
          `{${'actorKey'}}`,
          encodeURIComponent(String(requestParameters.actorKey))
        ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: EditRoleRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Users with the User/editRoleAssignment grant can assign edit a role for a user. They can edit when the role is valid along with the supervisor of the role.
   * Edits a role assignment for a user.
   */
  async editRole(requestParameters: EditRoleRequest): Promise<void> {
    await this.editRoleRaw(requestParameters)
  }

  /**
   * Fetch details of a user account visible to the requester along with role info. The requester must have an active permission to \'view User\', else a 403 status code will be returned. If their permission is restricted to hierarchy, those returned user account must be within the requester\'s downline. If the requester has a \'view Program\' permission as well, the returned user account can also be a family enrolled in programs visible to the requester.
   * Fetch details of a user account visible to the requester along with role info.
   */
  async fetchUserRaw(
    requestParameters: FetchUserRequest
  ): Promise<runtime.ApiResponse<UserAccountDetails>> {
    if (
      requestParameters.userKey === null ||
      requestParameters.userKey === undefined
    ) {
      throw new runtime.RequiredError(
        'userKey',
        'Required parameter requestParameters.userKey was null or undefined when calling fetchUser.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/u/account/{userKey}`.replace(
        `{${'userKey'}}`,
        encodeURIComponent(String(requestParameters.userKey))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserAccountDetailsFromJSON(jsonValue)
    )
  }

  /**
   * Fetch details of a user account visible to the requester along with role info. The requester must have an active permission to \'view User\', else a 403 status code will be returned. If their permission is restricted to hierarchy, those returned user account must be within the requester\'s downline. If the requester has a \'view Program\' permission as well, the returned user account can also be a family enrolled in programs visible to the requester.
   * Fetch details of a user account visible to the requester along with role info.
   */
  async fetchUser(
    requestParameters: FetchUserRequest
  ): Promise<UserAccountDetails> {
    const response = await this.fetchUserRaw(requestParameters)
    return await response.value()
  }

  /**
   * Fetches a list of roles associated to the provided user with pagination and the ability to sort by the following columns: actorKey\', \'name\', \'validFrom\', \'validTo\' and \'supervisor\'. The requester must have an active \'User/view\' permission, otherwise a 403 message will be displayed.
   * Fetches paginated roles with sorting options.
   */
  async fetchUserAccountRolesRaw(
    requestParameters: FetchUserAccountRolesRequest
  ): Promise<runtime.ApiResponse<FetchUserRolesResponse>> {
    if (
      requestParameters.userKey === null ||
      requestParameters.userKey === undefined
    ) {
      throw new runtime.RequiredError(
        'userKey',
        'Required parameter requestParameters.userKey was null or undefined when calling fetchUserAccountRoles.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize
    }

    if (requestParameters.orderBy) {
      queryParameters['orderBy'] = requestParameters.orderBy.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/u/account/{userKey}/roles`.replace(
        `{${'userKey'}}`,
        encodeURIComponent(String(requestParameters.userKey))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FetchUserRolesResponseFromJSON(jsonValue)
    )
  }

  /**
   * Fetches a list of roles associated to the provided user with pagination and the ability to sort by the following columns: actorKey\', \'name\', \'validFrom\', \'validTo\' and \'supervisor\'. The requester must have an active \'User/view\' permission, otherwise a 403 message will be displayed.
   * Fetches paginated roles with sorting options.
   */
  async fetchUserAccountRoles(
    requestParameters: FetchUserAccountRolesRequest
  ): Promise<FetchUserRolesResponse> {
    const response = await this.fetchUserAccountRolesRaw(requestParameters)
    return await response.value()
  }

  /**
   *  Lists user accounts visible to the requester along with role info. Allows for ordering, filtering, and pagination.  ## PERMISSIONS: - Requester must possess a User/view grant, else a 403 will be returned. - User/view[their team\'s] grant allows the requester to view users only in the requester\'s downline. - Program/view[their team\'s] grant allows the requester to see users corresponding to families enrolled in programs visible to the requester. - User/view[any] grant allows the user to see any and all users.  ## FILTERING: - search: search text must be at least 3 characters in length. Including this query parameter will match your search text to a user\'s firstName, lastName, username, email, and name of any role they actively have. - roleKey: including a roleKey will return only users that actively have this role assigned to them. - downlineOnly: setting this to true will return only users and their actors that are within the requester\'s downline team. - validNowOnly: setting this to true will filter returned actors to be only actors that are currently valid.  ## PAGINATION: - If pagination query parameters are not provided, pagination will not be included in the request. WARNING: this could cause a very large query and is not recommended unless the result set is known to be limited. - If pagination query parameters are included, the request will be paginated and ordered by the specified page, pageSize, and orderBy. - orderBy: valid order by properties include: userKey, username, firstName, lastName, email. Supplying anything else will result in an error. - The requested pagination will be returned in the response, including the total count of all data, which will allow the requester to calculate the total amount of pages available with the current pageSize.
   * Lists user accounts visible to the requester along with role info.
   */
  async fetchUsersRaw(
    requestParameters: FetchUsersRequest
  ): Promise<runtime.ApiResponse<FetchUsersReply>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize
    }

    if (requestParameters.orderBy) {
      queryParameters['orderBy'] = requestParameters.orderBy.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    if (requestParameters.search !== undefined) {
      queryParameters['search'] = requestParameters.search
    }

    if (requestParameters.roleKey !== undefined) {
      queryParameters['roleKey'] = requestParameters.roleKey
    }

    if (requestParameters.downlineOnly !== undefined) {
      queryParameters['downlineOnly'] = requestParameters.downlineOnly
    }

    if (requestParameters.validNowOnly !== undefined) {
      queryParameters['validNowOnly'] = requestParameters.validNowOnly
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/u`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FetchUsersReplyFromJSON(jsonValue)
    )
  }

  /**
   *  Lists user accounts visible to the requester along with role info. Allows for ordering, filtering, and pagination.  ## PERMISSIONS: - Requester must possess a User/view grant, else a 403 will be returned. - User/view[their team\'s] grant allows the requester to view users only in the requester\'s downline. - Program/view[their team\'s] grant allows the requester to see users corresponding to families enrolled in programs visible to the requester. - User/view[any] grant allows the user to see any and all users.  ## FILTERING: - search: search text must be at least 3 characters in length. Including this query parameter will match your search text to a user\'s firstName, lastName, username, email, and name of any role they actively have. - roleKey: including a roleKey will return only users that actively have this role assigned to them. - downlineOnly: setting this to true will return only users and their actors that are within the requester\'s downline team. - validNowOnly: setting this to true will filter returned actors to be only actors that are currently valid.  ## PAGINATION: - If pagination query parameters are not provided, pagination will not be included in the request. WARNING: this could cause a very large query and is not recommended unless the result set is known to be limited. - If pagination query parameters are included, the request will be paginated and ordered by the specified page, pageSize, and orderBy. - orderBy: valid order by properties include: userKey, username, firstName, lastName, email. Supplying anything else will result in an error. - The requested pagination will be returned in the response, including the total count of all data, which will allow the requester to calculate the total amount of pages available with the current pageSize.
   * Lists user accounts visible to the requester along with role info.
   */
  async fetchUsers(
    requestParameters: FetchUsersRequest
  ): Promise<FetchUsersReply> {
    const response = await this.fetchUsersRaw(requestParameters)
    return await response.value()
  }

  /**
   * Users with the User/unassignRole grant can unassign a role from a user. The validTo date on the role is set to current date and time, making the role no longer active.
   * Unassigns a role from a user.
   */
  async unassignRoleRaw(
    requestParameters: UnassignRoleRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userKey === null ||
      requestParameters.userKey === undefined
    ) {
      throw new runtime.RequiredError(
        'userKey',
        'Required parameter requestParameters.userKey was null or undefined when calling unassignRole.'
      )
    }

    if (
      requestParameters.actorKey === null ||
      requestParameters.actorKey === undefined
    ) {
      throw new runtime.RequiredError(
        'actorKey',
        'Required parameter requestParameters.actorKey was null or undefined when calling unassignRole.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/u/{userKey}/role/{actorKey}`
        .replace(
          `{${'userKey'}}`,
          encodeURIComponent(String(requestParameters.userKey))
        )
        .replace(
          `{${'actorKey'}}`,
          encodeURIComponent(String(requestParameters.actorKey))
        ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Users with the User/unassignRole grant can unassign a role from a user. The validTo date on the role is set to current date and time, making the role no longer active.
   * Unassigns a role from a user.
   */
  async unassignRole(requestParameters: UnassignRoleRequest): Promise<void> {
    await this.unassignRoleRaw(requestParameters)
  }

  /**
   * Users with the User/edit grant can update a users basic profile information (first name, last name, username, etc).
   * Updates a user.
   */
  async updateUserRaw(
    requestParameters: UpdateUserRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling updateUser.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/u/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(requestParameters.userId))
      ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: UpdateUserRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Users with the User/edit grant can update a users basic profile information (first name, last name, username, etc).
   * Updates a user.
   */
  async updateUser(requestParameters: UpdateUserRequest): Promise<void> {
    await this.updateUserRaw(requestParameters)
  }
}

import { useTranslation } from 'react-i18next'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../components/Context/SnackbarContext'
import { extractedErrorObject } from '../api/swagger'
import useLoadingContext from './useLoadingContext'
import { useState } from 'react'

/*
 * useEndpoint:
 *
 *  A hook for abstracting try-catch and snackbar logic around endpoint calls
 *
 * How to use:
 *
 *  Provide a swaggerCall, which is a function that calls the endpoint you wish,
 *
 *  example: swaggerCall: () => baseApi.endPoint({ parameters or body here })
 *
 *  then provide a loadingId, into the parameters as well.
 *
 *  finally use loadingContext, and add your loadingId to the loadingContext
 *
 *  You're done!
 *
 *  There are lots of optional parameters for adding additional logic
 *  to your endpoint call, see below for more details
 *
 * Snackbar Handling:
 *
 *  One of the main things useEndpoint does is handle snackbar implementation for you,
 *
 *  By default if a endpoint call is successful there is no successMessage, if you
 *  would like to have a successMessage after an endpoint call you must provide one.
 *
 *  By default if a endpoint call fails, the snackbar will display whatever error message,
 *  was extracted from the error object, if none could be extracted, a generic error Message
 *  will be displayed.
 *
 *  This default generic error message can, and should be changed.
 *
 */

export interface UseEndpointProps<ReturnType> {
  /* Function that calls the baseApi.endpoint() with all the parameters needed. */
  swaggerCall: (abortController?: AbortController) => ReturnType

  /* Loading id you want associated with the endpoint call. */
  loadingId: string

  /* Snackbar message to show after a successful endpoint call,
   * this will call with SnackbarSeverity.Success. */
  successMessage?: string

  /* Snackbar message to show after a failed endpoint call,
   * this will call with SnackbarSeverity.Error. */
  failureMessage?: string

  /* Message displayed if no failure message is provided, and no error message
   * could be extracted from the error. */
  genericErrorMessage?: string

  /* An abort controller that handles aborting calls made to the backend apis. */
  abortController?: AbortController

  /* Callback that will be ran on a successful endpoint call. This is called after
   * returnValue has been set, and the Snackbar message has been displayed. */
  successCallback?: () => void

  /* Callback that will be ran on a failed endpoint call. calling the done() callback
   * provided in the parameters, will stop the sncakbar message from executing. */
  failureCallback?: (error: unknown, done: () => void) => void

  /* Callback that will run before any endpoint logic has started, if callback returns true,
   * the endpoint will not be called, if the callback returns false, the hook will continue as normal. */
  dontCall?: () => boolean
}

interface UseEndpointReturn<ReturnType> {
  /* Value that is returned from the swaggerCall function passed into the hook */
  returnValue?: ReturnType
  triggerFetch: () => void
}

const useEndpoint = <ReturnType>({
  swaggerCall,
  loadingId,
  successMessage,
  failureMessage,
  genericErrorMessage,
  abortController,
  successCallback,
  failureCallback,
  dontCall,
}: UseEndpointProps<ReturnType>): UseEndpointReturn<ReturnType> => {
  const { t } = useTranslation()
  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()
  const [returnValue, setReturnValue] = useState<ReturnType>()

  const callEndpoint = async () => {
    if (dontCall && dontCall()) return

    try {
      const value = await swaggerCall(abortController)

      if (!abortController?.signal.aborted) {
        setReturnValue(value)
      }

      if (successMessage) {
        setSnackbarMessage(successMessage)
        setSnackbarSeverity(SnackbarSeverity.Success)
        setSnackbarState(true)
      }

      successCallback && successCallback()
    } catch (error) {
      if (failureCallback) {
        let errorWasHandled = false
        failureCallback(error, () => (errorWasHandled = true))
        if (errorWasHandled) return
      }

      const errorObj = (await extractedErrorObject(error)) ?? {
        code: 'Unknown',
        message:
          genericErrorMessage ??
          t(
            'Hooks.useEndpoint.Snackbar.FailGeneric',
            `An unknown error occurred while calling an endpoint`
          ),
      }

      setSnackbarMessage(failureMessage ?? errorObj.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarState(true)
    } finally {
      abortController?.abort()
    }
  }

  const { triggerFetch } = useLoadingContext({
    loadingId,
    asyncFunction: callEndpoint,
  })


  return { returnValue, triggerFetch }
}

export default useEndpoint

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import BasicModal from './BasicModal'
import { Box, MenuItem, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import ActionButtons from '../Buttons/ActionButtons'
import { TextButtonVariant } from '../Buttons/TextButton'
import {
  CommunityFilterOption,
  DirectorFilterOption,
  ProgramType,
} from '../../swagger'
import DebouncedAutocomplete from '../Elements/DebouncedAutocomplete'
import useLoadingContext from '../../hooks/useLoadingContext'
import { paymentsApi } from '../../api/swagger'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { useErrorSnackbar } from '../../hooks/useErrorSnackbar'
import { formatDirectorOption } from './utils/formatDirectorOptions'

type FilterOption = { value: string; id: string }

export type Filters = {
  director: FilterOption
  programType: FilterOption
  community: FilterOption
}

export type OnFilterChange = <T extends keyof Filters>(
  name: T,
  value: Filters[T]
) => void

type IntlLicensingFiltersModalProps = {
  isOpen: boolean
  onClose: () => void
  onFilter: (values: Filters) => void
  actorKey: number
}

const IntlLicensingFiltersModal = forwardRef<
  { clearFilter: (filterName: keyof Filters) => void },
  IntlLicensingFiltersModalProps
>(({ isOpen, onClose, onFilter, actorKey }, ref) => {
  const { t } = useTranslation()
  const {
    PaymentApi: {
      fetchInternationalLicensingFilterOptions:
        fetchIntlLicensingFilterOptionsLoadingId,
    },
  } = useLoadingIds()

  const { handleError } = useErrorSnackbar()

  const initialValues = useRef({
    director: { value: '', id: '' },
    programType: { value: '', id: '' },
    community: { value: '', id: '' },
  })

  const [filters, setFilters] = useState<Filters>(initialValues.current)
  const [draftFilters, setDraftFilters] = useState<Filters>(
    initialValues.current
  )
  const [directorOptions, setDirectorOptions] = useState<
    Array<DirectorFilterOption>
  >([])
  const [communityOptions, setCommunityOptions] = useState<
    Array<CommunityFilterOption>
  >([])

  const getDirectorAndCommunityOptions = async () => {
    try {
      const { communities, directors } =
        await paymentsApi.fetchInternationalLicensingFilterOptions({
          directorName: draftFilters.director.value,
          communityName: draftFilters.community.value,
          actorKey,
        })
      setCommunityOptions(communities)
      setDirectorOptions(directors)
    } catch (error) {
      await handleError(
        error,
        t(
          'IntlLicensingFiltersModal.InternationalLicensingFilterOptions.ValidationMessage.Error',
          'Something went wrong while fetching the filter options'
        )
      )
    }
  }
  const { triggerFetch: fetchAutocompleteOptions } = useLoadingContext({
    asyncFunction: getDirectorAndCommunityOptions,
    loadingId: fetchIntlLicensingFilterOptionsLoadingId,
  })

  useEffect(() => {
    if (isOpen) {
      setDraftFilters(filters)
    }
  }, [isOpen, filters])

  const handleFilterChange: OnFilterChange = (name, { value, id }) => {
    setDraftFilters((prevFilters) => ({
      ...prevFilters,
      [name]: {
        value: 'All Program Types'.includes(value) ? '' : value,
        id: 'All Program Types'.includes(value) ? '' : id,
      },
    }))
  }

  useImperativeHandle(ref, () => ({
    clearFilter(filterName: keyof Filters) {
      const newValue = {
        ...filters,
        [filterName]: '',
      }
      setFilters(newValue)
      onFilter(newValue)
    },
  }))

  const handleFilterApply = () => {
    setFilters(draftFilters)
    onFilter(draftFilters)
    setDirectorOptions([])
    setCommunityOptions([])
    onClose()
  }

  const handleClose = () => {
    setDirectorOptions([])
    setCommunityOptions([])
    onClose()
  }

  const isClearAllFilterButtonVisible = Object.values(filters).some(
    ({ value }) => value !== ''
  )

  const DialogContent = () => {
    return (
      <Box>
        <Box pb={3.5} key="directorField" width="100%">
          <DebouncedAutocomplete
            id={'available-directors'}
            options={directorOptions}
            handleChange={(selection: DirectorFilterOption | null) => {
              handleFilterChange('director', {
                value: formatDirectorOption(selection),
                id: `${selection?.directorKey ?? 0}`,
              })
            }}
            noOptionsTextValue={t(
              'IntlLicensingFiltersModal.DirectorSearch.NoResults',
              'No director has been found by that name'
            )}
            initialValue={
              !!filters.director.value
                ? ({
                    name: filters.director.value,
                    directorKey: +filters.director.id,
                  } as DirectorFilterOption)
                : undefined
            }
            optionsLabel={(option: DirectorFilterOption) => option.name}
            renderOptions={(
              props: React.HTMLAttributes<HTMLLIElement>,
              option: DirectorFilterOption
            ) => (
              <Box component="li" {...props} key={option.directorKey}>
                {formatDirectorOption(option)}
              </Box>
            )}
            updateSearchResults={(search) => {
              handleFilterChange('director', {
                value: search,
                id: search,
              })
              fetchAutocompleteOptions()
            }}
            textFieldLabelValue={t(
              'IntlLicensingFiltersModal.DirectorSearch.Label',
              'Search a Director'
            )}
          />
        </Box>
        <Box pb={3} key="programField" width="100%">
          <TextField
            name="programType"
            label={t(
              'IntlLicensingFiltersModal.Label.ProgramType',
              'All Program Types'
            )}
            value={draftFilters.programType.value}
            onChange={(e) =>
              handleFilterChange('programType', {
                value: e.target.value,
                id: e.target.value,
              })
            }
            variant="filled"
            fullWidth
            select
          >
            {[
              t(
                'IntlLicensingFiltersModal.Label.AllProgramTypes',
                'All Program Types'
              ),
              ...Object.values(ProgramType),
            ].map((programType) => (
              <MenuItem key={`programType-${programType}`} value={programType}>
                {programType}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box pb={3} key="communityField" width="100%">
          <DebouncedAutocomplete
            id={'available-communities'}
            options={communityOptions}
            handleChange={(selection: CommunityFilterOption | null) => {
              handleFilterChange('community', {
                value: selection?.name ?? '',
                id: `${selection?.communityKey ?? 0}`,
              })
            }}
            initialValue={
              !!filters.community.value
                ? ({
                    name: filters.community.value,
                    communityKey: +filters.community.id,
                  } as CommunityFilterOption)
                : undefined
            }
            noOptionsTextValue={t(
              'IntlLicensingFiltersModal.CommunitySearch.NoResults',
              'No Community has been found by that name'
            )}
            optionsLabel={(option: CommunityFilterOption) => option.name}
            renderOptions={(
              props: React.HTMLAttributes<HTMLLIElement>,
              option: CommunityFilterOption
            ) => (
              <Box component="li" {...props} key={option.communityKey}>
                {option.name}
              </Box>
            )}
            updateSearchResults={(search) => {
              handleFilterChange('community', {
                value: search,
                id: search,
              })
              fetchAutocompleteOptions()
            }}
            textFieldLabelValue={t(
              'IntlLicensingFiltersModal.CommunitySearch.Label',
              'Search a Community'
            )}
          />
        </Box>
      </Box>
    )
  }

  const DialogTitle = () => {
    return t(
      'IntlLicensingFiltersModal.Title.FilterLicensing',
      'Filter Licensing'
    )
  }

  const DialogActions = () => {
    return (
      <ActionButtons
        primaryButtonLabel={ContainedButtonVariant.Filter}
        secondaryClick={() => {
          /**
           * update the internal reference and propagate
           * the update to the parent component
           */
          setFilters(initialValues.current)
          onFilter(initialValues.current)
        }}
        secondaryButtonLabel={
          isClearAllFilterButtonVisible
            ? TextButtonVariant.ClearAllFilters
            : undefined
        }
        tertiaryButtonLabel={TextButtonVariant.Cancel}
        tertiaryClick={handleClose}
        alwaysStack
        useBaseButton
      />
    )
  }

  return (
    <BasicModal
      isOpen={isOpen}
      handleFormSubmit={handleFilterApply}
      maxWidth="xs"
      dialogContent={DialogContent()}
      dialogActions={DialogActions()}
      ariaLabel={DialogTitle()}
      dialogTitle={DialogTitle()}
      labelledBy="IntlLicensingFiltersModal-filters"
    />
  )
})

export default IntlLicensingFiltersModal

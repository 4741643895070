import React from 'react'
import FullScreenAsset from './FullScreenAsset'
import { useParams } from 'react-router'
import { useFetchWidenAsset } from '../../hooks/useFetchWidenAsset'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { Box, CircularProgress } from '@mui/material'
import { CanAccess } from '../Elements/Access'
import EmptyLearningCenter from './EmptyLearningCenter'
import { useMountEffect } from '../../hooks/useMountEffect'

const FullScreenContainer: React.FC = () => {
  const { widenAssetKey } = useParams<{
    widenAssetKey: string
    academicYear: string
  }>()

  const { LearningCenter } = useLoadingIds()
  const parsedAssetKey = parseInt(`${widenAssetKey}`)
  const { contentAsset, isLoading, fetchAsset, errorFetchingContent } =
    useFetchWidenAsset({
      assetKey: parsedAssetKey,
      loadingId: LearningCenter.fetchContentDetail,
    })

  const { filetype, embedUrl } = contentAsset ?? {}

  useMountEffect(() => {
    fetchAsset()
  })

  if (errorFetchingContent || !contentAsset) {
    return <EmptyLearningCenter isLoading={isLoading} />
  }

  return (
    <CanAccess I="learningCenter" on="Feature">
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <FullScreenAsset embedUrl={embedUrl} fileType={filetype} />
      )}
    </CanAccess>
  )
}

export default FullScreenContainer

/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  RegistrantsToUpdate,
  RegistrantsToUpdateFromJSON,
  RegistrantsToUpdateToJSON,
} from './'

/**
 * The body to update registrant receives an object with the following required properties: firstName, lastName, email, attending and registrantKey.
 * @export
 * @interface UpdateRegistrantsRequestBody
 */
export interface UpdateRegistrantsRequestBody {
  /**
   * List of event registrants to update
   * @type {Array<RegistrantsToUpdate>}
   * @memberof UpdateRegistrantsRequestBody
   */
  registrants: Array<RegistrantsToUpdate>
}

export function UpdateRegistrantsRequestBodyFromJSON(
  json: any
): UpdateRegistrantsRequestBody {
  return UpdateRegistrantsRequestBodyFromJSONTyped(json, false)
}

export function UpdateRegistrantsRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateRegistrantsRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    registrants: (json['registrants'] as Array<any>).map(
      RegistrantsToUpdateFromJSON
    ),
  }
}

export function UpdateRegistrantsRequestBodyToJSON(
  value?: UpdateRegistrantsRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    registrants: (value.registrants as Array<any>).map(
      RegistrantsToUpdateToJSON
    ),
  }
}

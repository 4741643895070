/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Timezones, TimezonesFromJSON } from '../models'
import * as runtime from '../runtime'

/**
 *
 */
export class TimezoneApi extends runtime.BaseAPI {
  /**
   * List of timezone names and their offset in the IANA time zone format i.e. America/Los_Angeles .
   */
  async fetchTimezonesRaw(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<runtime.ApiResponse<Timezones>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/timezones`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TimezonesFromJSON(jsonValue)
    )
  }

  /**
   * List of timezone names and their offset in the IANA time zone format i.e. America/Los_Angeles .
   */
  async fetchTimezones(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<Timezones> {
    const response = await this.fetchTimezonesRaw(requestParameters)
    return await response.value()
  }
}

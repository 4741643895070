import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { Event, Registrant } from '../../../swagger'
import { events } from '../../../api/swagger'
import { useLoadingIds } from '../../../hooks/useLoadingIds'
import { useMountEffect } from '../../../hooks/useMountEffect'
import LoadingProgress from '../../Elements/LoadingProgress'
import EmptyPage from '../../Elements/EmptyPage'
import { Box, IconButton, styled, Typography } from '@mui/material'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import RegistrantsReportPDF from './RegistrantsReportPDF'
import { Page } from '../../Elements/PageMargins'
import TitleContext from '../../../TitleContext'
import useEndpoint from '../../../hooks/useEndpoint'
import { LoadingContext } from '../../Context/LoadingContext'
import DynamicBreadcrumbs from '../../Elements/DynamicBreadcrumbs'
import PdfIcon from '@mui/icons-material/PictureAsPdf'
import ContainedButton, {
  ContainedButtonVariant,
} from '../../Buttons/ContainedButton'
import { useShowOnDesktop } from '../../../hooks/useShowOnDesktop'
import { getCommaSeparatedList } from '../../../utils/getCommaSeparatedList'

const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',

  paddingBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    width: '100%',
  },
}))

export const csvHeaders = 'First Name,Last Name,Email,Phone,Attending,Roles'
export const downloadedCSVName = 'registrants-report.csv'

const title = 'Events'

export const RegistrantsReportPDFPreview: React.FC = () => {
  const { eventKey } = useParams()
  const { t } = useTranslation()
  const availableLoadingIds = useLoadingIds()
  const { addLoadingIds, loadingIds } = useContext(LoadingContext)
  const { useTitleEffect } = useContext(TitleContext)
  const navigate = useNavigate()
  useTitleEffect(title)
  const isShowOnDesktop = useShowOnDesktop()
  //   states
  const [eventDetails, setEventDetails] = useState<Event>()
  const [registrants, setRegistrants] = useState<Registrant[]>([])
  const [downloadUrl, setDownloadUrl] = useState<string | undefined>()

  const userAgent = navigator.userAgent.toLowerCase()
  const isMobileBrowser =
    /iphone|ipad|ipod|android|blackberry|windows phone|ipad|tablet|playbook|silk/g.test(
      userAgent
    )

  useEndpoint({
    loadingId: availableLoadingIds.Events.fetchEvent,
    async swaggerCall() {
      const fetchedEventDetails = await events.fetchEvent({
        eventKey: +(eventKey as unknown as number),
      })
      setEventDetails(fetchedEventDetails)
    },
  })

  useEndpoint({
    loadingId: availableLoadingIds.Events.fetchEventRegistrants,
    async swaggerCall() {
      const fetchedRegistrants = await events.fetchEventRegistrants({
        eventKey: +(eventKey as unknown as number),
      })
      setRegistrants(fetchedRegistrants.data)
    },
  })

  useMountEffect(() => {
    addLoadingIds([
      availableLoadingIds.Events.fetchEvent,
      availableLoadingIds.Events.fetchEventRegistrants,
    ])
  })

  useEffect(() => {
    if (registrants.length > 0) {
      const csvRows: string[] = [
        // Our initial CSV rows are just the headers
        csvHeaders,
        // Map each registrant to a CSV row
        ...registrants.map((registrant) =>
          getCommaSeparatedList([
            registrant.firstName,
            registrant.lastName,
            registrant.email ?? '',
            registrant.phone ?? '',
            registrant?.attending ? 'Yes' : 'No',

            registrant.roles && registrant.roles.length > 0
              ? registrant.roles?.join(' ')
              : '',
          ]).replace(' y ', ' , ')
        ),
      ]
      // Join all rows with line breaks to form the final CSV content
      const csvRowsWithBreaks = csvRows.join('\n')
      // Create a Blob with the CSV content and generate an object URL
      const blob = new Blob([csvRowsWithBreaks], {
        type: 'text/csv',
      })
      const url = URL.createObjectURL(blob)
      // Store the CSV URL for downloading
      setDownloadUrl(url)
    }
  }, [registrants])

  if (loadingIds.size > 0) {
    return <LoadingProgress />
  }

  if (registrants.length === 0 || !eventDetails) {
    return (
      <EmptyPage
        message={
          <Typography variant="subtitle1" component="p">
            {t(
              'RegistrantsReportPDFPreview.PDF.Preview.ErrorMessage',
              'No registrants found. Unable to generate the PDF report. Please try again later.'
            )}
          </Typography>
        }
      />
    )
  }

  const breadcrumbs = [
    {
      label: t('RegistrantsReportPDFPreview.Breadcrumb.Events', 'Event'),
      onClick: () => navigate(`/events`),
    },
    {
      label: eventDetails?.name,
      onClick: () => navigate(`/events/event-details/${eventKey}`),
    },

    {
      label: t(
        'RegistrantsReportPDFPreview.Breadcrumb.RegistrantsReport',
        'Registrants Report'
      ),
    },
  ]

  return (
    <Page>
      <DynamicBreadcrumbs breadcrumbs={breadcrumbs} />

      <ButtonsWrapper>
        <ContainedButton
          css={{
            width: isShowOnDesktop ? '35vw' : '100%',
          }}
          variant={ContainedButtonVariant.DownloadRegistrantsCsv}
          id="Download Registrant CSV"
          download={downloadedCSVName}
          href={downloadUrl}
          disabled={!downloadUrl}
        />
      </ButtonsWrapper>

      <Box display="flex" flexDirection={'column'} height={'70vh'}>
        <Box position="relative" width="100%" height="100%">
          <Box
            position="absolute"
            width="100%"
            height="100%"
            top={10}
            zIndex={2}
          >
            {isMobileBrowser && (
              <Box
                justifyContent="center"
                alignItems="center"
                display="flex"
                flexDirection="column"
                textAlign="center"
              >
                <Typography>
                  {t(
                    'Registrants.PDF.Preview.PreviewNotAvailableMessage',
                    'Preview not available on mobile devices, please download the PDF clicking the button below.'
                  )}
                </Typography>
                <PDFDownloadLink
                  document={
                    <RegistrantsReportPDF
                      registrants={registrants}
                      event={eventDetails}
                    />
                  }
                  fileName="registrants.pdf"
                >
                  <IconButton color="success">
                    <PdfIcon />
                    <Typography>
                      {t('Report.PDF.Preview.DownloadPDF', 'Download PDF')}
                    </Typography>
                  </IconButton>
                </PDFDownloadLink>
              </Box>
            )}
            <PDFViewer style={{ flex: 1, width: '100%', height: '100%' }}>
              <RegistrantsReportPDF
                registrants={registrants}
                event={eventDetails}
              />
            </PDFViewer>
          </Box>
        </Box>
      </Box>
    </Page>
  )
}

import { extractedErrorObject } from '../api/swagger'
import { SnackbarSeverity } from '../components/Alerts/SnackbarAlert'
import { useSnackbarContext } from '../components/Context/SnackbarContext'

type ErrorObject = { code: string; message: string }

/**
 * Hook to handle error messages and display them in a snackbar. *
 * This hook provides a function to handle errors by extracting relevant
 * information and setting the snackbar state, message, and severity.
 * @returns {object} - An object with a single method `handleError`.
 * @property {Function} handleError - A function to process errors and display them in the snackbar.
 * @param {unknown} error - The error object to process.
 * @param {string} [defaultErrorMessage] - The default error message to use if none is available.
 **/
export const useErrorSnackbar = (): {
  handleError: (error: unknown, defaultErrorMessage?: string) => Promise<void>
} => {
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()
  const handleError = async (
    error: unknown,
    defaultErrorMessage = 'An unexpected error occurred'
  ): Promise<void> => {
    const errorObj: ErrorObject = (await extractedErrorObject(error)) ?? {
      code: 'Unknown',
      message: (error as unknown as Error)?.message ?? defaultErrorMessage,
    }
    setSnackbarState?.(true)
    setSnackbarMessage?.(errorObj.message)
    setSnackbarSeverity?.(SnackbarSeverity.Error)
  }
  return { handleError }
}

import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TitleContext from '../../TitleContext'
import ResponsiveFab from '../Buttons/ResponsiveFab'
import AddIcon from '@mui/icons-material/Add'
import { addIconFudgeFactor } from '../../utils/addIconFudgeFactor'
import { standardSort } from '../../utils/standardSort'
import {
  EventEventTypeEnum,
  UserAccountListing,
  FetchEventsResponse,
  PaginationResponse,
  OrderByDirection,
} from '../../swagger'
import {
  events as eventApi,
  extractedErrorObject,
  userAccountsApi,
} from '../../api/swagger'
import spacetime, { Spacetime } from 'spacetime'
import { todaysDateFormattedLocal } from '../../utils/todaysDateFormatted'
import { Can } from '@casl/react'
import { useAuth } from '../Routes/AuthProvider'
import Box from '@mui/material/Box'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import { dateToDashString } from '../../utils/dateUtility'
import { reinterpretYearMonthDayAsLocalTime } from '../../utils/reinterpretYearMonthDayAsLocalTime'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { useSnackbarOnNavigation } from '../../hooks/useSnackbarOnNavigation'
import EventsTab from './EventsTab'
import { LoadingContext } from '../Context/LoadingContext'
import useLoadingContext from '../../hooks/useLoadingContext'
import { useMountEffect } from '../../hooks/useMountEffect'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { defaultPageSize } from '../Pagination/TableFooterPagination'
import { EventSortBy } from './EventEnums'
import {
  DEFAULT_PAGE_SIZE,
  MINIMUM_DEBOUNCED_SEARCH_QUERY_LENGTH,
} from '../../utils/constants'
import { useEventContext } from '../Context/EventContext'
import getOrderByClause from '../../utils/getOrderByClause'
import useEndpoint from '../../hooks/useEndpoint'

const EventTypes = {
  AllEvents: 'All Event Types',
  ...EventEventTypeEnum,
}

export const createSpacetime = (
  event: FetchEventsResponse,
  useStartTime = true
): Spacetime => {
  // [year, month, date]
  const dateArray = [
    event.startDate.getUTCFullYear(),
    event.startDate.getUTCMonth(),
    event.startDate.getUTCDate(),
  ]
  switch (useStartTime) {
    case true:
      return spacetime(dateArray, event.timezone).time(event.startTime)
    default:
      return spacetime(dateArray, event.timezone).time(
        event.endTime ?? new Date().toISOString()
      )
  }
}

export const sortEventsByDate = (
  a: FetchEventsResponse,
  b: FetchEventsResponse
): number => {
  const epochA = createSpacetime(a).epoch
  const epochB = createSpacetime(b).epoch

  return standardSort(epochA, epochB)
}

export const getTodaysEarliestEvent = (
  array: FetchEventsResponse[]
): FetchEventsResponse => {
  return array
    .filter((event) => {
      const eventStartDateTime = createSpacetime(event)
      return spacetime.now().startOf('day').isBefore(eventStartDateTime)
    })
    .sort((a, b) => {
      return sortEventsByDate(a, b)
    })[0]
}

const defaultSortProperty = EventSortBy.StartDate
const defaultSortOrder = OrderByDirection.Asc

const eventDefaultCreatorOption = 'All Event Creators'

export interface EventFilter {
  fromDate: string
  page: number
  pageSize: number
  orderBy: Array<string>
  search: string
  eventType: string
  eventCreator: string
}

export const UserEvents: React.FunctionComponent = () => {
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()
  const { t } = useTranslation()
  const title = t('Events.Title', 'Events')
  const { permissionAbility } = useAuth()
  useSnackbarOnNavigation()
  const { resetContextToDefaults } = useEventContext()
  resetContextToDefaults()
  const { useTitleEffect } = React.useContext(TitleContext)
  useTitleEffect(title)

  const { addLoadingIds } = React.useContext(LoadingContext)
  const availableLoadingIds = useLoadingIds()
  const fetchEvetsLoadingId = availableLoadingIds.Events.fetchEvents

  const [isLoading, setIsLoading] = useState(true)

  const [events, setEvents] = useState<FetchEventsResponse[]>([])

  const [pagination, setPagination] = useState<PaginationResponse>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: 0,
    orderBy: [{ [defaultSortProperty]: defaultSortOrder }],
  })

  const [minDateFilter, setMinDateFilter] = useState(String)
  const [eventCreators, setEventCreators] = useState<UserAccountListing[]>([])
  const [eventFilters, setEventFilters] = useState<EventFilter>({
    fromDate: todaysDateFormattedLocal(),
    page: 1,
    pageSize: defaultPageSize,
    orderBy: [`${defaultSortProperty} ${defaultSortOrder}`],
    search: '',
    eventType: EventTypes.AllEvents,
    eventCreator: eventDefaultCreatorOption,
  })

  const [searchTerm, setSearchTerm] = useState('')

  const fetchEventDirectorsErrorMessage = t(
    'Events.Error.FetchUsers',
    'Unknown Error occurred fetching users'
  )

  const updateSearchResults = (search: string) => {
    // set state of search term
    setSearchTerm(search)

    // call and update the event creators based on the search term
    addLoadingIds([availableLoadingIds.Events.fetchUsers])
  }

  const fetchEventCreators = async (opts?: {
    search?: string
  }): Promise<void> => {
    const {
      search = searchTerm.length < MINIMUM_DEBOUNCED_SEARCH_QUERY_LENGTH
        ? undefined
        : searchTerm,
    } = opts ?? {}
    try {
      const { userAccounts: eventCreators } = await userAccountsApi.fetchUsers({
        //downlineOnly is set to true when the actor lacks the accessUsersTab admin permission.
        downlineOnly: !permissionAbility.can('accessUsersTab', 'Admin'),
        validNowOnly: true,
        search,
      })
      setEventCreators(eventCreators)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'UnknownError',
        message:
          (e as unknown as Error).message ?? fetchEventDirectorsErrorMessage,
      }
      setSnackbarState(true)
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  }

  useLoadingContext({
    asyncFunction: fetchEventCreators,
    loadingId: availableLoadingIds.Events.fetchUsers,
  })

  const resetEventCreators = () => {
    setEventCreators([])
  }

  const initMinDateFilter = () => {
    const today = new Date()
    const minDate = new Date()

    minDate.setDate(1)
    minDate.setMonth(0)
    minDate.setFullYear(today.getFullYear() - 2)

    const minDateFormatted = dateToDashString(
      reinterpretYearMonthDayAsLocalTime(minDate)
    )
    setMinDateFilter(minDateFormatted)
  }

  useEndpoint({
    loadingId: fetchEvetsLoadingId,
    swaggerCall: async () => {
      const { events, pagination: paginationResponse } =
        await eventApi.fetchEvents({
          ...eventFilters,
          orderBy: getOrderByClause(pagination.orderBy),
          page: pagination.page,
          pageSize: pagination.pageSize,
          search: eventFilters.search === '' ? undefined : eventFilters.search,
          eventType:
            eventFilters.eventType === EventTypes.AllEvents
              ? undefined
              : eventFilters.eventType,
          eventCreator:
            eventFilters.eventCreator === eventDefaultCreatorOption
              ? undefined
              : eventFilters.eventCreator,
        })
      setEvents(events)
      setIsLoading(false)
      //init the min and max date of datePicker
      initMinDateFilter()

      if (events) {
        setEvents(events)
      }
      if (paginationResponse) {
        const { page, pageSize, totalCount } = paginationResponse

        setPagination({
          ...pagination,
          page,
          pageSize,
          totalCount,
        })
      }
    },
  })

  const newFilter = useRef<EventFilter>({ ...eventFilters })
  const handleEventFilterChange = useCallback(
    <K extends keyof EventFilter>(filter: K, value: EventFilter[K]) => {
      newFilter.current[filter] = value
      setEventFilters(newFilter.current)
      addLoadingIds([fetchEvetsLoadingId])
    },
    [newFilter, addLoadingIds, fetchEvetsLoadingId]
  )

  useMountEffect(() => {
    addLoadingIds([fetchEvetsLoadingId])
  })

  const handleEventsPagination = (pageInformation: PaginationResponse) => {
    if (pagination.page < 1) return

    setPagination({
      ...pageInformation,
      orderBy: pageInformation.orderBy,
      page: pageInformation.page + 1,
    })
    addLoadingIds([fetchEvetsLoadingId])
  }

  const handleClose = () => {
    setSnackbarState(false)
  }

  const AddEventFAB = () => (
    <Can I="create" on="Event" ability={permissionAbility}>
      <Box component="span" onClick={handleClose}>
        <ResponsiveFab
          to="/events/add-event"
          icon={AddIcon}
          spacingFudgeFactor={addIconFudgeFactor}
          iconLabel={t('Events.AddEventButton.IconLabel', 'Add')}
          textLabel={t('Events.AddEventButton.TextLabel', 'Event')}
          fullLabel={t('Events.AddEventButton.FullLabel', 'Add Event')}
        />
      </Box>
    </Can>
  )

  return (
    <>
      {AddEventFAB()}
      <EventsTab
        events={events}
        isLoading={isLoading}
        minDateFilter={minDateFilter}
        dateToFilter={eventFilters.fromDate}
        handleEventFilterChange={handleEventFilterChange}
        handlePaginationChange={handleEventsPagination}
        eventCreators={eventCreators}
        updateSearchResults={updateSearchResults}
        resetEventCreators={resetEventCreators}
        pagination={pagination}
      />
    </>
  )
}
export default UserEvents

/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  FetchMetricResponseBody,
  FetchMetricResponseBodyFromJSON,
  FetchMetricsResponseBody,
  FetchMetricsResponseBodyFromJSON,
} from '../models'
import * as runtime from '../runtime'

export interface FetchMetricRequest extends runtime.BaseRequestParameters {
  metricId: FetchMetricMetricIdEnum
  actorKey?: number
}

/**
 *
 */
export class DashboardApi extends runtime.BaseAPI {
  /**
   * Return the calculated result of the passed in metric.
   */
  async fetchMetricRaw(
    requestParameters: FetchMetricRequest
  ): Promise<runtime.ApiResponse<FetchMetricResponseBody>> {
    if (
      requestParameters.metricId === null ||
      requestParameters.metricId === undefined
    ) {
      throw new runtime.RequiredError(
        'metricId',
        'Required parameter requestParameters.metricId was null or undefined when calling fetchMetric.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.actorKey !== undefined) {
      queryParameters['actorKey'] = requestParameters.actorKey
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/metric/{metricId}`.replace(
        `{${'metricId'}}`,
        encodeURIComponent(String(requestParameters.metricId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FetchMetricResponseBodyFromJSON(jsonValue)
    )
  }

  /**
   * Return the calculated result of the passed in metric.
   */
  async fetchMetric(
    requestParameters: FetchMetricRequest
  ): Promise<FetchMetricResponseBody> {
    const response = await this.fetchMetricRaw(requestParameters)
    return await response.value()
  }

  /**
   * Return all metrics granted to the current actingAs. The response will include a summary of the aggregate for each metric.
   */
  async fetchMetricsRaw(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<runtime.ApiResponse<FetchMetricsResponseBody>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/metrics`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FetchMetricsResponseBodyFromJSON(jsonValue)
    )
  }

  /**
   * Return all metrics granted to the current actingAs. The response will include a summary of the aggregate for each metric.
   */
  async fetchMetrics(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<FetchMetricsResponseBody> {
    const response = await this.fetchMetricsRaw(requestParameters)
    return await response.value()
  }
}

/**
 * @export
 * @enum {string}
 */
export enum FetchMetricMetricIdEnum {
  LicensingDue = 'LicensingDue',
  InProgressInvitations = 'InProgressInvitations',
  PendingEnrollments = 'PendingEnrollments',
  UnassignedDirectors = 'UnassignedDirectors',
  OutstandingTuition = 'OutstandingTuition',
  Programs = 'Programs',
}

import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  EventEventTypeEnum,
  UserAccountListing,
  FetchEventsResponse,
  PaginationResponse,
  OrderByDirection,
} from '../../swagger/models'
import EventSummaryTable from './EventSummaryTable'
import Header, { HeaderVariant } from '../Elements/Header'
import EventCardMobile from '../Card/EventCardMobile'
import SearchBar from '../Search/SearchBar'
import DropDown, { DropDownVariant } from '../Menus/DropDown'
import OutlinedButton, {
  OutlinedButtonVariant,
} from '../Buttons/OutlinedButton'
import TodaysEventCard from '../Card/TodaysEventCard'
import { Page } from '../Elements/PageMargins'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { createSpacetime, EventFilter, getTodaysEarliestEvent } from './Events'
import spacetime from 'spacetime'
import { TFunction } from 'i18next'
import Tooltip from '@mui/material/Tooltip'
import DateField, { DateFieldVariant } from '../Elements/DateField'
import { useNavigate } from 'react-router'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import { EventSortBy, EventSortOptions } from './EventEnums'
import { Help } from '@mui/icons-material'
import fullName from '../../utils/fullName'
import DebouncedAutocomplete from '../Elements/DebouncedAutocomplete'
import CardFormHeader from '../Card/CardFormHeader'
import Card from '@mui/material/Card'

const labelForEventTypesFilter = (sortOption: string, t: TFunction) => {
  switch (sortOption) {
    case EventTypes.InformationMeeting:
      return t('Events.EventTypes.InformationMeeting', 'Information Meeting')
    case EventTypes.OpenHouse:
      return t('Events.EventTypes.OpenHouse', 'Open House')
    case EventTypes.Practicum:
      return t('Events.EventTypes.Practicum', 'Practicum')
    default:
      return t('Events.EventTypes.AllEventTypes', 'All Event Types')
  }
}

const StyledHeader = styled(Header)(({ theme }) => ({
  margin: theme.spacing(3, 'auto'),
  padding: 0,
}))

interface EventsTabProps {
  events: FetchEventsResponse[]
  isLoading: boolean
  minDateFilter: string
  dateToFilter: string
  handleEventFilterChange: <K extends keyof EventFilter>(
    filter: K,
    value: EventFilter[K]
  ) => void
  eventCreators: UserAccountListing[]
  updateSearchResults: (s: string) => void
  resetEventCreators: () => void
  handlePaginationChange: (pagination: PaginationResponse) => void
  pagination: PaginationResponse
}

const EventTypes = {
  AllEvents: 'All Event Types',
  ...EventEventTypeEnum,
}

const labelForSortOption = (
  sortOption: EventSortOptions,
  t: TFunction
): string => {
  switch (sortOption) {
    case EventSortOptions.EventName:
      return t('Team.SortOptions.EventName', 'Sort By: Event Name')
    case EventSortOptions.StartTime:
      return t('Team.SortOptions.StartTime', 'Sort By: Start Time')
    case EventSortOptions.CreatedBy:
      return t('Team.SortOptions.CreatedBy', 'Sort By: Created By')
    default:
      return t('Team.SortOptions.StartDate', 'Sort By: Start Date')
  }
}

export const EventsTab: React.FunctionComponent<EventsTabProps> = (props) => {
  const {
    events,
    isLoading,
    minDateFilter,
    dateToFilter,
    handleEventFilterChange,
    eventCreators,
    updateSearchResults,
    resetEventCreators,
    handlePaginationChange,
    pagination,
  } = props

  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const isMobileOrSmaller = useMediaQuery(theme.breakpoints.down('sm'))
  const [filterType, setFilterType] = useState('')
  const [paramValue, setParamValue] = useState('')

  const todayEvent = useMemo(() => getTodaysEarliestEvent(events), [events])

  let isNextEventToday = true

  if (todayEvent) {
    isNextEventToday = spacetime
      .now()
      .isSame(createSpacetime(todayEvent), 'day')
  }

  const translationText = t(
    'Events.AddTittleToolTip.TittleTranslation',
    'Search for events by date, past or future, to see all events on that date and after'
  )

  const dateOnOrAfterFilter = () => (
    <Box display="flex" alignItems="center">
      <DateField
        dateVariant={DateFieldVariant.EventsByDate}
        inputProps={{
          min: `${minDateFilter}`,
          ref: React.createRef(),
        }}
        variant="filled"
        type="date"
        fullWidth={true}
        onChangeForParent={handleDateChange}
        value={dateToFilter}
        onKeyDown={handleOnkeyDown}
      />
      <Tooltip title={translationText}>
        <Help />
      </Tooltip>
    </Box>
  )

  const handleDateChange = (Event: React.ChangeEvent<HTMLInputElement>) => {
    handleEventFilterChange('fromDate', Event.target.value)
  }
  const handleOnkeyDown = (Event: React.KeyboardEvent<HTMLInputElement>) => {
    Event.preventDefault()
  }

  const toEventDetailsPage = (id: number, eventName: string) => {
    navigate(
      {
        pathname: `/events/event-details/${id}`,
      },
      {
        state: { eventName },
      }
    )
  }

  const handleDropDownSelection = (
    selection: string,
    id: string,
    selectedOptionId: string | number
  ) => {
    switch (id) {
      case 'filterByEventType':
        setParamValue(selection)
        setFilterType('eventType')
        break
      case 'filterByEventCreator':
        setParamValue(selection)
        setFilterType('eventCreator')
        // When click on the clear icon, set the eventCreators array back to empty
        if (selectedOptionId === 'clear') {
          resetEventCreators()
        }
        break
      // dropdown sort is only available in mobile version
      case 'sort':
        let orderByProperty: string = EventSortBy.StartDate
        switch (selection) {
          case EventSortOptions.StartDate:
            orderByProperty = EventSortBy.StartDate
            break
          case EventSortOptions.StartTime:
            orderByProperty = EventSortBy.StartTime
            break
          case EventSortOptions.CreatedBy:
            orderByProperty = EventSortBy.CreatedBy
            break
          case EventSortOptions.EventName:
            orderByProperty = EventSortBy.EventName
            break
          default:
            break
        }

        let orderDirection = OrderByDirection.Asc
        if (Object.keys(pagination.orderBy[0]).includes(orderByProperty)) {
          // we use the 0 index because we are using a single dropdown to select the
          // ordering so we only can apply the ordering one by the time
          orderDirection = Object.values(pagination.orderBy[0]).includes(
            OrderByDirection.Asc
          )
            ? OrderByDirection.Desc
            : OrderByDirection.Asc
        }

        handlePaginationChange({
          ...pagination,
          page: 0,
          orderBy: [{ [orderByProperty]: orderDirection }],
        })

        break
    }
  }

  const handleChangeAutocomplete = (
    selection: UserAccountListing | null,
    reason: string
  ) => {
    const selectedValue = selection ? selection.username : 'All Event Creators'

    handleDropDownSelection(
      selectedValue,
      'filterByEventCreator',
      reason as string
    )
  }

  const handleSearch = (searchText: string) => {
    setFilterType('search')
    setParamValue(searchText)
  }

  const getEventCreatorsOptionLabel = (option: UserAccountListing) =>
    fullName({
      firstName: option.firstName,
      lastName: option.lastName,
    })

  const getEventCreatorsRenderOptions = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: UserAccountListing
  ) => (
    <Box component="li" {...props} key={option.userKey}>
      {option.firstName} {option.lastName}
    </Box>
  )

  useEffect(() => {
    handleEventFilterChange(filterType as keyof EventFilter, paramValue)
  }, [handleEventFilterChange, filterType, paramValue])

  return (
    <Page>
      {isMobileOrSmaller ? (
        <>
          <StyledHeader
            id="todayEventHeader"
            headerName={
              isNextEventToday
                ? t('Events.Card.Header.Today', "Today's Event")
                : t('Events.Card.Header.Upcoming', 'Upcoming Event')
            }
          />

          {todayEvent !== undefined ? (
            <EventCardMobile
              event={todayEvent}
              todaysEvent={true}
              isLoading={isLoading}
            />
          ) : null}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              margin: theme.spacing(0, 'auto'),
            }}
          >
            <SearchBar handleSearch={handleSearch} />
          </Box>
          <section>
            <Box display="flex" justifyContent={'space-between'}>
              <DropDown
                id="filterByEventType"
                menuOptions={Object.values(EventTypes).map((option) => {
                  return {
                    name: labelForEventTypesFilter(option, t),
                    id: option,
                  }
                })}
                defaultValue={t('Events.FilterOption.EventTypes', 'All Events')}
                handleSelection={handleDropDownSelection}
                formControlProps={{
                  width: `calc(100% - ${theme.spacing(2)})`,
                  marginRight: theme.spacing(2),
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    margin: theme.spacing(2),
                  },
                }}
                variant={DropDownVariant.SortAndFilter}
              />
            </Box>
            <Box display="flex" justifyContent={'space-between'}>
              <DebouncedAutocomplete
                id={'filterByEventCreator'}
                options={eventCreators}
                handleChange={handleChangeAutocomplete}
                noOptionsTextValue={t(
                  'Events.CreatorSearch.NoResults',
                  'No Event creator with the given name found'
                )}
                optionsLabel={getEventCreatorsOptionLabel}
                renderOptions={getEventCreatorsRenderOptions}
                updateSearchResults={updateSearchResults}
                textFieldLabelValue={t(
                  'Event.Label.EventCreator',
                  'Filter By Event Creator'
                )}
                textFieldStyle={{}}
              />
            </Box>
            <Box textAlign="center">{dateOnOrAfterFilter()}</Box>
          </section>
          <StyledHeader
            id="eventsHeader"
            headerName={t('Events.Card.Header.Events', 'Events')}
          />

          <Box textAlign={'center'}>
            <DropDown
              fullWidth
              cssProps={{
                display: 'flex',
                justifyContent: 'center',
                maxWidth: `calc(100vw - ${theme.spacing(4)})`,
              }}
              id="sort"
              menuOptions={Object.values(EventSortOptions).map((option) => {
                return {
                  name: labelForSortOption(option, t),
                  id: option,
                }
              })}
              defaultValue={t('Events.SortOption.Sort', 'Sort')}
              handleSelection={handleDropDownSelection}
              variant={DropDownVariant.SortAndFilter}
            />
          </Box>

          {events.map((event) =>
            event !== undefined ? (
              <EventCardMobile
                key={event.eventKey}
                event={event}
                isLoading={isLoading}
              />
            ) : null
          )}
          {/** Hide button once we are viewing all available Events */}
          {pagination.totalCount > pagination.pageSize ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: theme.spacing(6, 0),
              }}
            >
              <OutlinedButton
                data-testid="viewMoreButton"
                id="viewMoreDraftEvents"
                onClick={() =>
                  handlePaginationChange({
                    ...pagination,
                    pageSize: pagination.pageSize + 10, // add 10 more events to the page
                    page: 0, /// Reset the page to the first page
                  })
                }
                variant={OutlinedButtonVariant.ViewMore}
              />
            </Box>
          ) : null}
        </>
      ) : (
        <>
          {todayEvent && (
            <TodaysEventCard
              isLoading={isLoading}
              onClick={() =>
                toEventDetailsPage(
                  (todayEvent.eventKey as number) ?? '',
                  todayEvent.name
                )
              }
              todaysEvent={todayEvent}
              headerLabel={
                isNextEventToday
                  ? t('Events.Card.Header.Today', "Today's Event")
                  : t('Events.Card.Header.Upcoming', 'Upcoming Event')
              }
            />
          )}
          <Card>
            <CardFormHeader
              header={
                <Header
                  id="transcripts-header"
                  headerName={t('Events.Card.Header', 'Events')}
                  component="h2"
                  variant={HeaderVariant.Card}
                />
              }
              buttons={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SearchBar
                    handleSearch={handleSearch}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        margin: 0,
                        marginTop: theme.spacing(1),
                      },
                    }}
                  />
                  <Box
                    sx={{
                      margin: theme.spacing(0, 3),
                      marginTop: theme.spacing(2),
                    }}
                  >
                    <DropDown
                      id="filterByEventType"
                      menuOptions={Object.values(EventTypes).map((option) => {
                        return {
                          name: labelForEventTypesFilter(option, t),
                          id: option,
                        }
                      })}
                      defaultValue={t(
                        'Events.FilterOption.EventTypes',
                        'All Event Types'
                      )}
                      handleSelection={handleDropDownSelection}
                      variant={DropDownVariant.SortAndFilter}
                    />
                  </Box>
                  <Box mr={theme.spacing(3)}>
                    <DebouncedAutocomplete
                      id={'filterByEventCreator'}
                      options={eventCreators}
                      handleChange={handleChangeAutocomplete}
                      noOptionsTextValue={t(
                        'Events.CreatorSearch.NoResults',
                        'No Event creator with the given name found'
                      )}
                      optionsLabel={getEventCreatorsOptionLabel}
                      renderOptions={getEventCreatorsRenderOptions}
                      updateSearchResults={updateSearchResults}
                      textFieldLabelValue={t(
                        'Event.Label.EventCreator',
                        'Filter By Event Creator'
                      )}
                    />
                  </Box>
                  <Box textAlign="center">{dateOnOrAfterFilter()}</Box>
                </Box>
              }
            />
            <Box>
              <EventSummaryTable
                events={events}
                isLoading={isLoading}
                handlePaginationChange={handlePaginationChange}
                pagination={pagination}
              />
            </Box>
          </Card>
        </>
      )}
    </Page>
  )
}

export default EventsTab

/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 * Describes a permission that can be granted to a role.
 *
 * Note the split between the code properties, intended as a controlled vocabulary for computer consumption, and the other properties, intended for display to humans. Each `[actionCode, resourceCode]` pair should be unique. (The codes could also be used to drive client-side localization of the other human-centric fields, which are only provided in English.)
 * @export
 * @interface Permission
 */
export interface Permission {
  /**
   * Suggested category for organizing the permission. Often very similar to `resourceCode`.
   * @type {string}
   * @memberof Permission
   */
  category?: string
  /**
   * Suggested user-friendly name for the permission.
   * @type {string}
   * @memberof Permission
   */
  name?: string
  /**
   * Explanatory text for the permission.
   * @type {string}
   * @memberof Permission
   */
  description?: string
  /**
   * The action name used when checking if an actor is allowed to take this action on the targeted resource.
   * @type {string}
   * @memberof Permission
   */
  actionCode: string
  /**
   * The type name of the resource used when checking if an actor is allowed to take this action on the targeted resource.
   * @type {string}
   * @memberof Permission
   */
  resourceCode: string
}

export function PermissionFromJSON(json: any): Permission {
  return PermissionFromJSONTyped(json, false)
}

export function PermissionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Permission {
  if (json === undefined || json === null) {
    return json
  }
  return {
    category: !exists(json, 'category') ? undefined : json['category'],
    name: !exists(json, 'name') ? undefined : json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    actionCode: json['actionCode'],
    resourceCode: json['resourceCode'],
  }
}

export function PermissionToJSON(value?: Permission | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    category: value.category,
    name: value.name,
    description: value.description,
    actionCode: value.actionCode,
    resourceCode: value.resourceCode,
  }
}

import { Document, Image, Page, Text, View } from '@react-pdf/renderer'
import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import Html from 'react-pdf-html'
import { LicensingBillingForPDF } from './LicensingBillingForPDF'
import { styles } from './licensingBillingPdfStyles'
import CCLogo from '../../../../Images/Branding/cc-combination-blue.png'
import { LicensingBill } from '../../../../swagger'
import { useTranslation } from 'react-i18next'

export const LicensingBillingPDF: React.FC<{
  licensingBills: LicensingBill[]
  fullName: string
}> = ({ licensingBills, fullName }) => {
  const { t } = useTranslation()

  return (
    <Document title="Billing">
      <Page size="LETTER" style={styles.page}>
        <Image style={styles.logo} src={CCLogo} />
        <View style={styles.billToContainer}>
          <Text style={styles.billToLabel}>
            {t(
              'Billing.PDFPreview.BillTo',
              'Program Licensing for {{fullName}}',
              {
                fullName,
              }
            )}
          </Text>
        </View>
        <Html>
          {renderToStaticMarkup(
            <LicensingBillingForPDF licensingBills={licensingBills} />
          )}
        </Html>
      </Page>
    </Document>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import BasicModal from './BasicModal'
import { Box, MenuItem, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import {
  getLabelForTransactionOption,
  TransactionOption,
} from './TransactionModal'
import useLoadingContext from '../../hooks/useLoadingContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { validateAmount } from './utils/validateAmount'
import { amountFromInput } from '../../utils/amountFromInput'
import { getAmountFromLocaleCurrency } from '../../utils/getAmountFromLocalCurrency'
import { useErrorSnackbar } from '../../hooks/useErrorSnackbar'
import { paymentsApi } from '../../api/swagger'

interface Fields {
  key: string
  id: string
  name: string
  label: string
  value: string | number
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  fullWidth?: boolean
  multiline?: boolean
  rows?: number
  helperText?: string
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  select?: boolean
  options?: {
    key: string
    value: string
    label: string
    disabled?: boolean
  }[]
}

type IntlAccountingTransactionModalProps = {
  isOpen: boolean
  onClose: () => void
  userKey: number
  actorKey: number
}

export type Step = 'selectTransaction' | 'enterPayment' | 'issueRefund'

const IntlAccountingTransactionModal: React.FC<
  IntlAccountingTransactionModalProps
> = ({ isOpen, onClose, userKey, actorKey }) => {
  const { t } = useTranslation()
  const {
    PaymentModal: {
      addInternationalLicensingPayment:
        addInternationalLicensingPaymentLoadingId,
    },
    BillingTab: {
      fetchBilling: fetchBillingLoadingId,
      fetchCountryCoordinatorTotalBalance:
        fetchCountryCoordinatorTotalBalanceLoadingId,
    },
    CountryCoordinatorLicensingPaymentHistory: {
      fetchLicensingInternationalPayments:
        fetchLicensingInternationalPaymentsLoadingId,
    },
  } = useLoadingIds()

  const { handleError } = useErrorSnackbar()

  const transactionOptions = Object.entries(TransactionOption).map(
    ([key, value]) => ({
      key,
      name: getLabelForTransactionOption(value, t),
    })
  )

  const initialPayment = useRef({
    amount: '0.00',
    description: '',
    tax: '0.00',
    orderId: '',
  })

  const [transactionData, setTransactionData] = useState(initialPayment.current)
  const [currentStep, setCurrentStep] = useState<Step>('selectTransaction')

  const isAddTransactionDisabled = !validateAmount(transactionData.amount)

  // If we have a transaction with a positive or zero amount: it is a payment, negative: it is a refund, no transaction: require a selection (default '')
  const [transactionType, setTransactionType] = useState('')

  const refineTransactionData = (step: Step) => {
    setTransactionData((prevData) => {
      const refinedAmountString = amountFromInput(prevData.amount)
      const refinedTaxString = amountFromInput(prevData.tax)

      const [refinedAmount, refinedTax] =
        step === 'issueRefund'
          ? [`-${refinedAmountString}`, `-${refinedTaxString}`]
          : [refinedAmountString, refinedTaxString]

      return {
        ...prevData,
        amount: refinedAmount,
        tax: refinedTax,
      }
    })
  }

  // Update the `currentStep` based on the dropdown selection (transactionType)
  useEffect(() => {
    if (transactionType === transactionOptions[0].name) {
      setCurrentStep('enterPayment')
    }

    if (transactionType === transactionOptions[1].name) {
      setCurrentStep('issueRefund')
    }
  }, [transactionOptions, transactionType])

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target

    setTransactionData((prevData) => {
      return { ...prevData, [name]: value }
    })
  }

  useEffect(() => {
    refineTransactionData(currentStep)
  }, [currentStep])

  const handleInputBlur = (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name } = event.target

    if (name === 'amount' || name === 'tax') {
      refineTransactionData(currentStep)
    }
  }

  /** ******************* Add Transaction ******************* */
  const handleTransaction = async () => {
    try {
      await paymentsApi.addInternationalLicensingPayment({
        body: {
          userKey,
          actorKey,
          amount: getAmountFromLocaleCurrency(transactionData.amount),
          desc: transactionData.description,
          orderId: transactionData.orderId,
          tax: getAmountFromLocaleCurrency(transactionData.tax),
        },
      })
      addLoadingIds([
        fetchBillingLoadingId + actorKey,
        fetchLicensingInternationalPaymentsLoadingId,
        fetchCountryCoordinatorTotalBalanceLoadingId,
      ])
      onClose()
    } catch (e) {
      await handleError(
        e,
        t(
          'IntlAccountingTransactionModal.ValidationMessage.AddTransactionError',
          'Something went wrong while adding the Payment.'
        )
      )
    }
  }

  const { triggerFetch: addTransaction, addLoadingIds } = useLoadingContext({
    asyncFunction: handleTransaction,
    loadingId: addInternationalLicensingPaymentLoadingId,
  })

  /** ******************* Modal-related Functions ******************* */
  const DialogContent = () => {
    let amountLabel = t(
      'IntlAccountingTransactionModal.Label.PaymentAmount',
      'Payment Amount (excluding tax)'
    )
    let descriptionLabel = t(
      'IntlAccountingTransactionModal.Label.PaymentDescription',
      'Payment Description (optional)'
    )

    if (currentStep === 'issueRefund') {
      amountLabel = t(
        'IntlAccountingTransactionModal.Label.RefundAmount',
        'Refund Amount (excluding tax)'
      )
      descriptionLabel = t(
        'IntlAccountingTransactionModal.Label.RefundDescription',
        'Refund Description (optional)'
      )
    }

    const fields: Fields[] = [
      {
        key: 'transactionTypeField',
        id: 'transactionTypeField',
        name: 'transactionType',
        label: t(
          'IntlAccountingTransactionModal.Label.Transaction',
          'Transaction'
        ),
        value: transactionType,
        onChange: (e) => setTransactionType(e.target.value),
        fullWidth: true,
        select: true,
        options: transactionOptions?.length
          ? transactionOptions.map((option) => ({
              key: option.key.toString(),
              value: option.name,
              label: option.name,
            }))
          : [
              {
                key: 'no-options',
                value: '',
                label: t(
                  'IntlAccountingTransactionModal.MenuItem.NoOptions',
                  'No Options'
                ),
                disabled: true,
              },
            ],
      },
      {
        key: 'amount',
        id: 'amount',
        name: 'amount',
        label: amountLabel,
        value: transactionData.amount,
        onChange: handleInputChange,
        onBlur: handleInputBlur,
        fullWidth: true,
      },
      {
        key: 'taxAmount',
        id: 'taxAmount',
        name: 'tax',
        label: t(
          'IntlAccountingTransactionModal.Label.TaxAmount',
          'Tax Amount'
        ),
        value: transactionData.tax,
        onChange: handleInputChange,
        onBlur: handleInputBlur,
        fullWidth: true,
      },
      {
        key: 'description',
        id: 'description',
        name: 'description',
        label: descriptionLabel,
        value: transactionData?.description ?? '',
        onChange: handleInputChange,
        fullWidth: true,
        multiline: true,
        rows: 4,
        helperText: t(
          'IntlAccountingTransactionModal.Description.HelperText.MaxCharacters',
          '60 characters max'
        ),
        inputProps: { maxLength: 60 },
      },
      {
        key: 'acumaticaOrderNumberField',
        id: 'acumaticaOrderNumberField',
        name: 'orderId',
        label: t(
          'IntlAccountingTransactionModal.Label.AcumaticaOrderNumber',
          'Acumatica Order # (Optional)'
        ),
        value: transactionData.orderId,
        onChange: handleInputChange,
        fullWidth: true,
      },
    ]

    const renderField = (field: Fields) => (
      <Box
        pb={3.5}
        key={field.key}
        width={field.fullWidth ? '100%' : undefined}
      >
        <TextField
          id={field.id}
          name={field.name}
          label={field.label}
          value={field.value}
          onChange={field.onChange}
          variant="filled"
          fullWidth={field.fullWidth}
          multiline={field.multiline}
          onBlur={field.onBlur}
          rows={field.rows}
          helperText={field.helperText}
          inputProps={field.inputProps}
          select={field.select}
        >
          {field.select &&
            field.options?.map((option) => (
              <MenuItem
                key={option.key}
                value={option.value}
                disabled={option.disabled}
              >
                {option.label}
              </MenuItem>
            ))}
        </TextField>
      </Box>
    )

    return (
      <Box>
        {currentStep === 'selectTransaction'
          ? // Render the first field directly for the initial dropdown
            renderField(fields[0])
          : fields.map(renderField)}
      </Box>
    )
  }

  const DialogTitle = () => {
    return t(
      'IntlAccountingTransactionModal.Title.AddNewTransaction',
      'Add New Transaction'
    )
  }

  const DialogActions = () => {
    return (
      <ActionButtons
        primaryButtonLabel={ContainedButtonVariant.Create}
        disablePrimaryButton={isAddTransactionDisabled}
        secondaryClick={onClose}
        secondaryButtonLabel={TextButtonVariant.Cancel}
        tertiaryButtonLabel={undefined}
        tertiaryClick={undefined}
        alwaysStack
        primaryButtonLoadingId={''}
        useBaseButton
      />
    )
  }

  const resetModalState = () => {
    setTransactionData(initialPayment.current)
    setCurrentStep('selectTransaction')
    setTransactionType('')
  }

  return (
    <BasicModal
      isOpen={isOpen}
      handleFormSubmit={addTransaction}
      maxWidth="xs"
      dialogContent={DialogContent()}
      dialogActions={DialogActions()}
      ariaLabel={DialogTitle()}
      dialogTitle={DialogTitle()}
      afterClose={resetModalState}
      labelledBy="IntlAccountingTransactionModal-form-title"
    />
  )
}

export default IntlAccountingTransactionModal

import React, { useContext, useEffect, useRef, useState } from 'react'
import PrintIcon from '@mui/icons-material/Print'
import TableContainer from '@mui/material/TableContainer'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { Box, Chip, Divider, IconButton, useTheme } from '@mui/material'
import type { TFunction } from 'i18next'
import CardFormHeader from '../../Card/CardFormHeader'
import Header, { HeaderVariant } from '../../Elements/Header'
import { styled } from '@mui/system'
import {
  CashAmount,
  CoordinatorLicensingBilling,
  PaginationResponse,
} from '../../../swagger'
import { Sort } from '@mui/icons-material'
import useCountryCoordinatorBillingDetails from '../../../hooks/useCountryCoordinatorBillingDetails'
import { Close as CloseIcon } from '@mui/icons-material'
import SpaceBetweenSection from '../../Elements/SpaceBetweenSection'
import IconTextButton, {
  IconTextButtonVariant,
} from '../../Buttons/IconTextButton'
import { InternationalLicensingBreakdown } from './InternationalLicensingBreakdown'
import {
  ActionableTable,
  ActionableTableColumn,
} from '../../Table/ActionableTable'
import { GridInputRowSelectionModel, GridRowParams } from '@mui/x-data-grid'
import IntlLicensingFiltersModal, {
  Filters,
} from '../../Modals/IntlLicensingFiltersModal'
import { useLoadingIds } from '../../../hooks/useLoadingIds'
import { LoadingContext } from '../../Context/LoadingContext'
import ContainedButton, {
  ContainedButtonVariant,
} from '../../Buttons/ContainedButton'
import { useShowOnDesktop } from '../../../hooks/useShowOnDesktop'
import IntlAccountingTransactionModal from '../../Modals/IntlAccountingTransactionModal'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router'
import { useAuth } from '../../Routes/AuthProvider'
import { Can } from '@casl/react'
import getLocaleCurrencyForAmount from '../../../utils/getLocaleCurrencyForAmount'

export type CoCoLicensingPaginationHandler = (
  pagination: Partial<PaginationResponse>
) => void

const StyledTableContainer = styled(TableContainer)({
  paddingLeft: 0,
})

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.textOrIcon.onPrimary,
  marginRight: theme.spacing(1),
}))

export enum CountryCoordinatorLicensingTableVariant {
  LicensingTable = 'LicensingTable',

  LicensingHistoryTable = 'LicensingHistoryTable',
}

/**
 * Props for the CountryCoordinatorLicensingTable component.
 */
export interface CountryCoordinatorLicensingTableProps {
  /**
   * List of licensing and billing information for the country coordinator.
   */
  countryCoordinatorLicensing?: CoordinatorLicensingBilling[] | undefined

  /**
   * Total balance for the country coordinator.
   */
  countryCoordinatorTotalBalance?: CashAmount | undefined

  /**
   * Indicates whether the view is for accounting purposes.
   */
  isAccountingView: boolean

  /**
   * Function to handle pagination updates.
   * @param _pagination - The new pagination response.
   */
  handlePagination?: (_pagination: PaginationResponse) => void

  /**
   * Key to uniquely identify the actor.
   */
  actorKey?: number

  /**
   * Current pagination data.
   */
  pagination?: PaginationResponse

  /**
   * Active filters for the table data.
   */
  filters?: Filters

  /**
   * Function to update the filters state.
   * @param value - The new filters state.
   */
  setFilters?: React.Dispatch<React.SetStateAction<Filters>>

  /**
   * Indicates whether data is currently being loaded.
   */
  isLoading?: boolean

  /**
   * Loading ID for the fetch billing operation.
   */
  fetchBillingLoadingID?: string
}

const getHeaders = (
  t: TFunction,
  isAccountingView: boolean
): ActionableTableColumn[] => {
  const cocoTableCommunityNameHeader: ActionableTableColumn = {
    columnHeaderName: t(
      'CountryCoordinatorLicensingTable.CountryCoordinator.Licensing.CommunityName.Header',
      'Community Name'
    ),
    align: 'left',
    fieldName: 'communityName',
  }
  const cocoTableProgramHeader: ActionableTableColumn = {
    columnHeaderName: t(
      'CountryCoordinatorLicensingTable.CountryCoordinator.Licensing.Program.Header',
      'Program'
    ),
    align: 'left',
    fieldName: 'program',
  }
  const cocoTableDirectorNameHeader: ActionableTableColumn = {
    columnHeaderName: t(
      'CountryCoordinatorLicensingTable.CountryCoordinator.Licensing.DirectorName.Header',
      'Director Name'
    ),
    align: 'left',
    fieldName: 'directorName',
  }
  const cocoTableDirectorOwesCountryCoordinatorHeader: ActionableTableColumn = {
    columnHeaderName: t(
      'CountryCoordinatorLicensingTable.CountryCoordinator.Licensing.DirectorOwes.Header',
      'Director Owes Country Coordinator'
    ),
    align: 'right',
    headerAlign: 'right',
    fieldName: 'directorOwes',
  }
  const cocoTableCountryCoordinatorOwesCCHeader: ActionableTableColumn = {
    columnHeaderName: t(
      'CountryCoordinatorLicensingTable.CountryCoordinator.Licensing.CountryCoordinatorOwes.Header',
      'Country Coordinator Owes CC'
    ),
    align: 'right',
    headerAlign: 'right',
    fieldName: 'coordinatorOwes',
  }

  return isAccountingView
    ? [
        cocoTableCommunityNameHeader,
        cocoTableProgramHeader,
        cocoTableDirectorNameHeader,
        cocoTableCountryCoordinatorOwesCCHeader,
      ]
    : [
        cocoTableCommunityNameHeader,
        cocoTableProgramHeader,
        cocoTableDirectorNameHeader,
        cocoTableDirectorOwesCountryCoordinatorHeader,
        cocoTableCountryCoordinatorOwesCCHeader,
      ]
}

interface CountryCoordinatorLicensingRow {
  communityName: string
  program: string
  directorName: string
  directorOwes: number
  coordinatorOwes: number
  programKey: number
  userKey: number
}

const CountryCoordinatorLicensingTable: React.FC<
  CountryCoordinatorLicensingTableProps
> = ({
  countryCoordinatorLicensing,
  countryCoordinatorTotalBalance,
  isAccountingView,
  handlePagination,
  pagination,
  filters,
  actorKey,
  setFilters,
  isLoading = false,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { userId } = useParams()
  const { permissionAbility } = useAuth()
  const {
    BillingTab: { fetchBilling: fetchBillingLoadingId },
  } = useLoadingIds()
  const { loadingIds } = useContext(LoadingContext)
  const showOnDesktop = useShowOnDesktop()
  const navigate = useNavigate()

  const detailsBoxRef = useRef<HTMLDivElement>(null)
  const scrollToDetails = () => detailsBoxRef?.current?.scrollIntoView()

  const removeFilterIcon = (
    <CloseIcon
      sx={{
        '&&': {
          color: theme.palette.textOrIcon.onPrimary,
          '&:hover': {
            borderRadius: '50%',
            backgroundColor: theme.palette.cookieBanner.main,
          },
        },
      }}
    />
  )

  /** State Objects */
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false)
  const [
    isIntlAccountingTransactionModalOpen,
    setIsIntlAccountingTransactionModalOpen,
  ] = useState(false)

  const [programKeyForDetails, setProgramKeyForDetails] = useState<
    number | undefined
  >(undefined)

  const [selectedRow, setSelectedRow] = useState({
    programKey: 0,
    directorName: '',
    userKey: 0,
  })

  const filtersModalRef = useRef<{
    clearFilter: (filterName: keyof Filters) => void
  }>(null)

  const { billingDetails, setBillingDetailsForCoco, isLoadingBillingDetails } =
    useCountryCoordinatorBillingDetails(programKeyForDetails)

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridInputRowSelectionModel>([])

  /** Methods */

  const handleLicensingTableRowClick = (
    params: GridRowParams<CountryCoordinatorLicensingRow>
  ) => {
    setRowSelectionModel(params.id)
    setSelectedRow({
      directorName: params.row.directorName,
      programKey: params.row.programKey,
      userKey: params.row.userKey,
    })
    setProgramKeyForDetails(params.row.programKey)
  }

  const handleRemoveFilter = (filter: keyof Filters) => {
    if (filtersModalRef.current) {
      filtersModalRef.current.clearFilter(filter)
    }
  }

  const hideLicensingDetails = () => {
    setProgramKeyForDetails(undefined)
    setBillingDetailsForCoco(undefined)
  }

  useEffect(() => {
    scrollToDetails()
  }, [billingDetails])

  /** Other Objects */
  const tableHeaders: ActionableTableColumn[] = getHeaders(t, isAccountingView)

  const countryCoordinatorLicensingRows = !!countryCoordinatorLicensing
    ? countryCoordinatorLicensing?.map(
        (record: CoordinatorLicensingBilling) => {
          return {
            communityName: record.community,
            program: `${record.program} (${new Date(
              record.semesterOneStartDate
            ).toLocaleDateString()})`,
            directorName: `${record.directorFirstName} ${record.directorLastName}`,
            directorOwes: getLocaleCurrencyForAmount(
              record.directorOwes.amount,
              record.directorOwes.currencyCode
            ),
            coordinatorOwes: getLocaleCurrencyForAmount(
              record.coordinatorOwes.amount,
              record.coordinatorOwes.currencyCode
            ),
            programKey: record.programKey as number,
            userKey: record.userKey,
          }
        }
      )
    : []

  return (
    <>
      <Box
        margin={theme.spacing(2, 3, 0, 2)}
        id="CountryCoordinatorLicensingTableContainer"
      >
        <StyledTableContainer>
          {!isAccountingView && (
            <Box>
              <SpaceBetweenSection
                isCentered
                marginRight={2}
                marginLeft={2}
                left={
                  <CardFormHeader
                    header={
                      <Box>
                        <Header
                          id="CountryCoordinatorLicensingTableHeader"
                          headerName={t(
                            'CountryCoordinatorLicensingTable.Header.Licensing',
                            'Licensing'
                          )}
                          component="h3"
                          variant={HeaderVariant.Card}
                        />
                      </Box>
                    }
                    headerContainerProps={{ marginLeft: 0 }}
                  />
                }
                right={
                  <IconTextButton
                    disabled={!programKeyForDetails}
                    id="printReports"
                    variant={IconTextButtonVariant.LicensingFeeReport}
                    startIcon={<PrintIcon />}
                    onClick={() =>
                      navigate(
                        `/account/billing/international-licensing/${programKeyForDetails}`
                      )
                    }
                  />
                }
              />
              <Divider
                sx={{ borderBottomWidth: '3px', padding: '25px, 0, 25px, 0' }}
              />
            </Box>
          )}
          {!!billingDetails && (
            <Box ref={detailsBoxRef} margin={theme.spacing(2, 3, 0, 2)}>
              <InternationalLicensingBreakdown
                billingDetails={billingDetails}
                hideLicensingDetails={hideLicensingDetails}
                isAccountingView={isAccountingView}
                isLoading={isLoadingBillingDetails}
                directorName={selectedRow.directorName}
                programKey={selectedRow.programKey}
                userKey={selectedRow.userKey}
                fetchBillingLoadingId={
                  fetchBillingLoadingId + JSON.stringify(filters)
                }
              />
            </Box>
          )}
          <Box
            m={2}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <Box mx={2}>
              {!!filters &&
                Object.entries(filters).map(([key, value]) => (
                  <StyledChip
                    key={key}
                    label={value.value}
                    onDelete={() => handleRemoveFilter(key as keyof Filters)}
                    color="primary"
                    hidden={!value.value}
                    deleteIcon={removeFilterIcon}
                  />
                ))}
            </Box>
            <Typography
              variant="body1"
              component="span"
              color={theme.palette.textOrIcon.subheader}
            >
              {t('CountryCoordinatorLicensingTable.Filter.Label', 'Filters')}
            </Typography>

            <IconButton
              aria-label="Filter By"
              onClick={() => setIsFiltersModalOpen(true)}
            >
              <Sort />
            </IconButton>
          </Box>
          {isAccountingView && (
            <Box
              m={2}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
              }}
            >
              <Can
                I="createLicensingPayment"
                on="Payment"
                ability={permissionAbility}
              >
                <ContainedButton
                  id="makeAPayment"
                  variant={ContainedButtonVariant.RecordAPayment}
                  onClick={() => setIsIntlAccountingTransactionModalOpen(true)}
                  fullWidth={!showOnDesktop}
                />
              </Can>
            </Box>
          )}
          <ActionableTable
            isLoading={
              loadingIds.has(fetchBillingLoadingId + JSON.stringify(filters)) ||
              isLoading
            }
            columns={tableHeaders}
            rows={countryCoordinatorLicensingRows}
            ariaLabelledBy={'CountryCoordinatorLicensingTableHeader'}
            noResultsMessage={t(
              'CountryCoordinatorLicensingTable.Transactions.NoPreviousTransactions',
              'No transaction history'
            )}
            onRowClick={handleLicensingTableRowClick}
            rowHeight={50}
            rowSelectionModel={rowSelectionModel}
            pagination={
              !!pagination
                ? {
                    ...pagination,
                    page: pagination.page - 1,
                  }
                : undefined
            }
            handlePaginationChange={(params: PaginationResponse) =>
              handlePagination?.({ ...params, page: params.page + 1 })
            }
          />
        </StyledTableContainer>
      </Box>

      {!!countryCoordinatorTotalBalance && (
        <Box
          m={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            marginRight: theme.spacing(3),
          }}
        >
          <Typography
            sx={{
              color: theme.palette.secondary.main,
              fontSize: theme.spacing(4),
            }}
            variant="subtitle2"
            component="h5"
          >
            {t(
              'CountryCoordinatorLicensingTable.countryCoordinatorLicensing.TotalBalance',
              'Total Balance: {{countryCoordinatorTotalBalance}}',
              {
                countryCoordinatorTotalBalance: `${getLocaleCurrencyForAmount(
                  countryCoordinatorTotalBalance.amount,
                  countryCoordinatorTotalBalance.currencyCode
                )}`,
              }
            )}
          </Typography>
        </Box>
      )}
      <IntlLicensingFiltersModal
        /**
         * This hack is necessary to avoid some jest warnings.
         */
        ref={process.env.NODE_ENV === 'test' ? undefined : filtersModalRef}
        isOpen={isFiltersModalOpen}
        onClose={() => setIsFiltersModalOpen(false)}
        onFilter={(params) => setFilters?.(params)}
        actorKey={actorKey ?? 0}
      />
      <IntlAccountingTransactionModal
        isOpen={isIntlAccountingTransactionModalOpen}
        onClose={() => setIsIntlAccountingTransactionModalOpen(false)}
        userKey={Number(userId) ?? 0}
        actorKey={Number(actorKey) ?? 0}
      />
    </>
  )
}

export default CountryCoordinatorLicensingTable

import React from 'react'
import { Document, Page } from '@react-pdf/renderer'
import { Html } from 'react-pdf-html'
import { Transcript } from '../../../swagger'
import { Font } from '@react-pdf/renderer'
import fullName from '../../../utils/fullName'
import studentKeyToStudentIdForTranscript from '../../../utils/studentKeyToStudentIdForTranscript'
import { dateToSlashStringReinterpretedAsLocal } from '../../../utils/dateUtility'
import { prepareListForHtmlString } from '../../../utils/prepareListForHtmlString'

interface TranscriptPDFProps {
  transcripts: Transcript[]
  actingAs: 'parent' | number
}

const TranscriptPDF: React.FC<TranscriptPDFProps> = ({
  transcripts,
  actingAs,
}) => {
  Font.registerHyphenationCallback((word) => [word])

  /**
   * This returns a carefully formatted HTML string to be put into the Html tag.
   *
   * It's curated to allow for no hyphenation if we find a row wants to wrap.
   *
   * Modify this at your peril. Some CSS, especially for the tables, will change if you modify.
   * I've tried to leave this as easy as possible to use CSS wise. Take an example "pl-2" which
   * gives padding-left: 2px and figure out the rest. Please create CSS in the same convention.
   */
  const html = ({
    student,
    studentAddress,
    schoolAddress,
    studentAddressSameAsSchool,
    studentDateOfBirth,
    schoolName,
    dateGraduation,
    gpa,
    summary,
    studentEmail,
    totalCredits,
    transcriptYear1,
    transcriptYear2,
    transcriptYear3,
    transcriptYear4,
    gradingScale,
  }: Transcript) => `
    <html lang="en">
      <head>
        <style>
          @font-face {
            font-family: "Open Sans";
            src: url("/fonts/openSansRegular.ttf") format("truetype");
            font-weight: 400;
          }
          @font-face {
            font-family: "Open Sans";
            src: url("/fonts/openSans600.ttf") format("truetype");
            font-weight: 600;
          }
          body {
            font-family: "Open Sans"
          }
          div.columns {
            display: flex;
            gap: 6px;
            flex-direction: row;
          }
          div.collection {
            display: flex;
            flex-direction: column;
          }
          div.rows {
            margin:  0 24px;
            display: flex;
            flex-direction: column;
            gap: 6px;
          }
          div.center {
            display: flex;
            align-items: center;
          }
          div.info {
            padding-left: 4px;
            width: 100%;
          }
          div.space {
            display: flex;
            flex-direction:row;
            justify-content: space-between;
            padding-right: 4px;
          }
          div.flex-row {
            display: flex;
            flex-direction:row;
          }
          div.flex-col {
            display: flex;
            flex-direction:column;
          }
          h1 {
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 8px;
          }
          p {
            font-size:8px;
            margin: 0;
          }
          div.f-grid {
            display: flex;
            flex-direction: column;
            gap: 2px;
          }
          .mw-50 {
            max-width: 50%
          }
          div.i-grid {
            display: flex;
            flex-direction: row;
          }
          .label {
            grid-column: 1;
            margin:0;
            width: 50%
          }
          .e-label {
            grid-column: 1;
            margin:0;
            width: 19.5%;
          }
          .value {
            grid-column: 1;
            margin:0;
            width: 100%;
          }
          .year-grid {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
          .class-header {
            text-decoration: underline;
            font-weight: bold;
          }
          .border {
            border: 1px solid black;
          }
          .border-right {
            border-right: 1px solid black;
          }
          .border-left {
            border-left: 1px solid black;
          }
          .border-top {
            border-top: 1px solid black;
          }
          .border-bottom {
            border-bottom: 1px solid black;
          }
          .border-x {
            border-right: 1px solid black;
            border-left: 1px solid black;
          }
          .border-y {
            border-top: 1px solid black;
            border-bottom: 1px solid black;
          }
          th {
            align-items:center;
            font-size: 8px;
            font-weight: 400;
          }
          th.weight {
            max-width:35px;
          }
          th.weight-name {
            max-width:55px;
          }
          td.weight {
            max-width:35px;
          }
          td.weight-name {
            max-width:55px;
          }
          td {
            padding: 2px;
          }
          table {
            margin: 2px 0;
          }
          .pt-2 {
            padding-top: 2px;
          }
          .pl-2 {
            padding-left: 2px;
          }
          .pb-2 {
            padding-bottom: 2px;
          }
          .pr-2 {
            padding-right: 2px;
          }
          .info-no-padding {
            width: 100%;
          }
          .space-no-padding {
            display: flex;
            flex-direction:row;
          }
          .small {
            margin-left:8px; 
            max-width: 35%;
          }
          div.notes {
            min-height:86px;
          }
          .line-m {
            border-bottom: 1px solid black;
            min-width: 220px;
          }
          .line-l {
            border-bottom: 1px solid black;
            min-width: 473px;
          }
          .align-items-fe {
            align-items: flex-end;
          }
          .align-items-c {
            align-items: center;
          }
          .m-0 {
            margin: 0;
          }
          .bold {
            font-weight: bold;
          }
          .mb-24 {
            margin-bottom: 24px;
          }
        </style>
      </head>
      <body>
      <div class="center">
        <h1>Official High School Transcript</h1>
      </div>
      <!-- Student & School Information -->
      <div class="rows">
        <div class="collection">
          <div class="columns">
            <div class="info">
              <p class="pb-2">Student Information</p>
              <div class="f-grid">
                <div class="i-grid">
                  <p class="label">Name:</p>
                  <p class="value" id='student-name'>${fullName({
                    firstName: student.firstName,
                    lastName: student.lastName,
                  })}</p>
                </div>
                <div class="i-grid">
                  <p class="label" >Student Id:</p>
                  <p class="value" id='student-id'>${studentKeyToStudentIdForTranscript(
                    student.studentKey
                  )}</p>
                </div>
                <div class="i-grid">
                  <p class="label">Address:</p>
                  <p class="value" id='student-address-line-1'>${
                    (!studentAddressSameAsSchool
                      ? studentAddress
                      : schoolAddress
                    )?.streetAddress1
                  }</p>
                </div>
                <div class="i-grid">
                  <p class="label">City, State Zip:</p>
                  <p class="value" id='student-city-state-zip'>
                  ${
                    (!studentAddressSameAsSchool
                      ? studentAddress
                      : schoolAddress
                    )?.city
                  },
                  ${
                    (!studentAddressSameAsSchool
                      ? studentAddress
                      : schoolAddress
                    )?.state
                  } 
                  ${
                    (!studentAddressSameAsSchool
                      ? studentAddress
                      : schoolAddress
                    )?.zip
                  }</p>
                </div>
                <div class="i-grid">
                  <p class="label">Date of Birth:</p>
                  <p class="value" id='dob'>
                  ${
                    studentDateOfBirth
                      ? dateToSlashStringReinterpretedAsLocal(
                          studentDateOfBirth
                        )
                      : ''
                  }
                  </p>
                </div>
              </div>
            </div>
            <div class="info">
              <p class="pb-2">School Information</p>
              <div class="f-grid">
                <div class="i-grid">
                  <p class="label">School Name:</p>
                  <p class="value" id="school-name">${schoolName}</p>
                </div>
                <div class="i-grid">
                  <p class="label">Address:</p>
                  <p class="value" id='school-address-line-1'>${
                    schoolAddress.streetAddress1
                  }</p>
                </div>
                <div class="i-grid">
                  <p class="label">City, State Zip:</p>
                  <p class="value" id='school-city-state-zip'>
                    ${schoolAddress.city},
                    ${schoolAddress.state}
                    ${schoolAddress.zip}
                  </p>
                </div>
                <div class="i-grid">
                  <p class="label">Graduation Date:</p>
                  <p class="value" id='graduation-date'>${
                    dateGraduation
                      ? dateToSlashStringReinterpretedAsLocal(dateGraduation)
                      : ''
                  }</p>
                </div>
              </div>
            </div>
          </div>
          <div class="info email">
            <div class="i-grid pt-2">
              <p class="e-label">Student Email:</p>
              <p class="value" id="student-email">${studentEmail}</p>
            </div>
          </div>
        </div>
        <!-- 9th & 10th -->
        <div class="columns">
          <div class="info border">
            <div class="space">
              <p>Grade: 9th</p>
              <p id="9th-ay">${
                !!transcriptYear1?.academicYear
                  ? `${transcriptYear1?.academicYear}-${
                      transcriptYear1?.academicYear + 1
                    }`
                  : ''
              }</p>
            </div>
            <div class="flex-col">
              <div class="year-grid pr-2">
                <div id="9-class">
                  <p class="class-header">Class</p>
                  ${prepareListForHtmlString(
                    transcriptYear1?.transcriptYearCourseWork ?? [],
                    { key: 'courseName' }
                  )}
                </div>
                <div id="9-grade">
                  <p class="class-header">Grade</p>
                  ${prepareListForHtmlString(
                    transcriptYear1?.transcriptYearCourseWork ?? [],
                    { key: 'letterGrade' }
                  )}
                </div>
                <div id="9-credits" class="align-items-fe">
                  <p class="class-header">Credits</p>
                  ${prepareListForHtmlString(
                    transcriptYear1?.transcriptYearCourseWork ?? [],
                    {
                      mapper: ({ credits }) => `<p>${credits.toFixed(1)}</p>`,
                    }
                  )}
                </div>
              </div>
              <!-- TranscriptYear1 GPA & Credits --> 
              <div class="flex-row align-items-fe space bold mb-24">
                <div>
                  <p>GPA:
                    ${(transcriptYear1?.gpa ?? 0).toFixed(2)}
                  </p>
                </div>
                <div>
                  <p>Total Credits:
                    ${(transcriptYear1?.totalCredits ?? 0).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
            
          </div>
          <div class="info border">
            <div class="space">
              <p>Grade: 10th</p>
              <p id="10th-ay">${
                !!transcriptYear2?.academicYear
                  ? `${transcriptYear2?.academicYear}-${
                      transcriptYear2?.academicYear + 1
                    }`
                  : ''
              }</p>
            </div>
            <div class="flex-col">
              <div class="year-grid pr-2">
                <div id="10-class">
                  <p class="class-header">Class</p>
                  ${prepareListForHtmlString(
                    transcriptYear2?.transcriptYearCourseWork ?? [],
                    { key: 'courseName' }
                  )}
                </div>
                <div id="10-grade">
                  <p class="class-header">Grade</p>
                  ${prepareListForHtmlString(
                    transcriptYear2?.transcriptYearCourseWork ?? [],
                    { key: 'letterGrade' }
                  )}
                </div>
                <div id="10-credits" class="align-items-fe">
                  <p class="class-header">Credits</p>
                  ${prepareListForHtmlString(
                    transcriptYear2?.transcriptYearCourseWork ?? [],
                    {
                      mapper: ({ credits }) => `<p>${credits.toFixed(1)}</p>`,
                    }
                  )}
                </div>
              </div>
              <!-- TranscriptYear2 GPA & Credits --> 
              <div class="flex-row align-items-fe space bold mb-24">
                <div>
                  <p>GPA:
                    ${(transcriptYear2?.gpa ?? 0).toFixed(2)}
                  </p>
                </div>
                <div>
                  <p>Total Credits:
                    ${(transcriptYear2?.totalCredits ?? 0).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 11th & 12th -->
        <div class="columns">
          <div class="info border">
            <div class="space">
              <p>Grade: 11th</p>
              <p id="11th-ay">${
                !!transcriptYear3?.academicYear
                  ? `${transcriptYear3?.academicYear}-${
                      transcriptYear3?.academicYear + 1
                    }`
                  : ''
              }</p>
            </div>
            <div class="flex-col">
              <div class="year-grid pr-2">
                <div id="11-class">
                  <p class="class-header">Class</p>
                  ${prepareListForHtmlString(
                    transcriptYear3?.transcriptYearCourseWork ?? [],
                    { key: 'courseName' }
                  )}
                </div>
                <div id="11-grade">
                  <p class="class-header">Grade</p>
                  ${prepareListForHtmlString(
                    transcriptYear3?.transcriptYearCourseWork ?? [],
                    { key: 'letterGrade' }
                  )}
                </div>
                <div id="11-credits" class="align-items-fe">
                  <p class="class-header">Credits</p>
                  ${prepareListForHtmlString(
                    transcriptYear3?.transcriptYearCourseWork ?? [],
                    {
                      mapper: ({ credits }) => `<p>${credits.toFixed(1)}</p>`,
                    }
                  )}
                </div>
              </div>
              <!-- TranscriptYear3 GPA & Credits --> 
              <div class="flex-row align-items-fe space bold mb-24">
                <div>
                  <p>GPA:
                    ${(transcriptYear3?.gpa ?? 0).toFixed(2)}
                  </p>
                </div>
                <div>
                  <p>Total Credits:
                    ${(transcriptYear3?.totalCredits ?? 0).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="info border">
            <div class="space">
              <p>Grade: 12th</p>
              <p id="12th-ay">${
                !!transcriptYear4?.academicYear
                  ? `${transcriptYear4?.academicYear}-${
                      transcriptYear4?.academicYear + 1
                    }`
                  : ''
              }</p>
            </div>
            <div class="flex-col">
              <div class="year-grid pr-2">
                <div id="12-class">
                  <p class="class-header">Class</p>
                  ${prepareListForHtmlString(
                    transcriptYear4?.transcriptYearCourseWork ?? [],
                    { key: 'courseName' }
                  )}
                </div>
                <div id="12-grade">
                  <p class="class-header">Grade</p>
                  ${prepareListForHtmlString(
                    transcriptYear4?.transcriptYearCourseWork ?? [],
                    { key: 'letterGrade' }
                  )}
                </div>
                <div id="12-credits" class="align-items-fe">
                  <p class="class-header">Credits</p>
                  ${prepareListForHtmlString(
                    transcriptYear4?.transcriptYearCourseWork ?? [],
                    {
                      mapper: ({ credits }) => `<p>${credits.toFixed(1)}</p>`,
                    }
                  )}
                </div>
              </div>
              <!-- TranscriptYear4 GPA & Credits --> 
              <div class="flex-row align-items-fe space bold mb-24">
                <div>
                  <p>GPA:
                    ${(transcriptYear4?.gpa ?? 0).toFixed(2)}
                  </p>
                </div>
                <div>
                  <p>Total Credits:
                    ${(transcriptYear4?.totalCredits ?? 0).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Tables & Summary -->
        <div class="columns">
          <div class="info-no-padding space-no-padding">
            <div class="info-no-padding">
              <table class="scale">
                <tbody id="grading-scale">
                  <tr>
                    <th class="border-top border-x">Grade Scale</th>
                    <th class="border-top border-right">Letter Grade</th>
                    <th class="border-top border-right">Grade Points</th>
                  </tr>
                  ${gradingScale?.gradingScaleDetails
                    ?.map(
                      ({ scaleRange, letterGrade, credit }, index) =>
                        `<tr>
                      <td class="border-${
                        index ===
                        (gradingScale.gradingScaleDetails ?? []).length - 1
                          ? 'y'
                          : 'top'
                      } border-x align-items-c"><p>${scaleRange}</p></td>
                      <td class="border-${
                        index ===
                        (gradingScale.gradingScaleDetails ?? []).length - 1
                          ? 'y'
                          : 'top'
                      } border-right align-items-c"><p>${letterGrade}</p></td>
                      <td class="border-${
                        index ===
                        (gradingScale.gradingScaleDetails ?? []).length - 1
                          ? 'y'
                          : 'top'
                      } border-right align-items-c"><p>${credit?.toFixed(
                          2
                        )}</p></td>
                      </tr>`
                    )
                    .join('')
                    .replace(',', '')}
                </tbody>
              </table>
            </div>
           
            <div class="info-no-padding small">
             ${
               !!gradingScale?.gradingScaleWeight?.length
                 ? `
              <table class="weight">
                <tbody id="grading-scale-weight">
                  <tr class="">
                    <th class="border-top border-x weight-name">Weight Name</th>
                    <th class="border-top border-right weight">Weight</th>
                  </tr>
                  ${gradingScale?.gradingScaleWeight
                    ?.map(
                      ({ weight, weightName }, index) =>
                        `<tr class="">
                      <td class="border-${
                        index ===
                        (gradingScale.gradingScaleWeight ?? []).length - 1
                          ? 'y'
                          : 'top'
                      } border-x weight-name align-items-c"><p>${weightName}</p></td>
                      <td class="border-${
                        index ===
                        (gradingScale.gradingScaleWeight ?? []).length - 1
                          ? 'y'
                          : 'top'
                      } border-right weight align-items-c"><p>${weight.toFixed(
                          1
                        )}</p></td>
                      </tr>`
                    )
                    .join('')
                    .replace(',', '')}
                </tbody>
              </table>
                `
                 : ``
             }
            </div>
        
          </div>
          <div class="info">
            <div class="flex-row mw-50 pb-2 space">
              <div class="flex-col align-items-c"><p>Total Credits</p><p id="total-credits"><b>${totalCredits}</b></p></div>
              <div class="flex-col align-items-c"><p>Cumulative GPA</p><p id="total-gpa"><b>${gpa}</b></p></div>
            </div>
            <div class="border pl-2">
              <div class="pl-2"><p>NOTES:</p></div>
              <div id="summary" class="notes"><p>${summary}</p></div>
            </div>
          </div>
        </div>
        <!-- Signature Section -->
        ${
          actingAs === 'parent'
            ? `
        <div class="info m-0">
          <div>
            <p>I self-certify and affirm that this is the official transcript and
          record of ${fullName({
            firstName: student.firstName,
            lastName: student.lastName,
          })}'s academic studies through
          ${`${dateGraduation?.getUTCDate()} ${dateGraduation?.toLocaleString(
            'default',
            { month: 'long' }
          )}`}, ${dateGraduation?.getUTCFullYear() ?? ''}</p>
          </div>
          <div class="flex-row align-items-fe">
            <div>
              <p>Name:</p>
            </div>
            <div class="line-l">&nbsp;</div>
          </div>
          <div class="flex-row align-items-fe">
            <div>
              <p>Signature:</p>
            </div>
            <div class="line-m">&nbsp;</div>
            <div>
              <p>Date:</p>
            </div>
            <div class="line-m">&nbsp;</div>
          </div>
          <div class="pt-2">
            <p>[Enter administrator's full name](Administrator of Records)</p>
          </div>
        </div>`
            : ''
        }
      </div>
      </body>
    </html>
  `

  return (
    <Document>
      {transcripts.map((transcriptDetail) => (
        <Page key={transcriptDetail.transcriptKey}>
          <Html>{html(transcriptDetail)}</Html>
        </Page>
      ))}
    </Document>
  )
}

export default TranscriptPDF

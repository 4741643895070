/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface WebhookNotificationApplicableUsers
 */
export interface WebhookNotificationApplicableUsers {
  /**
   *
   * @type {string}
   * @memberof WebhookNotificationApplicableUsers
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof WebhookNotificationApplicableUsers
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof WebhookNotificationApplicableUsers
   */
  role?: string
  /**
   *
   * @type {string}
   * @memberof WebhookNotificationApplicableUsers
   */
  payloadApplicable?: string
}

export function WebhookNotificationApplicableUsersFromJSON(
  json: any
): WebhookNotificationApplicableUsers {
  return WebhookNotificationApplicableUsersFromJSONTyped(json, false)
}

export function WebhookNotificationApplicableUsersFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): WebhookNotificationApplicableUsers {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    email: !exists(json, 'email') ? undefined : json['email'],
    role: !exists(json, 'role') ? undefined : json['role'],
    payloadApplicable: !exists(json, 'payloadApplicable')
      ? undefined
      : json['payloadApplicable'],
  }
}

export function WebhookNotificationApplicableUsersToJSON(
  value?: WebhookNotificationApplicableUsers | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    email: value.email,
    role: value.role,
    payloadApplicable: value.payloadApplicable,
  }
}

import { Box, IconButton, Typography } from '@mui/material'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { paymentsApi } from '../../../../api/swagger'
import useLoadingContext from '../../../../hooks/useLoadingContext'
import { useLoadingIds } from '../../../../hooks/useLoadingIds'
import { useMountEffect } from '../../../../hooks/useMountEffect'
import {
  FetchCountryCoordinatorLicensingDetailsRequest,
  FetchCountryCoordinatorLicensingDetailsResponse,
} from '../../../../swagger'
import LoadingProgress from '../../../Elements/LoadingProgress'
import { PdfViewerSkeleton } from '../../../Skeleton/PdfViewerSkeleton'
import EmptyPage from '../../../Elements/EmptyPage'
import PdfIcon from '@mui/icons-material/PictureAsPdf'
import { isMobileBrowser } from '../../../../utils/isMobileBrowser'
import { InternationalLicensingPDF } from './InternationalLicensingPDF'

export const InternationalLicensingPDFPreview: React.FC = () => {
  const { programKey } = useParams()
  const { t } = useTranslation()
  const {
    BillingTab: {
      fetchCountryCoordinatorLicensingDetails:
        fetchCountryCoordinatorLicensingDetailsLoadingId,
    },
  } = useLoadingIds()
  const [licensingDetails, setLicensingDetails] =
    useState<FetchCountryCoordinatorLicensingDetailsResponse>()
  const [hasFetchingError, setHasFetchingError] = useState(false)

  const fetchLicensingDetails = async () => {
    try {
      const response =
        await paymentsApi.fetchCountryCoordinatorLicensingDetails({
          programKey,
        } as unknown as FetchCountryCoordinatorLicensingDetailsRequest)

      setLicensingDetails(response)
    } catch (e) {
      setHasFetchingError(true)
    }
  }

  const { isLoading, triggerFetch } = useLoadingContext({
    asyncFunction: fetchLicensingDetails,
    loadingId: fetchCountryCoordinatorLicensingDetailsLoadingId,
  })

  useMountEffect(triggerFetch)

  if (isLoading) {
    return <LoadingProgress />
  }

  if (hasFetchingError || !licensingDetails) {
    return (
      <EmptyPage
        message={
          <Typography variant="subtitle1" component="p">
            {t(
              'InternationalLicensingPDFPreview.PDF.Preview.ErrorMessage',
              'Could not load PDF report, please try again later.'
            )}
          </Typography>
        }
      />
    )
  }

  const isMobile = isMobileBrowser()

  return (
    <Box display="flex" flexDirection={'column'} height={'70vh'}>
      <Box position="relative" width="100%" height="100%">
        <Box position="absolute" width="100%" height="70%" top={0} zIndex={1}>
          {!isMobile && <PdfViewerSkeleton />}
        </Box>
        <Box position="absolute" width="100%" height="100%" top={0} zIndex={2}>
          {isMobile && (
            <Box
              justifyContent="center"
              alignItems="center"
              display="flex"
              flexDirection="column"
              textAlign="center"
            >
              <Typography>
                {t(
                  'InternationalLicensingPDFPreview.PDF.Preview.PreviewNotAvailableMessage',
                  'Preview not available on mobile devices, please download the PDF clicking the button below.'
                )}
              </Typography>
              <PDFDownloadLink
                document={
                  <InternationalLicensingPDF
                    licensingDetails={licensingDetails}
                  />
                }
                fileName="billing.pdf"
              >
                <IconButton color="success">
                  <PdfIcon />
                  <Typography>
                    {t(
                      'InternationalLicensingPDFPreview.PDF.Preview.DownloadPDF',
                      'Download PDF'
                    )}
                  </Typography>
                </IconButton>
              </PDFDownloadLink>
            </Box>
          )}
          <PDFViewer style={{ flex: 1, width: '100%', height: '100%' }}>
            <InternationalLicensingPDF licensingDetails={licensingDetails} />
          </PDFViewer>
        </Box>
      </Box>
    </Box>
  )
}

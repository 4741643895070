import React from 'react'
import { ActionableTable } from '../../Table/ActionableTable'
import {
  useCountryCoordinatorLicensingPaymentHistory,
  UseCountryCoordinatorLicensingPaymentHistoryProps,
} from '../../../hooks/useCountryCoordinatorLicensingPaymentHistory'
import { ActionableTableSkeleton } from '../../Skeleton/ActionableTableSkeleton'
import Header from '../../Elements/Header'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

type CountryCoordinatorLicensingPaymentHistoryProps =
  UseCountryCoordinatorLicensingPaymentHistoryProps

export const CountryCoordinatorLicensingPaymentHistory: React.FC<
  CountryCoordinatorLicensingPaymentHistoryProps
> = (props) => {
  const { t } = useTranslation()

  const key = 'userKey' in props ? 'userKey' : 'actorKey'
  const keyValue = 'userKey' in props ? props.userKey : props.actorKey

  const {
    columns,
    isLoading,
    noResultsMessage,
    rows,
    pagination,
    handlePagination,
  } = useCountryCoordinatorLicensingPaymentHistory({
    [key]: keyValue,
  } as UseCountryCoordinatorLicensingPaymentHistoryProps)

  if (isLoading) {
    return <ActionableTableSkeleton rows={pagination.pageSize} />
  }

  return (
    <Box marginRight={5} marginLeft={2} marginTop={2}>
      <Header
        id="CoColicensingPaymentHistory"
        headerName={t(
          'CoColicensingPaymentHistory.Heading.LicensingPayments',
          'Licensing Payments'
        )}
        component="h6"
      />

      <ActionableTable
        columns={columns}
        noResultsMessage={noResultsMessage}
        customCssTable={{
          border: 'none',
        }}
        rowHeight={40}
        rows={rows}
        handlePaginationChange={handlePagination}
        pagination={{
          ...pagination,
          page: pagination.page - 1,
        }}
      />
    </Box>
  )
}

import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import TableHeaders from '../Interfaces/TableHeaders'
import { dateToDashString } from '../../utils/dateUtility'
import { useNavigate } from 'react-router'
import TableHeader from '../Table/TableHeader'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import useDynamicFieldStates from '../../hooks/useDynamicFieldStates'
import SearchBar from '../Search/SearchBar'
import NoResultsFound from '../Table/NoResultsFound'
import {
  Enrollment,
  EnrollmentStatus,
  EnrollmentStatusUpdate,
  FeeType,
  PaginationResponse,
  Program,
  Program2,
  ProgramStatus,
  StudentTutorAssignment,
  TransferEnrollmentRequestParams,
  UpdateLicensingEnrollmentRequestBodyLicensingEnrollments,
  RemoveLicensingEnrollmentRequestBodyLicensingEnrollments,
  RemoveLicensingEnrollmentRequestBodyLicensingEnrollmentsFeeTypeKeyEnum,
  LicensingEnrollment,
  LicensingEnrollmentFeeTypeKeyEnum,
  ProgramType,
} from '../../swagger'
import DropDown, { DropDownVariant, MenuOption } from '../Menus/DropDown'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { TextButtonVariant } from '../Buttons/TextButton'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import EnrollmentRowInfo from '../Interfaces/EnrollmentRowInfo'
import { useAuth } from '../Routes/AuthProvider'
import {
  fetchOnlyTransferEnrollments,
  transferEnrollment,
  updateEnrollmentStatus,
} from '../../api/enrollments'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import {
  enrollments as enrollmentsApi,
  extractedErrorObject,
  programs,
} from '../../api/swagger'
import TableFooterPagination, {
  defaultPageSize,
} from '../Pagination/TableFooterPagination'
import { Box, useTheme } from '@mui/material'
import SpaceBetweenSection from '../Elements/SpaceBetweenSection'
import DropdownSelectOptions from '../Interfaces/DropdownSelectOptions'
import { Ability } from '@casl/ability'
import CardFormHeader from '../Card/CardFormHeader'
import IconTextButton, {
  IconTextButtonVariant,
} from '../Buttons/IconTextButton'
import { Cancel, Edit, Print, Save } from '@mui/icons-material'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { useNotistackSnackbarKeyContext } from '../Context/NotistackSnackbarKeyProvider'
import ConfirmationModal from '../Modals/ConfirmationModal'
import ActionButtons from '../Buttons/ActionButtons'
import { useUser } from '../../UserContext'
import { Can } from '@casl/react'
import {
  directorOnlyEnrollmentStatuses,
  filterAllowedEnrollmentDropDownOptions,
} from '../../helpers/enrollmentHelper'
import { LoadingContext } from '../Context/LoadingContext'
import TransferEnrollmentsModal from '../Modals/TransferEnrollmentsModal'
import { OperationIds } from '../../swagger/operationIdEnum'
import { styled } from '@mui/system'
import EmptyPage from '../Elements/EmptyPage'
import { TFunction } from 'i18next'
import { useCommunitiesContext } from '../Context/CommunitiesContext'
import { filterActorSelectOption } from '../Card/ProgramFormCard'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import TuitionPaidCheckboxes from './TuitionPaidCheckboxes'
import LicensingOwedCheckboxes from './LicensingOwedCheckboxes'
import ExemptReasonModal from '../Modals/ExemptReasonModal'
import {
  ApplicationTypeFilterOptions,
  EnrollmentFilters,
  defaultTutorFilterOption,
  enrollmentStatusFilterOptions,
} from '../Context/ProgramDetailsContext'
import useLoadingContext from '../../hooks/useLoadingContext'
import { MINIMUM_DEBOUNCED_SEARCH_QUERY_LENGTH } from '../../utils/constants'
import { useSpeedDialMenuContext } from '../Context/SpeedDialMenuContext'
import { useUnmountEffect } from '../../hooks/useUnmountEffect'
import { SpeedDialActions } from '../Elements/SpeedDialMenu'
import { useMountEffect } from '../../hooks/useMountEffect'

import { getCommaSeparatedList } from '../../utils/getCommaSeparatedList'
import { uniqueEmailsFromAllowedEnrollmentStatuses } from '../../helpers/emailFamiliesHelper'
import TransferStatusIcon from '../Elements/TransferStatusIcon'

const SearchSortAndFilterSection = styled('section')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

const ClickableCell = styled(TableCell)({
  '&:hover': {
    cursor: 'pointer',
  },
})

interface EnrollmentsTableProps {
  pagination: Omit<PaginationResponse, 'orderBy'>
  handleChangePage: (page: number) => void
  handleChangePageSize: (pageSize: number) => void
  setSearchState: (searchText: string) => void
  searchText?: string
  setFilters: (filters: EnrollmentFilters) => void
  filters?: EnrollmentFilters
  enrollments?: EnrollmentRowInfo[]
  enrollmentOptions: EnrollmentStatus[]
  refetchEnrollmentsData?: () => void
  tutors?: DropdownSelectOptions[]
  programInfo: Pick<Program2, 'programKey' | 'programType'> & {
    status: Program['status']
    firstSemesterStartDate?: Date
  }
  programAbility?: Ability
  setEditForParent?: (isEdit: boolean) => void
  programEmailDirector?: string
  academicYear?: string
  communityId: number
}

export enum CheckboxesTypes {
  semesterOneLicensingOwed = 'semesterOneLicensingOwed',
  semesterTwoLicensingOwed = 'semesterTwoLicensingOwed',
  semesterOneTuitionPaid = 'semesterOneTuitionPaid',
  semesterTwoTuitionPaid = 'semesterTwoTuitionPaid',
}

export interface HandleCheckboxesChangeProps {
  studentKey?: number
  programKey?: number
  checkboxesValues: CheckboxesTypes
}

// Allows us to show the DROPPED value for the labels.
// also allows us to apply the translation
export const getLabelForEnrollmentStatus = (
  value: string,
  t: TFunction
): string => {
  switch (value) {
    case EnrollmentStatus.Complete:
      return t(
        'Families.EnrollmentsTable.statusStudentLabel.Complete',
        'COMPLETE'
      )
    case EnrollmentStatus.Drop:
      return t(
        'Families.EnrollmentsTable.statusStudentLabel.Dropped',
        'DROPPED'
      )
    case EnrollmentStatus.Enrolled:
      return t(
        'Families.EnrollmentsTable.statusStudentLabel.Enrolled',
        'ENROLLED'
      )
    case EnrollmentStatus.MemberFull:
    case EnrollmentStatus.MemberFull.replace('_', ' '):
      return t(
        'Families.EnrollmentsTable.statusStudentLabel.MemberFull',
        'MEMBER FULL'
      )
    case EnrollmentStatus.MemberHalf:
    case EnrollmentStatus.MemberHalf.replace('_', ' '):
      return t(
        'Families.EnrollmentsTable.statusStudentLabel.MemberHalf',
        'MEMBER HALF'
      )
    case EnrollmentStatus.Pending:
      return t(
        'Families.EnrollmentsTable.statusStudentLabel.Pending',
        'PENDING'
      )
    default:
      return value
  }
}

export const textHeaderMessage =
  'Are you sure you want to email the families visible on your screen?'

export const confirmationRowsMessageText =
  'To email ALL families, excluding dropped students, change the "Rows per page" to 100 at the bottom of the list'

export const confirmationEmailFamiliesMessageText = `To email ALL families, excluding dropped students, click "Yes, I'm Sure"`

export const EnrollmentsTable: React.FC<EnrollmentsTableProps> = ({
  enrollments: initialEnrollments = [],
  enrollmentOptions,
  refetchEnrollmentsData,
  tutors,
  programInfo,
  programAbility,
  setEditForParent,
  programEmailDirector,
  academicYear = '',
  setSearchState,
  searchText: searchState = '',
  setFilters,
  filters = {
    applicationType: ApplicationTypeFilterOptions.AllTypes,
    enrollmentStatus: enrollmentStatusFilterOptions.AllEnrollmentStatuses,
    tutorId: defaultTutorFilterOption,
  },
  pagination,
  handleChangePage,
  handleChangePageSize,
  communityId,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const showOnDesktop = useShowOnDesktop()
  const { permissionAbility } = useAuth()
  const currentUser = useUser()
  const { addSnackbarKey, enqueueSnackbar } = useNotistackSnackbarKeyContext()
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()
  const { addLoadingIds, loadingIds } = useContext(LoadingContext)
  const {
    tutorOptions: tutorList,
    setDirectorsAndTutorsForCommunitySelection,
  } = useCommunitiesContext()
  const { Enrollments } = useLoadingIds()
  const {
    updateActions,
    resetContextToDefaults,
    updateJoyrideSteps,
    updateJoyrideCompleteId,
  } = useSpeedDialMenuContext()

  useEffect(() => {
    if (communityId) {
      setDirectorsAndTutorsForCommunitySelection(communityId)
    }
  }, [communityId, setDirectorsAndTutorsForCommunitySelection])

  const enrollmentsJoyrideCompleteId = 'enrollments-joyride-complete'
  const filename = 'EnrollmentsTable'
  const fetchTransferEnrollmentsLoadingId = `${filename}-${OperationIds.FetchEnrollments}-Transfer-Only`
  const { EnrollmentsTable: EnrollmentsTableLoadingIds } = useLoadingIds()

  const saveActionLoadingId = `${EnrollmentsTableLoadingIds.updateEnrollmentStatus}
    -
    ${EnrollmentsTableLoadingIds.updateStudentTutorAssignments}
    -
    ${Enrollments.updateLicensingEnrollments}
    -
    ${Enrollments.createLicensingEnrollment}
    -
    ${Enrollments.removeLicensingEnrollments}
    `

  const currentUserMail = currentUser.user?.username

  /** State Objects */
  const [transferEligibleEnrollments, setTransferEligibleEnrollments] =
    useState<Enrollment[]>([])
  const [errorMessage, setErrorMessage] = useState('')
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showTransferEnrollmentModal, setShowTransferEnrollmentModal] =
    useState(false)
  const [isEnrollmentsTableEdit, setIsEnrollmentsTableEdit] = useState(false)
  const [studentsMap, setStudentsMap] =
    useState<
      Map<number /*student key*/, number | undefined /*tutor actor key*/>
    >()
  const [licensingEnrollmentsToUpdate, setLicensingEnrollmentsToUpdate] =
    useState<UpdateLicensingEnrollmentRequestBodyLicensingEnrollments[]>([])
  const [createLicensingEnrollments, setCreateLicensingEnrollments] = useState<
    LicensingEnrollment[]
  >([])
  const [removeLicensingEnrollments, setRemoveLicensingEnrollments] = useState<
    RemoveLicensingEnrollmentRequestBodyLicensingEnrollments[]
  >([])
  /**
   * We use this to trigger the useEffect for fetching enrollments data.
   * When the value changes it will trigger the useEffect
   */
  const [triggerRefetch, setTriggerRefetch] = useState(false)

  const [showExemptReasonModal, setShowExemptReasonModal] = useState(false)
  const [currentStudentKeyAndFeeTypeKey, setCurrentStudentKeyAndFeeTypeKey] =
    useState<{
      studentKey: number
      feeTypeKey: FeeType
    }>({
      studentKey: 0,
      feeTypeKey: FeeType.SemesterOneLicensingFee,
    })

  const isChallenge = /Challenge/.test(`${programInfo.programType}`)

  const canManageLicensingEnrollment = permissionAbility.can(
    'manageLicensingEnrollment',
    'Enrollment'
  )
  const canRemoveTuitionPayment = permissionAbility.can(
    'removeTuitionPayment',
    'Enrollment'
  )

  const isAllowedToEditAndHandleCheckboxes =
    canManageLicensingEnrollment && canRemoveTuitionPayment

  const [enrollments, setEnrollments] =
    useState<EnrollmentRowInfo[]>(initialEnrollments)

  const areEnrollmentsAvailable = enrollments.length > 0

  const resetExemptReasonModalAndCurrentRowToInitialValues = () => {
    setCurrentStudentKeyAndFeeTypeKey({
      studentKey: 0,
      feeTypeKey: FeeType.SemesterOneLicensingFee,
    })
    setShowExemptReasonModal(false)
  }

  const handleConfirmationCancelExemptReason = () => {
    // remove the licensingEnrollment to update
    setLicensingEnrollmentsToUpdate(
      licensingEnrollmentsToUpdate.filter(
        (licensingEnrollment) =>
          !(
            licensingEnrollment.feeTypeKey ===
              currentStudentKeyAndFeeTypeKey.feeTypeKey &&
            licensingEnrollment.studentKey ===
              currentStudentKeyAndFeeTypeKey.studentKey
          )
      )
    )
    // return the checkbox to its previous state
    setEnrollments(
      enrollments.map((enrollment) => {
        if (
          enrollment.studentKey === currentStudentKeyAndFeeTypeKey.studentKey
        ) {
          return currentStudentKeyAndFeeTypeKey.feeTypeKey ===
            FeeType.SemesterOneLicensingFee
            ? {
                ...enrollment,
                semesterOneLicensingOwed: true,
              }
            : {
                ...enrollment,
                semesterTwoLicensingOwed: true,
              }
        }

        return enrollment
      })
    )

    resetExemptReasonModalAndCurrentRowToInitialValues()
  }

  const addExemptReason = (exemptReason: string) => {
    setLicensingEnrollmentsToUpdate(
      licensingEnrollmentsToUpdate.map((licensingEnrollment) => {
        if (
          licensingEnrollment.studentKey ===
            currentStudentKeyAndFeeTypeKey.studentKey &&
          licensingEnrollment.feeTypeKey ===
            currentStudentKeyAndFeeTypeKey.feeTypeKey
        ) {
          return { ...licensingEnrollment, exemptReason }
        }

        return licensingEnrollment
      })
    )
    resetExemptReasonModalAndCurrentRowToInitialValues()
  }

  const handleCheckedEnrollmentsLicensingData = (
    hasSemesterEnrollmentsWithDifferentValues: boolean,
    enrollment: EnrollmentRowInfo,
    feeTypeKey: FeeType,
    checkboxType: CheckboxesTypes,
    initialEnrollmentFiltered: EnrollmentRowInfo[]
  ) => {
    // Variable to know if the user is clicking on the types of checkboxes, "tuition paid" or "licensing owed"
    const isSemesterOneOrTwoLicensingOwedCheckbox = [
      CheckboxesTypes.semesterOneLicensingOwed,
      CheckboxesTypes.semesterTwoLicensingOwed,
    ].includes(checkboxType)
    /**
     * Variable to know if the checkboxes for "tuition paid semester one" or "tuition paid semester two"
     * are checked from the backend (true)
     */
    const originalCheckboxValue = initialEnrollmentFiltered[0][checkboxType]

    const isSemesterOneOfLicensingOwedChecked =
      initialEnrollmentFiltered[0].semesterOneLicensingOwed &&
      checkboxType === CheckboxesTypes.semesterOneLicensingOwed

    const isSemesterTwoOfLicensingOwedChecked =
      initialEnrollmentFiltered[0].semesterTwoLicensingOwed &&
      checkboxType === CheckboxesTypes.semesterTwoLicensingOwed

    // If they have different values(true), it means that the checkboxes have been clicked, to update, create or delete the record.
    if (hasSemesterEnrollmentsWithDifferentValues) {
      if (isSemesterOneOrTwoLicensingOwedCheckbox) {
        if (
          isSemesterOneOfLicensingOwedChecked ||
          isSemesterTwoOfLicensingOwedChecked
        ) {
          // show the modal and set the studentKey and FeeType of the selected row
          setShowExemptReasonModal(true)
          setCurrentStudentKeyAndFeeTypeKey({
            studentKey: enrollment.studentKey,
            feeTypeKey,
          })
        }

        setLicensingEnrollmentsToUpdate((prevEnrollmentToUpdate) => {
          return [
            ...prevEnrollmentToUpdate,
            {
              studentKey: enrollment.studentKey,
              programKey: enrollment.programKey,
              feeTypeKey,
            },
          ]
        })
      }
      // add data to state for use in createLicensingEnrollment endpoint
      if (
        !isSemesterOneOrTwoLicensingOwedCheckbox &&
        /**
         * If we are checking the checkbox and from the backend it is unchecked (false),
         * this record will be added to the state to create it
         */
        !originalCheckboxValue
      ) {
        setCreateLicensingEnrollments((prevEnrollmentToCreate) => {
          return [
            ...prevEnrollmentToCreate,
            {
              studentKey: enrollment.studentKey,
              programKey: enrollment.programKey,
              feeTypeKey:
                feeTypeKey as unknown as LicensingEnrollmentFeeTypeKeyEnum,
            },
          ]
        })
      }
      //add data to state for use in removeLicensingEnrollment endpoint
      if (
        !isSemesterOneOrTwoLicensingOwedCheckbox &&
        /**
         * If we are checking the checkbox and from the backend it is checked (true),
         * this record will be added to the state to remove it
         */
        originalCheckboxValue
      ) {
        setRemoveLicensingEnrollments((prevEnrollmentToCreate) => {
          return [
            ...prevEnrollmentToCreate,
            {
              studentKey: enrollment.studentKey,
              programKey: enrollment.programKey,
              feeTypeKey:
                feeTypeKey as unknown as RemoveLicensingEnrollmentRequestBodyLicensingEnrollmentsFeeTypeKeyEnum,
            },
          ]
        })
      }
    }
    /**
     * If they do not have different values(false), it means that the checkboxes have been clicked,
     *  but it will not update, create or remove the record, it will remove the change from either create, update, remove state objects.
     */
    if (!hasSemesterEnrollmentsWithDifferentValues) {
      const licensingEnrollmentToBeModified =
        isSemesterOneOrTwoLicensingOwedCheckbox
          ? licensingEnrollmentsToUpdate
          : !originalCheckboxValue
          ? createLicensingEnrollments
          : removeLicensingEnrollments

      const licensingEnrollmentToMap = [...licensingEnrollmentToBeModified]

      licensingEnrollmentToMap.map((licensingEnrollment, index) => {
        if (
          licensingEnrollment.studentKey === enrollment.studentKey &&
          licensingEnrollment.programKey === enrollment.programKey &&
          licensingEnrollment.feeTypeKey === feeTypeKey
        ) {
          licensingEnrollmentToBeModified.splice(index, 1)
        }
      })

      if (isSemesterOneOrTwoLicensingOwedCheckbox) {
        setLicensingEnrollmentsToUpdate(
          licensingEnrollmentToBeModified as unknown as UpdateLicensingEnrollmentRequestBodyLicensingEnrollments[]
        )
      }

      if (!isSemesterOneOrTwoLicensingOwedCheckbox && !originalCheckboxValue) {
        setCreateLicensingEnrollments(
          licensingEnrollmentToBeModified as unknown as LicensingEnrollment[]
        )
      }

      if (!isSemesterOneOrTwoLicensingOwedCheckbox && originalCheckboxValue) {
        setRemoveLicensingEnrollments(
          licensingEnrollmentToBeModified as unknown as RemoveLicensingEnrollmentRequestBodyLicensingEnrollments[]
        )
      }
    }
  }

  const handleCheckboxesChange = (props: HandleCheckboxesChangeProps) => {
    const { checkboxesValues } = props
    /**
     * We are filtering the initialEnrollments checkbox clicked by studentKey and programKey
     * to obtain the values of the semesters of the records to do the remove, create or update operation
     */
    const initialEnrollmentFiltered = initialEnrollments.filter(
      (initialEnrollment) =>
        initialEnrollment.studentKey === props.studentKey &&
        initialEnrollment.programKey === props.programKey
    )
    /**
     * We are mapping the enrollment to update the view of the checkboxes within the table,
     * it also initializes the functions that will add the values to the states that will perform the operations
     *  of remove, update and create the licensing Enrollment
     */
    const enrollmentsWithUpdatedSemesterValues = enrollments.map(
      (enrollment) => {
        if (
          enrollment.studentKey === props.studentKey &&
          enrollment.programKey === props.programKey
        ) {
          const enrollmentFoundWhenClicking = enrollment[checkboxesValues]
          const semesterValueOfEnrollmentChanged = {
            ...enrollment,
            [checkboxesValues]: !enrollmentFoundWhenClicking,
          }

          const hasSemestersDifferentValues =
            semesterValueOfEnrollmentChanged[checkboxesValues] !==
            initialEnrollmentFiltered[0][checkboxesValues]

          let newFeeTypeVariableSet = FeeType.SemesterTwoLicensingFee
          let newCheckBoxTypeVariableSet =
            CheckboxesTypes.semesterTwoTuitionPaid

          //Adds the information of the enrollments to their respective state to be used in calls to the backend
          switch (checkboxesValues) {
            case CheckboxesTypes.semesterOneLicensingOwed:
              newFeeTypeVariableSet = FeeType.SemesterOneLicensingFee
              newCheckBoxTypeVariableSet =
                CheckboxesTypes.semesterOneLicensingOwed
              break

            case CheckboxesTypes.semesterTwoLicensingOwed:
              newFeeTypeVariableSet = FeeType.SemesterTwoLicensingFee
              newCheckBoxTypeVariableSet =
                CheckboxesTypes.semesterTwoLicensingOwed
              break

            case CheckboxesTypes.semesterOneTuitionPaid:
              newFeeTypeVariableSet = FeeType.SemesterOneLicensingFee
              newCheckBoxTypeVariableSet =
                CheckboxesTypes.semesterOneTuitionPaid
              break

            case CheckboxesTypes.semesterTwoTuitionPaid:
              newFeeTypeVariableSet = FeeType.SemesterTwoLicensingFee
              newCheckBoxTypeVariableSet =
                CheckboxesTypes.semesterTwoTuitionPaid
              break

            default:
              break
          }
          handleCheckedEnrollmentsLicensingData(
            hasSemestersDifferentValues,
            enrollment,
            newFeeTypeVariableSet,
            newCheckBoxTypeVariableSet,
            initialEnrollmentFiltered
          )

          return semesterValueOfEnrollmentChanged
        } else {
          return enrollment
        }
      }
    )

    setEnrollments(enrollmentsWithUpdatedSemesterValues)
  }

  const defaultFetchTransferEnrollmentsErrorMessage = t(
    'EnrollmentsTable.Error.FetchTransferEnrollments',
    'Error occurred while retrieving program details'
  )

  const isNotProcessingLicensingEnrollmentModifications =
    !loadingIds.has(saveActionLoadingId)

  useEffect(() => {
    /**
     * Only refetch if when:
     * triggerRefetch = true -> user clicked saved button
     * isNotProcessingLicensingEnrollmentModifications = true -> loadingIds are not on context
     */

    if (triggerRefetch && isNotProcessingLicensingEnrollmentModifications) {
      refetchEnrollmentsData?.()
    }
  }, [
    triggerRefetch,
    isNotProcessingLicensingEnrollmentModifications,
    refetchEnrollmentsData,
  ])

  const [searchTerm, setSearchTerm] = useState('')

  const updateSearchResults = (search: string) => {
    // set state of search term
    setSearchTerm(search)

    // call and update the transferable enrollments based on the search term
    addLoadingIds([fetchTransferEnrollmentsLoadingId])
  }

  const fetchTransferEnrollments = async (opts?: {
    programKey?: number
    transfersOnly?: boolean
    year?: number
    search?: string
  }): Promise<void> => {
    const {
      programKey = programInfo.programKey,
      transfersOnly = true,
      year = +academicYear,
      search = searchTerm.length < MINIMUM_DEBOUNCED_SEARCH_QUERY_LENGTH
        ? undefined
        : searchTerm, // The backend will return an error if the search is less than 3 characters
    } = opts ?? {}

    try {
      //TODO: Add filter for academic year and programKey https://projekt202.atlassian.net/browse/CCP1-2490
      const { enrollments } = await fetchOnlyTransferEnrollments(
        programKey,
        transfersOnly,
        year,
        search
      )

      if (enrollments.length === 0) {
        setSnackbarState(true)
        setSnackbarMessage(
          t(
            'EnrollmentsTable.Error.FetchTransferredEnrollments',
            'There are no enrollments eligible to be transferred.'
          )
        )
        setSnackbarSeverity(SnackbarSeverity.Warning)
        return
      }
      setTransferEligibleEnrollments(enrollments)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown Code',
        message:
          (e as unknown as Error).message ??
          defaultFetchTransferEnrollmentsErrorMessage,
      }
      setErrorMessage(errorObject.message)
    }
  }

  const resetTransferEligibleEnrollments = () => {
    setTransferEligibleEnrollments([])
  }

  useLoadingContext({
    loadingId: fetchTransferEnrollmentsLoadingId,
    asyncFunction: fetchTransferEnrollments,
  })

  /**
   * If error fetching transfer available enrollments, display snack bar alert
   */
  useEffect(() => {
    if (!!errorMessage) {
      setSnackbarState(true)
      setSnackbarMessage(errorMessage)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  })

  useEffect(() => {
    // re-render enrollments table once the modal is closed and new enrollments are fetched
    setEnrollments(initialEnrollments)
  }, [initialEnrollments])
  /** Other Objects */
  const tableHeaders: TableHeaders[] = [
    {
      label: t('Families.Enrollments.StudentName.Header', 'Student Name'),
      align: 'left',
    },
    {
      label: t('Families.Enrollments.FamilyName.Header', 'Family Name'),
      align: 'left',
    },
    {
      label: t('Families.Enrollments.EnrollmentStatus.Header', 'Status'),
      align: 'left',
    },
    {
      label: t('Families.Enrollments.TuitionPaid.Header', 'Tuition Paid'),
      align: 'left',
    },
    ...(canManageLicensingEnrollment
      ? ([
          {
            label: t(
              'Families.Enrollments.LicensingOwed.Header',
              'Licensing Owed'
            ),
            align: 'left',
          },
        ] as TableHeaders[])
      : []),
    {
      id: 'tutorHeader',
      label: t('Programs.ProgramDetails.Students.Tutor.Header', 'Tutor'),
      align: 'left',
    },
  ]
  const sharedDropDownProps = {
    variant: DropDownVariant.SortAndFilter,
    fullWidth: !showOnDesktop,
  }

  const canUserUpdateAnyEnrollmentStatus = permissionAbility.can(
    'updateToAnyStatus',
    'Enrollment'
  )

  const canUserTransferEnrollments = permissionAbility.can(
    'allowTransfer',
    'Enrollment'
  )

  //adding default value to enrollment valid status
  const enrollmentFilterOptionsValidStatuses: string[] = [
    'All Enrollment Statuses',
    ...enrollmentOptions,
  ]

  const enrollmentStatusDropdownOptions =
    filterAllowedEnrollmentDropDownOptions(
      enrollmentOptions,
      canUserUpdateAnyEnrollmentStatus
    )

  const navigateToFamilyProfile = (userId?: number) => {
    const baseRoute = '/families'
    if (!userId) {
      navigate(
        { pathname: baseRoute },
        {
          /** Navigation Options */
        }
      )
      return
    }
    navigate(
      { pathname: `/family-profile/${userId.toString()}` },
      {
        /** Navigation Options */
      }
    )
  }

  const getEnrollmentStatusString = (status?: EnrollmentStatus) => {
    if (!!status) {
      switch (status) {
        case EnrollmentStatus.Complete:
          return 'COMPLETE'
        case EnrollmentStatus.Drop:
          return 'DROP'
        case EnrollmentStatus.Enrolled:
          return 'ENROLLED'
        case EnrollmentStatus.MemberFull:
          return 'MEMBER FULL'
        case EnrollmentStatus.MemberHalf:
          return 'MEMBER HALF'
        case EnrollmentStatus.Pending:
        default:
          return 'PENDING'
      }
    }
    return ''
  }

  const getEnrollmentStatusFromString = (status: string) => {
    switch (status) {
      case 'COMPLETE':
        return EnrollmentStatus.Complete
      case 'DROP':
        return EnrollmentStatus.Drop
      case 'ENROLLED':
        return EnrollmentStatus.Enrolled
      case 'MEMBER FULL':
        return EnrollmentStatus.MemberFull
      case 'MEMBER HALF':
        return EnrollmentStatus.MemberHalf
      case 'PENDING':
      default:
        return EnrollmentStatus.Pending
    }
  }

  const [isInitialEnrollmentLoad, setIsInitialEnrollmentLoad] = useState(true)
  const { fieldStates: enrollmentStates, setFieldStates: setEnrollmentStates } =
    useDynamicFieldStates({
      fields: enrollments?.map((it) => {
        return {
          label: `${it.rowId}`,
          state: {
            state: getEnrollmentStatusString(it.status),
            isValid: { input: true },
            required: true,
          },
        }
      }),
      isInitialLoad: isInitialEnrollmentLoad,
      setIsInitialLoad: setIsInitialEnrollmentLoad,
    })

  const handleEnrollmentStatusChange = async (opts: {
    fieldId: string
    enrollmentStatus: string
    studentKey: number
  }) => {
    // Set the enrollment status state so we don't really need to refetch each change
    const { fieldId, enrollmentStatus, studentKey } = opts

    setEnrollments((currentEnrollments) => {
      const updatedEnrollments = [...currentEnrollments]
      const studentIndex = currentEnrollments.findIndex(
        (enrollment) => enrollment.studentKey === studentKey
      )
      updatedEnrollments[studentIndex] = {
        ...updatedEnrollments[studentIndex],
        status: getEnrollmentStatusFromString(enrollmentStatus),
      }
      return updatedEnrollments
    })
    setEnrollmentStates({
      ...enrollmentStates,
      [fieldId]: {
        ...enrollmentStates[fieldId],
        state: enrollmentStatus,
      },
    })
  }

  const handleSearch = (searchText: string) => {
    if (searchText.length >= 3) {
      setSearchState(searchText)
    }
    if (searchText.length === 0) {
      setSearchState('')
    }
  }

  const handleEnrollmentStatusFilterSelection = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _selection: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _dropdownId: string,
    enrollmentStatus: string | number
  ) => {
    handleChangePage(0)
    setFilters({
      ...filters,
      enrollmentStatus: String(enrollmentStatus),
    })
  }

  const handleTutorFilterSelection = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _selection: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _dropdownId: string,
    tutorId: string | number
  ) => {
    handleChangePage?.(0)
    setFilters({
      ...filters,
      tutorId,
    })
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const updatedRowsPerPage = parseInt(event.target.value, 10)
    handleChangePageSize?.(updatedRowsPerPage)
  }

  const handleCancel = () => {
    refetchEnrollmentsData?.()
    setIsEnrollmentsTableEdit(false)
    setEditForParent?.(false)
    setEnrollments(initialEnrollments)
  }

  /*
  Removes data from the state that no longer needs updating
  (licensingEnrollmentsUpdated).
  */
  const removesModifiedLicensingEnrollmentsDataFromState = useCallback(() => {
    let licensingEnrollmentsUpdated = licensingEnrollmentsToUpdate
    // We map the state that contains the data to be removed
    removeLicensingEnrollments.forEach((removeLicensingEnrollment) => {
      /*
      We look for each of the data contained in the state (removeLicensingEnrollments)
      in the state (licensingEnrollmentsUpdated), if it finds a match, it will return a 
      new array without the data that comply with the condition. 
      */
      licensingEnrollmentsUpdated = licensingEnrollmentsUpdated.filter(
        (licensingEnrollment) => {
          return !(
            licensingEnrollment.studentKey ===
              removeLicensingEnrollment.studentKey &&
            licensingEnrollment.programKey ===
              removeLicensingEnrollment.programKey &&
            licensingEnrollment.feeTypeKey ===
              (removeLicensingEnrollment.feeTypeKey as unknown as FeeType)
          )
        }
      )
    })

    return licensingEnrollmentsUpdated
  }, [licensingEnrollmentsToUpdate, removeLicensingEnrollments])

  const handleSaveAction = async () => {
    try {
      // Find the students requiring an update
      if (
        initialEnrollments.some(
          (enrollment) =>
            enrollments.some((it) => it.studentKey === enrollment.studentKey) &&
            enrollment.tutorActorKey !==
              enrollments.find((it) => it.studentKey === enrollment.studentKey)
                ?.tutorActorKey
        )
      ) {
        // Find the difference of students
        const studentTutorAssignments: StudentTutorAssignment[] = []
        initialEnrollments.forEach((student) => {
          // Find the enrollment to compare to
          const tutorActorIdToCompare = enrollments.find(
            (it) => it.studentKey === student.studentKey
          )?.tutorActorKey
          // And validate the tutorActorKeys
          if (tutorActorIdToCompare !== student.tutorActorKey) {
            studentTutorAssignments.push({
              studentKey: student.studentKey,
              tutorActorKey: tutorActorIdToCompare ?? undefined,
            })
          }
        })

        await programs.updateStudentTutorAssignments({
          programId: programInfo.programKey,
          body: {
            studentTutorAssignments,
          },
        })

        setSnackbarState?.(true)
        setSnackbarSeverity?.(SnackbarSeverity.Success)
        setSnackbarMessage?.(
          t(
            'ProgramStudentsTable.StudentTutorAssignments.Success',
            'Successfully updated tutors for students.'
          )
        )
      }
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: t(
          'ProgramStudentsTable.StudentTutorAssignments.Error',
          'An unknown error occurred updating tutors for students.'
        ),
      }
      setSnackbarState?.(true)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarMessage?.(errorObject.message)
    }

    // Set the enrollment status state so we don't really need to refetch each change
    try {
      const studentsToUpdate: EnrollmentStatusUpdate[] = [...enrollments]
        .filter(
          (enrollment) =>
            initialEnrollments.find(
              (it) => it.studentKey === enrollment.studentKey
            )?.status !== enrollment.status
        )
        .map((it) => ({
          programKey: programInfo.programKey,
          studentKey: it.studentKey,
          status: it.status,
        }))

      const updatesForEnrollments = studentsToUpdate.map((updateBody) =>
        updateEnrollmentStatus(updateBody)
      )

      const updateResults = await Promise.allSettled(updatesForEnrollments)

      // Separate rejections from success messages
      const rejections = updateResults.filter((it) => it.status === 'rejected')
      const errorMessage: Set<string> = new Set()

      for (const rejection of rejections) {
        errorMessage.add(
          `${
            (
              await extractedErrorObject(
                (rejection as PromiseRejectedResult).reason
              )
            )?.message
          } \n`
        )
      }

      for (const msg of errorMessage) {
        addSnackbarKey(enqueueSnackbar(msg, { variant: 'error' }))
      }

      const successMessages: string[] = []

      const successes = updateResults.filter((it) => it.status === 'fulfilled')
      if (successes.length > 0) {
        successMessages.push(
          `${t(
            'EnrollmentsTable.EnrollmentStatusUpdate.Success',
            'Successfully updated enrollment status for one or more students.'
          )}`
        )
      }

      for (const msg of successMessages) {
        addSnackbarKey(enqueueSnackbar(msg, { variant: 'success' }))
      }
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message:
          (e as unknown as Error).message ??
          `${t(
            'EnrollmentsTable.EnrollmentStatusUpdate.Failure',
            'Failed to update enrollment status for one or more students.'
          )}`,
      }
      setSnackbarState?.(true)
      setSnackbarMessage?.(errorObject.message)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
    }

    const licensingEnrollmentsUpdated =
      removesModifiedLicensingEnrollmentsDataFromState()

    if (licensingEnrollmentsUpdated.length > 0) {
      try {
        await enrollmentsApi.updateLicensingEnrollments({
          body: { licensingEnrollments: licensingEnrollmentsUpdated },
        })
      } catch (e) {
        const errorObject = (await extractedErrorObject(e)) ?? {
          code: 'Unknown Code',
          message:
            (e as unknown as Error).message ??
            defaultFetchTransferEnrollmentsErrorMessage,
        }

        setSnackbarState(true)
        setSnackbarMessage(errorObject.message)
        setSnackbarSeverity(SnackbarSeverity.Error)
      } finally {
        setLicensingEnrollmentsToUpdate([])
      }
    }

    if (createLicensingEnrollments.length > 0) {
      try {
        await enrollmentsApi.createLicensingEnrollment({
          body: { licensingEnrollments: createLicensingEnrollments },
        })
      } catch (e) {
        const errorObject = (await extractedErrorObject(e)) ?? {
          code: 'Unknown Code',
          message:
            (e as unknown as Error).message ??
            defaultFetchTransferEnrollmentsErrorMessage,
        }

        setSnackbarState(true)
        setSnackbarMessage(errorObject.message)
        setSnackbarSeverity(SnackbarSeverity.Error)
      } finally {
        setCreateLicensingEnrollments([])
      }
    }

    if (removeLicensingEnrollments.length > 0) {
      try {
        const response = await enrollmentsApi.removeLicensingEnrollments({
          body: { licensingEnrollments: removeLicensingEnrollments },
        })
        if (response.recordsNotRemoved?.length) {
          const errorMessage: string[] = []
          for (const recordNotRemoved of response.recordsNotRemoved) {
            errorMessage.push(
              `${recordNotRemoved.studentKey}, ${recordNotRemoved.programKey}, ${recordNotRemoved.feeTypeKey} \n`
            )
          }
          if (errorMessage.length > 0) {
            addSnackbarKey(enqueueSnackbar(errorMessage, { variant: 'error' }))
          }
        }
      } catch (e) {
        const errorObject = (await extractedErrorObject(e)) ?? {
          code: 'Unknown Code',
          message:
            (e as unknown as Error).message ??
            defaultFetchTransferEnrollmentsErrorMessage,
        }

        setSnackbarState(true)
        setSnackbarMessage(errorObject.message)
        setSnackbarSeverity(SnackbarSeverity.Error)
      } finally {
        setRemoveLicensingEnrollments([])
      }
    }

    // Prevent edits on successful save
    setIsEnrollmentsTableEdit(false)
    setEditForParent?.(false)
    setTriggerRefetch(true)
  }

  useLoadingContext({
    asyncFunction: handleSaveAction,
    loadingId: saveActionLoadingId,
  })

  const noTutorOption: DropdownSelectOptions = useMemo(
    () => ({
      label: t('Programs.ProgramDetails.Students.Tutors.None', 'None'),
      id: -1,
      disabled: false,
    }),
    [t]
  )

  const [tutorOptions, setTutorOptions] = useState<DropdownSelectOptions[]>([
    noTutorOption,
  ])

  const filterTutorList = useCallback(() => {
    const filteredTutors = filterActorSelectOption(tutorList ?? [], {
      type: programInfo.programType,
      firstSemesterStartDate: dateToDashString(
        programInfo.firstSemesterStartDate || new Date(),
        true
      ),
      removeExpired: false,
    }).filter((it) => {
      return tutors?.map((it) => it.id).includes(it.actorKey)
    })

    const options = [
      ...filteredTutors.map((it) => ({
        id: it.actorKey,
        label: it.name,
        disabled: it.expired,
      })),
      noTutorOption,
    ]

    setTutorOptions(options)
  }, [
    noTutorOption,
    programInfo.firstSemesterStartDate,
    programInfo.programType,
    tutorList,
    tutors,
  ])

  useEffect(() => {
    filterTutorList()
  }, [filterTutorList, tutorList])

  const handleSelection = (selection: string, id: string) => {
    //First we get the studentKey
    const key = +id.split('-')[1]
    //Then we set the student map at the studentKey to be the current object with the changed tutor name
    const tutorOption = tutorOptions.find(
      (option) => option.label === selection
    )
    let tutorActorKey: number | undefined = undefined
    if (!!tutorOption && +tutorOption?.id > 0) {
      tutorActorKey = +tutorOption?.id
    }
    setStudentsMap(studentsMap?.set(key, tutorActorKey))
    setEnrollments((currentEnrollments) => {
      // currentStudents is a reference (initially) to initialEnrollments. Trying to modify it will
      // will modify initialEnrollments
      const updatedStudents = [...currentEnrollments]
      const studentIndex = currentEnrollments.findIndex(
        (enrollment) => enrollment.studentKey === key
      )
      updatedStudents[studentIndex] = {
        ...updatedStudents[studentIndex],
        tutorActorKey,
      }
      return updatedStudents
    })
  }

  const handleReportsClick = async () => {
    const defaultReportsErrorMessage = t(
      'EnrollmentsTable.Reports.Redirect',
      'An unknown error occurred when redirecting.'
    )
    const { href } = new URL(
      process.env.REACT_APP_REPORTS_URL +
        '?ReturnURL=' +
        encodeURIComponent(
          `${process.env.REACT_APP_DIRECTORY_REPORT_URL}&pk=${programInfo.programKey}`
        )
    )
    try {
      window.open(href, '_blank')
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: (e as unknown as Error).message ?? defaultReportsErrorMessage,
      }
      setSnackbarState?.(true)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarMessage?.(errorObject.message)
    }
  }

  const onEditClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setIsEnrollmentsTableEdit(true)
    setEditForParent?.(true)
  }

  const editAction = useRef({
    name: 'Edit',
    icon: <Edit />,
    onClick: onEditClick,
    onTourClick: () => {
      setIsEnrollmentsTableEdit(true)
      setEditForParent?.(true)
    },
    selector: 'edit-action',
    hidden: isEnrollmentsTableEdit,
    pauseTourBriefly: true,
  })

  const saveActionOnClick = useCallback(() => {
    addLoadingIds([saveActionLoadingId])
  }, [saveActionLoadingId, addLoadingIds])

  // isEnrollmentsTableEdit
  const saveAction = useRef<SpeedDialActions>({
    name: 'Save',
    icon: <Save />,
    // We could make this a fn, but we're currently just transferring functionality to ensure behavior.
    onClick: saveActionOnClick,
    // We can add this in if we want to demonstrate saving, but, cancel is better.
    // onTourClick: saveActionOnClick,
    selector: 'save-action',
    hidden: !isEnrollmentsTableEdit,
  })

  useEffect(() => {
    saveAction.current = {
      ...saveAction.current,
      onClick: saveActionOnClick,
    }
  })

  const cancelAction = useRef<SpeedDialActions>({
    name: 'Cancel',
    icon: <Cancel />,
    onClick: handleCancel,
    hidden: !isEnrollmentsTableEdit,
    onTourClick: handleCancel,
    selector: 'cancel-action',
  })

  const canEdit =
    (programAbility?.can('edit', 'Program') ||
      isAllowedToEditAndHandleCheckboxes) &&
    areEnrollmentsAvailable

  const actions: SpeedDialActions[] = useMemo(() => {
    cancelAction.current.hidden = !isEnrollmentsTableEdit
    saveAction.current.hidden = !isEnrollmentsTableEdit
    editAction.current.hidden = isEnrollmentsTableEdit
    return [
      ...(canEdit
        ? !isEnrollmentsTableEdit
          ? [editAction.current]
          : [saveAction.current, cancelAction.current]
        : [
            /** no actions if the user cannot edit */
          ]),
    ]
  }, [canEdit, isEnrollmentsTableEdit])

  useMountEffect(() => {
    updateJoyrideCompleteId(enrollmentsJoyrideCompleteId)
    updateJoyrideSteps([
      {
        target: '.MuiSpeedDial-fab',
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        content: t(
          'EnrollmentsTable.SpeedDialTour.StepZero.Content',
          'Click here to see the edit button.'
        ),
        placement: 'left',
        title: t(
          'EnrollmentsTable.SpeedDialTour.StepZero.Title',
          'Edit button has moved!'
        ),
        disableBeacon: true,
        disableScrolling: true,
      },
      {
        target: '.edit-action',
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        content: t(
          'EnrollmentsTable.SpeedDialTour.StepOne.Content',
          `It's as easy as that.`
        ),
        placement: 'left',
        title: t(
          'EnrollmentsTable.SpeedDialTour.StepOne.Title',
          'Click to edit!'
        ),
        disableBeacon: true,
        disableScrolling: true,
      },
      {
        target: '.save-action',
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        content: t(
          'EnrollmentsTable.SpeedDialTour.StepTwo.Content',
          `Don't forget to save your changes.`
        ),
        placement: 'left',
        title: t(
          'EnrollmentsTable.SpeedDialTour.StepTwo.Title',
          'Save is here too!'
        ),
        disableBeacon: true,
        disableScrolling: true,
      },
      {
        target: '.cancel-action',
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        content: t(
          'EnrollmentsTable.SpeedDialTour.StepThree.Content',
          `If you don't want to save any changes, click here.`
        ),
        placement: 'left',
        title: t(
          'EnrollmentsTable.SpeedDialTour.StepThree.Title',
          `Don't forget about cancel!`
        ),
        disableBeacon: true,
        disableScrolling: true,
      },
    ])
  })

  useEffect(() => {
    /** On load, provide some actions for the speed dial to render */
    if (actions.length > 0) {
      updateActions(actions)
    }
  }, [actions, updateActions, isEnrollmentsTableEdit])

  /** Remove actions when leaving. */
  useUnmountEffect(() => {
    resetContextToDefaults()
  })

  const handleShowConfirmationDialog = () => {
    setShowConfirmationModal(true)
  }

  const handleConfirmationCancel = () => {
    setShowConfirmationModal(false)
    return
  }

  const isTheProgramDirector = programEmailDirector === currentUserMail

  const handleEmailAllFamilies = (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault()
    setShowConfirmationModal(false)

    return (window.location.href = `mailto:${currentUserMail}?bcc=${getCommaSeparatedList(
      uniqueEmailsFromAllowedEnrollmentStatuses({
        enrollments,
      })
    )}`)
  }

  const headerMessage = `${t(
    'Families.EnrollmentTable.ConfirmationModal.Header',
    '{{textHeaderMessage}}',
    { textHeaderMessage }
  )}`

  const confirmationMessageText =
    pagination.pageSize < 100 && pagination.totalCount > pagination.pageSize
      ? confirmationRowsMessageText
      : confirmationEmailFamiliesMessageText

  const ConfirmationMessage = `${t(
    'Families.EnrollmentsTable.ConfirmationModal.Body',
    '{{confirmationMessageText}}',
    { confirmationMessageText }
  )}`

  const ConfirmationBody = (
    <Typography variant="body1" component="p" align="center">
      {ConfirmationMessage}
    </Typography>
  )

  const ModalActionButtons = (
    <ActionButtons
      primaryButtonLabel={ContainedButtonVariant.YesImSure}
      secondaryButtonLabel={TextButtonVariant.NoCancel}
      secondaryClick={handleConfirmationCancel}
    />
  )

  const ModalConfirmationProps = {
    isOpen: showConfirmationModal,
    dialogTitle: headerMessage,
    dialogContent: ConfirmationBody,
    dialogActions: ModalActionButtons,
    handleFormSubmit: handleEmailAllFamilies,
  }

  const handleTransferConfirmationCancel = () => {
    setShowTransferEnrollmentModal(false)
    return
  }

  const handleTransferInButtonClick = () => {
    setShowTransferEnrollmentModal(true)
    return
  }

  const [enrollmentToTransferStudentName, setEnrollmentToTransferStudentName] =
    useState('')

  const [enrollmentToTransferStudentData, setEnrollmentToTransferStudentData] =
    useState<TransferEnrollmentRequestParams>({
      studentKey: 0,
      programKey: 0,
      targetProgramKey: 0,
    })

  const handleTransferEnrollmentSelection = (enrollment: Enrollment) => {
    const { studentName, familyName, communityName, programType } = enrollment

    setEnrollmentToTransferStudentName(
      `${studentName} ${familyName} - ${communityName} - ${programType}`
    )

    setEnrollmentToTransferStudentData({
      studentKey: enrollment.studentKey,
      programKey: enrollment.programKey,
      targetProgramKey: programInfo.programKey,
    })
  }

  const programTransferredInto = programInfo.programType

  const handleEnrollmentTransfer = async (
    e: React.FormEvent<HTMLDivElement>
  ) => {
    e.preventDefault()

    const defaultReportsErrorMessage = t(
      'EnrollmentsTable.Transfer.Enrollment',
      'An unknown error occurred while attempting to transfer the student.'
    )
    const successMessage = t(
      'EnrollmentsTable.Transfer.Enrollment',
      'Transferred student {{enrollmentToTransferStudentName}} into {{programTransferredInto}}.',
      {
        enrollmentToTransferStudentName,
        programTransferredInto,
      }
    )

    try {
      await transferEnrollment(enrollmentToTransferStudentData)
      setSnackbarState?.(true)
      setSnackbarSeverity?.(SnackbarSeverity.Success)
      setSnackbarMessage?.(successMessage)
      // refetch students and invites to include the newly transferred student
      refetchEnrollmentsData?.()
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: (e as unknown as Error).message ?? defaultReportsErrorMessage,
      }
      setSnackbarState?.(true)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarMessage?.(errorObject.message)
    } finally {
      setShowTransferEnrollmentModal(false)
    }
    return
  }

  const programAcceptTransfers = useMemo(() => {
    return [ProgramStatus.Published, ProgramStatus.Draft].includes(
      programInfo.status
    )
  }, [programInfo.status])

  const filtrableTutorsOptions = () => {
    const options = tutorOptions.map((tutor) => {
      return {
        id: tutor.id,
        name: tutor.label,
      } as MenuOption
    })

    options.unshift({
      id: defaultTutorFilterOption,
      name: defaultTutorFilterOption,
    })

    return options
  }

  return (
    <>
      {
        <SearchSortAndFilterSection aria-label="searchSortAndFilter">
          <SearchBar handleSearch={handleSearch} searchValue={searchState} />
          <Box
            sx={{
              marginLeft: theme.spacing(2),
              [theme.breakpoints.down('sm')]: {
                marginLeft: theme.spacing(0),
                marginBottom: theme.spacing(2),
                width: '100%',
              },
            }}
          >
            <DropDown
              formControlProps={{
                [theme.breakpoints.down('sm')]: {
                  margin: theme.spacing(0),
                  width: '100%',
                },
              }}
              id="enrollmentStatusFilter"
              menuOptions={
                enrollmentFilterOptionsValidStatuses.map((status) => {
                  const spacedEnrollmentStatus = status.replace('_', ' ')
                  return {
                    name: getLabelForEnrollmentStatus(
                      spacedEnrollmentStatus,
                      t
                    ),
                    id: status,
                  }
                }) ?? ''
              }
              value={getLabelForEnrollmentStatus(
                filters?.enrollmentStatus as string,
                t
              )}
              handleSelection={handleEnrollmentStatusFilterSelection}
              {...{ ...sharedDropDownProps, fullWidth: false }}
            />
          </Box>
          <Box
            sx={{
              marginLeft: theme.spacing(2),
              [theme.breakpoints.down('sm')]: {
                marginLeft: theme.spacing(0),
                marginBottom: theme.spacing(2),
                width: '100%',
              },
            }}
          >
            <DropDown
              id="tutorFilter"
              formControlProps={{
                [theme.breakpoints.down('sm')]: {
                  margin: theme.spacing(0),
                  width: '100%',
                },
              }}
              menuOptions={filtrableTutorsOptions()}
              value={
                filtrableTutorsOptions().find(
                  (tutor) => tutor.id === filters?.tutorId
                )?.name ?? defaultTutorFilterOption
              }
              handleSelection={handleTutorFilterSelection}
              {...{ ...sharedDropDownProps, fullWidth: false }}
            />
          </Box>
        </SearchSortAndFilterSection>
      }
      <Box pb={4}>
        <CardFormHeader
          header={null}
          buttons={
            <>
              <Can I="view" on="Family" ability={permissionAbility}>
                {isTheProgramDirector && areEnrollmentsAvailable && (
                  <ContainedButton
                    id="emailFamiliesButton"
                    variant={ContainedButtonVariant.EmailFamilies}
                    css={{
                      marginBottom: theme.spacing(2.5),
                    }}
                    onClick={handleShowConfirmationDialog}
                  />
                )}
              </Can>
              {!isEnrollmentsTableEdit && (
                // If not in edit, render other buttons
                <SpaceBetweenSection
                  left={
                    <IconTextButton
                      id="reportsButton"
                      variant={IconTextButtonVariant.Report}
                      startIcon={
                        <Print
                          sx={{
                            color: theme.palette.primary.dark,
                          }}
                        />
                      }
                      onClick={handleReportsClick}
                      disabled={!areEnrollmentsAvailable}
                    />
                  }
                  right={
                    programAcceptTransfers && canUserTransferEnrollments ? (
                      <ContainedButton
                        id="transferStudent"
                        variant={ContainedButtonVariant.TransferIn}
                        css={{
                          marginBottom: theme.spacing(2.5),
                          [theme.breakpoints.down('sm')]: {
                            margin: theme.spacing(2.5, 0, 5),
                          },
                        }}
                        onClick={handleTransferInButtonClick}
                      />
                    ) : null
                  }
                />
              )}
            </>
          }
        />
        <ConfirmationModal {...ModalConfirmationProps} />
        <TransferEnrollmentsModal
          showTransferEnrollmentModal={showTransferEnrollmentModal}
          handleEnrollmentTransfer={handleEnrollmentTransfer}
          transferEligibleEnrollments={transferEligibleEnrollments}
          handleTransferEnrollmentSelection={handleTransferEnrollmentSelection}
          handleTransferConfirmationCancel={handleTransferConfirmationCancel}
          updateSearchResults={updateSearchResults}
          updateSearchResultsLoadingId={fetchTransferEnrollmentsLoadingId}
          resetTransferEligibleEnrollments={resetTransferEligibleEnrollments}
        />
        {
          <ExemptReasonModal
            showExemptReasonModal={showExemptReasonModal}
            handleConfirmationCancel={handleConfirmationCancelExemptReason}
            onAccept={addExemptReason}
          />
        }
      </Box>
      {areEnrollmentsAvailable ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableHeader tableHeaders={tableHeaders} />
            </TableHead>
            <TableBody>
              {enrollments &&
                enrollments.length > 0 &&
                enrollments.map((enrollment) => {
                  const onTableCellClick = () => {
                    navigateToFamilyProfile(enrollment.primaryContactId)
                  }
                  // Enrollment status of the current student row
                  const enrollmentStatusValue = enrollmentStates[
                    `${enrollment.rowId}`
                  ]
                    ? enrollmentStates[`${enrollment.rowId}`].state.replace(
                        '_',
                        ' '
                      )
                    : ''
                  return (
                    <TableRow key={enrollment.rowId}>
                      <ClickableCell onClick={onTableCellClick}>
                        <Typography variant="body1" component="p">
                          {enrollment.studentName}
                        </Typography>
                      </ClickableCell>
                      <ClickableCell onClick={onTableCellClick}>
                        <Typography variant="body1" component="p">
                          {enrollment.familyName}
                        </Typography>
                      </ClickableCell>
                      <TableCell>
                        {/*
                         * Do not require enrollment updateToAnyStatus check since
                         * the enrollment statuses are provided by the enrollmentOptions endpoint
                         */}
                        <Box
                          sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                        >
                          <TextField
                            id={`enrollmentSelect${enrollment.rowId}`}
                            select
                            variant="filled"
                            value={enrollmentStatusValue}
                            disabled={
                              // If we cannot update ANY enrollment status we are director
                              !isEnrollmentsTableEdit ||
                              (!canUserUpdateAnyEnrollmentStatus &&
                                // So disable the field if the value is something we cannot see (CHECK, INVITED)
                                // If student enrollment status is in director enrollment available statuses-
                                // then it will be true and turned to false (to not disable the field)
                                !directorOnlyEnrollmentStatuses.some(
                                  (status) => status === enrollmentStatusValue
                                ))
                            }
                            fullWidth
                            onChange={(e) =>
                              handleEnrollmentStatusChange({
                                fieldId: `${enrollment.rowId}`,
                                enrollmentStatus: e.target.value,
                                studentKey: enrollment.studentKey,
                              })
                            }
                          >
                            {enrollmentStatusDropdownOptions.map(
                              ({ id, name, disabled }) => (
                                <MenuItem
                                  key={id}
                                  value={name}
                                  /*
                                   * Disallow Enrolled status selection if user does
                                   * not have ability to update all statuses. This
                                   * means they are a director and are limited to
                                   * ENROLLED, MEMBER HALF/FULL, and DROP. They can
                                   * change the status to MEMBER HALF/FULL and DROP.
                                   */
                                  disabled={disabled}
                                >
                                  <Typography variant="subtitle1" component="p">
                                    {getLabelForEnrollmentStatus(name, t)}
                                  </Typography>
                                </MenuItem>
                              )
                            ) ?? ''}
                          </TextField>
                          {enrollment.transferStatus && (
                            <TransferStatusIcon
                              transferStatus={enrollment.transferStatus}
                              transferDate={enrollment.inviteDate}
                              communityName={
                                (enrollment.sourceCommunityName ||
                                  enrollment.destinationCommunityName) as string
                              }
                              programName={
                                (enrollment.sourceProgramTypeKey ||
                                  enrollment.destinationProgramTypeKey) as ProgramType
                              }
                            />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <TuitionPaidCheckboxes
                          enrollment={enrollment}
                          canRemoveTuitionPayment={canRemoveTuitionPayment}
                          isEnrollmentsTableEdit={isEnrollmentsTableEdit}
                          handleOnChange={handleCheckboxesChange}
                          isChallenge={isChallenge}
                        />
                      </TableCell>
                      {canManageLicensingEnrollment && (
                        <TableCell>
                          <LicensingOwedCheckboxes
                            enrollment={enrollment}
                            isChallenge={isChallenge}
                            isEnrollmentsTableEdit={isEnrollmentsTableEdit}
                            handleOnChange={handleCheckboxesChange}
                          />
                        </TableCell>
                      )}
                      <TableCell
                        sx={{
                          minWidth: '210px',
                        }}
                      >
                        <DropDown
                          id={`tutor-${enrollment.studentKey}`}
                          menuOptions={tutorOptions
                            // Remove the None option if the student has a tutor
                            .filter(
                              (option) =>
                                !(
                                  option.label === 'None' &&
                                  enrollment.tutorActorKey
                                )
                            )
                            .map((option) => {
                              return {
                                id: option.id,
                                name: option.label,
                                disabled: option.disabled,
                              } as MenuOption
                            })}
                          value={
                            enrollment.tutorActorKey
                              ? tutorOptions.find(
                                  (option) =>
                                    option.id === enrollment.tutorActorKey
                                )?.label ?? ''
                              : 'None'
                          }
                          defaultValue={''}
                          handleSelection={handleSelection}
                          variant={DropDownVariant.FormField}
                          disabled={!isEnrollmentsTableEdit}
                          fullWidth
                          cssProps={{
                            backgroundColor: !isEnrollmentsTableEdit
                              ? 'transparent'
                              : theme.palette.background.default,
                            padding: theme.spacing(0),
                            height: 'auto',
                            maxWidth: 210,
                          }}
                          formControlProps={{
                            height: 'auto',
                          }}
                          menuItemsAndRenderProps={{
                            //Matching css props from Status
                            fontWeight: '600',
                            color: theme.palette.textOrIcon.onBackground,
                            fontSize: '1rem',
                            letterSpacing: '0.00938em',
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}

              {enrollments.length === 0 && <NoResultsFound />}
            </TableBody>
            {pagination && pagination?.totalCount > defaultPageSize ? (
              <TableFooterPagination
                count={pagination.totalCount}
                onChangePage={(
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  _: React.MouseEvent<HTMLButtonElement> | null,
                  newPage: number
                ) => handleChangePage?.(newPage)}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={pagination.page - 1}
                rowsPerPage={pagination.pageSize}
                colSpan={tableHeaders.length}
              />
            ) : null}
          </Table>
        </TableContainer>
      ) : (
        <EmptyPage
          message={
            <Typography variant="subtitle1" component="p">
              {t(
                'Enrollments.Message.NoEnrollmentsData',
                'No enrollments for this program.'
              )}
            </Typography>
          }
        />
      )}
    </>
  )
}

export default EnrollmentsTable

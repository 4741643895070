import Box from '@mui/material/Box'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import PaymentSelection from '../Elements/PaymentSelection'
import BasicModal from './BasicModal'
import CreditCard from '../../Images/credit_92x61.svg'
import CreditCardSelected from '../../Images/credit-selected_92x61.svg'
import BankAccount from '../../Images/bank_76x72.svg'
import BankAccountSelected from '../../Images/bank-selected_76x72.svg'
import { TextButtonVariant } from '../Buttons/TextButton'
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import {
  Address,
  CashAmount,
  PaymentStartRequestBodyForEnrollments,
  PaymentStartRequestBodyForTypeEnum,
  PaymentMethodKey,
  PaymentResponse,
  PaymentStatusKey,
} from '../../swagger'
import AddressForm from '../Address/AddressForm'
import AdjacentLabels from '../Labels/AdjacentLabels'
import { dateToSlashString } from '../../utils/dateUtility'
import CheckCircle from '@mui/icons-material/CheckCircle'
import getLocaleCurrencyForAmount from '../../utils/getLocaleCurrencyForAmount'
import { TextFieldVariant } from '../Interfaces/FieldProps'
import { FieldValidity } from '../Interfaces/FieldValidity'
import { isEmpty } from '../../utils/stringUtility'
import { amountFromInput } from '../../utils/amountFromInput'
import {
  addressApi,
  extractedErrorObject,
  paymentsApi,
} from '../../api/swagger'
import {
  startPayment,
  updatePaymentAddress,
  payByAch,
} from '../../api/payments'
import ErrorAlert from '../Alerts/ErrorAlert'
import AcceptHosted, {
  TransactionResponse,
} from '../Account/Billing/AcceptHosted'
import { webhooksApi } from '../../api/swagger'
import { getAmountFromLocaleCurrency } from '../../utils/getAmountFromLocalCurrency'
import useValidationMessages from '../../hooks/useValidationMessages'
import { styled } from '@mui/system'
import InvalidAddress, {
  InvalidAddressVariant,
} from '../Address/InvalidAddress'
import AddressSelect from '../Address/AddressSelect'
import { useCountryContext } from '../Context/CountryContext'
import { LoadingContext } from '../Context/LoadingContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'

const BoldText = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.primary.main,
}))

const LabelText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  lineHeight: `${theme.spacing(4)}`,
}))

export enum PaymentModalVariant {
  Enrollment = 'enrollment',
  Licensing = 'licensing',
}

interface AchDiscount {
  /** Amount of the discount provided. */
  amount: number
  /** Number of times to apply the ach discount. Determined by # Unpaid Programs - # Applied Discounts */
  applyDiscountTimes: number
  /** Choose whether to display message on the Bank Transfer option */
  displayMessage?: boolean
}

interface PaymentModalProps {
  isOpen: boolean
  onClose: () => void
  variant?: PaymentModalVariant
  unpaidEnrollments?: PaymentStartRequestBodyForEnrollments[]
  programKey?: number
  amountDue: CashAmount
  achDiscount: AchDiscount
}

enum PaymentModalSteps {
  ChooseMethodOfPayment,
  EnterAmount,
  EnterBillingAddress,
  EnterPaymentInformation,
  PaymentComplete,
  PaymentCompleteZeroDue,
}

enum PaymentBillingAddressValidationSteps {
  ValidatingAddress,
  UnverifiedAddress,
  SelectValidAddress,
  DeniedAddress,
}

enum AmountSelection {
  TotalAmountDue = 'totalAmountDue',
  DifferentAmount = 'differentAmount',
}

enum validationMessagesEnum {
  defaultMessage = 'defaultMessage',
  accountNumber = 'accountNumber',
  RoutingNumber = 'RoutingNumber',
  bankAccountNumber = 'bankAccountNumber',
  confirmBankAccountNumber = 'confirmBankAccountNumber',
}

const initialAmountOption = AmountSelection.TotalAmountDue

const PaymentModal: React.FC<PaymentModalProps> = ({
  isOpen,
  onClose: yesCancel,
  variant = PaymentModalVariant.Enrollment,
  unpaidEnrollments,
  programKey = -1,
  amountDue,
  achDiscount,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const isLicensing = variant === PaymentModalVariant.Licensing
  /** Value is set to `undefined` since the address `validation` step has not been started  */
  const initialValueForPaymentBillingAddress = undefined
  const { countryOptions } = useCountryContext()
  const { PaymentModal } = useLoadingIds()
  const { addLoadingIds } = React.useContext(LoadingContext)
  /** State Objects */
  const [isCardSelected, setIsCardSelected] = useState(false)
  const [isBankSelected, setIsBankSelected] = useState(isLicensing)
  const [
    requiredBillingInformationPresent,
    setRequiredBillingInformationPresent,
  ] = useState(false)

  const [
    requiredPaymentInformationPresent,
    setRequiredPaymentInformationPresent,
  ] = useState(false)

  const [cancelProcess, setCancelProcess] = useState(false)
  const [achAllowed, setAchAllowed] = useState(false)

  const [
    stepToValidatePaymentBillingAddress,
    setStepToValidatePaymentBillingAddress,
  ] = useState<PaymentBillingAddressValidationSteps | undefined>()

  const additionalWarnings = [
    t(
      'PaymentModal.IsBankSelected.AdditionalWarning.Checking.Account',
      'Make sure the bank account you use is a "CHECKING" account'
    ),
    t(
      'PaymentModal.IsBankSelected.AdditionalWarning.Double.Check',
      'Please double-check your account number'
    ),
    t(
      'PaymentModal.IsBankSelected.AdditionalWarning.Time.Founds.Available',
      'NOTE that it may take up to 10 business days for funds to actually transfer'
    ),
  ]

  const emptyAddressInformation = {
    locationName: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    zip: '',
    countryCode: 'US',
  }

  useEffect(() => {
    if (!isLicensing) {
      const fetchAchAllowed = async () => {
        try {
          const options =
            await paymentsApi.fetchEnrollmentBillingPaymentOptions({})
          setAchAllowed(!!options.achAllowed)
        } catch (err) {
          const errorObject = (await extractedErrorObject(err)) ?? {
            code: 'UnknownError',
            message:
              (err as unknown as Error).message ??
              'Failed to fetch program details.',
          }
          setErrorMessage(errorObject.message)
          setSubmissionInProgress(false)
        }
      }
      fetchAchAllowed()
    }
  }, [isLicensing, programKey, t, achAllowed])

  // If there is no amount due for an Enrollment Payment, then we want skip to the end of the payment flow
  const shouldShortCircuitPaymentFlow = !isLicensing && !amountDue.amount

  const [step, setStep] = useState(
    shouldShortCircuitPaymentFlow
      ? PaymentModalSteps.PaymentCompleteZeroDue
      : PaymentModalSteps.ChooseMethodOfPayment
  )

  const [taxes, setTaxes] = useState(0)

  // Licensing does not contain tax, so the total is just the amountDue amount
  const total = !isLicensing ? amountDue.amount + taxes : amountDue.amount

  const [validAddresses, setValidAddresses] = useState<Address[]>([])
  const [billingAddressInformation, setBillingAddressInformation] = useState<{
    fullName: string
    address: Address
  }>({
    fullName: '',
    address: emptyAddressInformation,
  })

  const [bankPaymentInformation, setBankPaymentInformation] = useState({
    firstName: '',
    lastName: '',
    routingNumber: '', // Most banks are 9 numbers
    bankAccountNumber: '',
    confirmBankAccountNumber: '',
  })

  const [updateStateOptions, setUpdateStateOptions] = useState(false)
  const [amountOption, setAmountOption] = useState(initialAmountOption)
  const [amountOfPayment, setAmountOfPayment] = useState('')
  const [isAmountOfPaymentValid, setIsAmountOfPaymentValid] =
    useState<FieldValidity>({
      input: true,
      beforeMax: true,
    })

  useEffect(() => {
    if (updateStateOptions) {
      addLoadingIds([PaymentModal.fetchStates])
      setUpdateStateOptions(false)
    }
  }, [PaymentModal.fetchStates, addLoadingIds, updateStateOptions])

  const [bankAccountMatch, setBankAccountMatch] = useState<FieldValidity>({
    input: true,
  })

  const [validRoutingNumber, setValidRoutingNumber] = useState<FieldValidity>({
    input: true,
  })

  const validationMessageMap = useValidationMessages([
    {
      field: validationMessagesEnum.defaultMessage,
      message: t(
        'PaymentModal.EnterPaymentInformation.Error.Blocking.Invalid.Routing.Number',
        'The ACH routing number provider encountered an error processing your payment. Please enter a valid routing number.'
      ),
    },
    {
      field: validationMessagesEnum.accountNumber,
      message: t(
        'PaymentModal.EnterPaymentInformation.Error.Blocking.Accounts.Not.Match',
        'The ACH payment provider encountered an error processing your payment. Please enter the same ach account twice.'
      ),
    },
    {
      field: validationMessagesEnum.RoutingNumber,
      message: t(
        'PaymentModal.ValidationMessage.MustBeValidRoutingNumber',
        'Routing Number must be a valid Routing number.'
      ),
    },
    {
      field: validationMessagesEnum.bankAccountNumber,
      message: t(
        'PaymentModal.ValidationMessage.BankAccountMustMatch',
        'Accounts must match.'
      ),
    },
    {
      field: validationMessagesEnum.confirmBankAccountNumber,
      message: t(
        'PaymentModal.ValidationMessage.BankAccountConfirmMustMatch',
        'Accounts must match.'
      ),
    },
  ])

  const defaultMessageError = validationMessageMap.get(
    validationMessagesEnum.defaultMessage
  ) as string
  const bankAccountsDoesNotMatch = validationMessageMap.get(
    validationMessagesEnum.accountNumber
  ) as string
  const routingNumber = validationMessageMap.get(
    validationMessagesEnum.RoutingNumber
  ) as string
  const bankAccountNumberMatch = validationMessageMap.get(
    validationMessagesEnum.bankAccountNumber
  ) as string
  const confirmBankAccountNumberMatch = validationMessageMap.get(
    validationMessagesEnum.confirmBankAccountNumber
  ) as string

  const [paymentInformation, setPaymentInformation] =
    useState<PaymentResponse>()

  const [errorMessage, setErrorMessage] = useState('')
  const [errorProcessingPayment, setErrorProcessingPayment] = useState(false)
  const [allowRestart, setAllowRestart] = useState(true)

  const [submissionInProgress, setSubmissionInProgress] = useState(false)

  /** Methods */
  const resetFields = () => {
    setAllowRestart(true)
    setErrorProcessingPayment(false)
    setErrorMessage('')
    setAmountOption(initialAmountOption)
    setAmountOfPayment('')
    setTaxes(0)
    setIsCardSelected(false)
    setIsBankSelected(isLicensing)
    setIsAmountOfPaymentValid({ input: true, beforeMax: true })
    setSubmissionInProgress(false)
    setBillingAddressInformation({
      fullName: '',
      address: emptyAddressInformation,
    })
    setBankPaymentInformation({
      firstName: '',
      lastName: '',
      routingNumber: '',
      bankAccountNumber: '',
      confirmBankAccountNumber: '',
    })
  }

  const reloadPage = () => {
    setStep(PaymentModalSteps.ChooseMethodOfPayment)
    resetFields()
  }

  const onCancel = () => {
    setErrorMessage('')
    setCancelProcess(true)
  }

  const afterClose = () => {
    setStep(PaymentModalSteps.ChooseMethodOfPayment)
    resetFields()
    setCancelProcess(false)
  }

  const updateBillingPaymentAddress = async (selectedAddress?: Address) => {
    if (!!paymentInformation) {
      try {
        const updatedPaymentInformation = await updatePaymentAddress({
          paymentKey: paymentInformation.payment.paymentKey,
          body: selectedAddress ?? billingAddressInformation.address,
        })

        setTaxes(updatedPaymentInformation.payment.tax.amount)
        setPaymentInformation(updatedPaymentInformation)
        setErrorMessage('')
        setStep(PaymentModalSteps.EnterPaymentInformation)
        setStepToValidatePaymentBillingAddress(
          initialValueForPaymentBillingAddress
        )
        setSelectedValidAddress(initialSelectedValidAddress)
      } catch (e) {
        const errorObject = (await extractedErrorObject(e)) ?? {
          code: 'Unknown',
          message: (e as unknown as Error).message ?? defaultErrorMessage,
        }
        setErrorMessage(errorObject.message)
      } finally {
        setSubmissionInProgress(false)
      }
    }
  }

  const validateAddress = async (): Promise<boolean> => {
    try {
      setStepToValidatePaymentBillingAddress(
        PaymentBillingAddressValidationSteps.ValidatingAddress
      )
      const result = await addressApi.validateAddress({
        body: billingAddressInformation.address,
        disableGeocoding: false,
      })
      if (result.length === 0) {
        setSubmissionInProgress(false)
        setStepToValidatePaymentBillingAddress(
          PaymentBillingAddressValidationSteps.UnverifiedAddress
        )

        return false
      }
      /**
       * If we only have 1 valid result and it matches what the
       * user typed then skip the SelectValidAddress step
       */
      if (result.length === 1) {
        const validatedAddress = result[0]
        const isExactMatch =
          validatedAddress.streetAddress1 ===
            billingAddressInformation.address.streetAddress1 &&
          (validatedAddress.streetAddress2 ?? '') ===
            (billingAddressInformation.address.streetAddress2 ?? '') &&
          validatedAddress.city === billingAddressInformation.address.city &&
          validatedAddress.state === billingAddressInformation.address.state &&
          validatedAddress.countryCode ===
            billingAddressInformation.address.countryCode &&
          validatedAddress.zip === billingAddressInformation.address.zip

        if (isExactMatch) {
          return true
        }
      }

      setSubmissionInProgress(false)
      setValidAddresses(result)
      setStepToValidatePaymentBillingAddress(
        PaymentBillingAddressValidationSteps.SelectValidAddress
      )
      setSelectedValidAddress(JSON.stringify(result[0]))
      setRequiredBillingInformationPresent(true)

      return false
    } catch (err) {
      const errorObject = (await extractedErrorObject(err)) ?? {
        code: 'UnknownError',
        message:
          (err as unknown as Error).message ??
          'Failed to validate address at this time.',
      }
      setErrorMessage(errorObject.message)
      setStep(PaymentModalSteps.EnterBillingAddress)

      return false
    }
  }

  const geocodeAddress = async (): Promise<boolean> => {
    setStepToValidatePaymentBillingAddress(
      PaymentBillingAddressValidationSteps.ValidatingAddress
    )
    try {
      const result = await addressApi.validateAddress({
        body: billingAddressInformation.address,
        disableAddressVerification: true,
      })

      if (result.length === 0) {
        setStepToValidatePaymentBillingAddress(
          PaymentBillingAddressValidationSteps.DeniedAddress
        )
        return false
      }
      return true
    } catch (err) {
      const errorObject = (await extractedErrorObject(err)) ?? {
        code: 'UnknownError',
        message:
          (err as unknown as Error).message ??
          'Failed to validate address at this time.',
      }
      setErrorMessage(errorObject.message)
      setStepToValidatePaymentBillingAddress(
        PaymentBillingAddressValidationSteps.UnverifiedAddress
      )

      return false
    }
  }

  const handleFormSubmit = async (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault()
    setSubmissionInProgress(true)
    if (cancelProcess || errorProcessingPayment) {
      yesCancel()
    } else if (step === PaymentModalSteps.ChooseMethodOfPayment) {
      // Skip Enter Amount step if not the licensing payment flow
      if (!isLicensing) {
        try {
          const resultingPayment = await startPayment({
            body: {
              _for: {
                type: PaymentStartRequestBodyForTypeEnum.Enrollment,
                // We should always have enrollments if we are here, but better safely provide the default empty array.
                enrollments: unpaidEnrollments ?? [],
              },
              amount: isBankSelected ? total - totalAchDiscount : total,
              method: isBankSelected
                ? PaymentMethodKey.Ach
                : PaymentMethodKey.Cc,
            },
          })
          setPaymentInformation(resultingPayment)
          setStep(PaymentModalSteps.EnterBillingAddress)
        } catch (e) {
          const errorObject = (await extractedErrorObject(e)) ?? {
            code: 'Unknown',
            message: (e as unknown as Error).message ?? defaultErrorMessage,
          }
          setErrorMessage(errorObject.message)
        } finally {
          setSubmissionInProgress(false)
        }
      } else {
        setStep(PaymentModalSteps.EnterAmount)
        setSubmissionInProgress(false)
      }
    } else if (step === PaymentModalSteps.EnterAmount) {
      if (programKey > 0) {
        try {
          const resultingPayment = await startPayment({
            body: {
              _for: {
                type: PaymentStartRequestBodyForTypeEnum.Licensing,
                programKey: programKey,
              },
              amount: !!amountOfPayment
                ? getAmountFromLocaleCurrency(amountOfPayment)
                : total,
              method: PaymentMethodKey.Ach,
            },
          })
          setPaymentInformation(resultingPayment)
          setErrorMessage('')
          setStep(PaymentModalSteps.EnterBillingAddress)
        } catch (e) {
          const errorObject = (await extractedErrorObject(e)) ?? {
            code: 'Unknown',
            message: (e as unknown as Error).message ?? defaultErrorMessage,
          }
          setErrorMessage(errorObject.message)
        } finally {
          setSubmissionInProgress(false)
        }
      }
    } else if (
      step === PaymentModalSteps.EnterBillingAddress &&
      (stepToValidatePaymentBillingAddress ===
        PaymentBillingAddressValidationSteps.UnverifiedAddress ||
        stepToValidatePaymentBillingAddress ===
          PaymentBillingAddressValidationSteps.SelectValidAddress ||
        /** the validation is starting */
        stepToValidatePaymentBillingAddress ===
          initialValueForPaymentBillingAddress)
    ) {
      /**
       * Address only should be validated if stepToValidatePaymentBillingAddress is undefined
       * which mean the address was first entered and not validated yet.
       */
      if (
        stepToValidatePaymentBillingAddress ===
        initialValueForPaymentBillingAddress
      ) {
        const isValidAddress = await validateAddress()
        if (!isValidAddress) {
          return
        }
      }

      if (
        stepToValidatePaymentBillingAddress ===
          PaymentBillingAddressValidationSteps.UnverifiedAddress &&
        !geocodeAddress
      ) {
        return
      }

      if (
        stepToValidatePaymentBillingAddress ===
        PaymentBillingAddressValidationSteps.SelectValidAddress
      ) {
        setStepToValidatePaymentBillingAddress(
          PaymentBillingAddressValidationSteps.ValidatingAddress
        )
        const selectedAddress = {
          ...billingAddressInformation,
          address: {
            ...JSON.parse(selectedValidAddress),
            countryCode: 'US',
            streetAddress2:
              billingAddressInformation.address.streetAddress2 ?? '',
          },
        }
        setBillingAddressInformation(selectedAddress)

        await updateBillingPaymentAddress(selectedAddress.address)
      }

      /**
       * The address  was already up for this step (SelectValidAddress)
       * since we use a different address than the billingAddressInformation
       */
      if (
        stepToValidatePaymentBillingAddress !==
        PaymentBillingAddressValidationSteps.SelectValidAddress
      ) {
        await updateBillingPaymentAddress()
      }
    } else if (step === PaymentModalSteps.EnterPaymentInformation) {
      const doesTheBankAccountNumberMatchTheConfirmBankAccountNumber =
        bankPaymentInformation.bankAccountNumber ===
        bankPaymentInformation.confirmBankAccountNumber

      setBankAccountMatch({
        ...bankAccountMatch,
        input: doesTheBankAccountNumberMatchTheConfirmBankAccountNumber,
      })

      if (!doesTheBankAccountNumberMatchTheConfirmBankAccountNumber) {
        setSubmissionInProgress(false)
        setErrorMessage(bankAccountsDoesNotMatch)
        return
      }

      let isValidRoutingNumber
      try {
        if (doesTheBankAccountNumberMatchTheConfirmBankAccountNumber) {
          const validRoutingNumber = await paymentsApi.validateAchRoutingNumber(
            {
              routingNumber: bankPaymentInformation.routingNumber,
            }
          )
          isValidRoutingNumber = validRoutingNumber.validRoutingNumber
        }
      } catch (e) {
        const errorObject = (await extractedErrorObject(e)) ?? {
          code: 'Unknown',
          message: (e as unknown as Error).message ?? defaultErrorMessage,
        }
        setErrorMessage(errorObject.message)
      }

      if (
        !isValidRoutingNumber &&
        doesTheBankAccountNumberMatchTheConfirmBankAccountNumber
      ) {
        setSubmissionInProgress(false)
        setErrorMessage(defaultMessageError)
        setValidRoutingNumber({
          ...validRoutingNumber,
          input: isValidRoutingNumber as boolean,
        })
      }

      if (isValidRoutingNumber) {
        setValidRoutingNumber({
          ...validRoutingNumber,
          input: isValidRoutingNumber as boolean,
        })
      }

      if (!!paymentInformation) {
        if (
          isBankSelected &&
          doesTheBankAccountNumberMatchTheConfirmBankAccountNumber &&
          isValidRoutingNumber
        ) {
          try {
            const result = await payByAch({
              paymentKey: paymentInformation.payment.paymentKey,
              body: {
                routing: bankPaymentInformation.routingNumber,
                account: bankPaymentInformation.bankAccountNumber,
              },
            })

            if (result instanceof Error) {
              throw result
            } else {
              const achSuccess =
                PaymentStatusKey.Paid === result.payment.paymentStatusKey ||
                PaymentStatusKey.Pend === result.payment.paymentStatusKey

              if (achSuccess) {
                setTaxes(result.payment.tax.amount)
                setPaymentInformation(result)
                setErrorMessage('')
                setStep(PaymentModalSteps.PaymentComplete)
              } else {
                setErrorMessage(defaultAchErrorMessage)
                setErrorProcessingPayment(true)
                setAllowRestart(false)
              }
            }
          } catch (e) {
            const errorObject = (await extractedErrorObject(e)) ?? {
              code: 'Unknown',
              message: defaultAchErrorMessage,
            }
            const isBlockingError = errorObject.code === 'ThirdPartyFailure'
            setErrorMessage(
              isBlockingError
                ? contactCustomerSupportMessage
                : errorObject.message
            )
            setErrorProcessingPayment(true)
            setAllowRestart(false)
          } finally {
            setSubmissionInProgress(false)
          }
        }
      }
    } else {
      // Cleanup and close
      yesCancel()
      setSubmissionInProgress(false)
    }
  }

  const backToBillingAddress = () => {
    setBankPaymentInformation({
      firstName: '',
      lastName: '',
      routingNumber: '',
      bankAccountNumber: '',
      confirmBankAccountNumber: '',
    })
    setErrorMessage('')
    setStep(PaymentModalSteps.EnterBillingAddress)
    setSelectedValidAddress(initialSelectedValidAddress)
    setStepToValidatePaymentBillingAddress(initialValueForPaymentBillingAddress)
  }

  const backToMethodOfPayment = () => {
    if (isLicensing) {
      setAmountOfPayment('')
    } else {
      setTaxes(0)
      setBillingAddressInformation({
        fullName: '',
        address: emptyAddressInformation,
      })
    }
    setErrorMessage('')
    setStep(PaymentModalSteps.ChooseMethodOfPayment)
    setSelectedValidAddress(initialSelectedValidAddress)
    setStepToValidatePaymentBillingAddress(initialValueForPaymentBillingAddress)
  }

  const backToEnterAmount = () => {
    setBillingAddressInformation({
      fullName: '',
      address: emptyAddressInformation,
    })
    setErrorMessage('')
    setStep(PaymentModalSteps.EnterAmount)
  }

  /** Value must be JSON parse-able which is why it is a string */
  const initialSelectedValidAddress = 'null'
  /** Store a stringified version of the address in state to work with radio selection. */
  const [selectedValidAddress, setSelectedValidAddress] = useState(
    initialSelectedValidAddress
  )
  const handleAddressSelectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedValidAddress(event.target.value)
    setRequiredBillingInformationPresent(true)
  }
  const DialogActions = () => {
    const maximumPaymentAmount = 10000
    let primaryButtonLabel = ContainedButtonVariant.Next
    let secondaryButtonLabel: TextButtonVariant | undefined =
      TextButtonVariant.Cancel
    let tertiaryButtonLabel: TextButtonVariant | undefined =
      TextButtonVariant.Cancel
    let secondaryClick = onCancel
    let tertiaryClick = onCancel
    let disablePrimaryButton = false
    if (errorProcessingPayment) {
      primaryButtonLabel = ContainedButtonVariant.Close
      secondaryButtonLabel = undefined
      tertiaryButtonLabel = undefined
      disablePrimaryButton = false
    } else {
      if (!cancelProcess) {
        switch (step) {
          case PaymentModalSteps.EnterAmount:
            primaryButtonLabel = ContainedButtonVariant.Next
            secondaryButtonLabel = TextButtonVariant.Back
            tertiaryButtonLabel = TextButtonVariant.Cancel
            disablePrimaryButton =
              amountOption !== initialAmountOption
                ? !isAmountOfPaymentValid.input ||
                  !isAmountOfPaymentValid.beforeMax ||
                  !amountOfPayment ||
                  +amountOfPayment > maximumPaymentAmount
                : amountDue.amount > maximumPaymentAmount

            secondaryClick = backToMethodOfPayment
            tertiaryClick = onCancel
            break
          case PaymentModalSteps.PaymentComplete:
          case PaymentModalSteps.PaymentCompleteZeroDue:
            primaryButtonLabel = ContainedButtonVariant.Close
            secondaryButtonLabel = undefined
            tertiaryButtonLabel = undefined
            disablePrimaryButton = false
            break
          case PaymentModalSteps.EnterPaymentInformation:
            primaryButtonLabel = ContainedButtonVariant.Pay
            secondaryButtonLabel = TextButtonVariant.Back
            tertiaryButtonLabel = TextButtonVariant.Cancel
            // Update for isCardSelect when implementing https://projekt202.atlassian.net/browse/CCP1-2335
            disablePrimaryButton = isCardSelected
              ? false
              : !requiredPaymentInformationPresent
            secondaryClick = backToBillingAddress
            tertiaryClick = onCancel
            break
          case PaymentModalSteps.EnterBillingAddress:
            primaryButtonLabel = ContainedButtonVariant.Next
            secondaryButtonLabel = TextButtonVariant.Back
            tertiaryButtonLabel = TextButtonVariant.Cancel
            disablePrimaryButton = !requiredBillingInformationPresent
            secondaryClick = isLicensing
              ? backToEnterAmount
              : backToMethodOfPayment
            tertiaryClick = onCancel
            switch (stepToValidatePaymentBillingAddress) {
              case PaymentBillingAddressValidationSteps.UnverifiedAddress:
                primaryButtonLabel = ContainedButtonVariant.UseAnyway
                secondaryButtonLabel = TextButtonVariant.Back
                tertiaryButtonLabel = TextButtonVariant.Cancel
                disablePrimaryButton = !requiredBillingInformationPresent
                secondaryClick = isLicensing
                  ? backToBillingAddress
                  : backToMethodOfPayment
                tertiaryClick = onCancel
                break
              case PaymentBillingAddressValidationSteps.SelectValidAddress:
                primaryButtonLabel = ContainedButtonVariant.Confirm
                secondaryButtonLabel = TextButtonVariant.Back
                tertiaryButtonLabel = TextButtonVariant.Cancel
                disablePrimaryButton = !requiredBillingInformationPresent
                secondaryClick = isLicensing
                  ? backToBillingAddress
                  : backToMethodOfPayment
                tertiaryClick = onCancel
                break
            }
            break
          case PaymentModalSteps.ChooseMethodOfPayment:
          default:
            primaryButtonLabel = ContainedButtonVariant.Next
            secondaryButtonLabel = TextButtonVariant.Cancel
            disablePrimaryButton = !isBankSelected && !isCardSelected
            tertiaryButtonLabel = undefined
            secondaryClick = onCancel
        }
      } else {
        primaryButtonLabel = ContainedButtonVariant.YesCancel
        secondaryButtonLabel = TextButtonVariant.NoGoBack
        tertiaryButtonLabel = undefined
        secondaryClick = () => setCancelProcess(false)
        disablePrimaryButton = false
      }
    }
    return (
      !(
        isCardSelected && step === PaymentModalSteps.EnterPaymentInformation
      ) && (
        <ActionButtons
          primaryButtonLabel={primaryButtonLabel}
          disablePrimaryButton={submissionInProgress || disablePrimaryButton}
          secondaryClick={secondaryClick}
          tertiaryClick={tertiaryClick}
          secondaryButtonLabel={secondaryButtonLabel}
          tertiaryButtonLabel={tertiaryButtonLabel}
        />
      )
    )
  }

  const DialogTitle = () => {
    if (!cancelProcess) {
      switch (step) {
        case PaymentModalSteps.EnterAmount:
          return t('PaymentModal.Heading.EnterAmount', 'Enter Amount')
        case PaymentModalSteps.PaymentComplete:
          return isCardSelected
            ? t(
                'PaymentModal.Heading.ThankYouForPayment',
                'Thank you for your payment'
              )
            : t(
                'PaymentModal.Heading.PaymentSubmitted',
                'Your payment has been submitted'
              )
        case PaymentModalSteps.PaymentCompleteZeroDue:
          return t(
            'PaymentModal.Heading.StudentsEnrolled',
            'Students successfully enrolled'
          )
        case PaymentModalSteps.EnterPaymentInformation:
          return t(
            'PaymentModal.Heading.EnterPaymentInformation',
            'Enter Payment Information'
          )
        case PaymentModalSteps.EnterBillingAddress:
          return t(
            'PaymentModal.Heading.EnterBillingAddress',
            'Enter Billing Address'
          )
        case PaymentModalSteps.ChooseMethodOfPayment:
        default:
          return t(
            'PaymentModal.Heading.ChooseMethodOfPayment',
            'Choose a Method of Payment'
          )
      }
    } else {
      return t(
        'PaymentModal.Heading.Cancel',
        'Are you sure you want to cancel this payment process?'
      )
    }
  }

  const CreditCardImage = () => (
    <img
      alt={t('PaymentModal.AltText.CardOption', 'Credit or Debit Card')}
      src={isCardSelected ? CreditCardSelected : CreditCard}
    />
  )

  const BankAccountImage = () => (
    <img
      alt={t('PaymentModal.AltText.BankOption', 'Bank Account Transfer')}
      src={isBankSelected ? BankAccountSelected : BankAccount}
    />
  )

  const selectCreditOrDebit = () => {
    setIsCardSelected(true)
    setIsBankSelected(false)
  }

  const selectBankAccount = () => {
    setIsCardSelected(false)
    setIsBankSelected(true)
  }

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (step === PaymentModalSteps.EnterBillingAddress) {
      /** We trim the input value to account for user mistake. */
      setBillingAddressInformation({
        ...billingAddressInformation,
        [event.target.name]: event.target.value.trim(),
      })
    }
    // Only other step with fields is Payment Information
    else {
      // Then we check selection
      if (isBankSelected) {
        if (event.target.name === 'accountType') {
          setBankPaymentInformation({
            ...bankPaymentInformation,
          })
        }
      }
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (step === PaymentModalSteps.EnterBillingAddress) {
      if (event.target.name === 'fullName') {
        setBillingAddressInformation({
          ...billingAddressInformation,
          [event.target.name]: event.target.value,
        })
        return
      }
      /** Clear state field when country changes to avoid MUI warning. */
      if (event.target.name === 'countryCode') {
        setBillingAddressInformation({
          ...billingAddressInformation,
          address: {
            ...billingAddressInformation.address,
            [event.target.name]: event.target.value,
            state: '',
          },
        })
        if (!!event.target.value) {
          setUpdateStateOptions(true)
        }
      }
      setBillingAddressInformation({
        ...billingAddressInformation,
        address: {
          ...billingAddressInformation.address,
          [event.target.name]: event.target.value,
        },
      })
    }
    // Only other step with fields is Payment Information
    else {
      // Then we check selection
      if (isBankSelected) {
        if (
          event.target.name === 'routingNumber' ||
          event.target.name === 'bankAccountNumber' ||
          event.target.name === 'confirmBankAccountNumber'
        ) {
          const value = event.target.value.replace(/[^0-9]/g, '')
          setBankPaymentInformation({
            ...bankPaymentInformation,
            [event.target.name]: value,
          })
        } else {
          setBankPaymentInformation({
            ...bankPaymentInformation,
            [event.target.name]: event.target.value,
          })
        }
      }
    }
  }

  const onSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmountOption(event.target.value as AmountSelection)
  }

  const validatePaymentAmount = (amount: string) => {
    setIsAmountOfPaymentValid({
      ...isAmountOfPaymentValid,
      input: isEmpty(amount) || +amount > 0,
      beforeMax: +amount <= total,
    })
  }

  const handleAmountInputBlur = (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const refinedValue = amountFromInput(event.target.value)
    setAmountOfPayment(
      getLocaleCurrencyForAmount(
        parseFloat(refinedValue),
        amountDue.currencyCode
      )
    )
    validatePaymentAmount(refinedValue)
  }

  const handleAmountOfPaymentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const refinedValue = amountFromInput(event.target.value)
    setAmountOfPayment(event.target.value)
    validatePaymentAmount(refinedValue)
  }

  /** Other Objects */
  const defaultErrorMessage = t(
    'PaymentModal.ErrorMessage.Default',
    'This payment system is temporarily unavailable. Please cancel the payment process and try again later.'
  )

  const defaultAchErrorMessage = t(
    'PaymentModal.Error.PayByAch',
    'The ACH payment provider encountered an error processing the payment. Please verify your billing address and payment information and try again. If the problem persists please contact Customer Support.'
  )

  const contactCustomerSupportMessage = t(
    'PaymentModal.EnterPaymentInformation.Error.Blocking',
    'The ACH payment provider encountered an error processing your payment. Please contact Customer Support.'
  )

  const defaultCurrencyCode = 'USD'

  const sharedFieldProps = {
    onBlur: handleInputBlur,
    onChange: handleInputChange,
  }

  const totalAchDiscount = achDiscount.amount * achDiscount.applyDiscountTimes

  /** Hooks */
  useEffect(() => {
    const beginPaymentForZeroAmountDue = async () => {
      try {
        const resultingPayment = await startPayment({
          body: {
            _for: {
              type: PaymentStartRequestBodyForTypeEnum.Enrollment,
              enrollments: unpaidEnrollments ?? [],
            },
            amount: amountDue.amount,
            method: PaymentMethodKey.NA,
          },
        })
        setPaymentInformation(resultingPayment)
      } catch (e) {
        const errorObject = (await extractedErrorObject(e)) ?? {
          code: 'Unknown',
          message: (e as unknown as Error).message ?? defaultErrorMessage,
        }
        setStep(PaymentModalSteps.ChooseMethodOfPayment)
        setErrorMessage(errorObject.message)
      }
    }

    if (shouldShortCircuitPaymentFlow && isOpen) {
      beginPaymentForZeroAmountDue()
    }
  }, [
    amountDue.amount,
    defaultErrorMessage,
    isOpen,
    shouldShortCircuitPaymentFlow,
    unpaidEnrollments,
  ])

  useEffect(() => {
    if (step === PaymentModalSteps.EnterBillingAddress) {
      if (
        !!billingAddressInformation.fullName &&
        !!billingAddressInformation.address.streetAddress1 &&
        !!billingAddressInformation.address.city &&
        !!billingAddressInformation.address.state &&
        !!billingAddressInformation.address.zip &&
        !!billingAddressInformation.address.countryCode
      ) {
        setRequiredBillingInformationPresent(true)
      } else {
        setRequiredBillingInformationPresent(false)
      }

      if (!billingAddressInformation.address.countryCode && isBankSelected) {
        setBillingAddressInformation({
          ...billingAddressInformation,
          address: {
            ...billingAddressInformation.address,
            countryCode: 'US',
          },
        })
      }
    }

    if (
      step === PaymentModalSteps.EnterPaymentInformation &&
      !!bankPaymentInformation.firstName &&
      !!bankPaymentInformation.lastName &&
      !!bankPaymentInformation.bankAccountNumber &&
      !!bankPaymentInformation.confirmBankAccountNumber &&
      !!bankPaymentInformation.routingNumber &&
      bankPaymentInformation.routingNumber.length === 9 // Always require length of 9 for routing number.
    ) {
      setRequiredPaymentInformationPresent(true)
    } else {
      setRequiredPaymentInformationPresent(false)
    }
  }, [step, bankPaymentInformation, billingAddressInformation, isBankSelected])

  const formRef = useRef<HTMLFormElement>(null)

  const DialogContent = () => {
    if (errorProcessingPayment) {
      return (
        <ErrorAlert
          error={errorMessage}
          button={
            allowRestart ? (
              <Button
                sx={{ color: theme.palette.error.main }}
                onClick={reloadPage}
              >
                {t('TeamInvite.Button.Restart', 'Restart')}
              </Button>
            ) : undefined
          }
        />
      )
    }

    if (!cancelProcess) {
      switch (step) {
        case PaymentModalSteps.EnterAmount:
          return (
            <FormControl>
              <RadioGroup
                aria-label="amount selection"
                name="amount selection"
                value={amountOption}
                defaultValue={amountOption}
                onChange={onSelectionChange}
              >
                <FormControlLabel
                  key={AmountSelection.TotalAmountDue}
                  value={AmountSelection.TotalAmountDue}
                  control={
                    <Radio
                      sx={{
                        color: theme.palette.textOrIcon.checkbox,
                      }}
                    />
                  }
                  label={
                    <AdjacentLabels
                      leftLabel={t(
                        'PaymentModal.Option.TotalAmountDue',
                        'Pay the total amount due'
                      )}
                      rightLabel={
                        getLocaleCurrencyForAmount(
                          amountDue.amount,
                          defaultCurrencyCode
                        ) + ' Does not include tax'
                      }
                    />
                  }
                />
                <FormControlLabel
                  key={AmountSelection.DifferentAmount}
                  value={AmountSelection.DifferentAmount}
                  control={
                    <Radio
                      sx={{
                        color: theme.palette.textOrIcon.checkbox,
                      }}
                    />
                  }
                  label={
                    <LabelText variant="subtitle1">
                      {t(
                        'PaymentModal.Option.DifferentAmount',
                        'Enter a different amount (Maximum $10,000). Does not include tax'
                      )}
                    </LabelText>
                  }
                />
              </RadioGroup>
              {amountOption === 'differentAmount' && (
                <TextField
                  id="amountOfPayment"
                  name="amountOfPayment"
                  variant={TextFieldVariant.Filled}
                  value={amountOfPayment}
                  label={t(
                    'PaymentModal.Field.AmountOfPayment',
                    'Amount of Payment'
                  )}
                  error={
                    !isAmountOfPaymentValid.input ||
                    !isAmountOfPaymentValid.beforeMax
                  }
                  onChange={handleAmountOfPaymentChange}
                  onBlur={handleAmountInputBlur}
                  helperText={
                    !isAmountOfPaymentValid.input
                      ? t(
                          'PaymentModal.ValidationMessage.GreaterThanZero',
                          'Amount of Payment must be greater than 0.'
                        )
                      : !isAmountOfPaymentValid.beforeMax
                      ? t(
                          'PaymentModal.ValidationMessage.LessThanOrEqualToTotal',
                          'Amount of Payment must be less than or equal to the total.'
                        )
                      : ''
                  }
                />
              )}
            </FormControl>
          )
        case PaymentModalSteps.PaymentComplete:
          return (
            <>
              <Typography variant="body1" align="center">
                {t(
                  'PaymentModal.Instructions.CheckInbox',
                  'Check your inbox for a receipt of this transaction.'
                )}
                &nbsp;
                {isBankSelected &&
                  t(
                    'PaymentModal.Instructions.BusinessDays',
                    'Please allow between 5-7 business days for processing. Our accounting staff will be in contact if there are any issues.'
                  )}
              </Typography>
              <LabelText variant="subtitle2" align="center">
                {t('PaymentModal.Label.DateSubmitted', 'Date Submitted')}
                <BoldText variant="body2">
                  {dateToSlashString(new Date())}
                </BoldText>
              </LabelText>
              <LabelText variant="subtitle2" align="center">
                {t('PaymentModal.Label.Amount', 'Amount')}
                <BoldText variant="body2">
                  {getLocaleCurrencyForAmount(
                    isBankSelected && !isLicensing
                      ? total - totalAchDiscount
                      : !!amountOfPayment
                      ? getAmountFromLocaleCurrency(amountOfPayment)
                      : total,
                    'USD'
                  )}
                </BoldText>
              </LabelText>
              <LabelText variant="subtitle2" align="center">
                {t('PaymentModal.Label.PaymentMethod', 'Payment Method')}
                <BoldText variant="body2">
                  {isCardSelected
                    ? t(
                        'PaymentModal.MethodOfPayment.CreditCard',
                        'Credit Card'
                      )
                    : t(
                        'PaymentModal.MethodOfPayment.BankAccountTransfer',
                        'Bank Account Transfer'
                      )}
                </BoldText>
              </LabelText>
            </>
          )
        case PaymentModalSteps.PaymentCompleteZeroDue:
          return (
            <>
              <Typography variant="body1" align="center">
                {t(
                  'PaymentModal.Instructions.EnrollmentEmail',
                  'Check your inbox for successful enrollment emails for each of your new programs.'
                )}
              </Typography>
              <LabelText variant="subtitle2" align="center">
                {t('PaymentModal.Label.DateConfirmed', 'Date Confirmed')}
                <BoldText variant="body2">
                  {dateToSlashString(new Date())}
                </BoldText>
              </LabelText>
            </>
          )
        case PaymentModalSteps.EnterPaymentInformation:
          return (
            <>
              <Box
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
              >
                <Box display="flex" flexDirection="row">
                  <Box pr={3}>
                    {!isLicensing ? (
                      <AdjacentLabels
                        leftLabel={t(
                          'PaymentModal.Label.EnrollmentFee',
                          'Membership Fee'
                        )}
                        rightLabel={getLocaleCurrencyForAmount(
                          isBankSelected
                            ? amountDue.amount - totalAchDiscount
                            : amountDue.amount,
                          amountDue.currencyCode
                        )}
                      />
                    ) : (
                      <AdjacentLabels
                        leftLabel={t(
                          'PaymentModal.Label.EnrollmentFee',
                          'Licensing Fee'
                        )}
                        rightLabel={getLocaleCurrencyForAmount(
                          paymentInformation?.payment?.amount.amount ?? 0,
                          amountDue.currencyCode
                        )}
                      />
                    )}
                  </Box>
                  <Box>
                    <AdjacentLabels
                      leftLabel={t('PaymentModal.Label.Taxes', 'Taxes')}
                      rightLabel={getLocaleCurrencyForAmount(taxes, 'USD')}
                    />
                  </Box>
                </Box>
                <Box>
                  {!isLicensing ? (
                    <AdjacentLabels
                      leftLabel={t('PaymentModal.Label.Total', 'Total')}
                      rightLabel={getLocaleCurrencyForAmount(
                        isBankSelected ? total - totalAchDiscount : total,
                        'USD'
                      )}
                    />
                  ) : (
                    <AdjacentLabels
                      leftLabel={t('PaymentModal.Label.Total', 'Total')}
                      rightLabel={getLocaleCurrencyForAmount(
                        paymentInformation?.payment.total?.amount ?? 0,
                        'USD'
                      )}
                    />
                  )}
                </Box>
              </Box>

              {isBankSelected && (
                <Box display="flex" flexDirection="column">
                  <Box
                    mb={3}
                    display="flex"
                    flexDirection={{ xs: 'column', lg: 'row' }}
                  >
                    <Box
                      width={{ sm: '100%', lg: '50%' }}
                      pr={{ sm: 0, lg: 2 }}
                      mb={{ sm: 3, lg: 0 }}
                    >
                      <TextField
                        {...sharedFieldProps}
                        id="firstName"
                        name="firstName"
                        variant="filled"
                        label={t('PaymentModal.Field.FirstName', 'First Name')}
                        value={bankPaymentInformation.firstName}
                        fullWidth
                      />
                    </Box>
                    <Box
                      width={{ sm: '100%', lg: '50%' }}
                      pl={{ sm: 0, lg: 2 }}
                    >
                      <TextField
                        {...sharedFieldProps}
                        id="lastName"
                        name="lastName"
                        variant="filled"
                        label={t('PaymentModal.Field.LastName', 'Last Name')}
                        value={bankPaymentInformation.lastName}
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box mb={3}>
                    <TextField
                      {...sharedFieldProps}
                      id="routingNumber"
                      name="routingNumber"
                      variant="filled"
                      error={!validRoutingNumber.input}
                      helperText={!validRoutingNumber.input && routingNumber}
                      label={t(
                        'PaymentModal.Field.BankRoutingNumber',
                        'Bank Routing Number (9 digits)'
                      )}
                      value={bankPaymentInformation.routingNumber}
                      inputProps={{ maxLength: 9, minLength: 9 }}
                      fullWidth
                    />
                  </Box>
                  <Box mb={3}>
                    <TextField
                      {...sharedFieldProps}
                      id="bankAccountNumber"
                      name="bankAccountNumber"
                      variant="filled"
                      error={!bankAccountMatch.input}
                      helperText={
                        !bankAccountMatch.input && bankAccountNumberMatch
                      }
                      label={t(
                        'PaymentModal.Field.BankAccountNumber',
                        'Bank Account Number'
                      )}
                      value={bankPaymentInformation.bankAccountNumber}
                      inputProps={{ maxLength: 20 }}
                      fullWidth
                    />
                  </Box>
                  <Box mb={3}>
                    <TextField
                      {...sharedFieldProps}
                      id="confirmBankAccountNumber"
                      name="confirmBankAccountNumber"
                      variant="filled"
                      error={!bankAccountMatch.input}
                      helperText={
                        !bankAccountMatch.input && confirmBankAccountNumberMatch
                      }
                      label={t(
                        'PaymentModal.Field.ConfirmBankAccountNumber',
                        'Confirm Bank Account Number'
                      )}
                      value={bankPaymentInformation.confirmBankAccountNumber}
                      inputProps={{ maxLength: 20 }}
                      fullWidth
                    />
                  </Box>
                </Box>
              )}
              {isCardSelected && (
                <AcceptHosted
                  formToken={paymentInformation?.ccFormToken ?? ''}
                  formRef={formRef}
                  onCancel={async () => {
                    setErrorMessage('')
                    backToBillingAddress()
                  }}
                  onTransact={async (response: TransactionResponse) => {
                    // If no errors, go to the next step
                    if (!!response && !response.errors) {
                      setErrorMessage('')
                      await webhooksApi.authorizeDotNetEventWebhook({
                        body: {
                          payload: {
                            responseCode: +response.responseCode,
                            merchantReferenceId: response.refId,
                            id: response.transId,
                          },
                        },
                      })
                      setStep(PaymentModalSteps.PaymentComplete)
                    }
                  }}
                />
              )}
            </>
          )
        case PaymentModalSteps.EnterBillingAddress:
          switch (stepToValidatePaymentBillingAddress) {
            case PaymentBillingAddressValidationSteps.ValidatingAddress:
              return (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height={200}
                >
                  <CircularProgress />
                </Box>
              )
            case PaymentBillingAddressValidationSteps.UnverifiedAddress:
              return (
                <InvalidAddress
                  enteredAddress={billingAddressInformation.address}
                  variant={InvalidAddressVariant.Unverified}
                  onEditAddressClick={async () => {
                    setErrorMessage('')
                    backToBillingAddress()
                  }}
                  withEditAddressButton={!isLicensing}
                />
              )
            case PaymentBillingAddressValidationSteps.SelectValidAddress:
              return (
                <AddressSelect
                  enteredAddress={billingAddressInformation.address}
                  validAddresses={validAddresses}
                  selection={selectedValidAddress}
                  onSelectionChange={handleAddressSelectionChange}
                  onEditAddressClick={async () => {
                    setErrorMessage('')
                    backToBillingAddress()
                  }}
                  withEditAddressButton={!isLicensing}
                />
              )
            case PaymentBillingAddressValidationSteps.DeniedAddress:
              return (
                <InvalidAddress
                  enteredAddress={billingAddressInformation.address}
                  variant={InvalidAddressVariant.Denied}
                />
              )
            default:
              return (
                <>
                  <Box mb={3}>
                    <TextField
                      {...sharedFieldProps}
                      id="fullName"
                      name="fullName"
                      variant="filled"
                      label={t('PaymentModal.Field.FullName', 'Full Name')}
                      value={billingAddressInformation.fullName}
                      fullWidth
                    />
                  </Box>
                  <AddressForm
                    addressInformation={billingAddressInformation.address}
                    onInputBlur={handleInputBlur}
                    onInputChange={handleInputChange}
                    countryOptions={countryOptions}
                    billingAddress
                    isCardSelected={isCardSelected}
                  />
                </>
              )
          }
        case PaymentModalSteps.ChooseMethodOfPayment:
        default:
          return (
            <>
              {!isLicensing && (
                <Box mb={3}>
                  <PaymentSelection
                    header={t(
                      'PaymentModal.Option.CreditOrDebit',
                      'Credit or Debit Card'
                    )}
                    onClick={selectCreditOrDebit}
                    isSelected={isCardSelected}
                    image={CreditCardImage()}
                  />
                </Box>
              )}
              {(achAllowed || isLicensing) && (
                <>
                  <PaymentSelection
                    header={t(
                      'PaymentModal.Option.BankAccountTransfer',
                      'Bank Account Transfer'
                    )}
                    subheader={
                      achDiscount.displayMessage
                        ? `${getLocaleCurrencyForAmount(
                            achDiscount.amount,
                            'USD'
                          )} ${t(
                            'PaymentModal.Option.BankAccountDiscount',
                            'Discount per paid program for bank account payments'
                          )}`
                        : ''
                    }
                    onClick={selectBankAccount}
                    isSelected={isBankSelected || isLicensing}
                    image={BankAccountImage()}
                  />
                  {isBankSelected && (
                    <Box sx={{ mt: 2, ml: 3 }}>
                      {additionalWarnings.map((warning, index) => {
                        return (
                          <Typography
                            key={index}
                            component="li"
                            variant="subtitle2"
                          >
                            {warning}
                          </Typography>
                        )
                      })}
                    </Box>
                  )}
                </>
              )}
            </>
          )
      }
    }
  }

  return (
    <BasicModal
      isOpen={isOpen}
      afterClose={afterClose}
      handleFormSubmit={handleFormSubmit}
      maxWidth="sm"
      dialogTitle={DialogTitle()}
      dialogContent={
        <>
          {!errorProcessingPayment && !!errorMessage && (
            <ErrorAlert error={errorMessage} />
          )}
          {DialogContent()}
        </>
      }
      dialogActions={DialogActions()}
      // This is a special case where we don't want the modal to default as a form
      disableForm={
        step === PaymentModalSteps.EnterPaymentInformation &&
        !cancelProcess &&
        !isBankSelected
      }
      headerIcon={
        step === PaymentModalSteps.PaymentComplete ||
        step === PaymentModalSteps.PaymentCompleteZeroDue ? (
          <CheckCircle
            sx={{
              color: theme.palette.customBackground.stepperIcon,
              width: '50px',
              height: '50px',
            }}
          />
        ) : undefined
      }
      useBrand
    />
  )
}

export default PaymentModal

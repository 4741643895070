import React, { useRef, useState } from 'react'
import BasicModal from './BasicModal'
import { Box, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import ActionButtons from '../Buttons/ActionButtons'
import { TextButtonVariant } from '../Buttons/TextButton'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import useLoadingContext from '../../hooks/useLoadingContext'
import {
  PaymentMethodKey,
  PaymentStartRequestBodyForTypeEnum,
} from '../../swagger'
import { validateAmount } from './utils/validateAmount'
import { getAmountFromLocaleCurrency } from '../../utils/getAmountFromLocalCurrency'
import { amountFromInput } from '../../utils/amountFromInput'
import { useErrorSnackbar } from '../../hooks/useErrorSnackbar'
import { paymentsApi } from '../../api/swagger'

type IntlCountryCoordinatorPaymentModalProps = {
  programKey: number
  userKey: number
  isOpen: boolean
  onClose: () => void
  titleInfo: {
    programName: string
    communityName: string
    directorName: string
  }
  fetchBillingLoadingId?: string
}

const IntlCountryCoordinatorPaymentModal: React.FC<
  IntlCountryCoordinatorPaymentModalProps
> = ({
  isOpen,
  onClose,
  titleInfo,
  programKey,
  userKey,
  fetchBillingLoadingId,
}) => {
  const { t } = useTranslation()
  const {
    PaymentModal: { startPayment: startPaymentLoadingId },
    BillingTab: {
      fetchBilling: fetchBillingDefaultLoadingId,
      fetchCountryCoordinatorTotalBalance:
        fetchCountryCoordinatorTotalBalanceLoadingId,
    },
    PaymentApi: {
      fetchCountryCoordinatorLicensingDetails:
        fetchCountryCoordinatorLicensingDetailsLoadingId,
    },
    CountryCoordinatorLicensingPaymentHistory: {
      fetchLicensingInternationalPayments:
        fetchLicensingInternationalPaymentsLoadingId,
    },
  } = useLoadingIds()

  const { handleError } = useErrorSnackbar()

  const initialPayment = useRef({
    amount: '0.00',
    description: '',
    begunAt: '',
  })

  const [transactionData, setTransactionData] = useState(initialPayment.current)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    if (name === 'amount') {
      setTransactionData((prevData) => ({
        ...prevData,
        amount: amountFromInput(value),
      }))
    }

    setTransactionData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const isAddPaymentDisabled = !(
    validateAmount(transactionData.amount) &&
    transactionData.begunAt.length > 0 &&
    transactionData.description.length > 0
  )

  /** ******************* Add Payment ******************* */
  const handleAddPayment = async () => {
    try {
      await paymentsApi.startPayment({
        userKey,
        body: {
          _for: {
            type: PaymentStartRequestBodyForTypeEnum.Licensing,
            programKey,
          },
          amount: getAmountFromLocaleCurrency(transactionData.amount),
          desc: transactionData.description,
          method: PaymentMethodKey.Adj,
        },
      })
      // re-fetch the billing data and billing details
      addLoadingIds([
        fetchBillingLoadingId ?? fetchBillingDefaultLoadingId,
        fetchCountryCoordinatorLicensingDetailsLoadingId,
        fetchLicensingInternationalPaymentsLoadingId,
        fetchCountryCoordinatorTotalBalanceLoadingId,
      ])
      onClose()
    } catch (e) {
      await handleError(
        e,
        t(
          'IntlCountryCoordinatorPaymentModal.ValidationMessage.AddPaymentError',
          'Something went wrong while adding the Payment.'
        )
      )
    }
  }

  const { triggerFetch: addPayment, addLoadingIds } = useLoadingContext({
    asyncFunction: handleAddPayment,
    loadingId: startPaymentLoadingId,
  })

  /** ******************* Modal-related Functions ******************* */
  const DialogContent = () => {
    const fields = [
      {
        id: 'dateReceived',
        name: 'begunAt',
        label: t(
          'IntlCountryCoordinatorPaymentModal.Label.DateReceived',
          'Date Received'
        ),
        type: 'date',
        value: transactionData.begunAt,
        InputLabelProps: { shrink: true },
      },
      {
        id: 'amount',
        name: 'amount',
        label: t(
          'IntlCountryCoordinatorPaymentModal.Label.PaymentAmount',
          'Amount'
        ),
        value: transactionData.amount,
        onBlur: (event: React.FocusEvent<HTMLTextAreaElement>) => {
          setTransactionData((prevData) => ({
            ...prevData,
            amount: amountFromInput(event.target.value),
          }))
        },
      },
      {
        id: 'description',
        name: 'description',
        label: t(
          'IntlCountryCoordinatorPaymentModal.Label.PaymentDescription',
          'Description'
        ),
        value: transactionData.description,
        multiline: true,
        rows: 4,
        inputProps: { maxLength: 75 },
        helperText: t(
          'IntlCountryCoordinatorPaymentModal.Description.HelperText.MaxCharacters',
          '75 characters max'
        ),
      },
    ]

    return (
      <Box>
        <Box pb={3.5} textAlign="center">
          <Typography variant="subtitle2" component="p" fontSize={14}>
            {titleInfo.programName} - {titleInfo.communityName}
          </Typography>
          <Typography variant="subtitle2" component="p" fontSize={14}>
            Director {titleInfo.directorName}
          </Typography>
        </Box>
        {fields.map((field) => (
          <Box pb={3.5} key={field.id}>
            <TextField
              id={field.id}
              name={field.name}
              label={field.label}
              variant="filled"
              value={field.value}
              onChange={handleInputChange}
              type={field.type || 'text'}
              fullWidth
              multiline={field.multiline}
              rows={field.rows}
              inputProps={field.inputProps}
              helperText={field.helperText}
              InputLabelProps={field.InputLabelProps}
              onBlur={field.onBlur}
            />
          </Box>
        ))}
      </Box>
    )
  }

  const DialogTitle = () => {
    return t(
      'IntlCountryCoordinatorPaymentModal.Title.PaymentReceived',
      'Payment Received'
    )
  }

  const DialogActions = () => {
    return (
      <ActionButtons
        primaryButtonLabel={ContainedButtonVariant.AddPayment}
        disablePrimaryButton={isAddPaymentDisabled}
        secondaryClick={onClose}
        secondaryButtonLabel={TextButtonVariant.Cancel}
        tertiaryButtonLabel={undefined}
        tertiaryClick={undefined}
        alwaysStack
        useBaseButton
      />
    )
  }

  const resetModalState = () => {
    setTransactionData(initialPayment.current)
  }

  return (
    <BasicModal
      isOpen={isOpen}
      handleFormSubmit={addPayment}
      maxWidth="xs"
      dialogContent={DialogContent()}
      dialogActions={DialogActions()}
      ariaLabel={DialogTitle()}
      dialogTitle={DialogTitle()}
      afterClose={resetModalState}
      labelledBy="IntlCountryCoordinatorPaymentModal-form"
    />
  )
}

export default IntlCountryCoordinatorPaymentModal

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import Logo from '../../Images/Branding/cc-logo-white.svg'
import LogoBlue from '../../Images/Branding/cc-logo-blue.svg'
import { FetchEventsResponse, PaginationResponse } from '../../swagger'
import { displayFormattedSlashDate } from '../../utils/displayFormattedDate'
import { createSpacetime } from './Events'
import { EventSortBy } from './EventEnums'
import { timezoneTitleCase } from '../../utils/timeFormatUtilities'
import { useTheme } from '@mui/material'
import LoadingProgress from '../Elements/LoadingProgress'
import { GridValueFormatterParams } from '@mui/x-data-grid'
import {
  ActionableTable,
  ActionableTableColumn,
} from '../Table/ActionableTable'

const EventSummaryTable: React.FC<{
  events: FetchEventsResponse[]
  isLoading: boolean
  handlePaginationChange: (pagination: PaginationResponse) => void
  pagination: PaginationResponse
}> = ({ isLoading, events, handlePaginationChange, pagination }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const toEventDetailsPage = (id: number, eventName: string) => {
    navigate(
      {
        pathname: `/events/event-details/${id}`,
      },
      {
        state: { eventName },
      }
    )
  }

  const handleSorting = (
    pageInformation: PaginationResponse
  ): PaginationResponse => {
    /**
     * There is a default orderBy on the table request so this
     * should never be undefined the fromDate which is set on
     * the events file where the filtes are setup
     */
    const orderBy = pageInformation.orderBy?.[0]

    for (const key in orderBy) {
      let orderByProperty = key
      switch (key) {
        case 'date':
          orderByProperty = EventSortBy.StartDate
          break
        case 'time':
          orderByProperty = EventSortBy.StartTime
          break
        case 'createdBy':
          orderByProperty = EventSortBy.CreatedBy
          break
        default:
          break
      }
      return {
        ...pageInformation,
        orderBy: [{ [orderByProperty]: orderBy[key] }],
      }
    }

    return pageInformation
  }

  if (isLoading) {
    return <LoadingProgress />
  }

  const ActionableTableColumnsHeader: ActionableTableColumn[] = [
    {
      // No field name since we show an image
      fieldName: '',
      columnHeaderName: t('Events.Table.Header.Image', ''),
      maxWidth: 72,
      align: 'left',
      renderCell: (params) => {
        const image = (
          <img
            src={params.row.isOnline ? LogoBlue : Logo}
            alt={t('Events.Table.EventImage.AltText', 'Event Image')}
            style={{
              height: 40,
              width: 40,
              padding: theme.spacing(1),
              ...(params.row.isOnline
                ? {
                    background: theme.palette.customBackground.logo,
                  }
                : {
                    background: theme.palette.primary.main,
                  }),
            }}
          />
        )

        return (
          <div id="eventImage" style={{ padding: theme.spacing(1) }}>
            {image}
          </div>
        )
      },
      sortable: false,
    },
    {
      fieldName: 'name',
      columnHeaderName: t('Events.Table.Header.EventName', 'Event Name'),
      align: 'left',
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        return params.value
      },
      sortable: true,
      sortingOrder: ['desc', 'asc'],
    },
    {
      fieldName: 'eventType',
      columnHeaderName: t('Events.Table.Header.EventType', 'Event Type'),
      align: 'left',
      sortable: false,
    },
    {
      fieldName: 'city',
      columnHeaderName: t('Events.Table.Header.City', 'City'),
      align: 'left',
      sortable: false,
    },
    {
      fieldName: 'date',
      columnHeaderName: t('Events.Table.Header.Date', 'Date'),
      align: 'left',
      valueGetter: (params) => {
        return `${displayFormattedSlashDate(
          params.row.startDate.toISOString(),
          params.row.endDate?.toISOString() ?? ''
        )}`
      },
      sortable: true,
      sortingOrder: ['desc', 'asc'],
    },
    {
      fieldName: 'time',
      columnHeaderName: t('Events.Table.Header.Time', 'Time'),
      align: 'left',
      valueGetter: (params) => {
        const time = `${createSpacetime(params.row).time()} - ${createSpacetime(
          params.row,
          false
        ).time()} ${timezoneTitleCase(params.row.timezone)}`

        return time
      },
      sortable: true,
      sortingOrder: ['desc', 'asc'],
    },
    {
      fieldName: 'attendees',
      columnHeaderName: t(
        'Events.Registrants.Table.Header.Attendees',
        'Attendees'
      ),
      align: 'left',
      sortable: false,
    },
    {
      fieldName: 'createdBy',
      columnHeaderName: t(
        'Events.Registrants.Table.Header.CreatedBy',
        'Created By'
      ),
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        return params.value
      },
      align: 'center',
      headerAlign: 'center',
      sortable: true,
      sortingOrder: ['desc', 'asc'],
    },
  ]

  return (
    <ActionableTable
      columns={ActionableTableColumnsHeader}
      rows={events}
      noResultsMessage={t('Events.Table.NoEvents', 'No Events found')}
      customCssTable={{
        '&:hover': {
          cursor: 'pointer',
        },
        '& .MuiDataGrid-columnHeader': {
          backgroundColor: theme.palette.textOrIcon.mapButton,
        },
      }}
      pagination={{
        ...pagination,
        page: pagination.page - 1,
      }}
      handlePaginationChange={(pagination) => {
        pagination = handleSorting(pagination)
        return handlePaginationChange(pagination)
      }}
      onRowClick={(eventClicked) => {
        toEventDetailsPage(eventClicked.row.eventKey, eventClicked.row.name)
      }}
    />
  )
}

export default EventSummaryTable

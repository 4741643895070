/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface AgreementDocumentSelectOption
 */
export interface AgreementDocumentSelectOption {
  /**
   * Unique identifier for the agreementDocument which corresponds with the Adobe Sign documentId for a Library Document.
   * @type {string}
   * @memberof AgreementDocumentSelectOption
   */
  agreementDocumentId: string
  /**
   * The name of the agreementDocument.
   * @type {string}
   * @memberof AgreementDocumentSelectOption
   */
  agreementDocumentName: string
}

export function AgreementDocumentSelectOptionFromJSON(
  json: any
): AgreementDocumentSelectOption {
  return AgreementDocumentSelectOptionFromJSONTyped(json, false)
}

export function AgreementDocumentSelectOptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AgreementDocumentSelectOption {
  if (json === undefined || json === null) {
    return json
  }
  return {
    agreementDocumentId: json['agreementDocumentId'],
    agreementDocumentName: json['agreementDocumentName'],
  }
}

export function AgreementDocumentSelectOptionToJSON(
  value?: AgreementDocumentSelectOption | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    agreementDocumentId: value.agreementDocumentId,
    agreementDocumentName: value.agreementDocumentName,
  }
}

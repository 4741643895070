import SelectOption from '../components/Interfaces/SelectOption'
import countriesAndStates from '../helpers/placeholderData/countriesAndStates'

export enum CountryCode {
  USA = 'US',
  Canada = 'CA',
  Chile = 'CL',
}

export const isStateInCountry = (country: string, state: string): boolean => {
  const { US, Chile, Canada, Countries } = countriesAndStates
  let stateArray: SelectOption[] = []
  switch (country) {
    // US
    case Countries[0].value:
      stateArray = US
      break
    // Canada
    case Countries[1].value:
      stateArray = Canada
      break
    // Chile
    case Countries[2].value:
      stateArray = Chile
      break
  }
  return stateArray.some((option) => option.value === state)
}

export default isStateInCountry

import { debounce, InputAdornment, TextField } from '@mui/material'
import {
  DEBOUNCE_LIMIT,
  MINIMUM_DEBOUNCED_SEARCH_QUERY_LENGTH,
} from '../../utils/constants'
import { Search } from '@mui/icons-material'
import Autocomplete from '@mui/material/Autocomplete'
import React, { CSSProperties, ReactNode, useEffect, useMemo } from 'react'
import { useTheme } from '@mui/material/styles'

interface DebouncedAutocompleteProps<T> {
  id: string
  options: T[]
  handleChange: (selectedOption: T | null, reason: string) => void
  noOptionsTextValue: string
  optionsLabel: (options: T) => string
  renderOptions: (
    props: React.HTMLAttributes<HTMLLIElement>,
    options: T
  ) => ReactNode
  updateSearchResults: (s: string) => void
  textFieldLabelValue: string
  textFieldStyle?: CSSProperties
  initialValue?: T | null
}

/**
 Utilizing a comma is a Typescript convention for denoting multiple type parameters. In this context, the parameter T
 can take on the type of either Enrollment[] or UserAccountListing[].
 */
const DebouncedAutocomplete = <T,>({
  id,
  options,
  handleChange,
  noOptionsTextValue,
  optionsLabel,
  renderOptions,
  updateSearchResults,
  textFieldLabelValue,
  textFieldStyle = {},
  initialValue = null,
}: DebouncedAutocompleteProps<T>): JSX.Element => {
  const theme = useTheme()
  const [inputValue, setInputValue] = React.useState(
    initialValue ? optionsLabel(initialValue) : ''
  )
  const [selectedValue, setSelectedValue] = React.useState<T | null>(
    initialValue
  )

  const debouncedSearch = useMemo(
    () =>
      debounce((search: string) => {
        if (search.length >= MINIMUM_DEBOUNCED_SEARCH_QUERY_LENGTH) {
          updateSearchResults(search)
        }
      }, DEBOUNCE_LIMIT),
    [updateSearchResults]
  )

  useEffect(() => {
    return () => {
      debouncedSearch.clear()
    }
  }, [debouncedSearch])

  return (
    <Autocomplete
      id={id}
      sx={{
        color: theme.palette.primary.light,
      }}
      popupIcon={null}
      fullWidth
      autoComplete
      clearOnEscape
      noOptionsText={noOptionsTextValue}
      ListboxProps={{
        // This style is needed so the list of elements does not overflow out of the modal
        style: { maxHeight: '150px' },
      }}
      value={selectedValue}
      inputValue={inputValue}
      options={options}
      getOptionLabel={optionsLabel}
      renderOption={renderOptions}
      onChange={(_event, value, reason) => {
        setSelectedValue(value)
        setInputValue(value ? optionsLabel(value) : '')
        handleChange(value, reason)
      }}
      onInputChange={(_event, value, reason) => {
        setInputValue(value)
        if (reason === 'input') {
          debouncedSearch(value)
        }
      }}
      renderInput={(params) => (
        <TextField
          style={
            !!textFieldStyle
              ? textFieldStyle
              : {
                  width: theme.spacing(30),
                  paddingBottom: theme.spacing(3.5),
                }
          }
          {...params}
          variant="filled"
          label={textFieldLabelValue}
          fullWidth
          margin="normal"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment
                id="searchIcon"
                // This style is needed b/c of a MUI bug where the start adornment is misaligned
                style={{ marginTop: 0 }}
                disablePointerEvents={true}
                position="start"
              >
                <Search
                  sx={{
                    fill: theme.palette.textOrIcon.searchIcon,
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}

export default DebouncedAutocomplete

import { Page } from '../Elements/PageMargins'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router'
import TitleContext from '../../TitleContext'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import EmptyPage from '../Elements/EmptyPage'
import Typography from '@mui/material/Typography'
import LoadingProgress from '../Elements/LoadingProgress'
import { EventFormCardVariants } from './EventEnums'
import { useEventContext } from '../Context/EventContext'
import { Tab } from '@mui/material'
import { TabContext, TabList } from '@mui/lab'

type TabNames = 'Details' | 'Registrants'

export const EventDetails: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const title = t('Events.EventDetails.Header', 'Events')
  const {
    breadcrumbs,
    updateBreadcrumbs,
    eventDetails,
    updateEventKey,
    updateVariant,
    isLoadingEventDetails,
  } = useEventContext()
  const navigate = useNavigate()
  const location = useLocation()
  const [activeTab, setActiveTab] = useState<TabNames>('Details')

  const { eventKey } = useParams<{
    eventKey: string
  }>()

  useEffect(() => {
    const [selectedTabKey] = location.pathname.split('/').slice(-1)
    if (selectedTabKey === 'registrants') {
      setActiveTab('Registrants')
      return
    }
    setActiveTab('Details')
  }, [location.pathname])

  const eventId = parseInt(`${eventKey}`)

  useEffect(() => {
    updateEventKey(eventId)
    updateVariant(EventFormCardVariants.EditEvent)
  }, [eventId, updateEventKey, updateVariant])

  const { useTitleEffect } = React.useContext(TitleContext)
  useTitleEffect(title)

  const handleTabChange = (event: React.SyntheticEvent, newValue: TabNames) => {
    switch (newValue) {
      case 'Details':
        navigate({
          pathname: `/events/event-details/${eventId}/overview`,
        })
        return
      case 'Registrants':
        navigate({
          pathname: `/events/event-details/${eventId}/registrants`,
        })
        return
    }
  }
  const tabsLabelEventDetails = {
    Details: t('EventDetails.TabTitle.Details', 'Details'),
    Registrants: t('EventDetails.TabTitle.Registrants', 'Registrants'),
  }

  const tabs = Object.values(tabsLabelEventDetails).map((label) => {
    return <Tab key={label} value={label} label={label} />
  })

  useEffect(() => {
    updateBreadcrumbs(eventDetails?.name)
  }, [eventDetails?.name, updateBreadcrumbs])

  if (!eventDetails && !isLoadingEventDetails) {
    return (
      <EmptyPage
        message={
          <Typography variant="subtitle1" component="p">
            {t(
              'Events.EmptyState.Message',
              `The event either does not exist or you do not have permission to view it.`
            )}
          </Typography>
        }
      />
    )
  }

  if (isLoadingEventDetails || eventDetails === undefined) {
    return <LoadingProgress />
  }

  return (
    <TabContext value={activeTab}>
      <TabList
        value={activeTab}
        textColor="primary"
        variant="scrollable"
        scrollButtons={false}
        onChange={handleTabChange}
      >
        {tabs}
      </TabList>
      <Page>
        <DynamicBreadcrumbs breadcrumbs={breadcrumbs} />
        <Outlet />
      </Page>
    </TabContext>
  )
}

export default EventDetails

/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  CashAmount,
  CashAmountFromJSON,
  CashAmountToJSON,
  ProgramType,
  ProgramTypeFromJSON,
  ProgramTypeToJSON,
} from './'

/**
 * Object that encapsulates the information needed for each invoice.
 * @export
 * @interface ParentInvoiceInfo
 */
export interface ParentInvoiceInfo {
  /**
   * The unique identifier of the invoice sent by the director or tutor.
   * @type {number}
   * @memberof ParentInvoiceInfo
   */
  invoiceKey?: number
  /**
   * The date the invoice was sent to the parent.
   * @type {Date}
   * @memberof ParentInvoiceInfo
   */
  lastSentEmail?: Date
  /**
   *
   * @type {CashAmount}
   * @memberof ParentInvoiceInfo
   */
  amountOwed?: CashAmount
  /**
   *
   * @type {Array<string>}
   * @memberof ParentInvoiceInfo
   */
  communityNames?: Array<string>
  /**
   * The academic year for the invoice program.
   * @type {string}
   * @memberof ParentInvoiceInfo
   */
  programAcademicYear?: string
  /**
   * The name of the director or tutor whose program the invoice belongs to.
   * @type {string}
   * @memberof ParentInvoiceInfo
   */
  invoiceRequesterName?: string
  /**
   *
   * @type {Array<ProgramType>}
   * @memberof ParentInvoiceInfo
   */
  programTypes?: Array<ProgramType>
}

export function ParentInvoiceInfoFromJSON(json: any): ParentInvoiceInfo {
  return ParentInvoiceInfoFromJSONTyped(json, false)
}

export function ParentInvoiceInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ParentInvoiceInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    invoiceKey: !exists(json, 'invoiceKey') ? undefined : json['invoiceKey'],
    lastSentEmail: !exists(json, 'lastSentEmail')
      ? undefined
      : new Date(json['lastSentEmail']),
    amountOwed: !exists(json, 'amountOwed')
      ? undefined
      : CashAmountFromJSON(json['amountOwed']),
    communityNames: !exists(json, 'communityNames')
      ? undefined
      : json['communityNames'],
    programAcademicYear: !exists(json, 'programAcademicYear')
      ? undefined
      : json['programAcademicYear'],
    invoiceRequesterName: !exists(json, 'invoiceRequesterName')
      ? undefined
      : json['invoiceRequesterName'],
    programTypes: !exists(json, 'programTypes')
      ? undefined
      : (json['programTypes'] as Array<any>).map(ProgramTypeFromJSON),
  }
}

export function ParentInvoiceInfoToJSON(value?: ParentInvoiceInfo | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    invoiceKey: value.invoiceKey,
    lastSentEmail:
      value.lastSentEmail === undefined
        ? undefined
        : value.lastSentEmail.toISOString().substr(0, 10),
    amountOwed: CashAmountToJSON(value.amountOwed),
    communityNames: value.communityNames,
    programAcademicYear: value.programAcademicYear,
    invoiceRequesterName: value.invoiceRequesterName,
    programTypes:
      value.programTypes === undefined
        ? undefined
        : (value.programTypes as Array<any>).map(ProgramTypeToJSON),
  }
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmWithButtonsModal from './ConfirmWithButtonsModal'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import { TranscriptYearCourseWork } from '../../swagger'

interface TranscriptConfirmDeleteRowModalProps {
  isOpen: boolean
  onConfirm: () => void
  onCancel: () => void
  courseWork: TranscriptYearCourseWork | undefined
}

const TranscriptConfirmDeleteRowModal: React.FC<
  TranscriptConfirmDeleteRowModalProps
> = ({ isOpen, onConfirm, onCancel, courseWork }) => {
  const { t } = useTranslation()

  return (
    <ConfirmWithButtonsModal
      isOpen={isOpen}
      title={t(
        'Transcript.TranscriptFormCard.DeleteRowModal.Title',
        'Delete Row'
      )}
      question={t(
        'Transcript.TranscriptFormCard.DeleteRowModal.Question',
        `Are you sure you want to delete class "{{courseName}}" with a letter grade of "{{letterGrade}}" & credit total of "{{credits}}"?`,
        {courseName: `${courseWork?.courseName}`, letterGrade: `${courseWork?.letterGrade}`, credits: `${courseWork?.credits}`}
      )}
      questionProps={{ variant: 'body1', align: 'center' }}
      confirmVariant={ContainedButtonVariant.YesProceed}
      cancelVariant={TextButtonVariant.NoCancel}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  )
}

export default TranscriptConfirmDeleteRowModal

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DynamicBreadcrumbs from '../../Elements/DynamicBreadcrumbs'
import { useNavigate, useParams } from 'react-router'
import { Page } from '../../Elements/PageMargins'
import { BillingPDFPreview } from '../../Account/Billing/BillingPDF/BillingPDFPreview'
import { useMountEffect } from '../../../hooks/useMountEffect'
import { useLoadingIds } from '../../../hooks/useLoadingIds'
import { UserAccountDetails } from '../../../swagger'
import { userAccountsApi } from '../../../api/swagger'
import fullName from '../../../utils/fullName'
import useEndpoint from '../../../hooks/useEndpoint'
import { LoadingContext } from '../../Context/LoadingContext'

export const UserAccountLicensingReport: React.FC = () => {
  const { userKey } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { addLoadingIds } = React.useContext(LoadingContext)

  const {
    UserAccountLicensingReport: { fetchUser: fetchUserLoadingId },
  } = useLoadingIds()

  const [userAccount, setUserAccount] = useState<UserAccountDetails>()

  useEndpoint({
    loadingId: fetchUserLoadingId,
    swaggerCall: async () => {
      const fetchedUserAccount = await userAccountsApi.fetchUser({
        userKey: userKey as unknown as number,
      })
      setUserAccount(fetchedUserAccount)
    },
  })

  useMountEffect(() => {
    addLoadingIds([fetchUserLoadingId])
  })

  return (
    <Page withinTab>
      <DynamicBreadcrumbs
        breadcrumbs={[
          {
            label: t(
              'UserAccountLicensingReport.BreadCrumb.UserAccounts',
              'User Accounts'
            ),
            onClick: () =>
              navigate({
                pathname: '/admin/user-accounts',
              }),
          },
          {
            label: fullName(userAccount),
            onClick: () =>
              navigate({
                pathname: `/admin/user-accounts/${userKey}`,
              }),
          },
          {
            label: t(
              'UserAccountLicensingReport.BreadCrumb.LicensingReport',
              'Licensing Report'
            ),
          },
        ]}
      />
      <BillingPDFPreview userAccount={userAccount} />
    </Page>
  )
}

export default UserAccountLicensingReport

import React, {
  useEffect,
  useMemo,
  useState,
  ReactElement,
  ReactNode,
} from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SelectChangeEvent,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import Card from '@mui/material/Card'
import Header, { HeaderVariant } from '../Elements/Header'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import { useLocation, useNavigate } from 'react-router'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import CardFormHeader from '../Card/CardFormHeader'
import { inviteFamily } from '../../api/Families'
import programTypeMap from '../../utils/programTypeMap'
import { ProgramType } from '../../swagger/models/ProgramType'
import {
  AgreementTemplateFormFieldInputType,
  ProgramInviteFamilyOptions,
  UserProfile,
} from '../../swagger'
import { extractedErrorObject, families, invitesApi } from '../../api/swagger'
import useValidationMessages from '../../hooks/useValidationMessages'
import IconTextButton, {
  IconTextButtonVariant,
} from '../Buttons/IconTextButton'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { FormDivider } from '../Elements/FormDivider'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { FieldValidity } from '../Interfaces/FieldValidity'
import Checkbox from '@mui/material/Checkbox'
import {
  Box,
  CircularProgress,
  FormControlLabel,
  styled,
  Typography,
  useTheme,
} from '@mui/material'
import { ParentFields, ParentFormField } from '../Families/ParentFields'
import useDynamicFieldStates from '../../hooks/useDynamicFieldStates'
import { DynamicFieldStates } from '../Interfaces/DynamicFieldStates'
import { dateToDashString, UTCYearString } from '../../utils/dateUtility'
import { reinterpretYearMonthDayAsLocalTime } from '../../utils/reinterpretYearMonthDayAsLocalTime'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import ConfirmationModal from '../Modals/ConfirmationModal'
import {
  InviteFamilyCommunitiesOptions,
  ProgramInvite,
  ProgramInviteFamilyOptionsResponsePrograms,
} from '../../swagger'
import { validateEmail, validateEmailText } from '../../helpers/validateEmail'
import PersonIcon from '@mui/icons-material/Person'
import ActionButtons from '../Buttons/ActionButtons'
import { fetchUser } from '../../api/user'
import useEmailMessage from '../../hooks/useEmailMessage'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { MenuOption } from '../Menus/DropDown'
import BasicModal from '../Modals/BasicModal'
import { useMountEffect } from '../../hooks/useMountEffect'
import { useNotistackSnackbarKeyContext } from '../Context/NotistackSnackbarKeyProvider'
import { fetchInviteFamilyPrograms } from '../../api/programs'
import { LoadingContext } from '../Context/LoadingContext'
import { OperationIds } from '../../swagger/operationIdEnum'
import useLoadingContext from '../../hooks/useLoadingContext'
import InviteFamilyProgramCapacityContentCard from './InviteFamilyProgramCapacityContentCard'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import PreviousInvitationsModal from '../Modals/PreviousInvitationsModal'
import CircleIcon from '@mui/icons-material/Circle'
import formatYearsInText from '../../utils/formatYearsInText'

const InviteAFamilyCard = styled(Card)(({ theme }) => ({
  maxWidth: 1200,
  padding: theme.spacing(3, 4),
  color: theme.palette.primary.main,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3, 4),
  },
})) as typeof Card

const Form = styled('form')(({ theme }) => ({
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 0),
    },
  },
}))

const SectionOne = styled('section')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const SectionTwo = styled('section')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '50% 25% 25%',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}))
export interface AvailableSpots {
  programKey: number
  programType: string
  dateProgram: Date
  availableSpots: number
  spotsOfferedInCurrentInvite: number
  dropdownsKeys: string[] // to know when a selector has changed its value to a new program
}

enum InviteFamilyValidationMessageTypes {
  Default = 'default',
  ParentEmailAddress = 'parentEmailAddress',
  InviteFamily = 'inviteFamily',
}

interface ProgramInviteFamilyOptionsExtends
  extends ProgramInviteFamilyOptionsResponsePrograms {
  outOfSpots?: boolean
}

interface StudentProgramFormField {
  isApplicationFeePaid: boolean
  programType: ProgramType | ''
  semesterOneStartDate: string
  programKey: number
  availableSpots: number
  outOfSpots?: boolean
}

interface InviteFamilyFormCardProps {
  userId?: string
}

const areRequiredFieldsEmpty = (parentFieldStates: DynamicFieldStates) => {
  for (const [, { state }] of Object.entries(parentFieldStates)) {
    if (!state) {
      return true
    }
  }
  return false
}

const areProgramsValid = (
  studentProgramMap: Map<string, StudentProgramFormField>
) => {
  let hasAtLeastOneProgram = false
  let isValidProgramInformation = true
  for (const value of studentProgramMap.values()) {
    if (!!value.programType) {
      hasAtLeastOneProgram = true
    }
    if (!value.programType) {
      // Removed application fee is paid because it is optional to check
      isValidProgramInformation = false
    }
  }
  return hasAtLeastOneProgram && isValidProgramInformation
}

const removeAvailableSpotsWithoutSpotsOfferedInCurrentInvite = (
  arrayAvailableSpots: AvailableSpots[]
) => {
  return arrayAvailableSpots.filter(
    (availableSpot) =>
      Object.keys(availableSpot).length !== 0 &&
      availableSpot.spotsOfferedInCurrentInvite !== 0
  )
}

//FIXME: Use availableLoadingIds
export const fetchInviteFamilyProgramsId = `${OperationIds.FetchInviteFamilyPrograms}.InviteFamilyPrograms`
const FetchInviteFamilyCommunitiesId = `${OperationIds.FetchInviteFamilyCommunities}.InviteFamilyCommunities`

const sortProgramTypes = (
  programsType: ProgramInviteFamilyOptionsExtends[]
): ProgramInviteFamilyOptionsExtends[] => {
  const orderOfPrograms = [
    ProgramType.Foundations,
    ProgramType.Essentials,
    ProgramType.ChallengeA,
    ProgramType.ChallengeB,
    ProgramType.ChallengeI,
    ProgramType.ChallengeIi,
    ProgramType.ChallengeIii,
    ProgramType.ChallengeIv,
  ]
  const orderedProgramTypes: ProgramInviteFamilyOptionsExtends[] = []
  orderOfPrograms.forEach((programTypeInOrder) => {
    const sortedArrayOfEachProgramType = programsType
      .filter((programType) => programType.programType === programTypeInOrder)
      .sort((a, b) =>
        a.semesterOneStartDate > b.semesterOneStartDate ? 1 : -1
      )
    orderedProgramTypes.push(...sortedArrayOfEachProgramType)
  })
  return orderedProgramTypes
}

export const InviteFamilyFormCard: React.FC<InviteFamilyFormCardProps> = (
  props
) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { userId } = props
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()
  const { addSnackbarKey, enqueueSnackbar } = useNotistackSnackbarKeyContext()
  const { InviteFamilyFormCard } = useLoadingIds()

  const { validationMessages } = useEmailMessage()

  const showOnDesktop = useShowOnDesktop()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [userInfo, setUserInfo] = useState<UserProfile | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [inviteFamilyProgramsInformation, setInviteFamilyProgramsInformation] =
    useState<string>('')

  const [availableSpots, setAvailableSpots] = useState<AvailableSpots[]>([])
  // Makes it easier to have always updated the identifier of the "Program" drop-down when the community changes.
  const [synchronizeDropDownKey, setSynchronizeDropDownKey] =
    useState<string>('')
  const [showPreviousInvitationsModal, setShowPreviousInvitationsModal] =
    useState(false)

  const [hasNoAvailableSpots, setHasNoAvailableSpots] = useState<boolean>(false)

  const location = useLocation()

  const [communityKey, setCommunityKey] = useState<number | undefined>(
    location.state ? location.state.communityId : undefined
  )
  const [isInvoiceConfirmationModalOpen, setIsInvoiceConfirmationModalOpen] =
    useState(false)

  const { addLoadingIds, removeLoadingIds, loadingIds } =
    React.useContext(LoadingContext)

  const defaultFetchProfileErrorMessage = t(
    'FamilyProfile.Error.FetchProfile',
    'Error occurred fetching profile information'
  )

  const defaultFetchCommunitiesErrorMessage = t(
    'FamilyProfile.Error.fetchInviteFamilyCommunities',
    'Error occurred fetching communities information'
  )

  const defaultValidateInvitationErrorMessage = t(
    'FamilyProfile.Error.ValidateInvitation',
    'Error occurred while validating the invitation'
  )

  const academicYearBeginning = location.state
    ? location.state.academicYearBeginning
    : new Date().getFullYear()

  useMountEffect(() => {
    setIsInvoiceConfirmationModalOpen(true)
  })

  useEffect(() => {
    if (!!userId) {
      addLoadingIds([InviteFamilyFormCard.fetchUserProfile])
    }
  }, [
    userId,
    defaultFetchProfileErrorMessage,
    setSnackbarState,
    setSnackbarMessage,
    setSnackbarSeverity,
    addLoadingIds,
    InviteFamilyFormCard.fetchUserProfile,
  ])

  const fetchProfileInformation = async () => {
    try {
      const fetchedUserProfile = await fetchUser(userId)
      setIsLoading(false)
      setUserInfo(fetchedUserProfile)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message:
          (e as unknown as Error).message ?? defaultFetchProfileErrorMessage,
      }
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarState(true)
    }
  }

  useLoadingContext({
    asyncFunction: fetchProfileInformation,
    loadingId: InviteFamilyFormCard.fetchUserProfile,
  })

  const inviteFamilyFormHeader = t(
    'Families.FamilyForm.NewFamily',
    'Invite a New Family'
  )

  const studentInvitationsHeader = t(
    'Families.FamilyForm.StudentInvitations',
    'Student Invitations'
  )

  const validationMessageMap = useValidationMessages([
    {
      field: InviteFamilyValidationMessageTypes.Default,
      message: t(
        'Families.InviteFamilyForm.ValidationMessage.Default',
        'Something went wrong. Please make sure you have filled out the required fields'
      ),
    },
    {
      field: InviteFamilyValidationMessageTypes.InviteFamily,
      message: t(
        'Families.InviteFamilyForm.ValidationMessage.InviteFamily',
        'Error occurred attempting to invite family'
      ),
    },
  ])

  /*
   *  Prevent error "Cannot include a '?' character in a manually specified...."
   *  With new router version we can't match a Route on the query string in the path.
   *  More info: https://github.com/remix-run/react-router/issues/9613
   */

  const communityId = location.state ? location.state.communityId : communityKey

  const redirectionUrl = `/communities/community-details/${communityId}/families`

  const urlParams = `?year=${academicYearBeginning}`

  const handleCancel = () => {
    navigate(
      {
        pathname: redirectionUrl,
      },
      {
        state: {
          isFamilyTabSelected: true,
        },
      }
    )
  }

  const validatePrograms = () => {
    let hasAtLeastOneProgram = false
    let isValidProgramInformation = true
    for (const value of studentProgramMap.values()) {
      if (!!value.programType) {
        hasAtLeastOneProgram = true
      }
      if (!value.programType) {
        // Remove application fee since it's optional
        isValidProgramInformation = false
      }
    }
    return hasAtLeastOneProgram && isValidProgramInformation
  }

  const validateParentFields = () => {
    let doParentFieldsValidate = true
    let parentFieldStateObject = {}
    for (const [key, { state, maxLength, inputType }] of Object.entries(
      parentFieldStates
    )) {
      const isOverLength = !!maxLength && state.length > maxLength
      if (!state) {
        doParentFieldsValidate = false
      }
      let isValidEmail = true
      let textErrorEmail = ''
      let validationMessage: string | undefined = undefined

      if (key === 'Parent Email Address') {
        isValidEmail = validateEmail(
          parentFieldStates['Parent Email Address'].state
        )
        if (!isValidEmail) {
          doParentFieldsValidate = false
          textErrorEmail = validateEmailText(
            parentFieldStates['Parent Email Address'].state
          )
          validationMessage = validationMessages(textErrorEmail)
          parentFieldStates['Parent Email Address'].validationMessage =
            validationMessage
        }
      }

      parentFieldStateObject = {
        ...parentFieldStateObject,
        [key]: {
          state,
          maxLength,
          inputType,
          validationMessage,
          isValid: {
            ...parentFieldStates[key].isValid,
            input: !!state && isValidEmail, // isValidEmail will be true if not the email field
            maxLength: !maxLength ? true : !isOverLength,
          },
        },
      }
    }
    setParentFieldStates({ ...parentFieldStates, ...parentFieldStateObject })
    return doParentFieldsValidate
  }

  const fieldsDoValidate = () => {
    const programsAreValid = validatePrograms()
    const parentFieldsAreValid = validateParentFields()
    // Ensure we have non empty fields
    return programsAreValid && parentFieldsAreValid
  }

  const [communities, setCommunities] = useState<
    InviteFamilyCommunitiesOptions[]
  >([])

  const [communitiesOptionsLoaded, setCommunitiesOptionsLoaded] =
    useState(false)

  const [programTypes, setProgramTypes] = useState(
    [] as ProgramInviteFamilyOptionsExtends[]
  )

  const setProgramTypeInformation = (
    programTypes: ProgramInviteFamilyOptionsExtends[]
  ) => {
    setProgramTypes(programTypes)
  }

  const handleRemoveOfferedInCurrentInvite = (inputKey: string) => {
    setAvailableSpots((currentAvailableSpots) => {
      return [
        ...currentAvailableSpots.map((availableSpot) => {
          if (availableSpot.dropdownsKeys.includes(inputKey)) {
            return {
              ...availableSpot,
              spotsOfferedInCurrentInvite:
                availableSpot.spotsOfferedInCurrentInvite - 1,
              dropdownsKeys: availableSpot.dropdownsKeys.filter(
                (dropdownKey) => dropdownKey !== inputKey
              ),
            }
          } else {
            return availableSpot
          }
        }),
      ].filter(
        (currentAvailableSpot) =>
          currentAvailableSpot.spotsOfferedInCurrentInvite !== 0
      )
    })
  }

  const createNewRecordForAvailableSpot = (
    programInformation: ProgramInviteFamilyOptionsResponsePrograms | undefined,
    fieldIdentifier: string
  ): AvailableSpots => {
    return {
      programKey: programInformation?.programKey ?? 0,
      programType: programInformation?.programType ?? '',
      dateProgram: programInformation?.semesterOneStartDate ?? new Date(),
      availableSpots: programInformation?.availableSpots ?? 0,
      spotsOfferedInCurrentInvite:
        Object.keys(programInformation ?? {}).length > 0 ? 1 : 0,
      dropdownsKeys: [`${fieldIdentifier}`],
    }
  }

  const handleAddAvailableSpots = async (
    programInformation: ProgramInviteFamilyOptionsResponsePrograms,
    dropDownIdentifier: string
  ) => {
    const { programKey } = programInformation
    let createRowAvailableSpot = false
    setAvailableSpots((currentAvailableSpots) => {
      const indexToLocateTheProgramKey = currentAvailableSpots.findIndex(
        (availableSpot) => availableSpot.programKey === programKey
      )
      // We check that the array contains elements
      if (currentAvailableSpots.length > 0) {
        return removeAvailableSpotsWithoutSpotsOfferedInCurrentInvite([
          ...currentAvailableSpots.map((availableSpot, index) => {
            // We check that the programKey exists in order to update it, if two selectors
            // have the same programKey selected, we simply add the invitation and save the selector reference
            if (indexToLocateTheProgramKey === index) {
              return {
                ...availableSpot,
                spotsOfferedInCurrentInvite:
                  availableSpot.spotsOfferedInCurrentInvite + 1,
                dropdownsKeys: [
                  ...availableSpot.dropdownsKeys,
                  dropDownIdentifier,
                ],
              }
            } else {
              // We decide when to create a new row for "availableSpots", we will create it
              //  only when the programKey does not exist in the "availableSpots".
              createRowAvailableSpot =
                indexToLocateTheProgramKey === -1 ? true : false
              return availableSpot
            }
          }),
          // When a "programKey" that does not exist was found, let's process to create it
          createRowAvailableSpot
            ? createNewRecordForAvailableSpot(
                programInformation,
                dropDownIdentifier
              )
            : ({} as AvailableSpots),
        ])
      } else {
        return [
          ...currentAvailableSpots,
          createNewRecordForAvailableSpot(
            programInformation,
            dropDownIdentifier
          ),
        ]
      }
    })
  }
  // This function is responsible for changing the state each time the "Student and program" selector is changed.
  // It returns as a result a new state of the actions corresponding to the change.
  const actionsToModifyState = (
    currentAvailableSpots: AvailableSpots[],
    dropDownIdentifier: string,
    programInformation: ProgramInviteFamilyOptionsResponsePrograms
  ): AvailableSpots[] => {
    const { programKey } = programInformation

    let createRowAvailableSpot = false
    // We look for the programKey if it exists we fetch its position
    const indexToLocateTheProgramKey = currentAvailableSpots.findIndex(
      (availableSpot) => availableSpot.programKey === programKey
    )
    // In case we have changed the program in a selector to a different one, this will facilitate its elimination.
    const referenceIndexOfEntryToDelete = currentAvailableSpots.findIndex(
      (availableSpot) =>
        availableSpot.dropdownsKeys.includes(dropDownIdentifier) &&
        availableSpot.programKey !== programKey
    )

    return [
      ...currentAvailableSpots.map((availableSpot, index) => {
        // We check that the "programKey" exists, to update simply, we add the selector reference
        // and add an invitation to the "spotsOfferedInCurrentInvite".
        if (indexToLocateTheProgramKey === index) {
          return {
            ...availableSpot,
            spotsOfferedInCurrentInvite:
              availableSpot.spotsOfferedInCurrentInvite + 1,
            dropdownsKeys: [...availableSpot.dropdownsKeys, dropDownIdentifier],
          }
        } else {
          createRowAvailableSpot =
            indexToLocateTheProgramKey === -1 ? true : false
          return referenceIndexOfEntryToDelete === index
            ? {
                ...availableSpot,
                spotsOfferedInCurrentInvite:
                  availableSpot.spotsOfferedInCurrentInvite - 1,
                dropdownsKeys: availableSpot.dropdownsKeys.filter(
                  (dropdownKey) => dropdownKey !== dropDownIdentifier
                ),
              }
            : availableSpot
        }
      }),
      createRowAvailableSpot
        ? createNewRecordForAvailableSpot(
            programInformation,
            dropDownIdentifier
          )
        : ({} as AvailableSpots),
    ]
  }

  const handleAvailableSpots = (
    programInformation: ProgramInviteFamilyOptionsResponsePrograms,
    dropDownIdentifier: string
  ) => {
    setAvailableSpots((prevState) => {
      return removeAvailableSpotsWithoutSpotsOfferedInCurrentInvite(
        actionsToModifyState(prevState, dropDownIdentifier, programInformation)
      )
    })
  }

  // **** Fetch Communities **** //
  const fetchUserCommunities = async () => {
    try {
      const { communities } = await families.fetchInviteFamilyCommunities({})
      setCommunities(communities)
      setCommunitiesOptionsLoaded(true)
    } catch (error) {
      const errorObject = (await extractedErrorObject(error)) ?? {
        code: 'Unknown',
        message:
          (error as unknown as Error).message ??
          defaultFetchCommunitiesErrorMessage,
      }
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarState(true)
    } finally {
      removeLoadingIds([FetchInviteFamilyCommunitiesId])
    }
  }

  useLoadingContext({
    asyncFunction: fetchUserCommunities,
    loadingId: FetchInviteFamilyCommunitiesId,
  })

  useMountEffect(() => {
    addLoadingIds([FetchInviteFamilyCommunitiesId])
  })

  // **** Fetch Programs **** //

  const fetchInviteCommunityPrograms = async () => {
    if (communityKey) {
      try {
        /** Cannot reset programType state here because of the iPad/safari
         *
         * See https://classicalconversations.my.workfront.com/issue/656756420005460a5a85837872d2595d/updates
         */
        const programs = await fetchInviteFamilyPrograms(communityKey)
        const sortedProgramTypes = sortProgramTypes(programs ?? [])
        setProgramTypeInformation(sortedProgramTypes)
      } catch (error) {
        //set no program when an error happen
        setProgramTypeInformation([])
      } finally {
        removeLoadingIds([fetchInviteFamilyProgramsId])
      }
    }
  }

  useLoadingContext({
    asyncFunction: fetchInviteCommunityPrograms,
    loadingId: fetchInviteFamilyProgramsId,
  })

  useMountEffect(() => {
    addLoadingIds([fetchInviteFamilyProgramsId])
  })

  const communityProgramsMap = useMemo(() => programTypes, [programTypes])

  const [studentProgramFields, setStudentProgramFields] = useState<
    ReactElement[]
  >([])

  const [fieldIdToRemove, setFieldIdToRemove] = useState('')

  const [
    studentProgramFieldsWithProgramTypesByCommunity,
    setStudentProgramFieldsWithProgramTypesByCommunity,
  ] = useState<ReactElement[]>([])
  useEffect(() => {
    if (!!programTypes && programTypes.length > 0) {
      setStudentProgramFieldsWithProgramTypesByCommunity(
        studentProgramFields.map((node: ReactElement) => {
          return {
            ...node,
            props: {
              ...node.props,
              programTypes: programTypes,
            },
          }
        })
      )
    } else {
      setStudentProgramFieldsWithProgramTypesByCommunity([])
    }
  }, [studentProgramFields, programTypes])

  const removeStudentProgramField = (fieldId: string) => {
    setFieldIdToRemove(fieldId)
  }

  const [studentProgramMap, setStudentProgramMap] = useState(
    new Map<string, StudentProgramFormField>()
  )
  // updates the values of programTypes for all selectors
  const updateAllSelectors = () => {
    setStudentProgramFields((currentStudentProgramFields) => {
      return currentStudentProgramFields.map((node) => {
        return {
          ...node,
          props: {
            ...node.props,
            programTypes: programTypes,
          },
        }
      })
    })
  }

  // Allows you to set when a program will be available for selection,
  // unavailable = availableSpots and spotsOfferedInCurrentInvite have the same value outOfSpots = true
  // available whenever availableSpots is greater than spotsOfferedInCurrentInvite outOfSpots = false
  useEffect(() => {
    if (availableSpots.length > 0) {
      const programWithoutAvailableSpots = availableSpots.find(
        (availableSpot) =>
          availableSpot.availableSpots ===
          availableSpot.spotsOfferedInCurrentInvite
      )
      if (!!programWithoutAvailableSpots) {
        programTypes.forEach((programType, index) => {
          if (
            programType.programKey === programWithoutAvailableSpots.programKey
          ) {
            programTypes[index].outOfSpots = true
          }
        })
      }
      programTypes.forEach((programType, index) => {
        if (programType.outOfSpots === true) {
          const programInAvailableSpots = availableSpots.find(
            (availableSpot) =>
              availableSpot.programKey === programType.programKey
          )
          if (!!programInAvailableSpots) {
            programInAvailableSpots.availableSpots >
              programInAvailableSpots.spotsOfferedInCurrentInvite &&
              (programTypes[index].outOfSpots = false)
          } else {
            programTypes[index].outOfSpots = false
          }
        } else {
          const search = availableSpots.find(
            (availableSpot) =>
              availableSpot.programKey === programType.programKey
          )
          if (!!search) {
            programTypes[index].outOfSpots =
              search.availableSpots === search.spotsOfferedInCurrentInvite
          }
        }
      })
      const areProgramsAvailable =
        programTypes
          .filter((programType) => programType.availableSpots > 0)
          .filter((program) => !program.outOfSpots).length === 0
      setHasNoAvailableSpots(areProgramsAvailable)
    }
  }, [availableSpots, programTypes, studentProgramFields])

  useEffect(() => {
    const haveFieldIdToRemoveAndFieldsAreLoaded =
      !!fieldIdToRemove && studentProgramFields.length > 0
    if (haveFieldIdToRemoveAndFieldsAreLoaded) {
      studentProgramFields.forEach((field, index) => {
        if (field.props.studentFieldId === fieldIdToRemove) {
          // Remove from our overall list of fields
          const modifiedStudentProgramFields = [...studentProgramFields]
          modifiedStudentProgramFields.splice(index, 1)
          setStudentProgramFields(modifiedStudentProgramFields)
          // And the ones we currently render
          studentProgramMap.delete(fieldIdToRemove)
        }
      })
      setFieldIdToRemove('')
    }
  }, [
    fieldIdToRemove,
    studentProgramFields,
    studentProgramFieldsWithProgramTypesByCommunity,
    studentProgramMap,
  ])
  const [fieldToMapState, setFieldToMapState] = useState<{
    fieldId: string
    state: StudentProgramFormField
  }>({
    fieldId: '',
    state: {
      isApplicationFeePaid: false,
      programType: programTypes[0]?.programType,
      semesterOneStartDate:
        programTypes[0] &&
        dateToDashString(
          reinterpretYearMonthDayAsLocalTime(
            programTypes[0]?.semesterOneStartDate
          )
        ),
      programKey: programTypes[0]?.programKey,
      availableSpots: programTypes[0]?.availableSpots,
    },
  })

  useEffect(() => {
    if (!!fieldToMapState.fieldId) {
      const modifiedStudentProgramMap = studentProgramMap.set(
        fieldToMapState.fieldId,
        fieldToMapState.state
      )
      setStudentProgramMap(modifiedStudentProgramMap)
      setProgramsAreValid(areProgramsValid(modifiedStudentProgramMap))
      setFieldToMapState({ fieldId: '', state: fieldToMapState.state })
    }
  }, [fieldToMapState, studentProgramMap])

  const updateStudentForParent = (
    state: StudentProgramFormField,
    fieldId: string
  ) => {
    setFieldToMapState({ fieldId, state })
  }

  // Ensures that the new program to be created has available Spots
  const verifyThatProgramHasAvailableSpots = (
    programType: ProgramInviteFamilyOptionsExtends,
    availableSpotsParam: AvailableSpots[]
  ): number => {
    const programInAvailableSpots = availableSpotsParam.find(
      (availableSpot) => availableSpot.programKey === programType.programKey
    )
    if (!!programInAvailableSpots) {
      return programInAvailableSpots.availableSpots ===
        programInAvailableSpots.spotsOfferedInCurrentInvite
        ? 0
        : programType.availableSpots
    } else {
      return programType.availableSpots
    }
  }

  const addStudentProgramField = () => {
    const dateTime = new Date().getTime()
    setStudentProgramFields([
      ...studentProgramFields,
      <StudentProgramFormFields
        handleAvailableSpots={handleAvailableSpots}
        handleRemoveOfferedInCurrentInvite={handleRemoveOfferedInCurrentInvite}
        key={`studentProgramFieldsKey-${dateTime}`}
        studentFieldId={`studentFieldId-${dateTime}`}
        studentFieldKey={`studentFieldKey-${dateTime}`}
        programFieldId={`programFieldId-${dateTime}`}
        programFieldKey={`programFieldKey-${dateTime}`}
        validationMessageMap={validationMessageMap}
        programTypes={programTypes}
        removeStudentProgramField={removeStudentProgramField}
        updateStudentForParent={updateStudentForParent}
        updateAllSelectors={updateAllSelectors}
        availableSpots={availableSpots}
        verifyThatProgramHasAvailableSpots={verifyThatProgramHasAvailableSpots}
      />,
    ])
    const firstProgramWithAvailableSpots = programTypes.find(
      (programType) =>
        verifyThatProgramHasAvailableSpots(programType, availableSpots) > 0
    )
    !!firstProgramWithAvailableSpots &&
      handleAddAvailableSpots(
        firstProgramWithAvailableSpots,
        `programFieldId-${dateTime}`
      )
  }

  const [parentFields, setParentFields] = useState<ParentFormField[]>([
    {
      fieldId: 'parentFirstNameField',
      inputName: 'parentFirstName',
      displayName: t(
        'Families.FamilyForm.FormField.ParentFirstName',
        'Parent First Name'
      ),
      maxTextLength: 50,
      required: true,
      inputType: AgreementTemplateFormFieldInputType.Text,
    },
    {
      fieldId: 'parentLastNameField',
      inputName: 'parentLastName',
      displayName: t(
        'Families.FamilyForm.FormField.ParentLastName',
        'Parent Last Name'
      ),
      maxTextLength: 50,
      required: true,
      inputType: AgreementTemplateFormFieldInputType.Text,
    },
    {
      fieldId: 'parentEmailAddressField',
      inputName: 'parentEmailAddress',
      displayName: t(
        'Families.FamilyForm.FormField.ParentEmailAddress',
        'Parent Email Address'
      ),
      maxTextLength: 50,
      required: true,
      inputType: AgreementTemplateFormFieldInputType.Text,
    },
    {
      fieldId: 'communitySelect',
      inputName: 'community',
      displayName: t('Families.FamilyForm.FormField.Community', 'Community'),
      required: true,
      inputType: AgreementTemplateFormFieldInputType.Dropdown,
      dropdownSelectOptions: [],
    },
  ])

  const [isInitialParentFieldLoad, setIsInitialParentFieldLoad] = useState(true)
  const {
    fieldStates: parentFieldStates,
    setFieldStates: setParentFieldStates,
  } = useDynamicFieldStates({
    fields: parentFields.map((field) => {
      const isCommunityField =
        field.inputType === AgreementTemplateFormFieldInputType.Dropdown
      const state =
        isCommunityField && location.state ? location.state.communityName : ''
      return {
        label: `${field.displayName}`,
        state: {
          state,
          isValid: {
            input: true,
            length: true,
            maxLength: true,
            minLength: true,
            email: true,
          },
          inputType: field.inputType,
          required: field.required,
        },
      }
    }),
    isInitialLoad: isInitialParentFieldLoad,
    setIsInitialLoad: setIsInitialParentFieldLoad,
  })

  const [isInitialStateLoad, setIsInitialStateLoad] = useState(true)
  const [parentUserKey, setParentUserKey] = useState(-1)

  useEffect(() => {
    if (
      location.state &&
      Object.values(parentFieldStates).length > 0 &&
      isInitialStateLoad &&
      !!userInfo
    ) {
      setParentUserKey(userInfo.id ?? 0)
      setParentFieldStates({
        ...parentFieldStates,
        [parentFields[0].displayName]: {
          ...parentFieldStates[parentFields[0].displayName],
          state:
            userInfo.firstName ??
            parentFieldStates[parentFields[0].displayName].state,
        },
        [parentFields[1].displayName]: {
          ...parentFieldStates[parentFields[1].displayName],
          state:
            userInfo.lastName ??
            parentFieldStates[parentFields[1].displayName].state,
        },
        [parentFields[2].displayName]: {
          ...parentFieldStates[parentFields[2].displayName],
          state: userInfo.username ?? '',
        },
      })
      setIsInitialStateLoad(false)
    }
  }, [
    parentFieldStates,
    setParentFieldStates,
    isInitialStateLoad,
    parentFields,
    userInfo,
    location.state,
  ])
  // to synchronize the Available Spots when the community changes
  // We reset the seats on community selection
  useEffect(() => {
    // If the community selector changes, we will also update our Program Capacity section
    if (programTypes.length > 0 && !!synchronizeDropDownKey) {
      return setAvailableSpots([])
    }
    return setAvailableSpots([])
  }, [programTypes, synchronizeDropDownKey])

  const updateParentFieldValueForParent = async <T,>(
    name: string,
    state: string,
    required: boolean,
    isOverMaxLength?: boolean,
    isUnderMinLength?: boolean,
    option?: T
  ) => {
    if (name === parentFields[3].displayName && !!state) {
      const key = option as unknown as number
      setCommunityKey(key)
      /** Cannot reset state here because of the iPad/safari
       *
       * See https://classicalconversations.my.workfront.com/issue/656756420005460a5a85837872d2595d/updates
       */
      addLoadingIds([fetchInviteFamilyProgramsId])
      const dateTime = new Date().getTime()
      setStudentProgramFields([])

      setStudentProgramMap(new Map<string, StudentProgramFormField>())
      setSynchronizeDropDownKey(`programFieldId-${dateTime}`)
    }
    setParentFieldStates({
      ...parentFieldStates,
      [name]: {
        ...parentFieldStates[name],
        state: name === parentFields[2].displayName ? state.trim() : state,
        isValid: {
          ...parentFieldStates[name].isValid,
          input: true,
          maxLength: !isOverMaxLength,
          minLength: !isUnderMinLength,
        },
        required: required,
        validationMessage: '',
      },
    })
  }

  useEffect(() => {
    if (communitiesOptionsLoaded && communityId) {
      setCommunityKey(communityId)
    }
  }, [communitiesOptionsLoaded, communityId, communityProgramsMap])

  useEffect(() => {
    if (communitiesOptionsLoaded) {
      const updateParentFieldStatesForCommunity = () => {
        const dropdownSelectOptions: MenuOption[] = communities.map((it) => {
          return { id: it.communityKey, name: it.communityName }
        })
        parentFields[3].dropdownSelectOptions = dropdownSelectOptions
        setParentFields(parentFields)
      }
      updateParentFieldStatesForCommunity()
      setCommunitiesOptionsLoaded(false)
    }
  }, [
    communitiesOptionsLoaded,
    communities,
    parentFieldStates,
    parentFields,
    programTypes,
    setParentFieldStates,
    studentProgramFields,
    validationMessageMap,
  ])

  const [programsAreValid, setProgramsAreValid] = useState(false)
  const [programSeats, setProgramSeats] = useState<ProgramInvite[]>([])

  const requiredFieldsAreEmpty = useMemo(() => {
    return areRequiredFieldsEmpty(parentFieldStates)
  }, [parentFieldStates])

  const areApplicationFeePaidBoxesUnchecked = (
    studentProgramMap: Map<string, StudentProgramFormField>
  ): boolean => {
    return Array.from(studentProgramMap.values()).some(
      (it) => !it.isApplicationFeePaid
    )
  }

  const applicationFeePaidBoxesUnchecked =
    areApplicationFeePaidBoxesUnchecked(studentProgramMap)

  const isSendInvitationDisabled = useMemo(() => {
    return (
      studentProgramFieldsWithProgramTypesByCommunity.length === 0 ||
      requiredFieldsAreEmpty ||
      !programsAreValid ||
      applicationFeePaidBoxesUnchecked
    )
  }, [
    studentProgramFieldsWithProgramTypesByCommunity,
    requiredFieldsAreEmpty,
    programsAreValid,
    applicationFeePaidBoxesUnchecked,
  ])

  const updateProgramSeats = () => {
    const seatMap = new Map<
      number /* programKey */,
      { key: number; seats: number }
    >()
    const invites: ProgramInvite[] = []
    studentProgramMap.forEach((field) => {
      const numberOfSeats = (seatMap.get(field.programKey)?.seats ?? 0) + 1
      seatMap.set(field.programKey, {
        key: field.programKey,
        seats: numberOfSeats,
      })
    })
    seatMap.forEach((seat) => {
      invites.push({
        programKey: seat.key,
        offeredSpots: seat.seats,
      })
    })
    const programsOfTheSameYear = loadProgramsInformation(invites)
    setProgramSeats(invites)

    return programsOfTheSameYear
  }

  const handleShowConfirmationDialog = () => {
    /**
     * if the `previous invitation` modal was displayed, and the user clicks `Accept`,
     * we need to close the modal and show the confirmation one
     */
    setShowPreviousInvitationsModal(false)
    if (fieldsDoValidate()) {
      //First make the object for program seats since the programs are valid
      const programsOfTheSameYear = updateProgramSeats()
      if (programsOfTheSameYear) {
        return setShowConfirmationModal(true)
      }
      setSnackbarState(true)
      setSnackbarMessage(familyErrorMessage)
      setSnackbarSeverity(SnackbarSeverity.Error)
      return
    } else {
      const e = {
        code: 'UnknownError',
        // We will always have a default string
        message: validationMessageMap.get(
          InviteFamilyValidationMessageTypes.Default
        ) as string,
      }
      setSnackbarState(true)
      setSnackbarMessage((e as unknown as Error).message)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  }

  const validatePreviousInvitations = async () => {
    try {
      const programKeys = [
        ...new Set(
          [...studentProgramMap.values()].map(
            (studentProgram) => studentProgram.programKey
          )
        ),
      ]

      const email = parentFieldStates['Parent Email Address'].state
      const { hasPreviousInvitations } = await invitesApi.validateInvitation({
        body: {
          email,
          programKeys,
        },
      })

      if (hasPreviousInvitations) {
        setShowPreviousInvitationsModal(true)
        return
      }

      handleShowConfirmationDialog()
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message:
          (e as unknown as Error).message ??
          defaultValidateInvitationErrorMessage,
      }
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarState(true)
    }
  }

  const handleConfirmationCancel = () => {
    setShowConfirmationModal(false)
    setShowPreviousInvitationsModal(false)
    return
  }

  const loadProgramsInformation = (
    selectedPrograms: ProgramInvite[]
  ): boolean => {
    let infoPrograms = ''
    const years: number[] = []
    selectedPrograms.forEach((programSeat, index) => {
      const result = programTypes.find(
        (program) => program.programKey === programSeat.programKey
      )
      if (!!result) {
        years.push(Number(UTCYearString(result.semesterOneStartDate)))
        infoPrograms = `${infoPrograms}${result.programType} (${UTCYearString(
          result.semesterOneStartDate
        )})${index === selectedPrograms.length - 1 ? '.' : ','} \n`
      }
    })
    const areProgramsOfTheSameYear = years.every((year) => year === years[0])
    setInviteFamilyProgramsInformation(infoPrograms)
    return areProgramsOfTheSameYear
  }

  const familyButtons = (
    <ActionButtons
      primaryButtonLoadingId={InviteFamilyFormCard.inviteFamily}
      primaryButtonLabel={ContainedButtonVariant.Send}
      secondaryButtonLabel={TextButtonVariant.Cancel}
      secondaryClick={handleConfirmationCancel}
      useBaseButton
    />
  )

  const headerMessage = `${t(
    'Families.FamilyForm.ConfirmationModal.Header',
    'You are about to send an invitation to'
  )} ${parentFieldStates[parentFields[2].displayName]?.state}`

  const familyConfirmationMessage = t(
    'Families.FamilyForm.ConfirmationModal.Body',
    'to join {{programsInformation}} Clicking "Send" will email the recipient an invitation with instructions on how to complete the next enrollment steps and pay the membership fee.',
    {
      programsInformation: inviteFamilyProgramsInformation,
    }
  )

  const familyErrorMessage = t(
    'Families.FamilyForm.ErrorModal.Body',
    'Unable to send invitations for multiple years. Please select programs from the same Academic Year.'
  )

  const familyConfirmationBody = (
    <Typography variant="body1" component="p" align="center">
      {formatYearsInText({
        text: familyConfirmationMessage,
        color: theme.palette.error.main,
      })}
    </Typography>
  )

  const sendFamilyInvitation = async () => {
    try {
      await inviteFamily({
        parentFirstName: parentFieldStates[parentFields[0].displayName].state,
        parentLastName: parentFieldStates[parentFields[1].displayName].state,
        parentEmail: parentFieldStates[parentFields[2].displayName].state,
        communityKey: communityKey as number, // If we are sending an invitation, this is a number
        programs: programSeats,
        parentUserKey: parentUserKey >= 0 ? parentUserKey : undefined,
      })
      navigate(
        {
          pathname: redirectionUrl,
          search: urlParams,
        },
        {
          state: {
            snackbarState: true,
            snackbarMessage: t(
              'Families.FamilyForm.SuccessMessage.SentInvitation',
              `Sent invitation to the {{familyName}} family to enroll in programs in {{community}}`,
              {
                familyName:
                  parentFieldStates[parentFields[1].displayName].state,
                community: parentFieldStates[parentFields[3].displayName].state,
              }
            ),
            snackbarSeverity: SnackbarSeverity.Success,
            isFamilyTabSelected: true,
          },
        }
      )
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown Error',
        message:
          (e as unknown as Error).message ??
          validationMessageMap.get(
            InviteFamilyValidationMessageTypes.InviteFamily
          ),
      }
      const messages = errorObject.message.split('\n')
      for (const message of messages) {
        if (message) {
          addSnackbarKey(enqueueSnackbar(message, { variant: 'error' }))
        }
      }
    }
  }

  useLoadingContext({
    asyncFunction: sendFamilyInvitation,
    loadingId: InviteFamilyFormCard.inviteFamily,
  })

  const handleAgreementConfirmationSubmit = async (
    event: React.FormEvent<HTMLDivElement>
  ) => {
    event.preventDefault()
    //no op
  }

  const handleInvoiceConfirmationModalConfirm = (
    event: React.FormEvent<HTMLDivElement>
  ) => {
    event.preventDefault()
    setIsInvoiceConfirmationModalOpen(false)
  }

  const familyConfirmationProps = {
    isOpen: showConfirmationModal,
    dialogTitle: headerMessage,
    dialogContent: familyConfirmationBody,
    dialogActions: familyButtons,
    handleFormSubmit: handleAgreementConfirmationSubmit,
    ...props,
  }

  if (isLoading) {
    return (
      <>
        <CircularProgress />
      </>
    )
  }

  const allProgramsAvailableSpots = (
    programs: ProgramInviteFamilyOptionsExtends[]
  ): AvailableSpots[] => {
    const availableSpots: AvailableSpots[] = []
    for (const program of programs) {
      const dateTime = new Date().getTime()
      const availableSpot = {
        programKey: program?.programKey ?? 0,
        programType: program?.programType ?? '',
        dateProgram: program?.semesterOneStartDate ?? new Date(),
        availableSpots: program?.availableSpots ?? 0,
        spotsOfferedInCurrentInvite: 0,
        dropdownsKeys: [`programFieldId-${dateTime}`],
      }
      availableSpots.push(availableSpot)
    }

    return availableSpots
  }

  // The selected community should have programs
  const hasPrograms = programTypes && programTypes.length > 0
  // Make sure the community has programs and at least on of the programs has available spots
  const hasCommunityProgramsWithAvailableSpots =
    hasPrograms && hasAvailableSpotsInPrograms(programTypes)
  // Ensure the fetchInviteFamilyPrograms endpoint was already called
  const hasFetchInviteFamilyProgramsIdLoaded = !loadingIds.has(
    fetchInviteFamilyProgramsId
  )
  // The community select name is in the state
  const hasCommunitySelectName =
    !!parentFieldStates[parentFields[3].displayName]?.state
  // Ensure the student programs field have values
  const hasStudentProgramFields = studentProgramFields.length > 0
  // Should show the Program capacity section for each of the student programs that is been added
  const isShowingProgramCapacityForStudentProgram =
    hasStudentProgramFields && hasCommunityProgramsWithAvailableSpots
  // Should show program capacity section for all programs in the community when none of the programs has available spots
  const isShowingProgramCapacityWithoutAvailableSpot =
    hasPrograms && !hasCommunityProgramsWithAvailableSpots
  // The community name and program endpoint should be loaded
  const hasCommunityAndProgramsEndPointCalled =
    hasCommunitySelectName && hasFetchInviteFamilyProgramsIdLoaded
  // The button 'student & program' should show as disabled when hasCommunityAndProgramEndpoint true and the hasCommunityProgramsWithAvailableSpots false
  const hasStudentAndProgramButtonDisabled =
    hasCommunityAndProgramsEndPointCalled &&
    hasPrograms &&
    !hasCommunityProgramsWithAvailableSpots
  // 'student & program' button should show enabled when hasCommunityAndProgramEndpoint true and the hasCommunityProgramsWithAvailableSpots true
  const hasStudentAndProgramsButtonEnabled =
    hasCommunityAndProgramsEndPointCalled &&
    hasCommunityProgramsWithAvailableSpots

  const dialogContentListItems = [
    t(
      'InviteFamilyFormCard.ConfirmationModal.Reminder.LicensingAmountDue',
      'Licensing amount due will increase once SEND is clicked'
    ),
    t(
      'InviteFamilyFormCard.ConfirmationModal.Reminder.PayAllApplicationAndTuition',
      'Pay all application and tuition related licensing fees within 2 weeks of receipt'
    ),
    t(
      'InviteFamilyFormCard.ConfirmationModal.Reminder.FinalPayment',
      'Final payment for all Semester 1 licensing fees is due 9/5'
    ),
  ]

  return (
    <>
      <BasicModal
        isOpen={isInvoiceConfirmationModalOpen}
        dialogTitle={t(
          'InviteFamilyFormCard.ConfirmationModal.Reminder.Title',
          'Please Remember!!'
        )}
        maxWidth={'xs'}
        handleFormSubmit={handleInvoiceConfirmationModalConfirm}
        dialogActions={
          <ActionButtons primaryButtonLabel={ContainedButtonVariant.Ok} />
        }
        dialogContent={
          <Box maxWidth={300} mx="auto" textAlign="center">
            <List>
              {dialogContentListItems.map((dialogContentListItem, index) => (
                <ListItem disablePadding disableGutters key={index}>
                  <ListItemIcon sx={{ minWidth: 28 }}>
                    <CircleIcon sx={{ fontSize: 14 }} />
                  </ListItemIcon>
                  <ListItemText primary={dialogContentListItem} />
                </ListItem>
              ))}
            </List>
          </Box>
        }
      />
      <InviteAFamilyCard component={Paper}>
        <section aria-labelledby="inviteAFamilyHeader">
          <CardFormHeader
            header={
              <Header
                id="inviteAFamilyHeader"
                headerName={inviteFamilyFormHeader}
                component="h2"
                variant={HeaderVariant.Card}
              />
            }
            buttons={
              showOnDesktop ? (
                <>
                  <TextButton
                    id="cancel"
                    onClick={handleCancel}
                    variant={TextButtonVariant.Cancel}
                  />
                  <ContainedButton
                    id="sendInvitation"
                    variant={ContainedButtonVariant.SendInvitation}
                    onClick={validatePreviousInvitations}
                    disabled={isSendInvitationDisabled}
                  />
                </>
              ) : null
            }
            headerContainerProps={{ margin: 0 }}
          />
          <Form>
            <SectionOne>
              {Object.values(parentFieldStates).length > 0 && (
                <ParentFields
                  parentFields={parentFields}
                  updateParentFieldValueForParent={
                    updateParentFieldValueForParent
                  }
                  parentFieldStates={parentFieldStates}
                />
              )}
            </SectionOne>
            {isShowingProgramCapacityForStudentProgram ? (
              <InviteFamilyProgramCapacityContentCard
                availableSpots={availableSpots}
                showOnDesktop={showOnDesktop}
              />
            ) : (
              isShowingProgramCapacityWithoutAvailableSpot && (
                <InviteFamilyProgramCapacityContentCard
                  availableSpots={allProgramsAvailableSpots(programTypes)}
                  showOnDesktop={showOnDesktop}
                />
              )
            )}
            <FormDivider />
            <Header
              id="studentInvitations"
              headerName={studentInvitationsHeader}
              component="h3"
              variant={HeaderVariant.Card}
            />
            <SectionTwo aria-labelledby="studentInvitations">
              {hasStudentAndProgramButtonDisabled ? (
                <>
                  <Box
                    sx={{
                      padding: theme.spacing(1, 0),
                      [theme.breakpoints.down('sm')]: {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: theme.spacing(1, 0),
                      },
                    }}
                  >
                    <IconTextButton
                      id="addStudentAndProgramButton"
                      variant={IconTextButtonVariant.StudentAndProgram}
                      startIcon={
                        <AddCircleIcon
                          style={{
                            height: '32px',
                            width: '32px',
                          }}
                        />
                      }
                      onClick={addStudentProgramField}
                      disabled={!hasAvailableSpotsInPrograms(programTypes)}
                    />
                  </Box>
                </>
              ) : hasStudentAndProgramsButtonEnabled ? (
                <>
                  {studentProgramFieldsWithProgramTypesByCommunity.map(
                    (it) => it
                  )}
                  <Box
                    sx={{
                      padding: theme.spacing(1, 0),
                      [theme.breakpoints.down('sm')]: {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: theme.spacing(1, 0),
                      },
                    }}
                  >
                    <IconTextButton
                      id="addStudentAndProgramButton"
                      variant={IconTextButtonVariant.StudentAndProgram}
                      startIcon={
                        <AddCircleIcon
                          style={{
                            height: '32px',
                            width: '32px',
                          }}
                        />
                      }
                      onClick={addStudentProgramField}
                      disabled={hasNoAvailableSpots}
                    />
                  </Box>
                </>
              ) : (
                <Typography variant="subtitle2" component="p">
                  {t(
                    'InviteFamily.NoProgramForCommunity.Error',
                    'No Programs Found for Community'
                  )}
                </Typography>
              )}
            </SectionTwo>
            {!showOnDesktop ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: theme.spacing(14),
                }}
              >
                <ContainedButton
                  id="sendInvitation"
                  variant={ContainedButtonVariant.SendInvitation}
                  onClick={validatePreviousInvitations}
                  disabled={isSendInvitationDisabled}
                />
                <TextButton
                  id="cancel"
                  onClick={handleCancel}
                  variant={TextButtonVariant.Cancel}
                />
              </Box>
            ) : null}
          </Form>
        </section>
        {/** Error Snackbar will pop up if a field is left empty when trying to Save. */}
      </InviteAFamilyCard>
      <ConfirmationModal {...familyConfirmationProps} />
      <PreviousInvitationsModal
        showModal={showPreviousInvitationsModal}
        handleConfirmationCancel={handleConfirmationCancel}
        onAccept={handleShowConfirmationDialog}
      />
    </>
  )
}

const isProgramTypeInAvailableOptions = (
  type: ProgramType,
  options: ProgramInviteFamilyOptions[]
) => {
  return options.some((option) => option.programType === type)
}

const getProgramLabelValue = (state: Partial<StudentProgramFormField>) => {
  return `${state.programType} (${state.semesterOneStartDate}) (${state.availableSpots}) (${state.programKey})`
}

interface StudentProgramFormFieldsProps {
  studentFieldId: string
  studentFieldKey: string
  programFieldId: string
  programFieldKey: string
  validationMessageMap: Map<string, string>
  programTypes: ProgramInviteFamilyOptionsExtends[]
  removeStudentProgramField?: (fieldId: string) => void
  updateStudentForParent: (
    state: StudentProgramFormField,
    fieldId: string
  ) => void
  isStudentValid?: FieldValidity
  handleAvailableSpots: (
    programInformation: ProgramInviteFamilyOptionsResponsePrograms,
    dropDownIdentifier: string
  ) => void
  handleRemoveOfferedInCurrentInvite: (dropDownIdentifier: string) => void
  updateAllSelectors: () => void
  availableSpots: AvailableSpots[]
  verifyThatProgramHasAvailableSpots: (
    programType: ProgramInviteFamilyOptionsExtends,
    availableSpotsParam: AvailableSpots[]
  ) => number
}

const StudentProgramFormFields: React.FC<StudentProgramFormFieldsProps> = (
  props
) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const programTypes = useMemo(() => {
    return props.programTypes
  }, [props])

  const initialProgramKey = useMemo(() => {
    return programTypes[0].programKey
  }, [programTypes])

  const initialOutOfSpots = programTypes[0].outOfSpots

  const initialProgramType = useMemo(() => {
    return programTypes.length === 0 ? '' : programTypes[0]?.programType
  }, [programTypes])

  const initialProgramAvailableSpots = useMemo(() => {
    return programTypes.length === 0 ? 0 : programTypes[0]?.availableSpots
  }, [programTypes])

  const initialSemesterOneDate = useMemo(() => {
    return programTypes.length === 0
      ? ''
      : dateToDashString(
          reinterpretYearMonthDayAsLocalTime(
            programTypes[0]?.semesterOneStartDate
          )
        )
  }, [programTypes])

  const [state, setState] = useState<StudentProgramFormField>({
    isApplicationFeePaid: false,
    programType: initialProgramType,
    semesterOneStartDate: initialSemesterOneDate,
    programKey: initialProgramKey,
    availableSpots: initialProgramAvailableSpots,
    outOfSpots: initialOutOfSpots,
  })

  const [isInitialLoad, setIsInitialLoad] = useState(true)

  useEffect(() => {
    if (isInitialLoad) {
      props.updateStudentForParent(state, props.studentFieldId)
      setIsInitialLoad(false)
    }
  }, [isInitialLoad, props, state, initialProgramKey])

  useEffect(() => {
    const selectedProgram = programTypes.find(
      (programType) =>
        props.verifyThatProgramHasAvailableSpots(
          programType,
          props.availableSpots
        ) > 0
    )
    if (
      (state.programType !== '' &&
        !isProgramTypeInAvailableOptions(
          state.programType as ProgramType,
          programTypes
        ) &&
        selectedProgram) ||
      (state.availableSpots <= 0 && selectedProgram) ||
      (state.outOfSpots === true && selectedProgram)
    ) {
      setState({
        isApplicationFeePaid: state.isApplicationFeePaid,
        programType: selectedProgram?.programType,
        semesterOneStartDate:
          selectedProgram &&
          dateToDashString(
            reinterpretYearMonthDayAsLocalTime(
              selectedProgram?.semesterOneStartDate
            )
          ),
        programKey: selectedProgram?.programKey,
        availableSpots: selectedProgram?.availableSpots,
      })
    }
  }, [programTypes, props, props.availableSpots, state])

  const searchProgramType = (
    programKey: number
  ): ProgramInviteFamilyOptionsResponsePrograms | undefined => {
    return props.programTypes.find(
      (programType) => programType.programKey === programKey
    )
  }

  const handleStudentProgramTypeChange = (
    event: SelectChangeEvent<unknown>,
    child: ReactNode
  ) => {
    const updatedValue = event.target.value as string

    const [programType, semesterOneStartDate, availableSpots] =
      updatedValue.split(' (')

    // Get the key as a React Key, stringify it, and remove the .$ for React Keys, then convert to a number
    const programKey: number = +((child as ReactElement).key
      ?.toString()
      .slice(2) as string)

    const newStateObj = {
      ...state,
      programType: programTypeMap.get(programType) ?? state.programType,
      semesterOneStartDate: semesterOneStartDate.substring(
        // Removes parentheses
        0,
        semesterOneStartDate.length - 1
      ),
      programKey,
      availableSpots: +availableSpots.substring(
        // Removes parentheses
        0,
        availableSpots.length - 1
      ),
    }

    setState(newStateObj)
    const programInformation =
      searchProgramType(programKey) ??
      ({} as ProgramInviteFamilyOptionsResponsePrograms)
    props.updateStudentForParent(newStateObj, props.studentFieldId)
    props.handleAvailableSpots(programInformation, props.programFieldId)
    props.updateAllSelectors()
  }

  const hasAvailableSpots = hasAvailableSpotsInPrograms(programTypes)

  const isProgramDisabled =
    !programTypes ||
    (!!programTypes && programTypes.length === 1) ||
    !hasAvailableSpots

  const programTypeExists = programTypes.some((programType) => {
    const programTypeAsState: Partial<StudentProgramFormField> = {
      programType: programType.programType,
      programKey: programType.programKey,
      semesterOneStartDate: `${dateToDashString(
        reinterpretYearMonthDayAsLocalTime(programType.semesterOneStartDate)
      )}`,
      availableSpots: programType.availableSpots,
    }

    return (
      getProgramLabelValue(programTypeAsState) === getProgramLabelValue(state)
    )
  })

  const programFieldValue =
    !!state.programType && !!state.semesterOneStartDate && programTypeExists
      ? getProgramLabelValue(state)
      : ''

  const programFieldKey =
    !!state.programKey && !!state.semesterOneStartDate ? state.programKey : ''

  const handleApplicationFeePaidChange = (currentState: boolean) => {
    setState({
      ...state,
      isApplicationFeePaid: !currentState,
    })
    props.updateStudentForParent(
      {
        ...state,
        isApplicationFeePaid: !currentState,
      },
      props.studentFieldId
    )
  }

  const handleRemoveField = () => {
    !!props.removeStudentProgramField &&
      props.removeStudentProgramField(props.studentFieldId)
    props.handleRemoveOfferedInCurrentInvite(props.programFieldId)
  }

  const isSelectorDisabled = (
    programType: ProgramInviteFamilyOptionsExtends
  ): boolean => {
    const isTheSameSelector = props.availableSpots.find((availableSpot) =>
      availableSpot.dropdownsKeys.includes(props.programFieldId)
    )
    if (!!isTheSameSelector) {
      return false
    } else {
      return programType.availableSpots === 0 || !!programType.outOfSpots
    }
  }

  return (
    <>
      <Box display={'flex'} alignItems="center">
        <PersonIcon
          sx={{
            color: theme.palette.textOrIcon.personIcon,
            height: '48px',
            width: '48px',
          }}
        />
        <TextField
          id={props.programFieldId}
          key={programFieldKey}
          label={t('Families.FamilyForm.FormField.Program', 'Program')}
          variant="filled"
          select={!isProgramDisabled}
          disabled={isProgramDisabled}
          value={programFieldValue}
          SelectProps={{ onChange: handleStudentProgramTypeChange }}
          fullWidth
        >
          {programTypes?.map((it) => (
            <MenuItem
              disabled={isSelectorDisabled(it)}
              key={it.programKey}
              value={`${it.programType} (${dateToDashString(
                reinterpretYearMonthDayAsLocalTime(it.semesterOneStartDate)
              )}) (${it.availableSpots}) (${it.programKey})`}
            >
              {`${it.programType} (${dateToDashString(
                reinterpretYearMonthDayAsLocalTime(it.semesterOneStartDate)
              )})`}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box
        sx={{
          gridColumn: '2 / span 2',
          display: 'grid',
          gridTemplateColumns: '50% 50%',
          [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: theme.spacing(1, 0),
          },
        }}
      >
        {!!state.programType && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.isApplicationFeePaid}
                  name="applicationFeePaid"
                  onChange={() =>
                    handleApplicationFeePaidChange(state.isApplicationFeePaid)
                  }
                  sx={{
                    color: theme.palette.textOrIcon.checkbox,
                    padding: theme.spacing(0, 0, 0, 1),
                    [theme.breakpoints.down('sm')]: {
                      padding: theme.spacing(1, 0),
                    },
                  }}
                />
              }
              label={
                <Typography variant="subtitle2" component="p">
                  {t(
                    'Families.FamilyForm.Checkbox.ApplicationFeeReceived',
                    'Application fee received from family'
                  )}
                </Typography>
              }
            />
            <TextButton
              id={`removeButton-${props.programFieldKey}`}
              variant={TextButtonVariant.Remove}
              onClick={handleRemoveField}
            />
          </>
        )}
      </Box>
    </>
  )
}

function hasAvailableSpotsInPrograms(
  programTypes: ProgramInviteFamilyOptionsResponsePrograms[]
): boolean {
  let totalAvailableSpots = 0

  programTypes.forEach((programType) => {
    totalAvailableSpots += programType.availableSpots
  })
  return totalAvailableSpots !== 0
}

export default InviteFamilyFormCard

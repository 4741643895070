/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface Program1
 */
export interface Program1 {
  /**
   * The name of the community whose programs the family has students enrolled in.
   * @type {number}
   * @memberof Program1
   */
  communityKey: number
  /**
   * The unique identifier of the program the family has students enrolled in.
   * @type {number}
   * @memberof Program1
   */
  programKey: number
  /**
   * The name of the program the family has students enrolled in.
   * @type {string}
   * @memberof Program1
   */
  programName: string
  /**
   *  The year of the semester 1 start date of the program.
   * @type {number}
   * @memberof Program1
   */
  year: number
}

export function Program1FromJSON(json: any): Program1 {
  return Program1FromJSONTyped(json, false)
}

export function Program1FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Program1 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    communityKey: json['communityKey'],
    programKey: json['programKey'],
    programName: json['programName'],
    year: json['year'],
  }
}

export function Program1ToJSON(value?: Program1 | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    communityKey: value.communityKey,
    programKey: value.programKey,
    programName: value.programName,
    year: value.year,
  }
}

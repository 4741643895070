import React, { PropsWithChildren } from 'react'
import { useBeacon } from './api/beacon'
import Appbar from './components/Appbar/Appbar'
import Sidebar from './components/Sidebar/Sidebar'
import TitleContext from './TitleContext'
import { styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import SpeedDialMenu from './components/Elements/SpeedDialMenu'
import { useSpeedDialMenuContext } from './components/Context/SpeedDialMenuContext'
import { useSidebarLogic } from './hooks/useSidebarLogic'

const drawerWidth = 240

const RootDiv = styled('div')({
  display: 'flex',
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // TODO: https://mui.com/material-ui/react-app-bar/#fixed-placement
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

interface ContentContainerProps extends PropsWithChildren {
  shiftContent: boolean
}

// Reference attaching props when changing margin programmatically https://emotion.sh/docs/with-props
const ContentContainer: React.FC<ContentContainerProps> = ({
  children,
  shiftContent,
}) => {
  return (
    <main
      style={{
        width: '100%',
        flexGrow: 1,
        margin: `0 0 0 ${shiftContent ? 0 : -drawerWidth}`,
      }}
    >
      {children}
    </main>
  )
}

export const AppScaffold: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { title } = React.useContext(TitleContext)
  const { openSidebar, toggleOpenSidebar, shiftContent } = useSidebarLogic()

  useBeacon(process.env.REACT_APP_HELP_SCOUT_BEACON_LOGGED_IN_ID)

  /**
   * This is purposefully in AppScaffold so the SpeedDialMenu can be
   * tested without context value updates.
   */
  const { actions, joyrideSteps, joyrideCompleteId, stepIndex, isOpen } =
    useSpeedDialMenuContext()

  return (
    <RootDiv>
      <Appbar
        open={shiftContent}
        toggleOpen={toggleOpenSidebar}
        title={title}
      />
      <Sidebar
        open={openSidebar}
        toggleOpen={toggleOpenSidebar}
        shiftContent={shiftContent}
      />
      <ContentContainer shiftContent={shiftContent}>
        {/* https://mui.com/material-ui/react-app-bar/#fixed-placement */}
        <Toolbar />
        <DrawerHeader />
        {/* 
        I'd love to put this in App.tsx, but we wouldn't be able to pass in actions
        to the component rather than pulling them in SpeedDialMenu. passing them makes
        testing the component so much easier.
         */}
        <SpeedDialMenu
          actions={actions}
          joyrideSteps={joyrideSteps}
          joyrideCompleteId={joyrideCompleteId}
          stepIndex={stepIndex}
          isOpen={isOpen}
        />
        {children}
      </ContentContainer>
    </RootDiv>
  )
}

export default AppScaffold

import React from 'react'
import { useTranslation } from 'react-i18next'
import TableHeaders from '../../Interfaces/TableHeaders'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Registrant } from '../../../swagger'
import { Box } from '@mui/system'
import { getCommaSeparatedList } from '../../../utils/getCommaSeparatedList'
import { TableHeaderForPDF } from '../../Table/TableHeaderForPDF'
import { styles } from './styles'
import { Event } from '../../../swagger/models/Event'
import { formatFullDateString } from '../../../utils/dateUtility'

const widthForName = '10%'
const witdhForEmail = '22%'
const witdhForPhone = '14%'
const witdhForAttending = '9%'
const witdhForRoles = '35%'

const RegistrantsPDF: React.FC<{ registrants: Registrant[]; event: Event }> = ({
  registrants,
  event,
}) => {
  const { t } = useTranslation()

  const tableHeaders: TableHeaders[] = [
    {
      label: t('RegistrantsPDf.Table.Header.FirstName', 'First Name'),
      align: 'left',
      cssProps: { ...styles.tableHeaderCell, width: widthForName },
    },
    {
      label: t('RegistrantsPDf.Table.Header.LastName', 'Last Name'),
      align: 'left',
      cssProps: { ...styles.tableHeaderCell, width: widthForName },
    },
    {
      label: t('RegistrantsPDf.Table.Header.Email', 'Email'),
      align: 'left',
      cssProps: { ...styles.tableHeaderCell, width: witdhForEmail },
    },
    {
      label: t('RegistrantsPDf.Table.Header.PhoneNumber', 'Phone'),
      align: 'left',
      cssProps: { ...styles.tableHeaderCell, width: witdhForPhone },
    },
    {
      label: t('RegistrantsPDf.Table.Header.Attending', 'Attending'),
      align: 'left',
      cssProps: { ...styles.tableHeaderCell, width: witdhForAttending },
    },
    {
      label: t('RegistrantsPDf.Table.Header.Roles', 'Roles'),
      align: 'left',
      cssProps: { ...styles.tableHeaderCell, width: witdhForRoles },
    },
  ]

  const buildAddressInformation = () => {
    const { city = '', state = '', zip = '' } = event.address || {}
    return [city, state, zip].filter(Boolean)
  }

  const isSameDate =
    event.endDate && event.startDate.getTime() === event.endDate.getTime()

  return (
    <Box>
      <div style={styles.eventInfo}>
        <Typography
          style={styles.title}
          variant="subtitle1"
          component="h5"
          justifyContent={'flex-start'}
        >
          {t(
            'RegistrantsPDf.Section.EventInformation',
            'Event Registrants - {{eventInfo}}',
            {
              eventInfo: `${event.eventType} - ${event.name}`,
            }
          )}
        </Typography>

        <div style={{ fontSize: '9px', left: 6, paddingBottom: 6 }}>
          {formatFullDateString(event.startDate)} {event.startTime} -{' '}
          {isSameDate || !event.endDate
            ? ''
            : formatFullDateString(event.endDate)}{' '}
          {event.endTime} - {event.timezone}
        </div>

        <div style={{ fontSize: '9px', left: 6 }}>
          {t(
            'RegistrantsPDf.Section.AddressInformation',
            'Registration required. Details to come, {{addressInfo}}',
            {
              addressInfo: getCommaSeparatedList(
                buildAddressInformation()
              ).replace(' y ', ' , '),
            }
          )}
        </div>
      </div>
      <Box sx={styles.containerTable}>
        <Table
          aria-labelledby={'registrants-table-for-pdf'}
          style={styles.table}
        >
          <TableHead>
            <TableHeaderForPDF tableHeaders={tableHeaders} />
          </TableHead>
          <TableBody>
            {registrants.map((row) => (
              <TableRow key={row.id}>
                <TableCell
                  style={{
                    ...styles.tableBodyCell,
                    width: widthForName,
                  }}
                >
                  {row.firstName}
                </TableCell>
                <TableCell
                  style={{
                    ...styles.tableBodyCell,
                    width: widthForName,
                  }}
                >
                  {row.lastName}
                </TableCell>
                <TableCell
                  style={{
                    ...styles.tableBodyCell,
                    width: witdhForEmail,
                  }}
                >
                  {row.email}
                </TableCell>
                <TableCell
                  style={{
                    ...styles.tableBodyCell,
                    width: witdhForPhone,
                  }}
                >
                  {row.phone}
                </TableCell>
                <TableCell
                  style={{
                    ...styles.tableBodyCell,
                    width: witdhForAttending,
                  }}
                >
                  {row.attending ? 'Yes' : 'No'}
                </TableCell>
                <TableCell
                  style={{
                    ...styles.tableBodyCell,
                    width: witdhForRoles,
                  }}
                >
                  {row.roles && row.roles.length > 0
                    ? row.roles && row.roles.length > 1
                      ? getCommaSeparatedList(row.roles)
                      : row.roles
                    : t('RegistrantsPDF.Table.Cell.NoRole', '')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  )
}

export default RegistrantsPDF

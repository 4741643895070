/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  Event,
  EventFromJSON,
  Events,
  Events1,
  Events1FromJSON,
  EventsFromJSON,
  EventToJSON,
  PublicEvent,
  PublicEventFromJSON,
  Registrant,
  Registrants,
  RegistrantsFromJSON,
  RegistrantToJSON,
  SearchPublicEventsReply,
  SearchPublicEventsReplyFromJSON,
} from '../models'
import * as runtime from '../runtime'

export interface CreateEventRequest extends runtime.BaseRequestParameters {
  body?: Event
}

export interface CreateRegistrantRequest extends runtime.BaseRequestParameters {
  body?: Registrant
}

export interface DeleteEventRequest extends runtime.BaseRequestParameters {
  eventKey: number
}

export interface FetchEventRequest extends runtime.BaseRequestParameters {
  eventKey: number
}

export interface FetchEventRegistrantsRequest
  extends runtime.BaseRequestParameters {
  eventKey: number
  page?: number
  pageSize?: number
  orderBy?: Array<string>
}

export interface FetchEventsRequest extends runtime.BaseRequestParameters {
  page?: number
  pageSize?: number
  orderBy?: Array<string>
  eventType?: string
  search?: string
  eventCreator?: string
  fromDate?: string
  toDate?: string
}

export interface FetchInternationalEventsRequest
  extends runtime.BaseRequestParameters {
  isGlobalEvent: boolean
  fromDate: string
  toDate: string
  eventType?: string
  countryCode?: string
}

export interface FetchPublicEventDetailsRequest
  extends runtime.BaseRequestParameters {
  publicEventId: string
}

export interface SearchPublicEventsRequest
  extends runtime.BaseRequestParameters {
  postalCode?: string
  usCitiesKey?: number
  milesRadius?: number
  fromDate?: Date
  showOnlineEvents?: boolean
  eventType?: SearchPublicEventsEventTypeEnum
}

export interface UpdateEventRequest extends runtime.BaseRequestParameters {
  body?: Event
}

/**
 *
 */
export class EventsApi extends runtime.BaseAPI {
  /**
   * Create a new Event.
   */
  async createEventRaw(
    requestParameters: CreateEventRequest
  ): Promise<runtime.ApiResponse<Event>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/events`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: EventToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EventFromJSON(jsonValue)
    )
  }

  /**
   * Create a new Event.
   */
  async createEvent(requestParameters: CreateEventRequest): Promise<Event> {
    const response = await this.createEventRaw(requestParameters)
    return await response.value()
  }

  /**
   * A registrant can sign up for an event with basic contact information.
   * Add a new registrant to an event.
   */
  async createRegistrantRaw(
    requestParameters: CreateRegistrantRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/registrants`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: RegistrantToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * A registrant can sign up for an event with basic contact information.
   * Add a new registrant to an event.
   */
  async createRegistrant(
    requestParameters: CreateRegistrantRequest
  ): Promise<void> {
    await this.createRegistrantRaw(requestParameters)
  }

  /**
   * Delete an event.
   */
  async deleteEventRaw(
    requestParameters: DeleteEventRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.eventKey === null ||
      requestParameters.eventKey === undefined
    ) {
      throw new runtime.RequiredError(
        'eventKey',
        'Required parameter requestParameters.eventKey was null or undefined when calling deleteEvent.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/events/{eventKey}`.replace(
        `{${'eventKey'}}`,
        encodeURIComponent(String(requestParameters.eventKey))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Delete an event.
   */
  async deleteEvent(requestParameters: DeleteEventRequest): Promise<void> {
    await this.deleteEventRaw(requestParameters)
  }

  /**
   * Provide event details given an event identifier.    ## PERMISSIONS:   - The requester must have an active Event/viewDetail permission, else a 403 status code will be returned.   - A Event/viewDetail[their team\'s] permission allows the requester to fetch event within their downline.   - A Event/viewDetail[any] permission allows the requester to fetch an event that anyone owns in the system.
   * Fetch an existing event given an identifier.
   */
  async fetchEventRaw(
    requestParameters: FetchEventRequest
  ): Promise<runtime.ApiResponse<Event>> {
    if (
      requestParameters.eventKey === null ||
      requestParameters.eventKey === undefined
    ) {
      throw new runtime.RequiredError(
        'eventKey',
        'Required parameter requestParameters.eventKey was null or undefined when calling fetchEvent.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/events/{eventKey}`.replace(
        `{${'eventKey'}}`,
        encodeURIComponent(String(requestParameters.eventKey))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EventFromJSON(jsonValue)
    )
  }

  /**
   * Provide event details given an event identifier.    ## PERMISSIONS:   - The requester must have an active Event/viewDetail permission, else a 403 status code will be returned.   - A Event/viewDetail[their team\'s] permission allows the requester to fetch event within their downline.   - A Event/viewDetail[any] permission allows the requester to fetch an event that anyone owns in the system.
   * Fetch an existing event given an identifier.
   */
  async fetchEvent(requestParameters: FetchEventRequest): Promise<Event> {
    const response = await this.fetchEventRaw(requestParameters)
    return await response.value()
  }

  /**
   * List of registrants for a given event.
   */
  async fetchEventRegistrantsRaw(
    requestParameters: FetchEventRegistrantsRequest
  ): Promise<runtime.ApiResponse<Registrants>> {
    if (
      requestParameters.eventKey === null ||
      requestParameters.eventKey === undefined
    ) {
      throw new runtime.RequiredError(
        'eventKey',
        'Required parameter requestParameters.eventKey was null or undefined when calling fetchEventRegistrants.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize
    }

    if (requestParameters.orderBy) {
      queryParameters['orderBy'] = requestParameters.orderBy.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/events/{eventKey}/registrants`.replace(
        `{${'eventKey'}}`,
        encodeURIComponent(String(requestParameters.eventKey))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RegistrantsFromJSON(jsonValue)
    )
  }

  /**
   * List of registrants for a given event.
   */
  async fetchEventRegistrants(
    requestParameters: FetchEventRegistrantsRequest
  ): Promise<Registrants> {
    const response = await this.fetchEventRegistrantsRaw(requestParameters)
    return await response.value()
  }

  /**
   * The list of events within a given date range. If a date range is not supplied then it defaults to dates between today and a year from today.    ## FILTERING: - search: search text must be at least 3 characters in length. Including this query parameter will match your search text to an event\'s name, postal code or creator. - eventType: will return events within the specific type - eventCreator: will return events for the specific creator   ## PAGINATION: - If pagination query parameters are included, the request will be paginated and ordered by the specified page, pageSize, and orderBy. - orderBy: valid order by properties include: name, startDate, startTime, CreatedBy. Supplying anything else will result in an error. - The requested pagination will be returned in the response, including the total count of all data, which will allow the requester to calculate the total amount of pages available with the current pageSize.       ## PERMISSIONS: - The requester must have an active Event/viewDetail permission, else a 403 status code will be returned. - A Event/viewDetail[their team\'s] permission allows the requester to fetch event within their downline. - A Event/viewDetail[any] permission allows the requester to fetch an event that anyone owns in the system.
   * List of events within a given date range.
   */
  async fetchEventsRaw(
    requestParameters: FetchEventsRequest
  ): Promise<runtime.ApiResponse<Events1>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize
    }

    if (requestParameters.orderBy) {
      queryParameters['orderBy'] = requestParameters.orderBy.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    if (requestParameters.eventType !== undefined) {
      queryParameters['eventType'] = requestParameters.eventType
    }

    if (requestParameters.search !== undefined) {
      queryParameters['search'] = requestParameters.search
    }

    if (requestParameters.eventCreator !== undefined) {
      queryParameters['eventCreator'] = requestParameters.eventCreator
    }

    if (requestParameters.fromDate !== undefined) {
      queryParameters['fromDate'] = requestParameters.fromDate
    }

    if (requestParameters.toDate !== undefined) {
      queryParameters['toDate'] = requestParameters.toDate
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/events`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      Events1FromJSON(jsonValue)
    )
  }

  /**
   * The list of events within a given date range. If a date range is not supplied then it defaults to dates between today and a year from today.    ## FILTERING: - search: search text must be at least 3 characters in length. Including this query parameter will match your search text to an event\'s name, postal code or creator. - eventType: will return events within the specific type - eventCreator: will return events for the specific creator   ## PAGINATION: - If pagination query parameters are included, the request will be paginated and ordered by the specified page, pageSize, and orderBy. - orderBy: valid order by properties include: name, startDate, startTime, CreatedBy. Supplying anything else will result in an error. - The requested pagination will be returned in the response, including the total count of all data, which will allow the requester to calculate the total amount of pages available with the current pageSize.       ## PERMISSIONS: - The requester must have an active Event/viewDetail permission, else a 403 status code will be returned. - A Event/viewDetail[their team\'s] permission allows the requester to fetch event within their downline. - A Event/viewDetail[any] permission allows the requester to fetch an event that anyone owns in the system.
   * List of events within a given date range.
   */
  async fetchEvents(requestParameters: FetchEventsRequest): Promise<Events1> {
    const response = await this.fetchEventsRaw(requestParameters)
    return await response.value()
  }

  /**
   * The list of events within a given date range (startDate BETWEEN from AND to). If no results between date ranges the endpoint will return the FIRST event with start_date >= To for the specific country provided by user,    the isFirstEventCloserToEndDate flag will be set to true so the developer may change the specific response to show in the FE.      ## FILTERING:   - eventType: will return events within the specific type   - eventLocationType: will return events within the specific location type 0=local, 1=global   - eventCountry: will return events within specific country      ## RESPONSE:   - isFirstEventCloserToEndDate: It will be only true if the aren\'t between date ranges and the there is at least ONE event near after such date ranges   - events: It will be either an array of Events or empty event, containing at least one event or empty array if there aren\'t events between date ranges nor near such ranges
   * List of events within a given date range, country, eventType and eventLocationType.
   */
  async fetchInternationalEventsRaw(
    requestParameters: FetchInternationalEventsRequest
  ): Promise<runtime.ApiResponse<Events>> {
    if (
      requestParameters.isGlobalEvent === null ||
      requestParameters.isGlobalEvent === undefined
    ) {
      throw new runtime.RequiredError(
        'isGlobalEvent',
        'Required parameter requestParameters.isGlobalEvent was null or undefined when calling fetchInternationalEvents.'
      )
    }

    if (
      requestParameters.fromDate === null ||
      requestParameters.fromDate === undefined
    ) {
      throw new runtime.RequiredError(
        'fromDate',
        'Required parameter requestParameters.fromDate was null or undefined when calling fetchInternationalEvents.'
      )
    }

    if (
      requestParameters.toDate === null ||
      requestParameters.toDate === undefined
    ) {
      throw new runtime.RequiredError(
        'toDate',
        'Required parameter requestParameters.toDate was null or undefined when calling fetchInternationalEvents.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.eventType !== undefined) {
      queryParameters['eventType'] = requestParameters.eventType
    }

    if (requestParameters.isGlobalEvent !== undefined) {
      queryParameters['isGlobalEvent'] = requestParameters.isGlobalEvent
    }

    if (requestParameters.countryCode !== undefined) {
      queryParameters['countryCode'] = requestParameters.countryCode
    }

    if (requestParameters.fromDate !== undefined) {
      queryParameters['fromDate'] = requestParameters.fromDate
    }

    if (requestParameters.toDate !== undefined) {
      queryParameters['toDate'] = requestParameters.toDate
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/events/international/search`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EventsFromJSON(jsonValue)
    )
  }

  /**
   * The list of events within a given date range (startDate BETWEEN from AND to). If no results between date ranges the endpoint will return the FIRST event with start_date >= To for the specific country provided by user,    the isFirstEventCloserToEndDate flag will be set to true so the developer may change the specific response to show in the FE.      ## FILTERING:   - eventType: will return events within the specific type   - eventLocationType: will return events within the specific location type 0=local, 1=global   - eventCountry: will return events within specific country      ## RESPONSE:   - isFirstEventCloserToEndDate: It will be only true if the aren\'t between date ranges and the there is at least ONE event near after such date ranges   - events: It will be either an array of Events or empty event, containing at least one event or empty array if there aren\'t events between date ranges nor near such ranges
   * List of events within a given date range, country, eventType and eventLocationType.
   */
  async fetchInternationalEvents(
    requestParameters: FetchInternationalEventsRequest
  ): Promise<Events> {
    const response = await this.fetchInternationalEventsRaw(requestParameters)
    return await response.value()
  }

  /**
   * Provide event details given an event identifier.
   * Fetch an existing event given an identifier.
   */
  async fetchPublicEventDetailsRaw(
    requestParameters: FetchPublicEventDetailsRequest
  ): Promise<runtime.ApiResponse<PublicEvent>> {
    if (
      requestParameters.publicEventId === null ||
      requestParameters.publicEventId === undefined
    ) {
      throw new runtime.RequiredError(
        'publicEventId',
        'Required parameter requestParameters.publicEventId was null or undefined when calling fetchPublicEventDetails.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/events/search/{publicEventId}`.replace(
        `{${'publicEventId'}}`,
        encodeURIComponent(String(requestParameters.publicEventId))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PublicEventFromJSON(jsonValue)
    )
  }

  /**
   * Provide event details given an event identifier.
   * Fetch an existing event given an identifier.
   */
  async fetchPublicEventDetails(
    requestParameters: FetchPublicEventDetailsRequest
  ): Promise<PublicEvent> {
    const response = await this.fetchPublicEventDetailsRaw(requestParameters)
    return await response.value()
  }

  /**
   * Returns some events within a radius of either a postal code (preferred) or a city and state. If an event type is provided then only those events will be included. In addition, if an online or offline event is required results can be limited only to those events, by default both online and offline are displayed.
   * Find nearby events so that users can register to an event. The search can further be filtered by type of event or whether it is online or offline, when left blank all events are displayed.
   */
  async searchPublicEventsRaw(
    requestParameters: SearchPublicEventsRequest
  ): Promise<runtime.ApiResponse<SearchPublicEventsReply>> {
    const queryParameters: any = {}

    if (requestParameters.postalCode !== undefined) {
      queryParameters['postalCode'] = requestParameters.postalCode
    }

    if (requestParameters.usCitiesKey !== undefined) {
      queryParameters['usCitiesKey'] = requestParameters.usCitiesKey
    }

    if (requestParameters.milesRadius !== undefined) {
      queryParameters['milesRadius'] = requestParameters.milesRadius
    }

    if (requestParameters.fromDate !== undefined) {
      queryParameters['fromDate'] = (requestParameters.fromDate as any)
        .toISOString()
        .substr(0, 10)
    }

    if (requestParameters.showOnlineEvents !== undefined) {
      queryParameters['showOnlineEvents'] = requestParameters.showOnlineEvents
    }

    if (requestParameters.eventType !== undefined) {
      queryParameters['eventType'] = requestParameters.eventType
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/events/search`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SearchPublicEventsReplyFromJSON(jsonValue)
    )
  }

  /**
   * Returns some events within a radius of either a postal code (preferred) or a city and state. If an event type is provided then only those events will be included. In addition, if an online or offline event is required results can be limited only to those events, by default both online and offline are displayed.
   * Find nearby events so that users can register to an event. The search can further be filtered by type of event or whether it is online or offline, when left blank all events are displayed.
   */
  async searchPublicEvents(
    requestParameters: SearchPublicEventsRequest
  ): Promise<SearchPublicEventsReply> {
    const response = await this.searchPublicEventsRaw(requestParameters)
    return await response.value()
  }

  /**
   * Update an existing Event.
   */
  async updateEventRaw(
    requestParameters: UpdateEventRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/events`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: EventToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Update an existing Event.
   */
  async updateEvent(requestParameters: UpdateEventRequest): Promise<void> {
    await this.updateEventRaw(requestParameters)
  }
}

/**
 * @export
 * @enum {string}
 */
export enum SearchPublicEventsEventTypeEnum {
  InformationMeeting = 'Information Meeting',
  OpenHouse = 'Open House',
  Practicum = 'Practicum',
}

import { useCallback, useEffect } from 'react'
import { LoadingContext } from '../components/Context/LoadingContext'
import React from 'react'

const inProgressSuffix = 'InProgress'

export type UseLoadingContextOptions = {
  /**
   * Async method needing to await processing.
   **/
  asyncFunction: () => Promise<void>
  /**
   * Unique Id of the operationId + component name being awaited.
   **/
  loadingId: string
  /**
   * Optional callback method to perform any actions after the async function is complete
   **/
  callback?: () => void
}

export type UseLoadingContext = {
  /**
   * Indicates whether the fetch operation is in progress.
   * this indicator is directly related to the loadingId provided in the hook options
   **/
  isLoading: boolean
  /**
   * Initiates the fetch process and adds the loading ID to the Loading context.
   **/
  triggerFetch: () => void
  /**
   * Exposes the functions of the loadingContext avoiding having to reimport
   * the context when this hook has already been imported
   * */
  loadingIds: Set<string>
  addLoadingIds: (state: string[]) => void
  removeLoadingIds: (state: string[]) => void
}

/**
 *    How to use:
 *
 *    1. Import the hook:
 *    import useLoadingContext from 'path/to/useLoadingContext';
 *
 *    2. Define your async function:
 *    const fetchData = async () => {
 *       Your async operation, e.g., fetching data from an API.
 *    };
 *
 *    3. Define your loadingId:
 *     const availablesLoadingIds = useLoadingIds()
 *
 *    4. Call the hook with the necessary options:
 *    const { isLoading, triggerFetch } = useLoadingContext({
 *      asyncFunction: fetchData,
 *      loadingId,
 *      callback: () => {
 *        console.log('Fetch operation completed');
 *      },
 *    });
 *
 *    5. Trigger the fetch operation:
 *    triggerFetch();
 *
 *    6. Use the `isLoading` boolean to display a loading indicator:
 *    {isLoading && <LoadingSpinner />}
 *
 */
const useLoadingContext = ({
  asyncFunction,
  callback,
  loadingId,
}: UseLoadingContextOptions): UseLoadingContext => {
  const { loadingIds, addLoadingIds, removeLoadingIds } =
    React.useContext(LoadingContext)

  /** Id to verify we are not currently processing the requested loadingId. If we are, don't recall the asyncFunction */
  const inProgressId = `${loadingId}${inProgressSuffix}`
  useEffect(() => {
    // Verify the loading Ids have the current loading Id and we aren't already processing the request
    if (loadingIds.has(loadingId) && !loadingIds.has(inProgressId)) {
      const clickTheButton = async () => {
        addLoadingIds([inProgressId])
        try {
          await asyncFunction()
        } catch (err) {
          // Error handling for the requests should not be handled here.
          // Remove anything awaiting our load since we failed to load
          removeLoadingIds([loadingId, inProgressId])
        }
        // Order of removal should be important. We want to show we're done loading and then we're finished processing.
        removeLoadingIds([loadingId, inProgressId])
        callback?.()
      }
      clickTheButton()
    }
  }, [
    addLoadingIds,
    asyncFunction,
    callback,
    loadingIds,
    removeLoadingIds,
    inProgressId,
    loadingId,
  ])

  const isLoading = loadingIds.has(loadingId)

  return {
    isLoading,
    triggerFetch: useCallback(
      () => addLoadingIds([loadingId]),
      [addLoadingIds, loadingId]
    ),
    loadingIds,
    addLoadingIds,
    removeLoadingIds,
  }
}

export default useLoadingContext

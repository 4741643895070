import React from 'react'
import { useTranslation } from 'react-i18next'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import ConfirmWithButtonsModal from './ConfirmWithButtonsModal'

interface RegistrationCloseDateModalProps {
  isOpen: boolean
  onConfirm: () => void
  onCancel: () => void
}

const RegistrationCloseDateModal: React.FC<
RegistrationCloseDateModalProps
> = ({ isOpen, onConfirm, onCancel }) => {
  const { t } = useTranslation()
  return (
    <ConfirmWithButtonsModal
      isOpen={isOpen}
      title={t(
        'Event.EventRegistrationCloseDate.EventModal.Title',
        'Verify Early Registration Close Date'
      )}
      question={t(
        'Event.EventRegistrationCloseDate.EventModal.Question',
        `Event date(s) were modified. Prior to publishing, please check the 'Early Registration Close Date' and verify it is correct for the new date(s). `
      )}
      questionProps={{ variant: 'body1', align: 'center' }}
      confirmVariant={ContainedButtonVariant.ReturnToEventDetails}
      cancelVariant={TextButtonVariant.Publish}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  )
}

export default RegistrationCloseDateModal

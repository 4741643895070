/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  CashAmount,
  CashAmountFromJSON,
  CashAmountToJSON,
  FeeDetails,
  FeeDetailsFromJSON,
  FeeDetailsToJSON,
  ProgramType,
  ProgramTypeFromJSON,
  ProgramTypeToJSON,
} from './'

/**
 *
 * @export
 * @interface FetchCountryCoordinatorLicensingDetailsResponse
 */
export interface FetchCountryCoordinatorLicensingDetailsResponse {
  /**
   * The Community name managed by the coordinator
   * @type {string}
   * @memberof FetchCountryCoordinatorLicensingDetailsResponse
   */
  community: string
  /**
   * Start date of the program's first semester.
   * @type {Date}
   * @memberof FetchCountryCoordinatorLicensingDetailsResponse
   */
  semesterOneStartDate: Date
  /**
   *
   * @type {ProgramType}
   * @memberof FetchCountryCoordinatorLicensingDetailsResponse
   */
  programType: ProgramType
  /**
   * The director's first name.
   * @type {string}
   * @memberof FetchCountryCoordinatorLicensingDetailsResponse
   */
  directorFirstName: string
  /**
   * The director's last name.
   * @type {string}
   * @memberof FetchCountryCoordinatorLicensingDetailsResponse
   */
  directorLastName: string
  /**
   *
   * @type {CashAmount}
   * @memberof FetchCountryCoordinatorLicensingDetailsResponse
   */
  totalPaid?: CashAmount
  /**
   * Details of the coordinator's licensing fees
   * @type {Array<FeeDetails>}
   * @memberof FetchCountryCoordinatorLicensingDetailsResponse
   */
  feeDetails: Array<FeeDetails>
}

export function FetchCountryCoordinatorLicensingDetailsResponseFromJSON(
  json: any
): FetchCountryCoordinatorLicensingDetailsResponse {
  return FetchCountryCoordinatorLicensingDetailsResponseFromJSONTyped(
    json,
    false
  )
}

export function FetchCountryCoordinatorLicensingDetailsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FetchCountryCoordinatorLicensingDetailsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    community: json['community'],
    semesterOneStartDate: new Date(json['semesterOneStartDate']),
    programType: ProgramTypeFromJSON(json['programType']),
    directorFirstName: json['directorFirstName'],
    directorLastName: json['directorLastName'],
    totalPaid: !exists(json, 'totalPaid')
      ? undefined
      : CashAmountFromJSON(json['totalPaid']),
    feeDetails: (json['feeDetails'] as Array<any>).map(FeeDetailsFromJSON),
  }
}

export function FetchCountryCoordinatorLicensingDetailsResponseToJSON(
  value?: FetchCountryCoordinatorLicensingDetailsResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    community: value.community,
    semesterOneStartDate: value.semesterOneStartDate
      .toISOString()
      .substr(0, 10),
    programType: ProgramTypeToJSON(value.programType),
    directorFirstName: value.directorFirstName,
    directorLastName: value.directorLastName,
    totalPaid: CashAmountToJSON(value.totalPaid),
    feeDetails: (value.feeDetails as Array<any>).map(FeeDetailsToJSON),
  }
}

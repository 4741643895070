import { Box, IconButton, Tooltip } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { ControlPointDuplicate, Clear, Delete, Edit } from '@mui/icons-material'
import LedgerTable, { LedgerRow } from '../Table/LedgerTable'
import TableHeaders from '../Interfaces/TableHeaders'
import { TranscriptYearCourseWork } from '../../swagger'
import { useTranslation } from 'react-i18next'

const classInformationHeaders: TableHeaders[] = [
  {
    id: 'class',
    label: 'Class',
    align: 'left',
  },
  {
    id: 'grade',
    label: 'Grade',
    align: 'left',
  },
  {
    id: 'credits',
    label: 'Credits',
    align: 'left',
  },
  {
    id: 'actions',
    label: '',
    align: 'left',
  },
]

interface ClassInformationTableProps {
  classInformation?: TranscriptYearCourseWork[]
  isFieldDisabled?: boolean
  onEditCourseWork: (courseWorkKey: TranscriptYearCourseWork) => void
  onClear: () => void
  onRowDelete: (courseWork: TranscriptYearCourseWork) => void
  onPrepopulate: () => void
  onAdd: () => void
}

const ClassInformationTable: React.FC<ClassInformationTableProps> = ({
  classInformation,
  isFieldDisabled,
  onEditCourseWork,
  onClear,
  onRowDelete,
  onPrepopulate,
  onAdd,
}) => {
  const { t } = useTranslation()

  const [classInformationRows, setClassInformationRows] = useState<LedgerRow[]>(
    []
  )

  const generateClassInformationRows = useCallback(() => {
    const newRows: LedgerRow[] = []
    if (!classInformation) {
      setClassInformationRows(newRows)
      return
    }
    for (const courseWork of classInformation) {
      if (Object.keys(courseWork).length !== 0) {
        newRows.push({
          cells: [
            {
              content: courseWork.courseName ?? '',
              align: 'left',
              cssTextProps: {
                paddingLeft: '15px',
              },
            },
            {
              content: courseWork.letterGrade ?? '',
              align: 'left',
            },
            {
              content: courseWork.credits?.toString() ?? '',
              align: 'left',
            },
            {
              content: isFieldDisabled ? null : (
                <>
                  <Tooltip
                    title={t(
                      'ClassInformationTable.ClassInformationRow.EditRow',
                      'Edit Row'
                    )}
                  >
                    <IconButton
                      aria-label="Edit Row"
                      onClick={() => onEditCourseWork(courseWork)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={t(
                      'ClassInformationTable.ClassInformationRow.DeleteRow',
                      'Delete Row'
                    )}
                  >
                    <IconButton
                      aria-label="Delete Row"
                      onClick={() => onRowDelete(courseWork)
                      }
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </>
              ),
              align: 'center',
              cssTextProps: {
                boxSizing: 'border-box',
                height: '35px',
              },
            },
          ],
        })
      }
    }
    setClassInformationRows(newRows)
  }, [classInformation, isFieldDisabled, onRowDelete, onEditCourseWork, t])

  useEffect(() => {
    generateClassInformationRows()
  }, [classInformation, generateClassInformationRows])

  return (
    <>
      {!isFieldDisabled && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Tooltip
            title={t('ClassInformationTable.ClearAllRows', 'Clear All Rows')}
          >
            <IconButton
              aria-label="Clear All Rows"
              onClick={onClear}
              disabled={isFieldDisabled}
              sx={{ color: 'black' }}
            >
              <Clear />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={t(
              'ClassInformationTable.PrepopulateTable',
              'Prepopulate Table'
            )}
          >
            <IconButton
              aria-label="Prepopulate Table"
              onClick={onPrepopulate}
              disabled={isFieldDisabled}
              sx={{ color: 'black' }}
            >
              <ControlPointDuplicate />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('ClassInformationTable.AddRow', 'Add Row')}>
            <IconButton
              aria-label="Add Row"
              onClick={onAdd}
              disabled={isFieldDisabled}
              sx={{
                color: 'black',
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <Box>
        <LedgerTable
          tableHeaders={classInformationHeaders}
          rows={classInformationRows}
          cssProps={{
            marginBottom: '14px',
          }}
          emptyRowCssProps={{
            padding: '28px',
          }}
          displayEmptyTable
        />
      </Box>
    </>
  )
}

export default ClassInformationTable

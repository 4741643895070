/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 * Describes a user belonging to a community with a specific role. The parent role is implied if no other role is specified.
 * @export
 * @interface UserCommunity
 */
export interface UserCommunity {
  /**
   * The user’s role, if any. Parents are not considered to have a role.
   * @type {string}
   * @memberof UserCommunity
   */
  role?: string
  /**
   * The identifier for the user's role, if any. Parents are not considered to have a role.
   * @type {number}
   * @memberof UserCommunity
   */
  roleKey?: number
  /**
   * The identifier for the user's actor granting the user this role, if any. Parents are not considered to have a role.
   * @type {number}
   * @memberof UserCommunity
   */
  actorKey?: number
  /**
   * The date the user account obtained this role.
   * @type {Date}
   * @memberof UserCommunity
   */
  validFrom?: Date
  /**
   * The date in which after the user account will no longer have this role.
   * @type {Date}
   * @memberof UserCommunity
   */
  validTo?: Date
  /**
   * The first name of the user's supervisor for this actor, if any. Parents will not have a supervisor.
   * @type {string}
   * @memberof UserCommunity
   */
  supervisorFirstName?: string
  /**
   * The last name of the user's supervisor for this actor, if any. Parents will not have a supervisor.
   * @type {string}
   * @memberof UserCommunity
   */
  supervisorLastName?: string
  /**
   * The community the user belongs to with the provided role, if any. If no role, then they are a parent to a child enrolled in the community.
   * @type {string}
   * @memberof UserCommunity
   */
  community: string
  /**
   * The identifier of the community to which the user belongs, if any. If it has no role, then it is the parent of a child enrolled in the community.
   * @type {number}
   * @memberof UserCommunity
   */
  communityKey?: number
  /**
   * The name assigned to the region the actor is in.
   * @type {string}
   * @memberof UserCommunity
   */
  region?: string
  /**
   * Indicates whether an actor role is currently valid.
   * @type {boolean}
   * @memberof UserCommunity
   */
  validNow?: boolean
}

export function UserCommunityFromJSON(json: any): UserCommunity {
  return UserCommunityFromJSONTyped(json, false)
}

export function UserCommunityFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserCommunity {
  if (json === undefined || json === null) {
    return json
  }
  return {
    role: !exists(json, 'role') ? undefined : json['role'],
    roleKey: !exists(json, 'roleKey') ? undefined : json['roleKey'],
    actorKey: !exists(json, 'actorKey') ? undefined : json['actorKey'],
    validFrom: !exists(json, 'validFrom')
      ? undefined
      : new Date(json['validFrom']),
    validTo: !exists(json, 'validTo') ? undefined : new Date(json['validTo']),
    supervisorFirstName: !exists(json, 'supervisorFirstName')
      ? undefined
      : json['supervisorFirstName'],
    supervisorLastName: !exists(json, 'supervisorLastName')
      ? undefined
      : json['supervisorLastName'],
    community: json['community'],
    communityKey: !exists(json, 'communityKey')
      ? undefined
      : json['communityKey'],
    region: !exists(json, 'region') ? undefined : json['region'],
    validNow: !exists(json, 'validNow') ? undefined : json['validNow'],
  }
}

export function UserCommunityToJSON(value?: UserCommunity | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    role: value.role,
    roleKey: value.roleKey,
    actorKey: value.actorKey,
    validFrom:
      value.validFrom === undefined ? undefined : value.validFrom.toISOString(),
    validTo:
      value.validTo === undefined ? undefined : value.validTo.toISOString(),
    supervisorFirstName: value.supervisorFirstName,
    supervisorLastName: value.supervisorLastName,
    community: value.community,
    communityKey: value.communityKey,
    region: value.region,
    validNow: value.validNow,
  }
}

import { useTranslation } from 'react-i18next'
import {
  CashAmount,
  OrderByDirection,
  PaginationResponse,
  ParentInvoiceInfo,
  ProgramType,
} from '../../../swagger'
import React, { useState } from 'react'
import getLocaleCurrencyForAmount from '../../../utils/getLocaleCurrencyForAmount'
import { GridValueFormatterParams } from '@mui/x-data-grid'
import PreviewIcon from '@mui/icons-material/Preview'

import {
  ActionableTable,
  ActionableTableColumn,
} from '../../Table/ActionableTable'
import { Box, Card, Typography } from '@mui/material'
import { Action } from '../../Table/RowActions'
import CardFormHeader from '../../Card/CardFormHeader'
import { dateToSlashString } from '../../../utils/dateUtility'
import { useNavigate } from 'react-router'
import { getCommaSeparatedList } from '../../../utils/getCommaSeparatedList'
const PAGE_SIZE = 10
interface ParentInvoiceCardProps {
  parentInvoices: ParentInvoiceInfo[]
}

export const ParentInvoiceCard: React.FC<ParentInvoiceCardProps> = ({
  parentInvoices,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE)

  const handleChangePage = (pagination: PaginationResponse) => {
    setPage(pagination.page)
    setRowsPerPage(pagination.pageSize)
  }

  const handleNavigateToInvoice = (invoiceKey: number) => {
    const basePath = '/invoice'
    const pathname = !invoiceKey ? basePath : `${basePath}/${invoiceKey}`

    navigate(
      {
        pathname,
      },
      {
        state: { showSendInvoiceButton: false },
      }
    )
  }

  const actionableTableColumnsHeader: ActionableTableColumn[] = [
    {
      fieldName: 'invoiceKey',
      columnHeaderName: t(
        'ParentInvoiceCard.TableHeader.InvoiceId',
        'Invoice Id'
      ),
    },
    {
      fieldName: 'programTypes',
      columnHeaderName: t(
        'ParentInvoiceCard.TableHeader.Transaction',
        'Program(s)'
      ),
      valueFormatter: (params: GridValueFormatterParams<ProgramType[]>) => {
        return getCommaSeparatedList(
          params.value.map((program) => program.toString())
        )
      },
    },
    {
      fieldName: 'programAcademicYear',
      columnHeaderName: t(
        'ParentInvoiceCard.TableHeader.ProgramAcademicYear',
        'Academic Year'
      ),
      valueFormatter: (params: GridValueFormatterParams<string[]>) => {
        return params.value?.toString()?.split('-')?.[0]
      },
    },
    {
      fieldName: 'communityNames',
      columnHeaderName: t(
        'ParentInvoiceCard.TableHeader.Program',
        'Communities'
      ),
      valueFormatter: (params: GridValueFormatterParams<string[]>) => {
        return getCommaSeparatedList(params.value)
      },
    },
    {
      fieldName: 'invoiceRequesterName',
      columnHeaderName: t(
        'ParentInvoiceCard.TableHeader.DirectorOrTutor',
        'Director/Tutor'
      ),
    },
    {
      fieldName: 'lastSentEmail',
      columnHeaderName: t(
        'ParentInvoiceCard.TableHeader.DateIssued',
        'Date Issued'
      ),
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        return `${dateToSlashString(params.value)}`
      },
    },
    {
      fieldName: 'amountOwed',
      columnHeaderName: t(
        'ParentInvoiceCard.TableHeader.Amount',
        'Total Amount Due'
      ),
      valueFormatter: (params: GridValueFormatterParams<CashAmount>) => {
        return getLocaleCurrencyForAmount(
          params.value.amount,
          params.value.currencyCode
        )
      },
    },
  ]

  const rowActions: Action<ParentInvoiceInfo>[] = [
    {
      actionName: t('ParentInvoiceCard.RowActions.ViewInvoice', 'View Invoice'),
      actionKey: 'edit',
      actionFunction: (row) =>
        handleNavigateToInvoice(row?.invoiceKey as number),
      menuItemIcon: <PreviewIcon />,
    },
  ]

  const tableData =
    rowsPerPage > 0
      ? parentInvoices.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : parentInvoices

  return (
    <Card>
      <Box ml={1} mb={7} mt={1}>
        <CardFormHeader
          header={
            <Typography component="p" variant="h6">
              {t('ParentInvoiceCard.ActionableTable.Title', 'Invoices')}
            </Typography>
          }
        />
      </Box>
      <ActionableTable
        columns={actionableTableColumnsHeader}
        rows={tableData}
        noResultsMessage={t(
          'ParentInvoiceCard.ActionableTable.Empty',
          'You currently do not have any active invoices.'
        )}
        rowActions={rowActions}
        pagination={{
          page,
          pageSize: PAGE_SIZE,
          totalCount: parentInvoices.length,
          orderBy: [{ date: OrderByDirection.Asc }],
        }}
        handlePaginationChange={handleChangePage}
      />
    </Card>
  )
}

/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { FeeType, FeeTypeFromJSON, FeeTypeToJSON } from './'

/**
 *
 * @export
 * @interface UpdateLicensingEnrollmentRequestBodyLicensingEnrollments
 */
export interface UpdateLicensingEnrollmentRequestBodyLicensingEnrollments {
  /**
   * The unique identifier for the student the tuition payment is for.
   * @type {number}
   * @memberof UpdateLicensingEnrollmentRequestBodyLicensingEnrollments
   */
  studentKey: number
  /**
   * The unique identifier for the program the tuition payment is for.
   * @type {number}
   * @memberof UpdateLicensingEnrollmentRequestBodyLicensingEnrollments
   */
  programKey: number
  /**
   *
   * @type {FeeType}
   * @memberof UpdateLicensingEnrollmentRequestBodyLicensingEnrollments
   */
  feeTypeKey: FeeType
  /**
   * The exempt amount.
   * @type {number}
   * @memberof UpdateLicensingEnrollmentRequestBodyLicensingEnrollments
   */
  exemptAmount?: number
  /**
   * Reason why the director is exempt to pay Licensing Fee.
   * @type {string}
   * @memberof UpdateLicensingEnrollmentRequestBodyLicensingEnrollments
   */
  exemptReason?: string
}

export function UpdateLicensingEnrollmentRequestBodyLicensingEnrollmentsFromJSON(
  json: any
): UpdateLicensingEnrollmentRequestBodyLicensingEnrollments {
  return UpdateLicensingEnrollmentRequestBodyLicensingEnrollmentsFromJSONTyped(
    json,
    false
  )
}

export function UpdateLicensingEnrollmentRequestBodyLicensingEnrollmentsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateLicensingEnrollmentRequestBodyLicensingEnrollments {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentKey: json['studentKey'],
    programKey: json['programKey'],
    feeTypeKey: FeeTypeFromJSON(json['feeTypeKey']),
    exemptAmount: !exists(json, 'exemptAmount')
      ? undefined
      : json['exemptAmount'],
    exemptReason: !exists(json, 'exemptReason')
      ? undefined
      : json['exemptReason'],
  }
}

export function UpdateLicensingEnrollmentRequestBodyLicensingEnrollmentsToJSON(
  value?: UpdateLicensingEnrollmentRequestBodyLicensingEnrollments | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    studentKey: value.studentKey,
    programKey: value.programKey,
    feeTypeKey: FeeTypeToJSON(value.feeTypeKey),
    exemptAmount: value.exemptAmount,
    exemptReason: value.exemptReason,
  }
}

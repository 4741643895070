import React, { useState } from 'react'
import RegistrantsTable from '../Events/RegistrantsTable'
import { styled } from '@mui/system'
import { CardContent } from '@mui/material'
import { useEventContext } from '../Context/EventContext'
import ResponsiveFab from '../Buttons/ResponsiveFab'
import { addIconFudgeFactor } from '../../utils/addIconFudgeFactor'
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from 'react-i18next'
import AddRegistrantModal from '../Modals/AddRegistrantModal'
import { PaginationResponse } from '../../swagger'

const CardContentRegistrant = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(0, 4),
}))

export const EventRegistrantCard: React.FunctionComponent = (props) => {
  const { t } = useTranslation()
  const {
    capacity,
    registrants,
    refetchRegistrant,
    eventDetails,
    pagination,
    handleRegistrantPagination,
  } = useEventContext()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const paginationPage = pagination?.page ? pagination.page - 1 : 1
  return (
    <>
      {eventDetails?.isAbleToAddRegistrant && (
        <ResponsiveFab
          icon={AddIcon}
          onClick={() => setIsModalOpen(true)}
          spacingFudgeFactor={addIconFudgeFactor}
          iconLabel={t(
            'EventRegistrantCard.AddRegistrantButton.IconLabel',
            'Add'
          )}
          textLabel={t(
            'EventRegistrantCard.AddRegistrantButton.TextLabel',
            'Registrant'
          )}
          fullLabel={t(
            'EventRegistrantCard.AddRegistrantButton.FullLabel',
            'Add Registrant'
          )}
        />
      )}
      <AddRegistrantModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <CardContentRegistrant>
        <RegistrantsTable
          {...props}
          eventRegistrants={
            (registrants ?? []).filter((registrant) => registrant.attending)
              .length
          }
          eventCapacity={capacity}
          registrants={registrants ?? []}
          refetch={refetchRegistrant}
          pagination={
            {
              ...pagination,
              page: paginationPage,
            } as PaginationResponse
          }
          handleRegistrantPagination={handleRegistrantPagination}
        />
      </CardContentRegistrant>
    </>
  )
}

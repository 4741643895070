import React from 'react'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { useNavigate } from 'react-router'
import {
  FileType,
  FileTypeIcon,
  FileTypeLabel,
} from '../Elements/FileTypeResource'
import ShareLinkButton from '../Buttons/ShareLinkButton'
import FavoriteButton from '../Buttons/FavoriteButton'
import DownloadButton from '../Buttons/DownloadButton'
import { styled } from '@mui/system'
import { useScrollContext } from '../Context/ScrollContext'
import { useLearningCenterContext } from '../Context/LearningCenterContext'
import { useTranslation } from 'react-i18next'

export enum LearningCenterCategories {
  AllCategories = 'All Categories',
  ArtMusic = 'Art & Music',
  English = 'English',
  Geography = 'Geography',
  LatinEnglish = 'Latin/English',
  SupplementalParentResource = 'Supplemental Parent Resource',
  LogicStrand = 'Logic Strand',
  GrammarStrand = 'Grammar Strand',
  MyFavorites = 'My Favorites',
}

interface LearningCenterCardProps {
  id: number
  fileType: FileType
  title: string
  description: string
  strand: string
  subTitle: string
  isFavorite: boolean
  viewOnly: boolean
  isLearningPath?: boolean
  // Used for displaying the learning path in the breadcrumbs
  learningPathTitle?: string
  routerState?: { assetKeysInLearningPath: string[] }
  isFavoriteButtonDisabled?: boolean
  handleFavoriteButtonClicked?: () => void
}

const LearningCenterCard = styled(Card)(({ theme }) => ({
  width: 340,
  margin: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    maxWidth: 340,
    marginTop: theme.spacing(2),
    '&:nth-of-type(1)': {
      marginTop: 0,
    },
  },
}))

const LearningCenterContentCard: React.FC<LearningCenterCardProps> = (
  props
) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { selectedTabKey } = useLearningCenterContext()
  const { updatePrevScrollY, updateScrollY } = useScrollContext()
  const {
    description,
    strand,
    subTitle,
    isFavorite,
    viewOnly,
    fileType,
    title,
    id,
    isLearningPath = false,
    learningPathTitle,
    routerState,
    isFavoriteButtonDisabled,
    handleFavoriteButtonClicked,
  } = props

  // If the week is 99 then we shouldn't show the subtitle
  let subtitleToUse = subTitle
  if (subTitle.includes('Week 99')) {
    subtitleToUse = ''
  }

  const handleCardClick = () => {
    updatePrevScrollY(window.scrollY)
    updateScrollY(0)
    navigate(
      {
        pathname: `/learning/${
          isLearningPath
            ? `${selectedTabKey.toLowerCase()}/learning-path/${id}`
            : `learning-content/${id}`
        }`,
      },
      {
        state: {
          learningPathTitle,
          assetKeysInLearningPath: routerState?.assetKeysInLearningPath,
        },
      }
    )
  }

  const multiLineEllipsis = {
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
  }

  const iconBoxStyleForLP = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.textOrIcon.mapButton,
  }

  return (
    <LearningCenterCard>
      <CardActionArea onClick={handleCardClick}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bgcolor={isLearningPath ? 'customBackground.logo' : 'primary.main'}
          color="white"
          py={2.25}
          px={2}
          minHeight={58}
        >
          <Box
            sx={isLearningPath ? iconBoxStyleForLP : {}}
            bgcolor={isLearningPath ? 'textOrIcon.helperText' : 'primary.main'}
          >
            <FileTypeIcon fileType={fileType} />
          </Box>

          <FileTypeLabel
            fileType={fileType}
            css={{
              marginLeft: theme.spacing(1),
              /**
               * Ensures that icon label does not extend past half of the card since
               * strand label is on the other side.
               */
              maxWidth: 116,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              alignSelf: 'flex-start',
              color: isLearningPath
                ? theme.palette.primary.dark
                : theme.palette.textOrIcon.mapButton,
            }}
          />

          {!isLearningPath && (
            <Typography
              sx={{
                marginLeft: theme.spacing(4),
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'end',
                minWidth: 200,
                [theme.breakpoints.down('sm')]: {
                  paddingRight: 1,
                },
              }}
              variant="button"
              component="p"
            >
              {strand}
            </Typography>
          )}
        </Box>

        <CardContent>
          <Typography
            sx={{
              ...multiLineEllipsis,
              color: theme.palette.primary.main,
              marginBottom: theme.spacing(1),
              height: 'calc(2 * 1.6 * 17.5px)',
            }}
            variant="h6"
            component="h3"
          >
            {title}
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary.main,
              marginBottom: theme.spacing(1),
            }}
            variant="body2"
            component="p"
          >
            {subtitleToUse}
          </Typography>
          <Typography
            sx={{
              ...multiLineEllipsis,
              color: theme.palette.primary.dark,
              height: 'calc(2 * 1.5 * 1rem)',
            }}
            variant="body1"
            component="p"
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <Divider />

      <CardActions
        sx={{
          display: 'flex',
          padding: theme.spacing(1),
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          {!isLearningPath && !viewOnly && <DownloadButton assetKey={id} />}
          <ShareLinkButton
            assetKey={id}
            isLearningCenter={true}
            {...props}
            isLearningPath={isLearningPath}
          />
        </Box>
        {!isLearningPath && (
          <FavoriteButton
            isFavorited={isFavorite}
            assetKey={id}
            disabled={isFavoriteButtonDisabled}
            handleFavoriteButtonClicked={handleFavoriteButtonClicked}
            tooltipOptions={{
              showTooltip: !!isFavoriteButtonDisabled,
              title: t(
                'Favorite.Button.Tooltip',
                'To enable favoriting this asset, un mark all other favorites in this Learning Path.'
              ),
            }}
          />
        )}
      </CardActions>
    </LearningCenterCard>
  )
}

export default LearningCenterContentCard

import React, { useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import SearchBar from '../Search/SearchBar'
import DropDown, { DropDownVariant } from '../Menus/DropDown'
import { useTranslation } from 'react-i18next'
import { buildFilterOptions } from '../../utils/buildFilterOptions'
import { styled, useTheme } from '@mui/system'
import {
  FilterFileByChallengeLevel,
  FilterFileType,
  LearningCenterTabs,
} from '../../utils/searchAndFilterEnums'
import {
  LearningCenterCategories,
  useLearningCenterContext,
} from '../Context/LearningCenterContext'
import { LoadingContext } from '../Context/LoadingContext'
import { FormControlLabel, Switch, Typography } from '@mui/material'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import OutlinedButton, {
  OutlinedButtonVariant,
} from '../Buttons/OutlinedButton'

const filterDropdownIds = {
  filterByCategory: 'filterByCategory',
  filterByType: 'filterByType',
  filterByCycle: 'filterByCycle',
  filterByLevel: 'filterByLevel',
}

const StyledDropDown = styled(DropDown)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0, 0, 2),
  },
}))

export const SearchAndFilter: React.FC = () => {
  const { t } = useTranslation()
  const {
    learningCenterOptions: filterOptions,
    searchQuery: searchValue,
    category: filterByCategory,
    contentType: filterByType,
    cycle: filterByCycle,
    level,
    updateLevel,
    contentLoadingId,
    challengePrograms,
    selectedTabKey,
    includeOnlyFavorites,
    tabSpecificSearchAndFilter,
    updateTabSpecificSearchAndFilter,
  } = useLearningCenterContext()
  const { addLoadingIds } = React.useContext(LoadingContext)

  const categories = buildFilterOptions(
    filterOptions.categories,
    'All Categories'
  )

  const cycles = (filterOptions.cycles ?? []).map((cycle) => ({
    name: `Cycle ${cycle}`,
    id: cycle,
  }))
  const firstCycle = (filterOptions.cycles ?? [])[0]

  const [isClearFilters, setIsClearFilters] = useState(false)

  const handleSearch = (searchText: string) => {
    updateTabSpecificSearchAndFilter({
      ...tabSpecificSearchAndFilter,
      [selectedTabKey]: {
        ...tabSpecificSearchAndFilter[selectedTabKey],
        cycle: filterByCycle,
        searchQuery: searchText,
      },
    })
    // Recall fetchLearningCenterContent with the updated search query
    addLoadingIds([contentLoadingId])
  }

  const handleDropDownSelection = (
    selection: string,
    dropdownId: string,
    selectedOptionId: string | number
  ) => {
    switch (dropdownId) {
      case filterDropdownIds.filterByCategory:
        const category = selection as LearningCenterCategories
        updateTabSpecificSearchAndFilter({
          ...tabSpecificSearchAndFilter,
          [selectedTabKey]: {
            ...tabSpecificSearchAndFilter[selectedTabKey],
            cycle: filterByCycle,
            category,
          },
        })
        break
      case filterDropdownIds.filterByType:
        const contentType = selection as FilterFileType
        updateTabSpecificSearchAndFilter({
          ...tabSpecificSearchAndFilter,
          [selectedTabKey]: {
            ...tabSpecificSearchAndFilter[selectedTabKey],
            cycle: filterByCycle,
            contentType,
          },
        })
        break

      case filterDropdownIds.filterByCycle:
        const cycle = parseInt(selectedOptionId as string)
        updateTabSpecificSearchAndFilter({
          ...tabSpecificSearchAndFilter,
          [selectedTabKey]: {
            ...tabSpecificSearchAndFilter[selectedTabKey],
            cycle,
          },
        })
        break

      case filterDropdownIds.filterByLevel:
        const level = selection as FilterFileByChallengeLevel
        updateLevel(level)
        break
    }
    /**
     * Always attempt to call fetchLearningCenterContent
     */
    addLoadingIds([contentLoadingId])
  }

  const handleClearAllFilters = () => {
    updateTabSpecificSearchAndFilter({
      ...tabSpecificSearchAndFilter,
      [selectedTabKey]: {
        ...tabSpecificSearchAndFilter[selectedTabKey],
        contentType: FilterFileType.AllTypes,
        category: LearningCenterCategories.AllCategories,
        includeOnlyFavorites: false,
        cycle: firstCycle,
        searchQuery: '',
      },
    })
    setIsClearFilters(true)
    addLoadingIds([contentLoadingId])
  }

  const getLabelForFilterOption = (level?: string): string => {
    switch (level) {
      case FilterFileByChallengeLevel.ChallengeA:
        return t('LearningCenter.FilterOption.ChallengeA', 'Challenge A')
      case FilterFileByChallengeLevel.ChallengeB:
        return t('LearningCenter.FilterOption.ChallengeB.', 'Challenge B')
      case FilterFileByChallengeLevel.Challenge1:
        return t('LearningCenter.FilterOption.ChallengeI', 'Challenge I')
      case FilterFileByChallengeLevel.Challenge2:
        return t('LearningCenter.FilterOption.ChallengeII.', 'Challenge II')
      case FilterFileByChallengeLevel.Challenge3:
        return t('LearningCenter.FilterOption.ChallengeIII', 'Challenge III')
      case FilterFileByChallengeLevel.Challenge4:
        return t('LearningCenter.FilterOption.ChallengeIV.', 'Challenge IV')
      default:
        return t(
          'LearningCenter.FilterOption.NoneAvailable.',
          'No Available Levels'
        )
    }
  }

  const filetypes = buildFilterOptions(filterOptions.filetypes, 'All Types')

  /**
   * We only show the Challenge Level filter if we have availableProgramTypes
   * loaded from fetchLearningCenterAvailableProgramTypes, it is non-empty,
   * and we have selected the (first) level so as not to provide undefined to
   * a dropdown.
   */
  const showLevelFilter =
    !!challengePrograms &&
    challengePrograms.length > 0 &&
    level &&
    selectedTabKey.includes(LearningCenterTabs.Challenge)

  const showCycleFilter =
    selectedTabKey === LearningCenterTabs.Foundations ||
    selectedTabKey === LearningCenterTabs.Essentials ||
    selectedTabKey === LearningCenterTabs.Scribblers

  const showOnDesktop = useShowOnDesktop()
  const theme = useTheme()

  const selectedCycle = useMemo(
    () =>
      !!filterByCycle && filterByCycle > 0
        ? `Cycle ${filterByCycle}`
        : `Cycle ${firstCycle}`,
    [filterByCycle, firstCycle]
  )

  return (
    <Box
      id="search-and-filter-container"
      display="flex"
      flexDirection={showOnDesktop ? 'row' : 'column'}
      sx={{
        ...(showOnDesktop && {
          marginBottom: theme.spacing(2),
          '& .MuiSelect-outlined': {
            width: '100%',
          },
        }),
      }}
    >
      <SearchBar handleSearch={handleSearch} searchValue={searchValue} />
      {showLevelFilter && (
        <Box my={{ xs: 1, md: 0 }} ml={2}>
          <StyledDropDown
            id="filterByLevel"
            /**
             * Really, challengePrograms is defined because of showLevelFilter
             * but typescript doesn't want you to know that, so we include the
             * ? operator and a ?? for a default that will never be returned
             * given the proper check for the options.
             */
            menuOptions={
              challengePrograms?.map((level, index) => {
                return { name: getLabelForFilterOption(level), id: index - 1 }
              }) ?? []
            }
            defaultValue={getLabelForFilterOption(level)}
            handleSelection={handleDropDownSelection}
            variant={DropDownVariant.SortAndFilter}
            value={level}
          />
        </Box>
      )}
      <Box my={{ xs: 1, md: 0 }} ml={2}>
        <StyledDropDown
          id={filterDropdownIds.filterByCategory}
          menuOptions={categories.map((category) => {
            return { name: category.name, id: category.id }
          })}
          handleSelection={handleDropDownSelection}
          variant={DropDownVariant.SortAndFilter}
          value={filterByCategory}
          isClearFilters={isClearFilters}
        />
      </Box>
      <Box my={{ xs: 1, md: 0 }} ml={2}>
        <StyledDropDown
          id={filterDropdownIds.filterByType}
          menuOptions={filetypes.map((filetype) => {
            return { name: filetype.name, id: filetype.id }
          })}
          handleSelection={handleDropDownSelection}
          variant={DropDownVariant.SortAndFilter}
          value={filterByType}
          isClearFilters={isClearFilters}
        />
      </Box>

      {showCycleFilter && (
        <Box my={{ xs: 1, md: 0 }} ml={2}>
          <StyledDropDown
            id={filterDropdownIds.filterByCycle}
            menuOptions={cycles}
            value={selectedCycle}
            handleSelection={handleDropDownSelection}
            variant={DropDownVariant.SortAndFilter}
            isClearFilters={isClearFilters}
          />
        </Box>
      )}

      <Box my={{ xs: 1, md: 0 }} mx={{ xs: 0, md: 2 }}>
        <FormControlLabel
          control={
            <Switch
              color="secondary"
              inputProps={{
                'aria-label': `Include only My Favorite content`,
              }}
              name={`my-favorite-toggle`}
              checked={includeOnlyFavorites}
              value={includeOnlyFavorites}
              onChange={() => {
                updateTabSpecificSearchAndFilter({
                  ...tabSpecificSearchAndFilter,
                  [selectedTabKey]: {
                    ...tabSpecificSearchAndFilter[selectedTabKey],
                    cycle: filterByCycle,
                    includeOnlyFavorites: !includeOnlyFavorites,
                  },
                })
                addLoadingIds([contentLoadingId])
              }}
            />
          }
          label={
            <Typography variant="body1" component="p">
              {t(
                'SearchAndFilter.Label.MyFavorites',
                'Include Only My Favorites'
              )}
            </Typography>
          }
        />
      </Box>
      <Box>
        <OutlinedButton
          id="clearAllFilters"
          variant={OutlinedButtonVariant.ClearAllFilters}
          onClick={handleClearAllFilters}
        />
      </Box>
    </Box>
  )
}

import { Document, Image, Page, Text, View } from '@react-pdf/renderer'
import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import Html from 'react-pdf-html'
import CCLogo from '../../../../Images/Branding/cc-combination-blue.png'
import { FetchCountryCoordinatorLicensingDetailsResponse } from '../../../../swagger'
import { fontFamily, styles } from '../BillingPDF/licensingBillingPdfStyles'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import {
  TableHeaderForPDF,
  TableHeaderForPDFProps,
} from '../../../Table/TableHeaderForPDF'
import { useTranslation } from 'react-i18next'
import { useFeeLabelMap } from '../../../../hooks/useFeeLabelMap'
import getLocaleCurrencyForAmount from '../../../../utils/getLocaleCurrencyForAmount'
import { dateToSlashStringReinterpretedAsLocal } from '../../../../utils/dateUtility'
import fullName from '../../../../utils/fullName'

export const InternationalLicensingPDF: React.FC<{
  licensingDetails: FetchCountryCoordinatorLicensingDetailsResponse
}> = ({ licensingDetails }) => {
  const { t } = useTranslation()
  const feeLabelMap = useFeeLabelMap(t)

  const { directorFirstName: firstName, directorLastName: lastName } =
    licensingDetails

  const billToLabel = t(
    'InternationalLicensingPDF.BillTo',
    'Licensing for {{name}}',
    {
      name: fullName({ firstName, lastName }),
    }
  )

  const tableHeaders: TableHeaderForPDFProps['tableHeaders'] = [
    {
      label: t('InternationalLicensingPDF.TableHeader.FeeName', 'Fee Name'),
      align: 'left',
      cssProps: {
        ...styles.tableHeaderCell,
        width: '40%',
      },
    },
    {
      label: t(
        'InternationalLicensingPDF.TableHeader.NumberOfStudents',
        '# Of Invited Students'
      ),
      align: 'right',
      cssProps: styles.tableHeaderCell,
    },
    {
      label: t(
        'InternationalLicensingPDF.TableHeader.DirectorOwes',
        'Director Owes To Country Coordinator'
      ),
      align: 'left',
      cssProps: styles.tableHeaderCell,
    },
    {
      label: t(
        'InternationalLicensingPDF.TableHeader.CoCoOwes',
        'Country Coordinator Owes CC'
      ),
      align: 'left',
      cssProps: styles.tableHeaderCell,
    },
  ]

  const directorTotalOwed = licensingDetails.feeDetails
    .map(({ directorOwes }) => directorOwes.amount)
    .reduce((acc, curr) => acc + curr, 0)

  const cocoTotalOwed = licensingDetails.feeDetails
    .map(({ coordinatorOwes }) => coordinatorOwes.amount)
    .reduce((acc, curr) => acc + curr, 0)

  const totalOwedRow = (
    <TableRow
      style={{
        borderTop: '1px dashed black',
        marginTop: '10px',
      }}
    >
      <TableCell
        style={{
          ...styles.tableBodyCell,
          textAlign: 'left',
          width: '40%',
        }}
      >
        {t('InternationalLicensingPDF.TotalOwed', 'Total Owed')}
      </TableCell>
      <TableCell
        style={{
          ...styles.tableBodyCell,
          textAlign: 'right',
        }}
      >
        -
      </TableCell>

      <TableCell
        style={{
          ...styles.tableBodyCell,
          textAlign: 'right',
          fontWeight: 'bold',
          fontFamily: fontFamily,
        }}
      >
        {getLocaleCurrencyForAmount(
          directorTotalOwed,
          licensingDetails.totalPaid?.currencyCode ?? ''
        )}
      </TableCell>
      <TableCell
        style={{
          ...styles.tableBodyCell,
          textAlign: 'right',
          fontWeight: 'bold',
          fontFamily: fontFamily,
        }}
      >
        {getLocaleCurrencyForAmount(
          cocoTotalOwed,
          licensingDetails.totalPaid?.currencyCode ?? ''
        )}
      </TableCell>
    </TableRow>
  )

  const totalPaidRow = (
    <TableRow>
      <TableCell
        style={{
          ...styles.tableBodyCell,
          textAlign: 'left',
          width: '40%',
        }}
      >
        {t('InternationalLicensingPDF.TotalPaid', 'Total Paid')}
      </TableCell>

      <TableCell
        style={{
          ...styles.tableBodyCell,
          textAlign: 'right',
        }}
      >
        -
      </TableCell>

      <TableCell
        style={{
          ...styles.tableBodyCell,
          textAlign: 'right',
          fontWeight: 'bold',
          fontFamily: fontFamily,
        }}
      >
        {getLocaleCurrencyForAmount(
          licensingDetails.totalPaid?.amount ?? 0,
          licensingDetails.totalPaid?.currencyCode ?? ''
        )}
      </TableCell>
      <TableCell
        style={{
          ...styles.tableBodyCell,
          textAlign: 'right',
        }}
      >
        -
      </TableCell>
    </TableRow>
  )

  const balanceRow = (
    <TableRow
      style={{
        borderTop: '1px dashed black',
        marginTop: '10px',
      }}
    >
      <TableCell
        style={{
          ...styles.tableBodyCell,
          textAlign: 'left',
          width: '40%',
        }}
      >
        {t('InternationalLicensingPDF.Balance', 'Balance')}
      </TableCell>

      <TableCell
        style={{
          ...styles.tableBodyCell,
          textAlign: 'right',
        }}
      >
        -
      </TableCell>

      <TableCell
        style={{
          ...styles.tableBodyCell,
          textAlign: 'right',
          fontWeight: 'bold',
          fontFamily: fontFamily,
        }}
      >
        {getLocaleCurrencyForAmount(
          directorTotalOwed - (licensingDetails.totalPaid?.amount ?? 0),
          licensingDetails.totalPaid?.currencyCode ?? ''
        )}
      </TableCell>
      <TableCell
        style={{
          ...styles.tableBodyCell,
          textAlign: 'right',
        }}
      >
        -
      </TableCell>
    </TableRow>
  )

  return (
    <Document title="Billing">
      <Page size="LETTER" style={styles.page}>
        <Image style={styles.logo} src={CCLogo} />
        <View style={styles.billToContainer}>
          <Text style={styles.billToLabel}>{billToLabel}</Text>
        </View>
        <View>
          <Text style={styles.communityName}>
            {`${licensingDetails.programType} ${
              licensingDetails.community
            } ${dateToSlashStringReinterpretedAsLocal(
              licensingDetails.semesterOneStartDate
            )}`}
          </Text>
        </View>
        <Html>
          {renderToStaticMarkup(
            <Box style={styles.table}>
              <Table style={styles.licensingPaymentBreakdownTable}>
                <TableHead>
                  <TableHeaderForPDF tableHeaders={tableHeaders} />
                </TableHead>

                <TableBody>
                  {licensingDetails.feeDetails.map((feeDetail) => (
                    <TableRow key={feeDetail.feeType}>
                      <TableCell
                        style={{
                          ...styles.tableBodyCell,
                          textAlign: 'left',
                          width: '40%',
                        }}
                      >
                        {feeLabelMap[feeDetail.feeType]}
                      </TableCell>
                      <TableCell
                        style={{
                          ...styles.tableBodyCell,
                          textAlign: 'right',
                        }}
                      >
                        {feeDetail.studentCount}
                      </TableCell>

                      <TableCell
                        style={{
                          ...styles.tableBodyCell,
                          textAlign: 'right',
                          fontWeight: 'bold',
                          fontFamily: fontFamily,
                        }}
                      >
                        {getLocaleCurrencyForAmount(
                          feeDetail.directorOwes.amount,
                          feeDetail.amount.currencyCode
                        )}
                      </TableCell>

                      <TableCell
                        style={{
                          ...styles.tableBodyCell,
                          textAlign: 'right',
                          fontWeight: 'bold',
                          fontFamily: fontFamily,
                        }}
                      >
                        {getLocaleCurrencyForAmount(
                          feeDetail.coordinatorOwes.amount,
                          feeDetail.amount.currencyCode
                        )}
                      </TableCell>
                    </TableRow>
                  ))}

                  {totalOwedRow}

                  {totalPaidRow}

                  {balanceRow}
                </TableBody>
              </Table>
            </Box>
          )}
        </Html>
      </Page>
    </Document>
  )
}

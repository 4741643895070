import React from 'react'
import { useTheme } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import { TextField } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Header, { HeaderVariant } from '../Elements/Header'
import { monthOptions, generateDayOptions } from '../../helpers/monthsAndDays'
import { ParentRegion, RegionManager } from '../../swagger'
import { Region } from '../../swagger'
import {
  RegionInformationValidity,
  licensingDueDateRangeMinimum,
  licensingDueDateRangeMaximum,
  TypeRegion,
} from './RegionFormCard'
import Checkbox from '@mui/material/Checkbox'
import { styled } from '@mui/system'

const StyledRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.textOrIcon.checkbox,
}))

const StyledGrid = styled(Grid)(() => ({
  borderBottom: `1px solid #dfdfdf`,
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  /**
   * use the default value of @mui/material/colors/grey.d.ts
   */
  color: theme.palette.grey[800],
  marginTop: theme.spacing(3),
}))

const StyledTextField = styled(TextField)<{ disabled: boolean }>(
  ({ disabled, theme }) => ({
    '& .MuiFilledInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '&:before': {
        borderBottom: disabled ? 'none' : '',
      },
      backgroundColor: disabled
        ? theme.palette.customBackground.transparent
        : '',
    },
  })
)

const displayEmptyStringWhenInvalid = (value?: number) =>
  (!value && value !== 0) || value === -1 ? '' : value

const cycleOptions = [1, 2, 3]

export interface RegionInformationCardProps {
  isFieldDisabled: boolean
  regionInformation: Region
  regionInformationValidity: RegionInformationValidity
  parentRegions: ParentRegion[]
  regionManagers: RegionManager[]
  handleRegionInfoChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const RegionInformationCard: React.FC<RegionInformationCardProps> = ({
  isFieldDisabled,
  regionInformation,
  regionInformationValidity,
  parentRegions,
  regionManagers,
  handleRegionInfoChange,
}) => {
  const theme = useTheme()

  const { t } = useTranslation()
  const minValueOfaACycleStartYear = 2000
  const semester1DayOptions = generateDayOptions(
    regionInformation.semesterOneStartMonth
  )
  const semester2DayOptions = generateDayOptions(
    regionInformation.semesterOneStartMonth
  )

  const licensingDueDateRangeExceededValueMessage = t(
    'RegionInformationCard.ValidationMessage.s1LicensingDueOffset',
    'Value cannot be less than {{licensingDueDateRangeMinimum}} nor more than {{licensingDueDateRangeMaximum}}',
    {
      licensingDueDateRangeMinimum,
      licensingDueDateRangeMaximum,
    }
  )

  const requiredFieldMessage = t(
    'RegionInformationCard.ValidationMessage.s1LicensingLateOffset',
    'This field is required'
  )

  return (
    <Card
      sx={{
        padding: theme.spacing(3, 4, 4),
        maxWidth: 1200,
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(3),
      }}
    >
      <section aria-labelledby="regionFormHeader">
        <Header
          id="regionFormHeader"
          headerName={t(
            'RegionInformationCard.Header.RegionInformation',
            'Region Information'
          )}
          component="h2"
          variant={HeaderVariant.Card}
        />

        <form noValidate autoComplete="off" aria-labelledby="regionFormHeader">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Grid container direction="column" spacing={3}>
                <Grid item xs>
                  <TextField
                    id="regionNameField"
                    name="name"
                    label={t(
                      'RegionInformationCard.FormField.RegionName',
                      'Region Name'
                    )}
                    fullWidth
                    variant="filled"
                    error={!regionInformationValidity.regionNameIsValid.input}
                    helperText={
                      !regionInformationValidity.regionNameIsValid.input
                        ? t(
                            'RegionInformationCard.ValidationMessage.RegionName',
                            'Region Name cannot be blank'
                          )
                        : null
                    }
                    disabled={isFieldDisabled}
                    value={regionInformation.name}
                    onChange={handleRegionInfoChange}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    id="parentRegionField"
                    name="parentRegionsKey"
                    label={t(
                      'RegionInformationCard.FormField.ParentRegion',
                      'Parent Region'
                    )}
                    fullWidth
                    select
                    variant="filled"
                    disabled={isFieldDisabled}
                    value={
                      parentRegions.length > 0
                        ? displayEmptyStringWhenInvalid(
                            regionInformation.parentRegionsKey
                          )
                        : ''
                    }
                    onChange={handleRegionInfoChange}
                  >
                    {parentRegions.map(({ regionsKey, name }) => (
                      <MenuItem key={regionsKey} value={regionsKey}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs>
                  <TextField
                    id="managerField"
                    name="actorKey"
                    label={t(
                      'RegionInformationCard.FormField.Manager',
                      'Manager'
                    )}
                    fullWidth
                    select
                    variant="filled"
                    error={!regionInformationValidity.actorKeyIsValid.input}
                    helperText={
                      !regionInformationValidity.actorKeyIsValid.input
                        ? t(
                            'RegionInformationCard.ValidationMessage.Manager',
                            'Please select a manager'
                          )
                        : null
                    }
                    disabled={isFieldDisabled}
                    value={
                      regionManagers.length > 0
                        ? displayEmptyStringWhenInvalid(
                            regionInformation.actorKey
                          )
                        : ''
                    }
                    onChange={handleRegionInfoChange}
                  >
                    {regionManagers.map(({ actorKey, name }) => (
                      <MenuItem key={actorKey} value={actorKey}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container direction="column" spacing={3}>
                <Grid item container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      id="semester1StartMonth"
                      name="semesterOneStartMonth"
                      label={t(
                        'RegionInformationCard.FormField.Semester1StartMonth',
                        '1st Semester Start Month'
                      )}
                      fullWidth
                      select
                      variant="filled"
                      error={
                        !regionInformationValidity.semesterOneStartMonthIsValid
                          .input ||
                        regionInformationValidity.semesterOneStartMonthIsValid
                          .sameDate
                      }
                      helperText={
                        !regionInformationValidity.semesterOneStartMonthIsValid
                          .input
                          ? t(
                              'RegionInformationCard.ValidationMessage.Semester1StartMonth',
                              'Please select a month'
                            )
                          : null
                      }
                      disabled={isFieldDisabled}
                      value={displayEmptyStringWhenInvalid(
                        regionInformation.semesterOneStartMonth
                      )}
                      onChange={handleRegionInfoChange}
                    >
                      {monthOptions.map((month) => (
                        <MenuItem key={month.label} value={month.value}>
                          {month.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="semester1StartDay"
                      name="semesterOneStartDay"
                      label={t(
                        'RegionInformationCard.FormField.Semester1StartDay',
                        '1st Semester Start Day'
                      )}
                      fullWidth
                      select
                      variant="filled"
                      error={
                        !regionInformationValidity.semesterOneStartDayIsValid
                          .input ||
                        regionInformationValidity.semesterOneStartMonthIsValid
                          .sameDate
                      }
                      helperText={
                        !regionInformationValidity.semesterOneStartDayIsValid
                          .input
                          ? t(
                              'RegionInformationCard.ValidationMessage.Semester1StartDay',
                              'Please select a day'
                            )
                          : null
                      }
                      disabled={isFieldDisabled}
                      value={displayEmptyStringWhenInvalid(
                        regionInformation.semesterOneStartDay
                      )}
                      onChange={handleRegionInfoChange}
                    >
                      {semester1DayOptions.map((day) => (
                        <MenuItem key={day} value={day}>
                          {day.toString()}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid item container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      id="semester2StartMonth"
                      name="semesterTwoStartMonth"
                      label={t(
                        'RegionInformationCard.FormField.Semester2StartMonth',
                        '2nd Semester Start Month'
                      )}
                      fullWidth
                      select
                      variant="filled"
                      error={
                        !regionInformationValidity.semesterTwoStartMonthIsValid
                          .input ||
                        regionInformationValidity.semesterOneStartMonthIsValid
                          .sameDate
                      }
                      helperText={
                        !regionInformationValidity.semesterTwoStartMonthIsValid
                          .input
                          ? t(
                              'RegionInformationCard.ValidationMessage.Semester2StartMonth',
                              'Please select a month'
                            )
                          : null
                      }
                      disabled={isFieldDisabled}
                      value={displayEmptyStringWhenInvalid(
                        regionInformation.semesterTwoStartMonth
                      )}
                      onChange={handleRegionInfoChange}
                    >
                      {monthOptions.map((month) => (
                        <MenuItem key={month.label} value={month.value}>
                          {month.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="semester2StartDay"
                      name="semesterTwoStartDay"
                      label={t(
                        'RegionInformationCard.FormField.Semester2StartDay',
                        '2nd Semester Start Day'
                      )}
                      fullWidth
                      select
                      variant="filled"
                      error={
                        !regionInformationValidity.semesterTwoStartDayIsValid
                          .input ||
                        regionInformationValidity.semesterOneStartMonthIsValid
                          .sameDate
                      }
                      helperText={
                        !regionInformationValidity.semesterTwoStartDayIsValid
                          .input
                          ? t(
                              'RegionInformationCard.ValidationMessage.Semester2StartDay',
                              'Please select a day'
                            )
                          : null
                      }
                      disabled={isFieldDisabled}
                      value={displayEmptyStringWhenInvalid(
                        regionInformation.semesterTwoStartDay
                      )}
                      onChange={handleRegionInfoChange}
                    >
                      {semester2DayOptions.map((day) => (
                        <MenuItem key={day} value={day}>
                          {day.toString()}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid item xs>
                  <TextField
                    id="regionCycleField"
                    name="cycle"
                    label={t(
                      'RegionInformationCard.FormField.RegionCycle',
                      'Cycle'
                    )}
                    fullWidth
                    select
                    variant="filled"
                    disabled={isFieldDisabled}
                    value={
                      regionInformation.cycle === -1
                        ? ''
                        : regionInformation.cycle
                    }
                    onChange={handleRegionInfoChange}
                  >
                    {cycleOptions.map((day) => (
                      <MenuItem key={day} value={day}>
                        {day.toString()}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="region privacy"
                  name="isPrivate"
                  value={
                    !!regionInformation.isPrivate
                      ? TypeRegion.Private
                      : TypeRegion.Public
                  }
                  onChange={handleRegionInfoChange}
                >
                  <FormControlLabel
                    disabled={isFieldDisabled}
                    value={TypeRegion.Public}
                    control={<StyledRadio />}
                    label={
                      <Typography
                        variant="subtitle1"
                        component="span"
                        lineHeight={1.4}
                      >
                        {t(
                          'Region.RegionForm.FormField.PublicRegion',
                          'Public Region'
                        )}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    disabled={isFieldDisabled}
                    value={TypeRegion.Private}
                    control={<StyledRadio />}
                    label={
                      <Typography
                        variant="subtitle1"
                        component="span"
                        lineHeight={1.4}
                      >
                        {t(
                          'Region.RegionForm.FormField.PrivateRegion',
                          'Private Region'
                        )}
                      </Typography>
                    }
                  />
                </RadioGroup>
                <FormControlLabel
                  key={`allowACHCheckboxLabel`}
                  control={
                    <Checkbox
                      name="achAllowed"
                      sx={{
                        color: theme.palette.textOrIcon.checkbox,
                      }}
                      size="medium"
                      color="secondary"
                      disabled={isFieldDisabled}
                      onChange={handleRegionInfoChange}
                      checked={regionInformation.achAllowed ?? false}
                    />
                  }
                  label={
                    <Typography variant="subtitle1" component="span">
                      {t('Region.RegionForm.FormField.AchAllowed', 'Allow Ach')}
                    </Typography>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="regionDiscourseUrlField"
                name="discourseUrl"
                label={t(
                  'RegionInformationCard.FormField.RegionDiscourseUrl',
                  'Discourse Url'
                )}
                fullWidth
                variant="filled"
                error={!regionInformationValidity.discourseUrlIsValid.input}
                helperText={
                  !regionInformationValidity.discourseUrlIsValid.input
                    ? t(
                        'RegionInformationCard.ValidationMessage.RegionDiscourseUrl',
                        'Discourse Url cannot be blank'
                      )
                    : null
                }
                disabled={isFieldDisabled}
                value={regionInformation.discourseUrl}
                onChange={handleRegionInfoChange}
              />
            </Grid>
            <Grid item container spacing={4}>
              <Grid item md={6} xs={12}>
                <Grid container direction="column" spacing={3}>
                  <Grid item xs>
                    <TextField
                      id="aYCycleStartYear"
                      name="aYCycleStartYear"
                      label={t(
                        'RegionInformationCard.FormField.AYCycleStartYear',
                        'Academic Year Cycle Start Year'
                      )}
                      type="number"
                      inputProps={{
                        inputMode: 'numeric',
                        min: 2000,
                        max: 9999,
                      }}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      variant="filled"
                      error={
                        !regionInformationValidity
                          .academicYearCycleStartYearIsValid.input
                      }
                      helperText={
                        !regionInformationValidity
                          .academicYearCycleStartYearIsValid.input
                          ? t(
                              'RegionInformationCard.ValidationMessage.AYCycleStartYear',
                              'The Academic Year Cycle Start Year must be between 2000 and 9999)'
                            )
                          : null
                      }
                      disabled={isFieldDisabled}
                      value={
                        !isFieldDisabled &&
                        regionInformation.aYCycleStartYear === 0
                          ? minValueOfaACycleStartYear
                          : regionInformation.aYCycleStartYear
                      }
                      onChange={handleRegionInfoChange}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id="countryCoordinatorMultiplier"
                      name="countryCoordinatorMultiplier"
                      label={t(
                        'RegionInformationCard.FormField.IntlRegionMultiplier',
                        'Intl Region Multiplier'
                      )}
                      type="number"
                      inputProps={{
                        inputMode: 'numeric',
                        min: 0,
                        max: 100,
                      }}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      variant="filled"
                      error={
                        !!regionInformationValidity.countryCoordinatorMultiplier
                          .input
                          ? !regionInformationValidity
                              .countryCoordinatorMultiplier.beforeMax ||
                            !regionInformationValidity
                              .countryCoordinatorMultiplier.afterMin
                          : false
                      }
                      helperText={
                        !!regionInformationValidity.countryCoordinatorMultiplier
                          .input
                          ? !regionInformationValidity
                              .countryCoordinatorMultiplier.beforeMax ||
                            !regionInformationValidity
                              .countryCoordinatorMultiplier.afterMin
                            ? t(
                                'RegionInformationCard.ValidationMessage.AYCycleStartYear',
                                'The Intl Region Multiplier must be between 0 and 100'
                              )
                            : null
                          : null
                      }
                      disabled={isFieldDisabled}
                      value={
                        regionInformation.countryCoordinatorMultiplier ?? ''
                      }
                      onChange={handleRegionInfoChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography
                  align="left"
                  color={theme.palette.grey[600]}
                  fontSize={theme.spacing(1.4)}
                  mb={1}
                >
                  {t(
                    'RegionInformationCard.FormField.LicensingDueDateRanges',
                    'Licensing Due Date Ranges'
                  )}
                </Typography>
                <Grid item container spacing={1}>
                  <StyledGrid item xs={6}>
                    <StyledTypography align="left">
                      {t(
                        'RegionInformationCard.FormField.S1LicensingDueOffset',
                        'S1 Licensing Due Offset'
                      )}
                    </StyledTypography>
                  </StyledGrid>
                  <StyledGrid item xs={6}>
                    <StyledTextField
                      id="s1LicensingDueOffset"
                      name="s1LicensingDueOffset"
                      type="number"
                      variant="filled"
                      fullWidth
                      error={
                        !regionInformationValidity.s1LicensingDueOffset.input ||
                        !regionInformationValidity.s1LicensingDueOffset
                          .afterMin ||
                        !regionInformationValidity.s1LicensingDueOffset
                          .beforeMax
                      }
                      helperText={
                        !regionInformationValidity.s1LicensingDueOffset.input
                          ? requiredFieldMessage
                          : !regionInformationValidity.s1LicensingDueOffset
                              .afterMin ||
                            !regionInformationValidity.s1LicensingDueOffset
                              .beforeMax
                          ? licensingDueDateRangeExceededValueMessage
                          : null
                      }
                      disabled={isFieldDisabled}
                      value={regionInformation.s1LicensingDueOffset}
                      onChange={handleRegionInfoChange}
                    />
                  </StyledGrid>
                  <StyledGrid item xs={6}>
                    <StyledTypography align="left">
                      {t(
                        'RegionInformationCard.FormField.S1LicensingLateOffset',
                        'S1 Licensing Late Offset'
                      )}
                    </StyledTypography>
                  </StyledGrid>
                  <StyledGrid item xs={6}>
                    <StyledTextField
                      id="s1LicensingLateOffset"
                      name="s1LicensingLateOffset"
                      type="number"
                      variant="filled"
                      fullWidth
                      error={
                        !regionInformationValidity.s1LicensingLateOffset
                          .input ||
                        !regionInformationValidity.s1LicensingLateOffset
                          .afterMin ||
                        !regionInformationValidity.s1LicensingLateOffset
                          .beforeMax
                      }
                      helperText={
                        !regionInformationValidity.s1LicensingLateOffset.input
                          ? requiredFieldMessage
                          : !regionInformationValidity.s1LicensingLateOffset
                              .afterMin ||
                            !regionInformationValidity.s1LicensingLateOffset
                              .beforeMax
                          ? licensingDueDateRangeExceededValueMessage
                          : null
                      }
                      disabled={isFieldDisabled}
                      value={regionInformation.s1LicensingLateOffset}
                      onChange={handleRegionInfoChange}
                    />
                  </StyledGrid>
                  <StyledGrid item xs={6}>
                    <StyledTypography align="left">
                      {t(
                        'RegionInformationCard.FormField.S2LicensingDueOffset',
                        'S2 Licensing Due Offset'
                      )}
                    </StyledTypography>
                  </StyledGrid>
                  <StyledGrid item xs={6}>
                    <StyledTextField
                      id="s2LicensingDueOffset"
                      name="s2LicensingDueOffset"
                      type="number"
                      variant="filled"
                      fullWidth
                      error={
                        !regionInformationValidity.s2LicensingDueOffset.input ||
                        !regionInformationValidity.s2LicensingDueOffset
                          .afterMin ||
                        !regionInformationValidity.s2LicensingDueOffset
                          .beforeMax
                      }
                      helperText={
                        !regionInformationValidity.s2LicensingDueOffset.input
                          ? requiredFieldMessage
                          : !regionInformationValidity.s2LicensingDueOffset
                              .afterMin ||
                            !regionInformationValidity.s2LicensingDueOffset
                              .beforeMax
                          ? licensingDueDateRangeExceededValueMessage
                          : null
                      }
                      disabled={isFieldDisabled}
                      value={regionInformation.s2LicensingDueOffset}
                      onChange={handleRegionInfoChange}
                    />
                  </StyledGrid>
                  <StyledGrid item xs={6}>
                    <StyledTypography align="left">
                      {t(
                        'RegionInformationCard.FormField.S2LicensingLateOffset',
                        'S2 Licensing Late Offset'
                      )}
                    </StyledTypography>
                  </StyledGrid>
                  <StyledGrid item xs={6}>
                    <StyledTextField
                      id="s2LicensingLateOffset"
                      name="s2LicensingLateOffset"
                      type="number"
                      variant="filled"
                      fullWidth
                      error={
                        !regionInformationValidity.s2LicensingLateOffset
                          .input ||
                        !regionInformationValidity.s2LicensingLateOffset
                          .afterMin ||
                        !regionInformationValidity.s2LicensingLateOffset
                          .beforeMax
                      }
                      helperText={
                        !regionInformationValidity.s2LicensingLateOffset.input
                          ? requiredFieldMessage
                          : !regionInformationValidity.s2LicensingLateOffset
                              .afterMin ||
                            !regionInformationValidity.s2LicensingLateOffset
                              .beforeMax
                          ? licensingDueDateRangeExceededValueMessage
                          : null
                      }
                      disabled={isFieldDisabled}
                      value={regionInformation.s2LicensingLateOffset}
                      onChange={handleRegionInfoChange}
                    />
                  </StyledGrid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </section>
    </Card>
  )
}

export default React.memo(RegionInformationCard)

import { Font, StyleSheet } from '@react-pdf/renderer'
import { colors } from '../../../stylesheet'

const fontWeight = 'bold'
export const fontFamily = 'Open Sans'
export const borderDashed = '1px dashed lightgrey'

/**
 * To allow the PDF to use a regular and bold font weight,
 * we need to register the font with react-pdf.
 *
 * Documentation: https://react-pdf.org/fonts
 */
Font.register({
  family: fontFamily,
  fonts: [
    {
      src: '/fonts/openSansRegular.ttf',
    },
    {
      src: '/fonts/openSans600.ttf',
      fontWeight: 600,
    },
  ],
})

export const styles = StyleSheet.create({
  page: {
    paddingVertical: 25,
    paddingHorizontal: 20,
  },
  logo: {
    maxWidth: 150,
    height: 'auto',
    marginBottom: 10,
  },
  containerTable: {
    border: `1px solid ${colors.lightBlue}`,
    padding: 2,
  },
  tableHeaderCell: {
    backgroundColor: colors.lightBlue,
    color: colors.royalBlue.main,
    fontSize: 9,
    fontWeight,
    paddingHorizontal: 4,
    paddingVertical: 8,
  },
  tableBodyCell: {
    padding: 4,
    borderTop: '1px lightgrey',
  },
  title: {
    marginBottom: 5,
  },
  programLicensingPaymentBreakdownContainer: {
    marginBottom: 5,
  },
  table: {
    border: '1px solid rgb(185 188 191)',
    fontSize: '9px',
  },
  eventInfo: {
    paddingBottom: 10,
  },
})

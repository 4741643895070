/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 6ece92a4 (Mon Mar 10 17:18:22 UTC 2025)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  CashAmount,
  CashAmountFromJSON,
  CashAmountToJSON,
  FeeType,
  FeeTypeFromJSON,
  FeeTypeToJSON,
} from './'

/**
 *
 * @export
 * @interface FeeDetails
 */
export interface FeeDetails {
  /**
   *
   * @type {FeeType}
   * @memberof FeeDetails
   */
  feeType: FeeType
  /**
   * The number of students invited to the program.
   * @type {number}
   * @memberof FeeDetails
   */
  studentCount: number
  /**
   *
   * @type {CashAmount}
   * @memberof FeeDetails
   */
  amount: CashAmount
  /**
   *
   * @type {CashAmount}
   * @memberof FeeDetails
   */
  directorOwes: CashAmount
  /**
   *
   * @type {CashAmount}
   * @memberof FeeDetails
   */
  coordinatorOwes: CashAmount
}

export function FeeDetailsFromJSON(json: any): FeeDetails {
  return FeeDetailsFromJSONTyped(json, false)
}

export function FeeDetailsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FeeDetails {
  if (json === undefined || json === null) {
    return json
  }
  return {
    feeType: FeeTypeFromJSON(json['feeType']),
    studentCount: json['studentCount'],
    amount: CashAmountFromJSON(json['amount']),
    directorOwes: CashAmountFromJSON(json['directorOwes']),
    coordinatorOwes: CashAmountFromJSON(json['coordinatorOwes']),
  }
}

export function FeeDetailsToJSON(value?: FeeDetails | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    feeType: FeeTypeToJSON(value.feeType),
    studentCount: value.studentCount,
    amount: CashAmountToJSON(value.amount),
    directorOwes: CashAmountToJSON(value.directorOwes),
    coordinatorOwes: CashAmountToJSON(value.coordinatorOwes),
  }
}

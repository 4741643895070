import React, { ReactNode, useMemo } from 'react'
import {
  EventFormCardVariants,
  EventFormValidationMessageTypes,
} from './EventEnums'
import {
  Box,
  FormControl,
  FormGroup,
  FormLabel,
  MenuItem,
  SelectChangeEvent,
  TextField,
  useMediaQuery,
} from '@mui/material'
import { styled } from '@mui/system'
import { useTheme } from '@mui/material/styles'
import Logo from '../../Images/Branding/cc-logo-white.svg'
import { useTranslation } from 'react-i18next'
import { EventFormValidFields, EventLocationTypes } from '../Card/EventFormCard'
import { Event, EventEventTypeEnum } from '../../swagger'
import useValidationMessages from '../../hooks/useValidationMessages'

export interface EventInformationDetails
  extends Pick<
    Event,
    'capacity' | 'owner' | 'possibleNewOwners' | 'publicUrl' | 'qRCodeImage'
  > {
  eventType: string
  eventLocationType: string
  eventName: string
}

interface EventInformationFieldsProps {
  eventInformationDetails: EventInformationDetails
  eventFieldValidity: EventFormValidFields
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  isFieldDisabled: boolean
  onOwnerChange: (event: SelectChangeEvent<unknown>, child: ReactNode) => void
  variant: EventFormCardVariants
}

const EventSection = styled('section')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const PublicUrlLink = styled('a')(({ theme }) => ({
  paddingLeft: '15px',
  color: theme.palette.primary.main,
  textDecoration: 'none',
  marginLeft: theme.spacing(2),
  '&:hover': { textDecoration: 'underline' },
}))

const HalfWidthField = styled(TextField)(({ theme }) => ({
  width: '50% !important',
  [theme.breakpoints.down('sm')]: {
    width: '99% !important',
  },
  [theme.breakpoints.down('xs')]: {
    width: '98% !important',
  },
}))

export const EventInformationFields: React.FC<EventInformationFieldsProps> = (
  props
) => {
  const theme = useTheme()
  const isMobileOrSmaller = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  const {
    eventInformationDetails,
    eventFieldValidity,
    handleInputChange,
    isFieldDisabled,
    onOwnerChange,
    variant,
  } = props
  const {
    capacity,
    eventType,
    eventLocationType,
    eventName,
    owner,
    possibleNewOwners,
    publicUrl,
    qRCodeImage,
  } = eventInformationDetails
  const isEditVariant = variant === EventFormCardVariants.EditEvent
  const eventTypeOptions = Object.values(EventEventTypeEnum)
  const eventLocationTypeOptions = [
    t('Event.EventLocationTypeOptions.Local', 'Local'),
    t('Event.EventLocationTypeOptions.Global', 'Global'),
  ]
  const sortedPossibleNewOwners = useMemo(() => {
    return [...(possibleNewOwners ?? [])].sort((a, b) =>
      a.user.localeCompare(b.user)
    )
  }, [possibleNewOwners])

  const validationMessageMap = useValidationMessages([
    {
      field: EventFormValidationMessageTypes.EventName,
      message: t(
        'Events.EventInformationField.ValidationMessage.EventName',
        'Event Name cannot be empty.'
      ),
    },
    {
      field: EventFormValidationMessageTypes.EventType,
      message: t(
        'Events.EventInformationField.ValidationMessage.EventType',
        'Please select an Event Type.'
      ),
    },
    {
      field: EventFormValidationMessageTypes.Registration,
      message: t(
        'Events.EventInformationField.ValidationMessage.Registration',
        'Please select a Registration Status.'
      ),
    },
    {
      field: EventFormValidationMessageTypes.MaxCapacity,
      message: t(
        'Events.EventInformationField.ValidationMessage.MaxCapacity',
        'Max Capacity must be between 1 and 999.'
      ),
    },
    {
      field: EventFormValidationMessageTypes.EventLocationType,
      message: t(
        'Events.EventInformationField.ValidationMessage.EventLocationType',
        'Please select an Event Location Type.'
      ),
    },
  ])

  return (
    <EventSection aria-labelledby="eventDetailsHeader">
      <Box
        sx={{
          background: theme.palette.primary.main,
          ...(eventLocationType === EventLocationTypes.Global && {
            background: theme.palette.customBackground.logo,
          }),
          width: 290,
          height: 290,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
          [theme.breakpoints.down('sm')]: {
            width: !isMobileOrSmaller ? '100%' : theme.spacing(13),
            height: theme.spacing(13),
          },
        }}
      >
        {qRCodeImage ? (
          <a
            href={publicUrl}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="event-link"
          >
            <img
              src={qRCodeImage}
              alt={t(
                'Events.EventInformationField.AltText',
                'QR Code with Logo'
              )}
              style={{
                width: !isMobileOrSmaller
                  ? theme.spacing(28)
                  : theme.spacing(12),
              }}
            />
          </a>
        ) : (
          <img
            src={Logo}
            alt={t('Events.EventInformationField.AltText', 'Event Image')}
            style={{
              ...(eventLocationType === EventLocationTypes.Global && {
                /** This is the filter we need to use to convert our primary color's hex (#051f42)
                 * to the same color but filtered for an SVG.
                 **/
                filter:
                  'brightness(0) saturate(100%) invert(9%) sepia(18%) saturate(6857%) hue-rotate(200deg) brightness(98%) contrast(99%)',
              }),
              width: !isMobileOrSmaller ? 160 : 70,
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& .MuiTextField-root': {
            width: 525,
            margin: theme.spacing(1),
          },
          [theme.breakpoints.down('sm')]: {
            '& .MuiTextField-root': {
              width: `calc(100% - ${theme.spacing(1)})`,
            },
          },
        }}
      >
        <TextField
          label={t('Events.EventInformationField.FormField.name', 'Event Name')}
          inputProps={{
            'aria-label': t(
              'Events.EventInformationField.AriaLabel.name',
              'Event Name'
            ),
          }}
          name="eventName"
          variant="filled"
          value={eventName}
          onChange={handleInputChange}
          disabled={isFieldDisabled}
          error={!eventFieldValidity.eventName.input}
          helperText={
            !eventFieldValidity.eventName.input
              ? validationMessageMap.get(
                  EventFormValidationMessageTypes.EventName
                )
              : null
          }
        />

        <TextField
          label={t(
            'Events.EventInformationField.FormField.EventType',
            'Event Type'
          )}
          inputProps={{
            'aria-label': t(
              'Events.EventInformationField.AriaLabel.EventType',
              'Event Type'
            ),
          }}
          variant="filled"
          select={!isFieldDisabled}
          value={eventType}
          name="eventType"
          onChange={handleInputChange}
          disabled={isFieldDisabled}
          error={!eventFieldValidity.eventType.input}
          helperText={
            !eventFieldValidity.eventType.input
              ? validationMessageMap.get(
                  EventFormValidationMessageTypes.EventType
                )
              : null
          }
        >
          {eventTypeOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        {isEditVariant ? (
          <FormControl
            component="fieldset"
            sx={{
              margin: '8px',
              [theme.breakpoints.down('sm')]: {
                margin: '11px 0px 0px 7px',
              },
            }}
          >
            <FormLabel
              component="legend"
              sx={{
                color: '#8a929f',
                transform: 'translate(12px, -2px) scale(0.75)',
              }}
              aria-label={t(
                'Events.EventInformationField.AriaLabel.PublicUrl',
                'Public Url'
              )}
            >
              {t('Events.EventInformationField.FormField.PublicUrl', 'Link')}
            </FormLabel>
            <FormGroup>
              <PublicUrlLink id={publicUrl} href={publicUrl} target="_blank">
                {publicUrl}
              </PublicUrlLink>
            </FormGroup>
            <hr />
          </FormControl>
        ) : null}
        <Box
          sx={{
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
              display: 'grid',
            },
          }}
        >
          <HalfWidthField
            label={t(
              'Events.EventInformationField.FormField.Capacity',
              'Capacity'
            )}
            inputProps={{
              'aria-label': t(
                'Events.EventInformationField.AriaLabel.Capacity',
                'Capacity'
              ),
            }}
            variant="filled"
            value={capacity?.toString() || ''}
            name="capacity"
            onChange={handleInputChange}
            disabled={isFieldDisabled}
            error={!eventFieldValidity.maxCapacity.input}
            helperText={
              !eventFieldValidity.maxCapacity.input
                ? validationMessageMap.get(
                    EventFormValidationMessageTypes.MaxCapacity
                  )
                : null
            }
          />
          <HalfWidthField
            label={t(
              'Events.EventInformationField.FormField.EventLocationType',
              'Event Location Type'
            )}
            inputProps={{
              'aria-label': t(
                'Events.EventInformationField.AriaLabel.EventLocationType',
                'Event Location Type'
              ),
            }}
            variant="filled"
            disabled={isFieldDisabled}
            select={!isFieldDisabled}
            value={eventLocationType}
            name="eventLocationType"
            onChange={handleInputChange}
            error={!eventFieldValidity.eventLocationType.input}
            helperText={
              !eventFieldValidity.eventLocationType.input
                ? validationMessageMap.get(
                    EventFormValidationMessageTypes.EventLocationType
                  )
                : null
            }
          >
            {eventLocationTypeOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </HalfWidthField>
          {possibleNewOwners && possibleNewOwners.length > 0 && (
            <HalfWidthField
              label={t(
                'Events.EventInformationField.FormField.PossibleNew',
                'Event Owner'
              )}
              inputProps={{
                'aria-label': t(
                  'Events.EventInformationField.AriaLabel.PossibleNew',
                  'Event Owner'
                ),
              }}
              variant="filled"
              disabled={isFieldDisabled}
              select={!isFieldDisabled}
              SelectProps={{ onChange: onOwnerChange }}
              name="eventOwner"
              value={owner}
            >
              {sortedPossibleNewOwners.map((option) => (
                <MenuItem key={option.actorKey} value={`${option.user}`}>
                  {`${option.user} / ${option.supervisorUser} / ${option.role} `}
                </MenuItem>
              ))}
            </HalfWidthField>
          )}
        </Box>
      </Box>
    </EventSection>
  )
}

export default EventInformationFields
